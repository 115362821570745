import React, { useState } from 'react';
import {
  ArrayPath,
  Control,
  FieldArray,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
  useFieldArray,
} from 'react-hook-form';
import { Chip, ChipContainer, ChipInput, ChipsHolder, Hint, InputHeader } from './inputChips.styles';
import { AnimatePresence } from 'framer-motion';
import { FiX } from 'react-icons/fi';
import { InputError, StyledInput } from '../inputs/textInput/textInput.styles';

interface InputChipsProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>,
> {
  control: Control<TFieldValues, string>;
  name: TName;
  removeCallback?: (i: number) => void;
  addCallback?: (v: string) => void;
  header: string;
  label: string;
  register: UseFormRegister<TFieldValues>;
  required: RegisterOptions<TFieldValues>;
  error?: FieldError;
  disabled?: boolean;
}

export const InputChips = <TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  removeCallback,
  addCallback,
  header,
  label,
  register,
  required,
  error,
  disabled,
}: InputChipsProps<TFieldValues>) => {
  const [value, setValue] = useState<string>('');
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name,
  });
  const isInArr = (val: string) => {
    const found = fields.some((option) => option.id === val);
    if (found) {
      return true;
    }
    return false;
  };
  const removeItem = (index: number, additionalRemoveCallback?: (i: number) => void) => {
    if (disabled) {
      return;
    }
    if (index === 0 && fields.length === 1) {
      replace([]);
      if (additionalRemoveCallback) {
        additionalRemoveCallback(index);
      }
    } else {
      remove(index);
      if (additionalRemoveCallback) {
        additionalRemoveCallback(index);
      }
    }
  };
  const onKeyPressEvent = (
    event: React.KeyboardEvent<HTMLInputElement>,
    additionalAddCallback?: (v: string) => void
  ) => {
    if (event.keyCode === 13) {
      if (isInArr(value.toUpperCase())) {
        return;
      }
      append({
        value: value.toUpperCase(),
      } as unknown as FieldArray<TFieldValues, ArrayPath<TFieldValues>>[]);
      if (additionalAddCallback) {
        additionalAddCallback(value.toUpperCase());
      }
      setValue('');
    }
  };
  return (
    <>
      <ChipContainer>
        <InputHeader $isRequired={required !== undefined}>{header}</InputHeader>
        <StyledInput
          disabled={disabled ?? false}
          style={{ width: '100%' }}
          $hasError={false}
          $align="left"
          placeholder={label}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.currentTarget.value)}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyPressEvent(e, addCallback)}
        />
        <ChipsHolder>
          <AnimatePresence>
            {fields.length > 0 &&
              fields.map((item, index) => {
                return (
                  <Chip
                    key={item.id}
                    onClick={() => removeItem(index, removeCallback)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    {item['value' as 'id']}
                    <ChipInput
                      defaultValue={item['value' as 'id']}
                      {...(register ? register(`${name}[${index}].value` as Path<TFieldValues>, required) : {})}
                      readOnly
                    />
                    {!disabled && <FiX />}
                  </Chip>
                );
              })}
          </AnimatePresence>
          {fields.length === 0 && <Hint>* press enter to add item</Hint>}
        </ChipsHolder>
      </ChipContainer>
      {error && <InputError>{error?.message}</InputError>}
    </>
  );
};
