import { OptionList } from '../utils/props';

export const ethnicityList = [
  {
    label: 'Asian or Asian British',
    value: 'ASIAN',
  },
  {
    label: 'Asian or Asian British (Bangladeshi)',
    value: 'ASIAN-BANGLADESHI',
  },
  {
    label: 'Asian or Asian British (Indian)',
    value: 'ASIAN-INDIAN',
  },
  {
    label: 'Asian or Asian British (Other)',
    value: 'ASIAN-OTHER',
  },
  {
    label: 'Asian or Asian British (Pakistani)',
    value: 'ASIAN-PAKISTANI',
  },
  {
    label: 'Black',
    value: 'BLACK',
  },
  {
    label: 'Black or Black British (African)',
    value: 'BLACK-AFRICAN',
  },
  {
    label: 'Black or Black British (Caribbean)',
    value: 'BLACK-CARIBBEAN',
  },
  {
    label: 'Black or Black British (Other)',
    value: 'BLACK-OTHER',
  },
  {
    label: 'Chinese',
    value: 'CHINESE',
  },
  {
    label: 'Chinese or other ethnic groups (Other)',
    value: 'CHINESE-OTHER',
  },
  {
    label: 'Mixed',
    value: 'MIXED',
  },
  {
    label: 'Mixed (Other)',
    value: 'MIXED-OTHER',
  },
  {
    label: 'Mixed (White and Asian)',
    value: 'MIXED-WHITE-ASIAN',
  },
  {
    label: 'Mixed (White and Black African)',
    value: 'MIXED-WHITE-BLACK-AFRICAN',
  },
  {
    label: 'Mixed (White and Black Caribbean)',
    value: 'MIXED-WHITE-BLACK-CARIBBEAN',
  },
  {
    label: 'White',
    value: 'WHITE',
  },
  {
    label: 'White British',
    value: 'WHITE-BRITISH',
  },
  {
    label: 'White Irish',
    value: 'WHITE-IRISH',
  },
  {
    label: 'White Other',
    value: 'WHITE-OTHER',
  },
  {
    label: 'Decline to answer',
    value: 'DECLINE-TO-ANSWER',
  },
];

export const tagOptions = [
  {
    label: 'Opted for career boost',
    value: 'OPTED-FOR-CAREER-BOOST',
  },
  {
    label: 'Part exchange',
    value: 'PART-EXCHANGE',
  },
  {
    label: 'Career boost',
    value: 'CAREER-BOOST',
  },
];

export const PROFILE_COMPLETE = 'COMPLETE';
export const PROFILE_INCOMPLETE = 'INCOMPLETE';

export const signonSources: OptionList[] = [
  {
    label: 'Bing',
    value: 'BING',
  },
  {
    label: 'Friends/Family',
    value: 'FRIENDS-FAMILY',
  },
  {
    label: 'Facebook',
    value: 'FACEBOOK',
  },
  {
    label: 'Youtube',
    value: 'YOUTUBE',
  },
  {
    label: 'Uber',
    value: 'UBER',
  },
  {
    label: 'Billboards',
    value: 'BILLBOARDS',
  },
  {
    label: 'Bus advert',
    value: 'BUS-ADVERTS',
  },
  {
    label: 'Google',
    value: 'GOOGLE',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const penaltyCodes: OptionList[] = [
  { value: 'ac10', label: 'AC10' },
  { value: 'ac20', label: 'AC20' },
  { value: 'ac30', label: 'AC30' },
  { value: 'ba10', label: 'BA10' },
  { value: 'ba30', label: 'BA30' },
  { value: 'ba40', label: 'BA40' },
  { value: 'ba60', label: 'BA60' },
  { value: 'cd10', label: 'CD10' },
  { value: 'cd20', label: 'CD20' },
  { value: 'cd30', label: 'CD30' },
  { value: 'cd33', label: 'CD33' },
  { value: 'cd40', label: 'CD40' },
  { value: 'cd50', label: 'CD50' },
  { value: 'cd60', label: 'CD60' },
  { value: 'cd70', label: 'CD70' },
  { value: 'cd80', label: 'CD80' },
  { value: 'cd90', label: 'CD90' },
  { value: 'cu10', label: 'CU10' },
  { value: 'cu20', label: 'CU20' },
  { value: 'cu30', label: 'CU30' },
  { value: 'cu40', label: 'CU40' },
  { value: 'cu50', label: 'CU50' },
  { value: 'cu80', label: 'CU80' },
  { value: 'dd10', label: 'DD10' },
  { value: 'dd40', label: 'DD40' },
  { value: 'dd60', label: 'DD60' },
  { value: 'dd80', label: 'DD80' },
  { value: 'dd90', label: 'DD90' },
  { value: 'dr10', label: 'DR10' },
  { value: 'dr20', label: 'DR20' },
  { value: 'dr30', label: 'DR30' },
  { value: 'dr31', label: 'DR31' },
  { value: 'dr61', label: 'DR61' },
  { value: 'dr40', label: 'DR40' },
  { value: 'dr50', label: 'DR50' },
  { value: 'dr60', label: 'DR60' },
  { value: 'dr70', label: 'DR70' },
  { value: 'dg10', label: 'DG10' },
  { value: 'dg40', label: 'DG40' },
  { value: 'dg60', label: 'DG60' },
  { value: 'dg70', label: 'DG70' },
  { value: 'dg80', label: 'DG80' },
  { value: 'dg90', label: 'DG90' },
  { value: 'in10', label: 'IN10' },
  { value: 'lc20', label: 'LC20' },
  { value: 'lc30', label: 'LC30' },
  { value: 'lc40', label: 'LC40' },
  { value: 'lc50', label: 'LC50' },
  { value: 'mr09', label: 'MR09' },
  { value: 'mr19', label: 'MR19' },
  { value: 'mr29', label: 'MR29' },
  { value: 'mr39', label: 'MR39' },
  { value: 'mr49', label: 'MR49' },
  { value: 'mr59', label: 'MR59' },
  { value: 'ms10', label: 'MS10' },
  { value: 'ms20', label: 'MS20' },
  { value: 'ms30', label: 'MS30' },
  { value: 'ms50', label: 'MS50' },
  { value: 'ms60', label: 'MS60' },
  { value: 'ms70', label: 'MS70' },
  { value: 'ms80', label: 'MS80' },
  { value: 'ms90', label: 'MS90' },
  { value: 'mw10', label: 'MW10' },
  { value: 'pc10', label: 'PC10' },
  { value: 'pc20', label: 'PC20' },
  { value: 'pc30', label: 'PC30' },
  { value: 'sp10', label: 'SP10' },
  { value: 'sp20', label: 'SP20' },
  { value: 'sp30', label: 'SP30' },
  { value: 'sp40', label: 'SP40' },
  { value: 'sp50', label: 'SP50' },
  { value: 'ts10', label: 'TS10' },
  { value: 'ts20', label: 'TS20' },
  { value: 'ts30', label: 'TS30' },
  { value: 'ts40', label: 'TS40' },
  { value: 'ts50', label: 'TS50' },
  { value: 'ts60', label: 'TS60' },
  { value: 'ts70', label: 'TS70' },
  { value: 'tt99', label: 'TT99' },
  { value: 'ut50', label: 'UT50' },
];

export const GOOD_DRIVER = 'Good driver';
export const TAG_ALL_IN_ORDER = 'All in order';
export const TAG_LOW_RISK = 'Low risk';
export const TAG_NO_RISK = 'No risk';
export const TAG_STATUS_RESOLVED = 'RESOLVED';

export const ORDWAY_CUSTOMER_PATH = `${process.env.REACT_APP_ORDWAY_BASE_URL}customers`;
export const ORDWAY_SUBSCRIPTION_PATH = `${process.env.REACT_APP_ORDWAY_BASE_URL}subscriptions`;
