import { Controller, FieldValues, UseFormReturn, Path, FieldErrors, PathValue } from 'react-hook-form';
import { TextFieldLabel } from '../../inputs/textField/textField.styles';
import { Container, ErrorMessage, StyledWrapper } from './dateTimePicker.styles';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';

interface DateTimePickerProps<T extends FieldValues> {
  control: UseFormReturn<T>['control'];
  errors?: FieldErrors<T>;
  name: Path<T>;
  label: string;
  rules?: {
    required: string;
  };
  defaultSelectedDate?: Date;
  disabled?: boolean;
}

export function DateTimePickerComponent<T extends FieldValues>({
  control,
  name,
  label,
  rules,
  errors,
  defaultSelectedDate,
  disabled,
}: DateTimePickerProps<T>): JSX.Element {
  return (
    <Container>
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={
          defaultSelectedDate !== null ? (moment(defaultSelectedDate).toDate() as PathValue<T, Path<T>>) : undefined
        }
        render={({ field: { onChange, value } }) => (
          <StyledWrapper $hasError={errors != null} $disabled={disabled ?? false}>
            <TextFieldLabel $isRequired={rules?.required !== undefined}>{label}</TextFieldLabel>
            <DateTimePicker
              disabled={disabled ?? false}
              onChange={(date) => onChange(date)}
              value={value}
              clearIcon={null}
              calendarIcon={null}
              format="y-MM-dd HH:mm"
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              hourPlaceholder="-- "
              minutePlaceholder=" --" // added extra space for better effect
            />
          </StyledWrapper>
        )}
      />
      <ErrorMessage>{errors && errors.message}</ErrorMessage>
    </Container>
  );
}
