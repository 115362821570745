import React, { useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DriverDetailsForm } from './formsAndDocs/driverDetailsForm';
import { DriverAdditionalDetailsForm } from './formsAndDocs/driverAdditionalDetailsForm';
import { DriverDocs } from './formsAndDocs/driverDocs';
import { SectionHeader } from '../sectionHeader';
import { DriverDetailsGrid, Separator } from '../../order/order.styles';
import { SectionProps } from '../../order/order';
import { DRIVERS } from '../../../../consts/routes';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { Spinner } from '../../../../uiComponents/uiControls/spinner/spinner';
import { getDriverByDVLA } from '../../../../api/get/driver.get';
import { submitAdditionalDriverDetails } from '../../../../api/post/driver.post';
import { PRIMARY_GREEN } from '../../../../common/styles/Colors';
import { CustomerProfile } from '../../../../models/driver';
import { fileToBase64, renderNotification, getBlob } from '../../../../utils/utils';
import { handleAPIError, ErrorType } from '../../../../utils/handleAPIError';
import { createDocument } from '../../../../api/post/document.post';
import { Notification } from '../../../../uiComponents/toast/toast';

enum DriverDocumentType {
  DVLA_FRONT = 'DVLA-FRONT',
  DVLA_BACK = 'DVLA-BACK',
  PCO = 'PCO',
  PCO_CERT = 'PCO-CERT',
  POA = 'POA',
}

interface IDriverDocument {
  documentType: DriverDocumentType;
  id?: string;
  contents?: string | null;
  type: string | null;
  s3_url?: string;
  expiry_date?: string;
}

export const DriverDetailsSection = ({ isComplete, isLocked, progress, checkProgress }: SectionProps) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(isComplete);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const {
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    reset,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CustomerProfile>({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const dvlaBackImage = watch('dvla_back') as Blob | File;
  const dvlaBackUrl: string | undefined = watch('dvla_back_s3_url');
  const dvlaFrontImage = watch('dvla_front') as Blob | File;
  const dvlaFrontUrl: string | undefined = watch('dvla_front_s3_url');
  const pcoImage = watch('pco') as Blob | File;
  const pcoUrl: string | undefined = watch('pco_s3_url');
  const pcoCertImage = watch('pco_cert') as Blob | File;
  const pcoCertUrl: string | undefined = watch('pco_cert_s3_url');
  const poaImage = watch('poa') as Blob | File;
  const poaUrl: string | undefined = watch('poa_s3_url');

  const pcoNo: string = watch('pco_no') || '';
  const dvlaExpiry: string = watch('dvla_expiry_date') || '';
  const pcoCertExpiry: string | undefined = watch('pco_cert_expiry_date');
  const pcoExpiry: string | undefined = watch('pco_expiry_date');
  const poaExpiry: string | undefined = watch('poa_expiry_date');
  const addressLine1: string | undefined = watch('address_line_1');
  const addressPostCode: string | undefined = watch('address_post_code');
  const addressCity: string | undefined = watch('address_city');

  const pcoNoValid: boolean = pcoNo !== '' && !errors?.pco_no;
  const dvlaExpiryValid: boolean = dvlaExpiry !== '' && !errors?.dvla_expiry_date;
  const pcoExpiryValid: boolean = pcoExpiry !== '' && !errors?.pco_expiry_date;
  const pcoCertExpiryValid: boolean = pcoCertExpiry !== '' && !errors?.pco_cert_expiry_date;
  const poaExpiryValid: boolean = poaExpiry !== '' && !errors?.poa_expiry_date;
  const addressValid: boolean = addressLine1 !== '' && !errors?.address_line_1;
  const cityValid: boolean = addressCity !== '' && !errors?.address_city;
  const postCodeValid: boolean = addressPostCode !== '' && !errors?.address_post_code;

  const canSubmit =
    (dvlaBackImage || dvlaBackUrl) &&
    (dvlaFrontImage || dvlaFrontUrl) &&
    (pcoCertImage || pcoCertUrl) &&
    (pcoImage || pcoUrl) &&
    (poaImage || poaUrl) &&
    pcoNoValid &&
    dvlaExpiryValid &&
    pcoExpiryValid &&
    pcoCertExpiryValid &&
    poaExpiryValid &&
    addressValid &&
    cityValid &&
    postCodeValid;

  const getImages = async (): Promise<IDriverDocument[]> => {
    const {
      dvla_front_id,
      dvla_front: dvlaFront,
      dvla_back_id,
      dvla_back: dvlaBack,
      dvla_expiry_date,
      pco_id,
      pco,
      pco_cert_id,
      pco_cert: pcoCert,
      poa_id,
      poa,
      poa_expiry_date,
      pco_expiry_date,
      pco_cert_expiry_date,
    }: CustomerProfile = getValues();
    return [
      {
        documentType: DriverDocumentType.DVLA_FRONT,
        type: dvlaFront?.type || null,
        contents: dvlaFront ? await fileToBase64(dvlaFront as File) : null,
        expiry_date: dvla_expiry_date,
        id: dvla_front_id,
      },
      {
        documentType: DriverDocumentType.DVLA_BACK,
        type: dvlaBack?.type || null,
        contents: dvlaBack ? await fileToBase64(dvlaBack as File) : null,
        expiry_date: dvla_expiry_date,
        id: dvla_back_id,
      },
      {
        documentType: DriverDocumentType.PCO,
        type: pco?.type || null,
        contents: pco ? await fileToBase64(pco as File) : null,
        expiry_date: pco_expiry_date,
        id: pco_id,
      },
      {
        documentType: DriverDocumentType.PCO_CERT,
        type: pcoCert?.type || null,
        contents: pcoCert ? await fileToBase64(pcoCert as File) : null,
        expiry_date: pco_cert_expiry_date,
        id: pco_cert_id,
      },
      {
        documentType: DriverDocumentType.POA,
        type: poa?.type || null,
        contents: poa ? await fileToBase64(poa as File) : null,
        expiry_date: poa_expiry_date,
        id: poa_id,
      },
    ];
  };

  const populateFields = useCallback(
    async (profile: CustomerProfile) => {
      setValue('driver_id', profile?.id);
      setValue('dvla_test_date', profile?.dvla_test_date ? moment(profile?.dvla_test_date)?.format('YYYY-MM-DD') : '');
      setValue(
        'dvla_expiry_date',
        profile?.dvla_expiry_date ? moment(profile?.dvla_expiry_date)?.format('YYYY-MM-DD') : ''
      );
      setValue('address_line_1', profile?.address_line_1 ? profile?.address_line_1 : '');
      setValue('address_post_code', profile?.address_post_code);
      setValue('address_city', profile?.address_city);
      setValue('dvla_front_id', profile?.dvla_front_id);
      setValue('dvla_back_id', profile?.dvla_back_id);
      setValue('dvla_back', profile?.dvla_back);
      setValue('pco_id', profile?.pco_id);
      setValue('pco_no', profile?.pco_no);
      setValue(
        'pco_expiry_date',
        profile?.pco_expiry_date ? moment(profile?.pco_expiry_date)?.format('YYYY-MM-DD') : ''
      );
      setValue('pco_cert_id', profile?.pco_cert_id);
      setValue(
        'pco_cert_expiry_date',
        profile?.pco_cert_expiry_date ? moment(profile?.pco_cert_expiry_date)?.format('YYYY-MM-DD') : ''
      );
      setValue('poa_id', profile?.poa_id);
      setValue(
        'poa_expiry_date',
        profile?.poa_expiry_date ? moment(profile?.poa_expiry_date)?.format('YYYY-MM-DD') : ''
      );
      setValue('uber_rating', profile?.uber_rating ?? '');
      setValue('trips_last_30_days', profile?.trips_last_30_days ?? 0);
      setValue('total_trips', profile?.total_trips ?? 0);

      if (profile?.dvla_front_s3_url) {
        const blob = await getBlob(profile?.dvla_front_s3_url);
        setValue('dvla_front', blob);
      }
      if (profile?.dvla_back_s3_url) {
        const blob = await getBlob(profile?.dvla_back_s3_url);
        setValue('dvla_back', blob);
      }
      if (profile?.pco_s3_url) {
        const blob = await getBlob(profile?.pco_s3_url);
        setValue('pco', blob);
      }
      if (profile?.pco_cert_s3_url) {
        const blob = await getBlob(profile?.pco_cert_s3_url);
        setValue('pco_cert', blob);
      }
      if (profile?.poa_s3_url) {
        const blob = await getBlob(profile?.poa_s3_url);
        setValue('poa', blob);
      }
    },
    [setValue]
  );

  const getDriverByDvlaNumber = useCallback(
    (value: string) => {
      getDriverByDVLA(value).then(
        (response: { data: CustomerProfile; message: undefined } | { message: 'OK'; data: undefined }) => {
          if (response?.data) {
            populateFields(response?.data);
          } else {
            reset({
              dvla_no: value,
              dvla_test_date: '',
              dvla_expiry_date: '',
              pco_no: '',
              pco_expiry_date: '',
              pco_cert_expiry_date: '',
              poa_expiry_date: '',
              total_trips: 0,
              trips_last_30_days: 0,
            });
          }
        }
      );
    },
    [reset, populateFields]
  );

  const uploadDocuments = async (documents: IDriverDocument[], driverId: string) => {
    try {
      for await (const doc of documents) {
        await createDocument({
          document_type: doc.documentType,
          document_category: 'DRIVER',
          category_id: driverId,
          expiry_date: doc.expiry_date,
          file: {
            fileName: 'DRIVER',
            type: doc.type as string,
            contents: doc.contents ?? '',
            expiry_date: doc.expiry_date,
          },
        });
      }
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Upload failed',
        message: `${err}`,
      });
      return [];
    }
  };

  useEffect(() => {
    if (progress?.dvla_document_no) {
      getDriverByDvlaNumber(progress.dvla_document_no);
    }
  }, [getDriverByDvlaNumber, progress.dvla_document_no]);

  const onSubmit = async (submitValues: CustomerProfile) => {
    try {
      setSubmitLoading(true);
      const images = await getImages();
      await uploadDocuments(images, progress?.driver_id ?? '');
      const payload = {
        ...submitValues,
        dvla_no: progress?.dvla_document_no,
        app_id: progress?.application_id,
      };
      const { data } = await submitAdditionalDriverDetails(payload);
      data.driver_id
        ? renderNotification('success', 'Success', 'Driver details have been successfully submitted')
        : renderNotification('error', 'Error', 'Failed to submit driver details', false);
      setIsCollapsed(true);
      setSubmitLoading(false);
      checkProgress?.();
    } catch (err) {
      setSubmitLoading(false);
      handleAPIError(err as ErrorType);
    }
  };

  return (
    <CollapsiblePanel
      header={
        <SectionHeader
          title="Driver details"
          completedText="Completed"
          isComplete={isComplete}
          isLocked={isLocked}
          expanded={false}
        />
      }
      expanded={!isCollapsed}
      onCollapse={() => {
        setIsCollapsed(!isCollapsed);
      }}
      styled={{ marginTop: 16 }}
    >
      <div>
        <SectionHeader title="Driver details" isComplete={isComplete} isLocked={isLocked} expanded />
        <DriverDetailsGrid
          numColumns={5}
          headers={[
            'Title:',
            'First name:',
            'Middle name:',
            'Last name:',
            'Date of birth:',
            'Mobile phone:',
            'Email:',
            'Address line 1:',
            'Address line 2:',
            'City:',
            'Post code:',
            'National insurance number:',
          ]}
          values={[
            'Mr',
            progress?.driver_first_name,
            progress?.driver_middle_name || '-',
            progress?.driver_last_name,
            moment(progress?.driver_date_of_birth)?.format('DD MMM YYYY'),
            progress?.driver_mobile,
            progress?.email,
            progress?.address_line_1,
            progress?.address_line_2 || '-',
            progress?.address_city,
            progress?.post_code,
            progress?.national_insurance_number || '-',
          ]}
        />
        <FlexLayout itemsX="end" styled={{ marginTop: 24 }}>
          <PrimaryButton onClick={() => navigate(`${DRIVERS}/${progress?.driver_id}`)} styled={{ width: 192 }}>
            View driver
          </PrimaryButton>
        </FlexLayout>
        <DriverDetailsForm
          errors={errors}
          register={register}
          setError={(name, msg) => setError(name, msg)}
          clearErrors={(name) => clearErrors(name)}
          setValue={(name, value) => setValue(name, value)}
          control={control}
          isComplete={isDisabled}
          driverData={{
            driver_id: progress.driver_id,
            address_line_1: progress.address_line_1,
            address_city: progress.address_city,
            post_code: progress.post_code,
          }}
        />
        <DriverDocs
          control={control}
          errors={errors}
          dvlaBackUrl={dvlaBackUrl ?? ''}
          dvlaFrontUrl={dvlaFrontUrl ?? ''}
          pcoUrl={pcoUrl ?? ''}
          pcoCertUrl={pcoCertUrl ?? ''}
          poaUrl={poaUrl ?? ''}
          isComplete={isDisabled}
        />
        <Separator />
        <DriverAdditionalDetailsForm errors={errors} register={register} isComplete={isDisabled} />
        <FlexLayout styled={{ marginTop: 27 }} itemsX="end" gap={16}>
          {isDisabled && <SecondaryButton onClick={() => setIsDisabled(false)}>Resubmit</SecondaryButton>}
          <PrimaryButton
            styled={{ minWidth: 186, height: 40 }}
            disabled={!canSubmit || isDisabled}
            onClick={handleSubmit(onSubmit)}
          >
            {submitLoading ? <Spinner size={20} color={PRIMARY_GREEN} /> : 'Submit'}
          </PrimaryButton>
        </FlexLayout>
      </div>
    </CollapsiblePanel>
  );
};
