import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyledToastContainer } from './appWrapper.styles';
import { PulseRoutes } from '../app/app';
import { AppContextProvider } from '../../utils/context';

export const AppWrapper = () => {
  const [pageTitle, setPageTitle] = useState('');
  const [activeSideNav, setActiveSideNav] = useState('');
  const [permissions, setPermissions] = useState({});
  const [superAdmin, setSuperAdmin] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [showShadow, setShowShadow] = useState<boolean>(false);
  const contextValue = {
    pageTitle,
    setPageTitle,
    activeSideNav,
    setActiveSideNav,
    permissions,
    setPermissions,
    superAdmin,
    setSuperAdmin,
    userId,
    setUserId,
    userName,
    setUserName,
    showShadow,
    setShowShadow,
  };

  return (
    <AppContextProvider value={contextValue}>
      <StyledToastContainer theme="colored" className="toastComponent" />
      <Router>
        <PulseRoutes />
      </Router>
    </AppContextProvider>
  );
};
