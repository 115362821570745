import Compressor from 'compressorjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiUploadCloud, FiCamera, FiImage } from 'react-icons/fi';
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import {
  STATUS_RED,
  SECONDARY_PURPLE_30,
  PRIMARY_WHITE,
  PRIMARY_PURPLE,
  PRIMARY_GREEN,
} from '../../../common/styles/Colors';
import { withStyledProps } from '../../../utils/colorUtils';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import {
  FakeButton,
  IconContainer,
  Image,
  UploadBackgroundImage,
  UploadImageText,
  UploadLabel,
  UploaderContainer,
  UploaderText,
  UploaderTitle,
} from './uploaderInput.styles';
import { Text } from '../../text/text';
import { BsPersonPlus } from 'react-icons/bs';
import { Notification } from '../../toast/toast';

const excelFileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

const compressImage = (img: File, callback: (result: File | Blob) => void) => {
  if (img.type !== 'application/pdf' && !excelFileType.includes(img.type)) {
    new Compressor(img, {
      quality: 0.6,
      convertTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'],
      convertSize: 52428,
      height: 2038,
      width: 1024,
      resize: 'none',
      success(result) {
        callback(result);
      },
    });
  }
  callback(img);
};

interface UploaderProps {
  defaultValue?: string;
  disabled?: boolean;
  name: string;
  error?: FieldError;
  onChange: (value: File | Blob) => void;
  value?: Blob | MediaSource;
  label: string;
  invalid?: boolean;
  required?: boolean;
  isExcelFile?: string[];
  isProfilePicture?: boolean;
  isImageOnly?: boolean;
  hideRequiredIndicator?: boolean;
  loading?: boolean;
  aditionalInfo?: string;
}

const Uploader = React.forwardRef<HTMLInputElement, UploaderProps>(
  (
    {
      defaultValue,
      disabled,
      loading,
      label,
      hideRequiredIndicator,
      name,
      error,
      onChange,
      invalid,
      value,
      required,
      isExcelFile,
      isProfilePicture,
      isImageOnly,
      aditionalInfo,
    }: UploaderProps,
    ref
  ) => {
    const [newFile, setNewFile] = useState<boolean>(true);
    const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({
      accept: isExcelFile
        ? { 'application/vnd.ms-excel': isExcelFile ?? ['.xlsx'] }
        : isImageOnly
          ? { 'image/*': ['.jpeg', '.jpg', '.png'] }
          : {
              'image/*': ['.jpeg', '.jpg', '.png'],
              'application/pdf': ['.pdf'],
            },
      multiple: false,
      maxSize: 5242880, // 5242880 5MB // 4194304 4MB // 3145728 3MB // 2097152 2MB
      onDrop: () => setNewFile(true),
      onFileDialogOpen: () => setNewFile(true),
    });

    useEffect(() => {
      if (fileRejections && fileRejections.length > 0) {
        Notification({
          type: 'error',
          title: 'Upload failed',
          message: 'File too big (5MB limit) or incorrect format',
          isAlert: true,
        });
      }
    }, [fileRejections]);

    const updateImage = useCallback(
      (imageToCompress: File) => {
        if (newFile) {
          setNewFile(false);
          compressImage(imageToCompress, (image: File | Blob) => {
            onChange?.(image);
          });
        }
      },
      [onChange, newFile]
    );

    useEffect(() => {
      if (acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[0].type !== 'application/vnd.ms-excel') {
        updateImage(acceptedFiles[0]);
      }
    }, [acceptedFiles, updateImage]);

    return (
      <div>
        <UploadLabel>
          {label}
          {aditionalInfo && <span style={{ marginLeft: '5px', fontSize: '10px' }}>{aditionalInfo}</span>}
          {required && !hideRequiredIndicator && <span style={{ color: STATUS_RED, marginLeft: '5px' }}>*</span>}
        </UploadLabel>
        {isProfilePicture ? (
          <FlexLayout gap={16} vertical itemsX="center" {...getRootProps()}>
            <IconContainer
              itemsX="center"
              itemsY="center"
              styled={{ cursor: defaultValue ? 'pointer' : 'default' }}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                e.stopPropagation();
                defaultValue ? window.open(defaultValue, '_blank') : null;
              }}
            >
              {acceptedFiles?.length > 0 || defaultValue ? (
                <Image
                  alt="profile-picture"
                  src={acceptedFiles?.[0] ? URL.createObjectURL(acceptedFiles[0]) : defaultValue ?? ''}
                />
              ) : (
                <BsPersonPlus size={50} color={PRIMARY_WHITE} />
              )}
            </IconContainer>
            <FakeButton variant="body7" color={PRIMARY_WHITE} weight={300}>
              Upload image
            </FakeButton>
            <input type="file" ref={ref} {...getInputProps()} name={name} />
          </FlexLayout>
        ) : isImageOnly ? (
          <UploaderContainer
            $error={invalid}
            $active={!!value || !!defaultValue}
            $disabled={disabled || loading}
            {...getRootProps()}
          >
            {!value && !defaultValue && (
              <FlexLayout vertical={false} styled={{ height: '100%' }} itemsX="start" itemsY="center">
                <FiCamera size={52} color={SECONDARY_PURPLE_30} />
                <UploadImageText>
                  <input type="file" ref={ref} {...getInputProps()} name={name} />
                  <UploaderTitle>Upload your image</UploaderTitle>
                  <UploaderText>Drag and drop or take a snapshot</UploaderText>
                </UploadImageText>
              </FlexLayout>
            )}
            {(value || defaultValue) && (
              <FlexLayout vertical={false} styled={{ height: '100%' }} itemsX="center" itemsY="center">
                <FiImage size={52} color={PRIMARY_GREEN} />
              </FlexLayout>
            )}
          </UploaderContainer>
        ) : (
          <UploaderContainer
            $error={invalid}
            $active={!!value || !!defaultValue}
            $disabled={disabled || loading}
            {...getRootProps()}
          >
            {!value && !defaultValue && (
              <FlexLayout vertical={false} styled={{ height: '100%' }} itemsX="center" itemsY="center">
                <FiUploadCloud size={52} color={SECONDARY_PURPLE_30} />
                <UploadImageText>
                  <UploaderTitle>Upload your file or image</UploaderTitle>
                  <UploaderText>Drag and drop or click to select file</UploaderText>
                </UploadImageText>
              </FlexLayout>
            )}
            {(value || defaultValue) && (
              <UploadBackgroundImage
                draggable="false"
                alt={isExcelFile ? 'Upload your file' : 'Upload your image'}
                width={40}
                src={value ? URL.createObjectURL(value) : defaultValue}
                $visible={!!value ?? false}
                $active={!!value || !!defaultValue}
              />
            )}
            {defaultValue && (
              <Text variant="body7" weight={500} color={PRIMARY_PURPLE}>
                <a href={defaultValue} target="_blank" rel="noopener noreferrer">
                  View
                </a>
              </Text>
            )}
            <UploadImageText>
              <input type="file" defaultValue={defaultValue} ref={ref} {...getInputProps()} name={name} />
              {value && !defaultValue && <UploaderText>{name}</UploaderText>}
              {!value && defaultValue && (
                <>
                  <UploaderText>{defaultValue}</UploaderText>
                  <UploaderText>click to replace</UploaderText>
                </>
              )}
            </UploadImageText>
          </UploaderContainer>
        )}
        {error && (
          <Text variant="body7" color={STATUS_RED} weight={300}>
            {error?.message ?? ''}
          </Text>
        )}
      </div>
    );
  }
);

interface UploaderInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> {
  disabled?: boolean;
  loading?: boolean;
  label: string;
  control: Control<TFieldValues, string>;
  name: TName;
  defaultValue?: TName;
  isExcelFile?: string[];
  isProfilePicture?: boolean;
  isImageOnly?: boolean;
  required?: boolean;
  error?: FieldError;
  hideRequiredIndicator?: boolean;
  aditionalInfo?: string;
}

export const UploaderInput = withStyledProps(
  <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>({
    defaultValue,
    disabled,
    label,
    name,
    required,
    loading,
    isExcelFile,
    isProfilePicture,
    isImageOnly,
    control,
    error,
    aditionalInfo,
    ...props
  }: UploaderInputProps<TFieldValues, TName>) => {
    return (
      <Controller
        control={control}
        rules={{
          required: required ? 'Please upload a file' : undefined,
        }}
        name={name}
        render={({ field: { value, onChange, name }, fieldState: { invalid } }) => {
          return (
            <div {...props}>
              <Uploader
                label={label}
                defaultValue={defaultValue}
                required={required}
                disabled={disabled}
                loading={loading}
                error={error}
                invalid={invalid}
                isExcelFile={isExcelFile}
                isProfilePicture={isProfilePicture}
                isImageOnly={isImageOnly}
                value={value}
                aditionalInfo={aditionalInfo}
                {...{ onChange, name }}
              />
            </div>
          );
        }}
      />
    );
  }
);
