import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer, Sections } from '../../drivers/driver/driverPage/driverPage.styles';
import { VehicleAgreements } from '../vehicle/vehicleAgreements';
import { VehicleDetails } from '../vehicle/vehicleDetails';
import { VehicleInfoCard } from '../vehicle/vehicleInfoCard';
import { VehicleServicing } from '../vehicle/vehicleServicing';
import { VehicleTransfers } from '../vehicle/vehicleTransfers';
import { ENQUIRIES, OPERATIONS } from '../../../consts/department';
import {
  AFTERCARE_STATUS,
  ASSIGNED_STATUS,
  COMPLETED_STATUS,
  COURTESY_ASSIGNED_STATUS,
  DECLARATION_COMPLETED_STATUS,
  LIVE_STATUS,
  SCRIVE_SENT,
} from '../../../consts/status';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../uiComponents/modals/modal';
import { DocumentsTable } from '../../../uiComponents/table/documentTable/documentTable';
import { Notification } from '../../../uiComponents/toast/toast';
import { Spinner } from '../../../uiComponents/uiControls/spinner/spinner';
import { getVehicleById, sendPermissionLetterRequest } from '../../../api/get/vehicle.get';
import { Vehicle } from '../../../models/vehicle';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { Notes } from '../../../uiComponents/notes/notes';
import { PermissionLetter } from './permissionLetter';

export interface VehicleInfoProps {
  vehicle: Vehicle;
}

export const VehiclePage = () => {
  const { vehicleId } = useParams();
  const navigate = useNavigate();
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [isPermissionLetModalOpen, setIsPermissionLetModalOpen] = useState<boolean>(false);
  const currentDepartment = localStorage.getItem('department');
  const [requestingLetter, setRequestingLetter] = useState<boolean>(false);
  const permLetterApplicationStatuses: string[] = [DECLARATION_COMPLETED_STATUS, COMPLETED_STATUS, SCRIVE_SENT];
  const permLetterVehicleStatuses: string[] = [
    AFTERCARE_STATUS,
    ASSIGNED_STATUS,
    COURTESY_ASSIGNED_STATUS,
    LIVE_STATUS,
  ];
  const permLetterDepartments: string[] = [ENQUIRIES, OPERATIONS];
  const showGeneratePermissionLetterButton: boolean =
    vehicle?.vehicle_status === LIVE_STATUS ||
    vehicle?.vehicle_status === AFTERCARE_STATUS ||
    vehicle?.vehicle_status === COURTESY_ASSIGNED_STATUS ||
    (permLetterApplicationStatuses.includes(vehicle?.application_status ?? '') &&
      permLetterVehicleStatuses.includes(vehicle?.vehicle_status ?? '') &&
      currentDepartment != null &&
      permLetterDepartments.includes(currentDepartment ?? ''));

  const fetchVehicleData = useCallback(() => {
    if (vehicleId) {
      getVehicleById(vehicleId).then((response: { data: Vehicle }) => {
        setVehicle(response?.data);
      });
    }
  }, [vehicleId]);

  useEffect(() => fetchVehicleData(), [fetchVehicleData]);

  const onRequestPermLetterClick = async () => {
    if (vehicle?.application_id) {
      try {
        setRequestingLetter(true);
        const { data } = await sendPermissionLetterRequest(vehicle.application_id);
        setRequestingLetter(false);
        if (data.success) {
          Notification({
            type: 'success',
            title: 'Email status',
            message: data.message,
            isAlert: true,
          });
        }
      } catch (err) {
        Notification({
          type: 'error',
          title: 'Email status',
          message: `${err}`,
          isAlert: true,
        });
      }
    }
  };

  return (
    <>
      <PageContainer>
        <FlexLayout itemsX="end" gap={16} styled={{ marginBottom: 16 }}>
          {showGeneratePermissionLetterButton && (
            <SecondaryButton onClick={() => setIsPermissionLetModalOpen(true)}>
              Generate permission letter
            </SecondaryButton>
          )}
          {permLetterVehicleStatuses.includes(vehicle?.vehicle_status ?? '') &&
            !permLetterDepartments?.includes(currentDepartment ?? '') && (
              <SecondaryButton
                isProcessing={requestingLetter}
                onClick={() => {
                  onRequestPermLetterClick();
                }}
                styled={{ minWidth: 202 }}
              >
                Request permission letter
              </SecondaryButton>
            )}
          <PrimaryButton onClick={() => navigate(-1)}>Back</PrimaryButton>
        </FlexLayout>
        {vehicle ? (
          <FlexLayout itemsY="start" gap={16}>
            <FlexLayout vertical gap={16} styled={{ width: 440 }}>
              <VehicleInfoCard vehicle={vehicle} />
              <Notes categoryId={vehicle.vehicle_id} />
            </FlexLayout>
            <Sections>
              <VehicleDetails vehicle={vehicle} fetchVehicleData={() => fetchVehicleData()} />
              <VehicleAgreements vehicle={vehicle} />
              <DocumentsTable
                categoryId={vehicle?.vehicle_id}
                documentCategory="VEHICLE"
                tableHeader="Vehicle documents"
                embedded
              />
              <VehicleTransfers vehicle={vehicle} />
              <VehicleServicing vehicle={vehicle} />
            </Sections>
          </FlexLayout>
        ) : (
          <FlexLayout itemsX="center">
            <Spinner size={32} color={PRIMARY_PURPLE} />
          </FlexLayout>
        )}
      </PageContainer>
      <Modal
        styled={{ width: '80%' }}
        title="Generate permission letter"
        open={isPermissionLetModalOpen}
        showClose
        onClose={() => setIsPermissionLetModalOpen(false)}
      >
        <>
          {isPermissionLetModalOpen && (
            <PermissionLetter getData={fetchVehicleData} setOpenPermission={setIsPermissionLetModalOpen} />
          )}
        </>
      </Modal>
    </>
  );
};
