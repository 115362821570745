import { Congestion } from '../../models/congestion';
import api from '../../utils/api';

export const uploadCongestionCharges = (payload: { contents: string }) =>
  api.post('additional-charge/congestion/upload', payload);

export const updateCongestion = (id: string, payload: { vrm: string; driver_id: string | null }) =>
  api.patch(`additional-charge/congestion/${id}`, payload);

export const updatePendingCongestionChargesStatus = (payload: { congestionData: Congestion[]; status: string }) =>
  api.post('additional-charge/congestion/multipleUpdate', payload);
