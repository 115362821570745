export const PRIMARY_PURPLE = '#230f58';
export const PRIMARY_GREEN = '#37d32f';
export const PRIMARY_GRAY = '#314049';
export const PRIMARY_WHITE = '#ffffff';
export const PRIMARY_BLACK = '#000000';
export const SECONDARY_PURPLE_5 = '#f4f3f7';
export const SECONDARY_PURPLE_30 = '#bdb7cd';
export const SECONDARY_PURPLE_70 = '#65578a';
export const SECONDARY_PURPLE_90 = '#392769';
export const SECONDARY_GREEN_30 = '#c3f2c1';
export const SECONDARY_GREEN_90 = '#4bd744';
export const SECONDARY_GRAY_20 = '#d6d9db';
export const SECONDARY_GRAY_50 = '#989fa4';
export const SECONDARY_GRAY_80 = '#5a666d';
export const GRAY_1 = '#eeeff0';
export const GRAY_2 = '#d9dcdd';
export const GRAY_3 = '#c4c8cb';
export const GRAY_4 = '#afb5b8';
export const GRAY_5 = '#9aa1a6';
export const GRAY_6 = '#858e93';
export const GRAY_7 = '#707a81';
export const GRAY_8 = '#5b676e';
export const GRAY_9 = '#46535c';
export const GRAY_10 = '#adacb7';
export const STATUS_ORANGE = '#ff7410';
export const SECONDARY_ORANGE = '#ffd3b4';
export const STATUS_RED = '#fa1919';
export const SECONDARY_RED = '#ffd9d9';
export const STATUS_BLUE = '#18acff';
export const STATUS_YELLOW = '#ffcc18';
export const STATUS_YELLOW_OPACITY = '#ffcc1820';
export const STATUS_BLUE_OPACITY = '#18acff20';
