import styled from 'styled-components';
import { AiFillCheckCircle } from 'react-icons/ai';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../common/styles/Colors';

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Eligibility = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
`;

export const ConfirmationCircle = styled(AiFillCheckCircle)`
  overflow: visible;
`;

export const EligibilityText = styled.span`
  font-size: 14px;
  margin-left: 10px;
`;

export const ViolationText = styled.div`
  font-size: 12px;
  color: ${STATUS_RED};
`;

export const Errors = styled.div`
  margin: 20px 0 20px 0;
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ValidationText = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  color: ${PRIMARY_PURPLE};
`;

export const AprovalNeededReasonSection = styled.div`
  margin: 20px 0 20px 0;
`;
