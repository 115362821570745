import React, { useContext, useEffect, useRef } from 'react';
import { Routes as AppRoutes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import { Div, ChildRouteContainer, Ref, RouteContainer } from './app.styles';
import { PageRoutesSelector } from '../pageRoutesSelector/pageRoutesSelector';
import { PageTitle } from '../pageTitle/pageTitle';
import { OttoPulseAdminRoutes, PulseAdminChildRoute, PulseAdminRoutes } from '../routes';
import { useInView } from '../../hooks/useInView';
import { Login } from '../../pages/login/login';
import { LoginLayout } from '../../pages/login/loginLayout';
import { APP_CONTEXT } from '../../utils/context';
import { NavTray } from '../navTray/navTray';
import {
  ADDITIONAL_CHARGES,
  ADMIN,
  AFTERCARE,
  AGREEMENTS,
  CREATE_TRANSFER,
  DASHBOARD,
  DRIVERS,
  NEW_DRIVER,
  SALES,
  SERVICING,
  TRANSFERS,
  VEHICLES,
  VEHICLE_BATCHES,
  VEHICLE_TYPES,
  INSURANCE,
} from '../../consts/routes';
import { CLAIMS } from '../../consts/department';

const getPageId = (pathName: string) => {
  if (pathName.includes(DASHBOARD)) {
    return 'dashboard';
  } else if (pathName.includes(SALES)) {
    return 'sales';
  } else if (pathName.includes(NEW_DRIVER)) {
    return 'createDriver';
  } else if (pathName.includes(`${DRIVERS}/`)) {
    return 'driverDetails';
  } else if (pathName.includes(DRIVERS)) {
    return 'driver';
  } else if (pathName.includes(`${AGREEMENTS}/`)) {
    return 'agreementDetails';
  } else if (pathName.includes(AGREEMENTS)) {
    return 'agreement';
  } else if (pathName.includes(AFTERCARE)) {
    return 'aftercare';
  } else if (pathName.includes(ADDITIONAL_CHARGES)) {
    return 'additionalCharges';
  } else if (pathName.includes(`${VEHICLES}/`)) {
    return 'vehicleDetails';
  } else if (pathName.includes(CREATE_TRANSFER)) {
    return 'conditionReport';
  } else if (pathName.includes(TRANSFERS)) {
    return 'transfers';
  } else if (pathName.includes(VEHICLE_TYPES)) {
    return 'vehicleTypes';
  } else if (pathName.includes(VEHICLE_BATCHES)) {
    return 'vehicleBatches';
  } else if (pathName.includes(VEHICLES)) {
    return 'vehicles';
  } else if (pathName.includes(ADMIN)) {
    return 'admin';
  } else if (pathName.includes(SERVICING)) {
    return 'servicing';
  } else if (pathName.includes(INSURANCE)) {
    return 'insurance';
  }
  return 'sales';
};

export const PulseRoutes = () => {
  const location = useLocation();
  const divRef = useRef<HTMLDivElement>(null);
  const divRefEl = divRef?.current;
  const { setShowShadow, superAdmin } = useContext(APP_CONTEXT);
  const [ref, isInView] = useInView(0, null, [0, 1]);
  const [childRef, isChildRefInView] = useInView(0, null, [0, 1]);
  const employeeDepartment: string | null = localStorage.getItem('department');

  useEffect(() => {
    setShowShadow(!isChildRefInView && divRefEl != null);
  }, [isChildRefInView, divRefEl, setShowShadow]);

  if (location.pathname === '/') {
    return <Navigate to="/login" />;
  }

  let ottoRoutes: PulseAdminRoutes[] = [];

  if (superAdmin && employeeDepartment === CLAIMS) {
    ottoRoutes = OttoPulseAdminRoutes;
  } else {
    ottoRoutes = OttoPulseAdminRoutes.filter((route: PulseAdminRoutes) => route.path !== INSURANCE);
  }

  return (
    <>
      <AppRoutes>
        <Route path="" element={<LoginLayout />}>
          <Route path="login" element={<Login formName="login" />} />
          <Route path="forgot-password" element={<Login formName="forgot-password" />} />
          <Route path="reset-password/:id" element={<Login formName="reset-password" />} />
        </Route>
        {ottoRoutes?.map((route: PulseAdminRoutes) => {
          return (
            <Route
              key={route?.title + route?.path}
              path={route?.path}
              element={
                <RouteContainer>
                  <Div ref={ref}></Div>
                  <PageTitle pageId={getPageId(location.pathname)} showShadow={!isInView} />
                  {route?.childRoutes && (
                    <PageRoutesSelector
                      routes={route?.childRoutes?.map((childRoute: PulseAdminChildRoute) => {
                        return { value: childRoute?.path, label: childRoute?.title };
                      })}
                    />
                  )}
                  {location?.pathname === route?.path && route?.redirect != null ? (
                    <Navigate to={route?.redirect} />
                  ) : (
                    route?.component
                  )}
                  <Outlet />
                </RouteContainer>
              }
            >
              {route?.childRoutes?.map((childRoute: PulseAdminChildRoute) => {
                return (
                  <Route
                    key={childRoute?.title + childRoute?.path}
                    path={childRoute?.path}
                    element={
                      <ChildRouteContainer>
                        <Ref ref={childRef}></Ref>
                        <Ref ref={divRef} />
                        {childRoute?.childRoutes && (
                          <PageRoutesSelector
                            routes={childRoute?.childRoutes?.map((routePath: PulseAdminChildRoute) => {
                              return { value: routePath?.path, label: routePath?.title };
                            })}
                            styled={childRoute?.styles}
                            isSecondary
                          />
                        )}
                        {location?.pathname === childRoute?.path && childRoute?.redirect != null ? (
                          <Navigate to={childRoute?.redirect} />
                        ) : (
                          childRoute?.component
                        )}
                        <Outlet />
                      </ChildRouteContainer>
                    }
                  >
                    {childRoute?.childRoutes &&
                      childRoute?.childRoutes?.map((r: PulseAdminChildRoute) => (
                        <Route key={r?.title + r?.path} path={r?.path} element={r?.component} />
                      ))}
                  </Route>
                );
              })}
            </Route>
          );
        })}
      </AppRoutes>
      {location?.pathname.includes('/app/') && <NavTray />}
    </>
  );
};
