import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';

import { getDatesByPolicyNumber, getPermissionLetterDetails } from '../../../api/get/vehicle.get';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { FieldGrid } from '../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { Loading } from '../../../uiComponents/table/table.styles';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { Spinner } from '../../../uiComponents/uiControls/spinner/spinner';
import { PermissionLetterData, GeneralPermissionLetterPolicy } from '../../../models/permissionLetter';
import { TextInput } from '../../../uiComponents/inputs/textInput/textInput';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { generatePermissionLetter } from '../../../api/post/vehicle.post';
import { Notification } from '../../../uiComponents/toast/toast';
import { ErrorType, handleAPIError } from '../../../utils/handleAPIError';
import { OptionList } from '../../../utils/props';
import { MultiValue, SingleValue } from 'react-select';

interface PermissionLetterProps {
  setOpenPermission: Dispatch<SetStateAction<boolean>>;
  getData: () => void;
}
export const PermissionLetter = ({ getData, setOpenPermission }: PermissionLetterProps) => {
  const { vehicleId } = useParams();
  const [permissionData, setPermissionData] = useState<PermissionLetterData>();
  const [genPolicy, setGenPolicy] = useState<{ policyNumber?: string; vehicle_id: string }>();
  const [isInputManufacturer, setIsInputManufacturer] = useState<boolean>(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    if (vehicleId) {
      try {
        const { data }: { data: PermissionLetterData } = await getPermissionLetterDetails(vehicleId);
        setPermissionData(data);
        setGenPolicy({
          policyNumber: data?.policies?.find((policy) => policy?.value === data?.policyNumber)?.value,
          vehicle_id: data?.vehicle_id,
        });
      } catch (err) {
        handleAPIError(err as ErrorType);
      }
    }
  }, [vehicleId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (permissionData) {
      if (name === 'policyNumber') {
        if (value.length === 9) {
          const { data } = await getDatesByPolicyNumber(value);
          setPermissionData({
            ...permissionData,
            start_date: data.start_date,
            end_date: data.end_date,
          });
        }
      }

      setGenPolicy({
        ...genPolicy,
        [name]: value,
        vehicle_id: permissionData.vehicle_id,
      });
    }
  };

  const handleConfirmation = async () => {
    let payload: GeneralPermissionLetterPolicy = {};
    if (!genPolicy && permissionData) {
      payload = {
        policyNumber: permissionData?.policyNumber,
        vehicle_id: permissionData?.vehicle_id,
      };
    } else if (genPolicy?.policyNumber === undefined && permissionData) {
      payload = {
        ...genPolicy,
        policyNumber: permissionData?.policyNumber,
      };
    } else if (genPolicy) {
      payload = genPolicy;
    }
    await generatePermissionLetter(payload)
      .then(() => {
        setOpenPermission(false);
        getData();
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Permission letter is successfully generated',
          isAlert: true,
        });
      })
      .catch((err) => {
        Notification({
          type: 'error',
          title: 'Error',
          message: 'Could not generate permission letter, please check your VRM',
          isAlert: true,
        });
        setIsOpenConfirmation(false);
        return err;
      });
  };

  const renderMakeAndModel = (name: string, permission: PermissionLetterData) => (
    <>
      <FlexLayout>
        {!isInputManufacturer ? (
          `${permission.manufacturer} ${permission.model}`
        ) : (
          <TextInput
            styled={{ padding: '2px 20px' }}
            type="text"
            name={name}
            onChange={(e) => handleChange(e)}
            defaultValue={`${permission.manufacturer} ${permission.model}`}
          />
        )}
        <FaEdit
          style={{
            cursor: 'pointer',
            marginLeft: '30px',
            fontSize: !isInputManufacturer ? 'large' : 'x-large',
          }}
          onClick={() => setIsInputManufacturer((prev) => !prev)}
        />
      </FlexLayout>
    </>
  );

  const defaultPolicy = permissionData?.policies?.find((policy) => policy?.value === permissionData?.policyNumber);

  const renderPolicyNumber = () => (
    <>
      {permissionData && (
        <DropDown
          options={permissionData?.policies}
          placeholder="Policy No"
          name="policies"
          defaultValue={defaultPolicy}
          onSelect={(item: SingleValue<string | OptionList> | MultiValue<string | OptionList>) =>
            setGenPolicy({ policyNumber: (item as OptionList)?.value, vehicle_id: vehicleId ?? '' })
          }
        />
      )}
    </>
  );

  return (
    <>
      {permissionData ? (
        <>
          <FieldGrid
            numColumns={3}
            headers={[
              'Vehicle make & model',
              'Vehicle reg',
              'Walsingham policy No',
              'Policy permission start date',
              'Policy permission end date',
            ]}
            values={[
              renderMakeAndModel('makeAndModel', permissionData),
              permissionData.vrm,
              renderPolicyNumber(),
              `${permissionData?.policyStartTime} on ${permissionData.start_date}`,
              `${permissionData?.policyEndTime} on ${permissionData.end_date}`,
            ]}
          />
          <FlexLayout itemsX="end" gap={18}>
            <SecondaryButton onClick={() => setOpenPermission(false)}>Cancel</SecondaryButton>
            <PrimaryButton onClick={() => setIsOpenConfirmation(true)}>Generate</PrimaryButton>
          </FlexLayout>
        </>
      ) : (
        <Loading itemsX="center">
          <Spinner size={30} color={PRIMARY_PURPLE} />
        </Loading>
      )}
      <ConfirmationModal
        title={'Are you sure you want to generate Permission Letter'}
        isOpen={isOpenConfirmation}
        onClose={() => setIsOpenConfirmation(false)}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
        preConfirm={async () => await handleConfirmation()}
      />
    </>
  );
};
