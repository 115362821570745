import {
  InitiateDataValidationPayload,
  InitiateInsuranceRunPayload,
  InsurancePolicy,
  PolicyPayload,
} from '../../models/insurancePolicy';
import api from '../../utils/api';

/**
 * Create insurance policy
 * @category API/POST
 * @param {object} policyData - Data for the policy creation
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 * }
 * createInsurancePolicy(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createInsurancePolicy = (params: { body: PolicyPayload }): Promise<{ data: InsurancePolicy }> =>
  api.post('insurance/policy', params.body);

/**
 * Iniate data validation check
 * @category API/POST
 * @param {object} policyId - id of policy to initiate data check
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 * }
 * initiateInsuranceDataValidation(id).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */

export const initiateInsuranceDataValidation = (payload: InitiateDataValidationPayload): Promise<{ status: string }> =>
  api.post(`insurance/policy/${payload.id}/data-validation`, payload.body);

/**
 * Iniate insurance
 * @category API/POST
 * @param {object} policyId - id of policy to initiate data check
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 * }
 * nitiateInsuranceRun(id).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */

export const initiateInsuranceRunProcess = (payload: InitiateInsuranceRunPayload): Promise<{ status: string }> =>
  api.post(`insurance/policy/${payload.id}/insurance-run`, payload.body);
