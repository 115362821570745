import { GenericAbortSignal } from 'axios';
import {
  CustomerProfile,
  Driver,
  DriverTag,
  DriverTagTypeResponse,
  DuplicateBPId,
  DuplicatePcoCheck,
  DuplicateShellCardCheck,
  FreshdeskTicket,
  OrdwayDetails,
} from '../../models/driver';
import {
  OrdwayHolidaySubscriptionDetails,
  DriverOrdwayHolidayEgibilityDetails,
  DriverPaymentHoliday,
} from '../../models/holiday';
import api from '../../utils/api';

/**
 * Get single driver request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getDriver(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getDriver = (id: string = ''): Promise<{ data: Driver }> => api.get(`driver/${id}`);

/**
 * Get all drivers from the system
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllDrivers(values).then((res) => {
 *  // Promise resolved
 * });
 */

export const getAllDrivers = (
  query: string = '',
  signal: GenericAbortSignal
): Promise<{ count: number; data: Driver[] }> => api.get(`driver${query && `?${query}`}`, { signal });

/**
 * Get all drivers with agreements and no duplicates (regular drivers)
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllDriversWithAgreements(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllDriversWithAgreements = (
  query: string = '',
  signal: GenericAbortSignal
): Promise<{ count: number; data: Driver[] }> => api.get(`driver${query && `?${query}`}`, { signal });

/**
 * Get all prospective drivers (those that have no agreements and 0 or more applications)
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllProspectDrivers(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllProspectDrivers = (
  query: string = '',
  signal: GenericAbortSignal
): Promise<{ count: number; data: Driver[] }> => api.get(`prospect-drivers${query && `?${query}`}`, { signal });

/**
 * Send all drivers export as email
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * exportDriversWithAgreements(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const exportAllDrivers = (query: string = ''): Promise<{ data: boolean }> =>
  api.get(`driver/export${query && `?${query}`}`);

/**
 * Send drivers with agreements export as email
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * exportDriversWithAgreements(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const exportDriversWithAgreements = (query: string = ''): Promise<{ data: boolean }> =>
  api.get(`driver/drivers-with-agreements/export${query && `?${query}`}`);

export const exportAllProspectDrivers = (query: string = ''): Promise<{ data: boolean }> =>
  api.get(`driver/prospect-drivers/export${query && `?${query}`}`);

export const getDriverByBP = (id: string, bpCardId: string): Promise<{ data: DuplicateBPId[] }> =>
  api.get(`driver/bp/${id}/${bpCardId}`);

export const getDriverByShell = (id: string, shellCardId: string): Promise<{ data: DuplicateShellCardCheck[] }> =>
  api.get(`driver/shell/${id}/${shellCardId}`);

export const getDriverByPCO = (id: string, pcoNo: string): Promise<{ data: DuplicatePcoCheck[] }> =>
  api.get(`driver/pco/${id}/${pcoNo}`);

export const getDriverByDVLA = (
  dvlaNo: string
): Promise<{ data: CustomerProfile; message: undefined } | { message: 'OK'; data: undefined }> =>
  api.get(`driver/dvla/${dvlaNo}`);

export const getOrdwaySubscriptionDetails = (
  ordwayId: string,
  subscriptionId: string
): Promise<{ data: OrdwayHolidaySubscriptionDetails }> => api.get(`driver/ordway/${ordwayId}/${subscriptionId}`);

export const getDriverTelematics = (params: { pulseDriverId: string; vehicleId: string }) =>
  api.get('driver/samsara/telematics', { params });

export const getOrdwayDetails = (ordwayId: string): Promise<{ data: OrdwayDetails }> =>
  api.get(`driver/ordway/${ordwayId}`);

export const verifyEmailAddressExists = (email: string) => api.get(`driver/email/${email}`);

export const verifyMobileNumberExists = (areaCode: string, mobileNumber: string) =>
  api.get(`driver/mobile/${areaCode}/${mobileNumber}`);

export const getFreshdeskDetails = (email: string, params: string): Promise<{ data: FreshdeskTicket[] }> =>
  api.get(`driver/freshdesk/${email}${params && `?${params}`}`);

export const getDriverHolidays = (driverId: string): Promise<{ data: DriverPaymentHoliday[] }> =>
  api.get(`holiday/driver/${driverId}`);

export const getDriverMileage = (driverId: string) => api.get(`driver/${driverId}/mileage`);

export const getDriverAdditional = (driverId: string) => api.get(`driver/${driverId}/additional`);

export const getOrdwayDriverHolidayEgibility = (
  ordwayId: string
): Promise<{ data: DriverOrdwayHolidayEgibilityDetails }> => api.get(`driver/ordway-holiday-elegibility/${ordwayId}`);

export const getTagTypesByCategory = (tagCategory: string): Promise<{ data: DriverTagTypeResponse }> =>
  api.get(`/driver/tags/${tagCategory}`);

export const getDriverTagsByDriverId = (driverId: string): Promise<{ data: DriverTag[] }> =>
  api.get(`/driver/tag/${driverId}`);
