import { InputType } from '../uiComponents/inputs/textInput/textInput';
import { Congestion } from '../models/congestion';
import { OptionList } from '../utils/props';

export const CONG_APPROVED = 'APPROVED';
export const CONG_IN_SERVICING = 'IN-SERVICING';
export const CONG_INVALID_ACCOUNT = 'INVALID-ACCOUNT';
export const CONG_INVALID_DRIVER = 'INVALID-DRIVER';
export const CONG_INVALID_VRM = 'INVALID-VRM';
export const CONG_INVOICED = 'INVOICED';
export const CONG_ORDERED = 'ORDERED';
export const CONG_PAID = 'PAID';
export const CONG_PENDING = 'PENDING';
export const CONG_REJECTED = 'REJECTED';
export const CONG_STAFF = 'STAFF';
export const CONG_VAN = 'VAN';

interface EditFields<T> {
  disabled?: boolean;
  label: string;
  name: keyof T;
  placeholder?: string;
  pattern?: {
    value: RegExp;
    message: string;
  };
  required?: string;
  type: InputType | 'select' | 'checkbox';
}

export const editFields: EditFields<Congestion>[] = [
  {
    disabled: true,
    label: 'Transaction date & time',
    name: 'transaction_date',
    type: 'text',
  },
  {
    disabled: true,
    label: 'Charge type',
    name: 'charge_type',
    type: 'text',
  },
  {
    label: 'VRM',
    name: 'vrm',
    type: 'text',
  },
  {
    label: 'Driver',
    name: 'driver_name',
    type: 'text',
  },
  {
    disabled: true,
    label: 'Cost centre',
    name: 'cost_centre',
    type: 'text',
  },
  {
    disabled: true,
    label: 'Journey date & time',
    name: 'transaction_date',
    type: 'text',
  },
  {
    label: 'Transaction ID',
    name: 'transaction_id',
    type: 'text',
    disabled: true,
  },
  {
    label: 'Amount',
    name: 'congestion_amount',
    type: 'text',
    disabled: true,
  },
  {
    label: 'Status',
    name: 'congestion_status',
    type: 'text',
    disabled: true,
  },
];

export const otherStatuses: OptionList[] = [
  {
    label: 'In-servicing',
    value: 'IN-SERVICING',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    label: 'Staff vehicle',
    value: 'STAFF',
  },
  {
    label: 'Van',
    value: 'VAN',
  },
];
