import React from 'react';
import { NavigationPillContainer, Pill } from './navigationPill.styles';
import { withStyledProps } from '../../../utils/colorUtils';
import { OptionList } from '../../../utils/props';

/**
 * Navigation pill component to switch between views
 * @options array of objects to show in the format [{ value: 'val1', label: 'label 1' }]
 * @function onSelect callback function that exposes the selected option (option) => void
 *
 */

interface NavigationPillProps {
  options: OptionList[];
  onSelect: (option: OptionList) => void;
  selected?: OptionList;
  isSecondaryNav?: boolean;
}

export const NavigationPill = withStyledProps(
  ({ options, onSelect, selected, isSecondaryNav, ...props }: NavigationPillProps) => {
    return (
      <NavigationPillContainer $isSecondaryNav={isSecondaryNav} inline itemsY="center" {...props}>
        {options?.map((option) => (
          <Pill
            itemsX="center"
            itemsY="center"
            key={option?.value}
            onClick={() => onSelect(option)}
            $selected={selected?.value === option?.value}
            $isSecondaryNav={isSecondaryNav}
          >
            {option?.label}
          </Pill>
        ))}
      </NavigationPillContainer>
    );
  }
);
