import { Transfer } from '../../models/transfer';
import api from '../../utils/api';

/**
 * Get condition report by id
 * @category API/GET
 * @return {Promise}  Returns a promise for the api request
 * @example
 * getConditionalReport().then((res) => {
 * // Promise resolved
 * });
 */

export const getConditionReport = (id: string): Promise<{ data: Transfer }> => api.get(`condition-report/${id}`);
/**
 * Get condition reports by vehicle id
 * @category API/GET
 * @return {Promise}  Returns a promise for the api request
 * @example
 * getRecentConditionReport().then((res) => {
 * // Promise resolved
 * });
 */
export const getRecentConditionReport = (id: string): Promise<{ data: Transfer }> =>
  api.get(`condition-report/${id}/vehicle`);
