import { MdKeyboardArrowDown, MdNavigateBefore, MdNavigateNext, MdOutlineSearch } from 'react-icons/md';
import { BiFirstPage, BiLastPage } from 'react-icons/bi';
import styled, { css } from 'styled-components';
import { TableVariant } from './table';
import { FlexLayout } from '../layouts/flexLayout/flexLayout';
import { GridLayout } from '../layouts/gridLayout/gridLayout';
import {
  GRAY_1,
  PRIMARY_PURPLE,
  PRIMARY_WHITE,
  SECONDARY_GRAY_20,
  SECONDARY_PURPLE_30,
  SECONDARY_PURPLE_5,
  SECONDARY_PURPLE_70,
  SECONDARY_PURPLE_90,
} from '../../common/styles/Colors';

export const TableContainer = styled.div<{ $tableType: TableVariant }>`
  border-radius: 16px;
  ${(props) => {
    if (props.$tableType === 'compact') {
      return css`
        border: 1px solid ${SECONDARY_PURPLE_30};
      `;
    }
  }}
`;

export const StickyHeader = styled.div<{ $stick: boolean; $theme?: 'purple' | 'white' }>`
  position: ${(props) => (props?.$stick ? 'sticky' : 'static')};
  top: 160px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: ${(props) => (props.$theme === 'purple' ? SECONDARY_PURPLE_5 : PRIMARY_WHITE)};
  z-index: 22;
`;

export const StickyHeaderMask = styled.div`
  position: fixed;
  left: 32px;
  right: 32px;
  top: 160px;
  height: 16px;
  background-color: ${GRAY_1};
  z-index: 15;
`;

export const HeaderArea = styled.div`
  padding: 32px;
  font-size: 20px;
  font-weight: 800;
  color: ${PRIMARY_PURPLE};
`;
export const RowsContainer = styled.div<{ $theme?: 'purple' | 'white' }>`
  background-color: ${(props) => (props.$theme === 'purple' ? SECONDARY_PURPLE_5 : PRIMARY_WHITE)};
`;

export const SearchFilters = styled(FlexLayout)`
  position: relative;
  margin-top: 40px;
`;

export const SearchIcon = styled(MdOutlineSearch)`
  position: absolute;
  top: 14px;
  left: 16px;
`;

export const TableRowGrid = styled(GridLayout)<{ $rowClickable: boolean; $borderColur: string; $rowColour?: string }>`
  position: relative;
  padding: 16px 32px;
  align-items: center;
  border-top: 1px solid ${(props) => props.$borderColur};
  cursor: ${(props) => (props.$rowClickable ? 'pointer' : 'default')};

  &:first-child {
    border-top: none;
  }

  ${(props) => {
    if (props.$rowClickable) {
      return css`
        transition: background-color 0.3s ease-in;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      `;
    } else {
      return null;
    }
  }}

  ${(props) => {
    if (props.$rowColour) {
      return css`
        background-color: ${props.$rowColour};
      `;
    }
  }}
`;

export const TableHeaders = styled(GridLayout)<{ $tableType: TableVariant }>`
  border-bottom: 1px solid ${SECONDARY_GRAY_20};
  ${(props) => {
    if (props.$tableType === 'compact') {
      return css`
        padding: 24px 32px;
        background-color: ${SECONDARY_PURPLE_5};
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      `;
    } else {
      return css`
        padding: 0 32px 24px;
      `;
    }
  }}
`;

export const TableFooter = styled(FlexLayout)<{
  $tableType: TableVariant;
  $embedded?: boolean;
  $theme?: 'purple' | 'white';
}>`
  position: ${(props) => (props?.$embedded ? 'static' : 'sticky')};
  bottom: ${(props) => (props.$embedded ? 0 : '96px')};
  height: 56px;
  padding: 8px;
  border-top: 1px solid ${SECONDARY_GRAY_20};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: ${(props) =>
    props.$theme === 'purple' || props.$tableType === 'compact' ? SECONDARY_PURPLE_5 : PRIMARY_WHITE};
  z-index: 5;
`;

const getIconStyle = (isDisabled: boolean) => {
  if (isDisabled) {
    return css`
      opacity: 0.3;
      cursor: default;
    `;
  } else {
    return css`
      cursor: pointer;
    `;
  }
};

export const FirstPageIcon = styled(BiFirstPage)<{ $disabled: boolean }>`
  color: ${PRIMARY_PURPLE};

  ${(props) => {
    return getIconStyle(props.$disabled);
  }}
`;

export const LastPageIcon = styled(BiLastPage)<{ $disabled: boolean }>`
  color: ${PRIMARY_PURPLE};
  margin-left: 8px;

  ${(props) => {
    return getIconStyle(props.$disabled);
  }}
`;

export const PreviousPageIcon = styled(MdNavigateBefore)<{ $disabled: boolean }>`
  color: ${PRIMARY_PURPLE};
  margin-left: 8px;

  ${(props) => {
    return getIconStyle(props.$disabled);
  }}
`;

export const NextPageIcon = styled(MdNavigateNext)<{ $disabled: boolean }>`
  color: ${PRIMARY_PURPLE};
  margin-left: 8px;

  ${(props) => {
    return getIconStyle(props.$disabled);
  }}
`;

export const TableActions = styled(FlexLayout)`
  cursor: pointer;
`;

export const Loading = styled(FlexLayout)`
  padding: 24px 0;
  border-top: 1px solid ${SECONDARY_GRAY_20};
`;

export const ColumnHeader = styled(FlexLayout)<{ $isSortable: boolean }>`
  ${(props) => {
    if (props.$isSortable) {
      return css`
        cursor: pointer;
      `;
    }
  }}
`;

export const RowArrow = styled(MdKeyboardArrowDown)<{ $rowExpanded: boolean }>`
  position: absolute;
  left: 10px;
  transform: ${(props) => `rotate(${props.$rowExpanded ? '180deg' : '0deg'})`};
  transition: transform 0.2s ease-out;
  transform-origin: center center;
`;

export const ExpandedRow = styled.div`
  background-color: ${SECONDARY_PURPLE_5};
`;

export const SelectRowsPerPage = styled.select<{ $tableType: TableVariant }>`
  border: none;
  outline: none;
  font-size: 12px;
  color: ${PRIMARY_PURPLE};
  cursor: pointer;
  background-color: ${(props) => (props.$tableType === 'standard' ? 'inherit' : SECONDARY_PURPLE_5)};
`;

export const OverflowMask = styled.div<{ $tableType: TableVariant }>`
  position: sticky;
  height: 112px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => (props?.$tableType === 'standard' ? GRAY_1 : PRIMARY_WHITE)};
  z-index: 1;
`;

export const FilterDateInput = styled.input`
  height: 24px;
  border-radius: 50px;
  min-width: 86px;
  border: 1px solid ${SECONDARY_PURPLE_70};
  box-shadow: none;
  outline: none;
  background-color: ${SECONDARY_PURPLE_90};
  padding: 0 8px;
  color: ${PRIMARY_WHITE};

  &::-webkit-calendar-picker-indicator {
    color-scheme: dark;
  }
  &::-webkit-datetime-edit-day-field:focus {
    background-color: ${SECONDARY_PURPLE_30};
    color: ${PRIMARY_PURPLE};
  }
  &::-webkit-datetime-edit-month-field:focus {
    background-color: ${SECONDARY_PURPLE_30};
    color: ${PRIMARY_PURPLE};
  }
  &::-webkit-datetime-edit-year-field:focus {
    background-color: ${SECONDARY_PURPLE_30};
    color: ${PRIMARY_PURPLE};
  }
`;
