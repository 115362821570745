import { useState } from 'react';
import { SectionProps } from '../order/order';
import { SectionHeader } from './sectionHeader';
import { FieldHeader, PaymentMethodPanel } from '../order/order.styles';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../common/styles/Colors';
import { Text } from '../../../uiComponents/text/text';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { activateOrdwayCustomer } from '../../../api/post/driver.post';

interface PaymentMethodSectionProps extends SectionProps {
  paymentMethodMissing: boolean;
}

export const PaymentMethodSection = ({
  isComplete,
  isLocked,
  paymentMethodMissing,
  progress,
}: PaymentMethodSectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [updatingOrdway, setUpdatingOrdway] = useState<boolean>(false);

  return (
    <PaymentMethodPanel
      $paymentMethodMissing={paymentMethodMissing}
      locked={isLocked}
      header={
        <div>
          <SectionHeader
            title="Payment method"
            completedText={progress?.ordwayData?.account_number}
            isComplete={isComplete}
            isLocked={isLocked}
            expanded={false}
          />
          {paymentMethodMissing && (
            <Text variant="body7" color={STATUS_RED} weight={300}>
              The driver's payment method is needed before going live.
            </Text>
          )}
        </div>
      }
      expanded={!isCollapsed}
      onCollapse={() => {
        setIsCollapsed(!isCollapsed);
      }}
    >
      <div>
        <SectionHeader title="Payment method" isComplete={isComplete} isLocked={isLocked} expanded />
        {progress?.ordwayData?.account_number ? (
          <>
            <FieldHeader
              styled={{ marginTop: 42, width: 220 }}
              variant="body7"
              color={PRIMARY_PURPLE}
              weight={300}
              block
            >
              Current card details:
            </FieldHeader>
            <Text styled={{ marginTop: 8, marginLeft: 32 }} variant="body7" color={PRIMARY_PURPLE} weight={500} block>
              {progress?.ordwayData?.account_number}
            </Text>
          </>
        ) : (
          <Text styled={{ marginTop: 42 }} variant="body7" color={PRIMARY_PURPLE} weight={300} block>
            There is no payment method stored.
          </Text>
        )}
        <PrimaryButton
          styled={{ marginTop: 24 }}
          isProcessing={updatingOrdway}
          onClick={() => {
            if (progress?.ordway_customer_status === 'Inactive' && progress?.ordway_customer_id) {
              setUpdatingOrdway(true);
              activateOrdwayCustomer(progress?.ordway_customer_id)
                .then(() => {
                  setUpdatingOrdway(false);
                  window.open(progress?.ordway_update_payment_method_url, '_blank');
                })
                .catch(() => {
                  setUpdatingOrdway(false);
                });
            } else {
              window.open(progress?.ordway_update_payment_method_url, '_blank');
            }
          }}
        >
          {progress?.ordwayData?.account_number ? 'Change' : 'Add payment method'}
        </PrimaryButton>
      </div>
    </PaymentMethodPanel>
  );
};
