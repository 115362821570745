import React, { Fragment, useState } from 'react';
import { BsCheckCircle, BsCircle } from 'react-icons/bs';
import { FuelLevel, FuelLevelSlider } from './styles';
import { ChecklistItem, sliderLevels, vehicleInformationItems } from '../../../../../consts/conditionReport';
import { CollapsiblePanel } from '../../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../../uiComponents/layouts/gridLayout/gridLayout';
import { Text } from '../../../../../uiComponents/text/text';
import { TextField } from '../../../../../uiComponents/inputs/textField/textField';
import { Checkbox } from '../../../../../uiComponents/uiControls/checkbox/checkbox';
import { PRIMARY_GREEN, PRIMARY_PURPLE } from '../../../../../common/styles/Colors';
import { handleMileageRangeError } from '../../../../../utils/validations';
import { ModifiedChecklistProps } from '../../../../../models/transfer';

export const VehicleCheck = ({ isComplete, errors, register, previousMileage }: ModifiedChecklistProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [oil, setOil] = useState<number>(0);
  const [screenWash, setScreenWash] = useState<number>(0);
  const [mileage, setMileage] = useState<number>(0);
  return (
    <CollapsiblePanel
      header={
        <FlexLayout gap={16} itemsY="center">
          {isComplete ? (
            <BsCheckCircle color={PRIMARY_GREEN} size={32} />
          ) : (
            <BsCircle size={32} color={PRIMARY_PURPLE} />
          )}
          <div>
            <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
              Vehicle information
            </Text>
            {isComplete && (
              <Text variant="body7" color={PRIMARY_GREEN} weight={300} block>
                Completed
              </Text>
            )}
          </div>
        </FlexLayout>
      }
      expanded={!isCollapsed}
      onCollapse={() => setIsCollapsed(!isCollapsed)}
      styled={{ marginTop: 16 }}
    >
      <div>
        <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
          Vehicle information
        </Text>
        <FlexLayout styled={{ marginTop: 24 }} gap={24}>
          <FuelLevel>
            <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block styled={{ marginBottom: 8 }}>
              Oil level
            </Text>
            <FlexLayout gap={8} itemsY="center">
              <FuelLevelSlider
                $mileage={oil}
                defaultValue={oil}
                type="range"
                min={0}
                max={100}
                {...register?.('oilLevel', {
                  onChange: (e) => setOil(+e.target.value),
                })}
              />
              <Text variant="body6" color={PRIMARY_PURPLE} weight={300}>{`${oil}%`}</Text>
            </FlexLayout>
            <GridLayout template={sliderLevels?.length}>
              {sliderLevels?.map((level: string) => (
                <Text key={level} variant="body8" color={PRIMARY_PURPLE} weight={300}>
                  {level}
                </Text>
              ))}
            </GridLayout>
          </FuelLevel>
          <FuelLevel>
            <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block styled={{ marginBottom: 8 }}>
              Screen wash level
            </Text>
            <FlexLayout gap={8} itemsY="center">
              <FuelLevelSlider
                $mileage={screenWash}
                defaultValue={screenWash}
                type="range"
                min={0}
                max={100}
                {...register?.('screenWashLevel', {
                  onChange: (e) => setScreenWash(+e.target.value),
                })}
              />
              <Text variant="body6" color={PRIMARY_PURPLE} weight={300}>{`${screenWash}%`}</Text>
            </FlexLayout>
            <GridLayout template={sliderLevels?.length}>
              {sliderLevels?.map((level: string) => (
                <Text key={level} variant="body8" color={PRIMARY_PURPLE} weight={300}>
                  {level}
                </Text>
              ))}
            </GridLayout>
          </FuelLevel>
        </FlexLayout>
        <FlexLayout styled={{ marginTop: 24 }} gap={24}>
          <FuelLevel>
            <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block styled={{ marginBottom: 8 }}>
              Fuel level
            </Text>
            <FlexLayout gap={8} itemsY="center">
              <FuelLevelSlider
                $mileage={mileage}
                defaultValue={mileage}
                type="range"
                min={0}
                max={100}
                {...register?.('fuelLevel', {
                  onChange: (e) => setMileage(+e.target.value),
                })}
              />
              <Text variant="body6" color={PRIMARY_PURPLE} weight={300}>{`${mileage}%`}</Text>
            </FlexLayout>
            <GridLayout template={sliderLevels?.length}>
              {sliderLevels?.map((level: string) => (
                <Text key={level} variant="body8" color={PRIMARY_PURPLE} weight={300}>
                  {level}
                </Text>
              ))}
            </GridLayout>
          </FuelLevel>
          <TextField
            type="number"
            label="Mileage"
            name="mileage"
            {...register?.('mileage', {
              required: 'Mileage is required',
              validate: {
                handleMileageRangeError: (value) => handleMileageRangeError(value, previousMileage),
              },
            })}
            placeholder="Mileage"
            error={errors?.mileage}
            required
            styled={{ width: '100%' }}
          />
        </FlexLayout>
        <FlexLayout gap={24} styled={{ marginTop: 32 }} wrap>
          {vehicleInformationItems?.map((item: ChecklistItem) => (
            <Fragment key={item?.name}>
              {register && (
                <FlexLayout gap={8} itemsY="center">
                  <Checkbox {...register?.(item?.name)} />
                  <Text variant="body6" weight={500} color={PRIMARY_PURPLE} styled={{ whiteSpace: 'nowrap' }}>
                    {item?.label}
                  </Text>
                  {item.aditionalInfo && (
                    <span style={{ fontSize: '10px', color: PRIMARY_PURPLE }}>{item.aditionalInfo}</span>
                  )}
                </FlexLayout>
              )}
            </Fragment>
          ))}
        </FlexLayout>
      </div>
    </CollapsiblePanel>
  );
};
