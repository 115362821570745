import React, { useState, useContext, useEffect, useCallback } from 'react';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { MdPlaylistAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { EditVehicleTypeForm } from './editVehicleTypeForm';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../../uiComponents/modals/modal';
import { ConfirmationModal } from '../../../../uiComponents/modals/confirmationModal/confirmationModal';
import { ActionIcon } from '../../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../../uiComponents/table/table';
import { vehicleTypeColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import { DropDownFilter, FilterItem } from '../../../../uiComponents/table/tableFilters/tableFilters';
import { TableHeader } from '../../../../uiComponents/table/tableHeader/tableHeader';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableTextCell,
  getQueryString,
} from '../../../../uiComponents/table/tableUtils/tableUtils';
import { Notification } from '../../../../uiComponents/toast/toast';
import { deleteVehicleType } from '../../../../api/delete/vehicleType.delete';
import { getAllVehicleTypes } from '../../../../api/get/vehicleType.get';
import { ModifiedVehicleType } from '../../../../models/vehicle';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { APP_CONTEXT } from '../../../../utils/context';
import { handleAPIError, ErrorType } from '../../../../utils/handleAPIError';
import { OptionList } from '../../../../utils/props';

export const VehicleTypeList = () => {
  const navigate = useNavigate();
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [vehicleTypeValues, setVehicleTypeValues] = useState<ModifiedVehicleType | null>(null);
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [selectedManufacturer, setSelectedManufacturer] = useState<OptionList[]>([]);
  const [selectedModel, setSelectedModel] = useState<OptionList[]>([]);
  const [selectedModelYear, setSelectedModelYear] = useState<OptionList[]>([]);
  const [selectedFuelType, setSelectedFuelType] = useState<OptionList[]>([]);
  const [selectedColour, setSelectedColour] = useState<OptionList[]>([]);
  const [vehicleTypes, setVehicleTypes] = useState<ModifiedVehicleType[]>();
  const [selectedId, setSelectedId] = useState<string>();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setSortAscending,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const allColours: OptionList[] = [];
  for (const i of vehicleTypes ?? []) {
    for (const j of i.colors) {
      allColours?.push({ value: j, label: j });
    }
  }

  const handleGetVehicleTypeResponse = useCallback(
    (count: number, lists: ModifiedVehicleType[]) => {
      const handleEditVehicleTypeModal = (row: ModifiedVehicleType) => {
        const edit = row;
        setEditModalOpen(!isEditModalOpen);
        setVehicleTypeValues(edit);
      };
      const handleDeleteVehicleType = (row: ModifiedVehicleType) => {
        // get vehicle type id
        const { id } = row;
        setSelectedId(id);
        setIsDeleteConfirmationModalOpen(true);
      };
      const vehicleTypeList = lists?.map((list: ModifiedVehicleType) => {
        return {
          rowData: { data: list },
          cells: [
            <TableTextCell value={list.manufacturer} />,
            <TableTextCell value={list.model} />,
            <TableTextCell value={list.spec} />,
            <TableTextCell value={list.model_year} />,
            <TableTextCell value={list.colors.map((col) => col).join(', ')} />,
            <TableTextCell value={list.fuel_type} />,
            <FlexLayout itemsY="center" gap={16}>
              <ActionIcon
                icon={
                  <MdPlaylistAdd
                    color={PRIMARY_PURPLE}
                    size={24}
                    onClick={() =>
                      navigate(`/app/fleet/vehicle-types/packages/${list.id}`, {
                        state: { queryParams: list },
                      })
                    }
                  />
                }
                tooltip="Manage Pricing"
              />
              <ActionIcon
                icon={
                  <BsPencil
                    color={PRIMARY_PURPLE}
                    onClick={() => handleEditVehicleTypeModal(list)}
                    size={24}
                    data-tip="Edit"
                  />
                }
                tooltip="Edit"
              />
              <ActionIcon
                icon={
                  <BsTrash
                    color={PRIMARY_PURPLE}
                    size={24}
                    onClick={() => {
                      handleDeleteVehicleType(list);
                    }}
                    data-tip="Delete"
                  />
                }
                tooltip="Delete"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(vehicleTypeList);
      setTotalRows(count);
    },
    [isEditModalOpen, navigate, setTableData, setTotalRows]
  );

  const fetchVehicleTypeList = useCallback(
    async (queryString: string) => {
      const controller = new AbortController();
      try {
        const response = await getAllVehicleTypes(queryString, controller.signal);
        handleGetVehicleTypeResponse(response.count, response.data);
        setVehicleTypes(response.data);
      } catch (err) {
        handleAPIError(err as ErrorType);
      }
    },
    [handleGetVehicleTypeResponse]
  );

  const manufacturers: OptionList[] = [
    ...new Map(vehicleTypes?.map((item) => [item['manufacturer'], item])).values(),
  ]?.map((vehicleType: ModifiedVehicleType) => {
    return { value: vehicleType.manufacturer, label: vehicleType.manufacturer };
  });

  const models: OptionList[] = [...new Map(vehicleTypes?.map((item) => [item['model'], item])).values()]?.map(
    (vehicleType: ModifiedVehicleType) => {
      return { value: vehicleType.model, label: vehicleType.model };
    }
  );

  const modelYears: OptionList[] = [...new Map(vehicleTypes?.map((item) => [item['model_year'], item])).values()]?.map(
    (vehicleType: ModifiedVehicleType) => {
      return { value: vehicleType.model_year, label: vehicleType.model_year };
    }
  );

  const fuelTypes: OptionList[] = [...new Map(vehicleTypes?.map((item) => [item['fuel_type'], item])).values()]?.map(
    (vehicleType: ModifiedVehicleType) => {
      return { value: vehicleType.fuel_type, label: vehicleType.fuel_type };
    }
  );

  const filters: FilterItem[] = [
    {
      name: 'manufacturer',
      element: (
        <DropDownFilter
          name="manufacturer"
          placeholder="Select a manufacturer"
          options={manufacturers}
          multiValues={selectedManufacturer}
          title="Manufacturer:"
          onChange={(items) => setSelectedManufacturer(items as OptionList[])}
        />
      ),
    },
    {
      name: 'model',
      element: (
        <DropDownFilter
          name="model"
          placeholder="Select a model"
          multiValues={selectedModel}
          options={models}
          title="Model:"
          onChange={(items) => setSelectedModel(items as OptionList[])}
        />
      ),
    },
    {
      name: 'model_year',
      element: (
        <DropDownFilter
          name="model_year"
          placeholder="Select a model year"
          multiValues={selectedModelYear}
          options={modelYears}
          title="Model year:"
          onChange={(items) => setSelectedModelYear(items as OptionList[])}
        />
      ),
    },
    {
      name: 'fuel_type',
      element: (
        <DropDownFilter
          name="fuel_type"
          placeholder="Select a fuel type"
          multiValues={selectedFuelType}
          options={fuelTypes}
          title="Fuel type:"
          onChange={(items) => setSelectedFuelType(items as OptionList[])}
        />
      ),
    },
    {
      name: 'colour',
      element: (
        <DropDownFilter
          name="colour"
          placeholder="Colour"
          multiValues={selectedColour}
          options={allColours}
          title="Colour"
          onChange={(items) => setSelectedColour(items as OptionList[])}
        />
      ),
    },
  ];

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchVehicleTypeList(queryString);
    },
    [fetchVehicleTypeList, setTableData, goToPageNumber, tableFilters]
  );

  useEffect(() => {
    setActiveSideNav('vehicleTypesListPage');
    setPageTitle('Vehicle Types');
    setSortingColumn('created_date');
    setSortAscending(false);
    fetchVehicleTypeList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=created_date:ASC`);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, setSortAscending, fetchVehicleTypeList]);

  useEffect(() => {
    setTableFilters([
      { columnName: 'manufacturer', options: selectedManufacturer },
      { columnName: 'model', options: selectedModel },
      { columnName: 'model_year', options: selectedModelYear },
      { columnName: 'fuel_type', options: selectedFuelType },
      { columnName: 'colors', options: selectedColour },
    ]);
  }, [setTableFilters, selectedManufacturer, selectedModel, selectedModelYear, selectedFuelType, selectedColour]);

  const onClearClick = useCallback(() => {
    setSelectedManufacturer([]);
    setSelectedModel([]);
    setSelectedModelYear([]);
    setSelectedFuelType([]);
    setSelectedColour([]);
  }, []);

  const handleConfirm = async () => {
    await deleteVehicleType(selectedId ? selectedId : '').then(() => {
      Notification({
        type: 'success',
        title: 'Success',
        message: 'Vehicle type has been successfully deleted',
      });
      applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
      setIsDeleteConfirmationModalOpen(false);
    });
  };

  const closeEditModal = (param?: string): void => {
    setEditModalOpen(!isEditModalOpen);
    if (param === 'close') {
      applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
    }
  };

  return (
    <>
      <Table
        header={
          <TableHeader
            tableHeaderTitle="Vehicle type list"
            actionButtonText="Create vehicle type"
            onButtonClick={() => {
              navigate('/app/fleet/vehicle-types/create');
            }}
          />
        }
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={vehicleTypeColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        filterQuery={filterQuery}
        filters={filters}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        downloadApi={getAllVehicleTypes}
        downloadName="Vehicle types"
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(0, value, searchString, sortingColumn, sortAscending);
        }}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
      />
      <Modal
        title="Edit vehicle type"
        open={isEditModalOpen}
        onClose={closeEditModal}
        showClose
        styled={{ minWidth: 800, width: '80vw' }}
        showOverflow={false}
      >
        {vehicleTypeValues ? <EditVehicleTypeForm close={closeEditModal} values={vehicleTypeValues} /> : <div></div>}
      </Modal>
      <ConfirmationModal
        title="Are you sure that you want to delete this vehicle type?"
        isOpen={isDeleteConfirmationModalOpen}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        preConfirm={() => handleConfirm()}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
    </>
  );
};
