import {
  BookedHolidayDetails,
  DriverHoliday,
  DriverHolidayDetails,
  DriverPaymentHoliday,
  EditHolidayPayload,
  NewHolidayPayload,
  OnSubmitHolidayValues,
  OrdwayHolidaySubscriptionDetails,
} from '../../../models/holiday';
import { FieldGrid } from '../../layouts/fieldGrid/fieldGrid';
import { useCallback, useEffect, useState } from 'react';
import { getOrdwaySubscriptionDetails } from '../../../api/get/driver.get';
import moment, { Moment, Duration } from 'moment';
import {
  AprovalNeededReasonSection,
  ConfirmationCircle,
  DropdownContainer,
  Eligibility,
  EligibilityText,
  Errors,
  SpinnerContainer,
  ValidationText,
  ViolationText,
} from './holidayTool.styles';
import { Spinner } from '../../uiControls/spinner/spinner';
import { PRIMARY_GREEN, PRIMARY_PURPLE, STATUS_YELLOW } from '../../../common/styles/Colors';
import { Text } from '../../text/text';
import {
  R2B_CLASSIC_PLUS_8,
  R2B_NEW_NATIONAL_PLUS_8,
  R2B_USED_NATIONAL_PLUS_8,
  R2B_USED_PLUS_8,
} from '../../../consts/plans';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { OptionList } from '../../../utils/props';
import {
  driver12WeekRule,
  driver4WeekRule,
  driver8WeekRule,
  getUpcomingThursday,
  holidayLengthsNew,
  holidayLengthsOld,
  noHolidayText,
} from './utils';
import { DropDown } from '../../uiControls/dropDown/dropDown';
import { TextFieldLabel } from '../../inputs/textField/textField.styles';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { DatePickerComponent } from '../datePickerComponent/datePickerComponent';
import { HOLIDAY_COMPLETED_STATUS } from '../../../consts/holiday';
import { IoIosWarning } from 'react-icons/io';
import { TextArea } from '../../inputs/textArea/textArea';
import { PrimaryButton } from '../../buttons/primaryButton/primaryButton';
import { updateHoliday } from '../../../api/patch/driver.patch';
import { createHoliday } from '../../../api/post/driver.post';

export interface HolidayToolProps {
  holidayToEdit?: DriverPaymentHoliday;
  driverDetails: DriverHolidayDetails;
  bookedDates: BookedHolidayDetails[];
}

export const HolidayTool = ({ driverDetails, bookedDates, holidayToEdit }: HolidayToolProps): JSX.Element => {
  const [subscriptionDetails, setSubscriptionDetails] = useState<OrdwayHolidaySubscriptionDetails>();
  const [subscriptionDetailsLoading, setSubscriptionDetailsLoading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<string>();
  const [availableHolidayStartDates, setAvailableHolidayStartDates] = useState<Date[]>([]);
  const [dateSelectedBeforeNextAvailable, setDateSelectedBeforeNextAvailable] = useState<boolean>(false);
  const [nextAvailableHolidayDateFromLastCompleted, setNextAvailableHolidayDateFromLastCompleted] = useState<Moment>();
  const [dateAlreadyBooked, setDateAlreadyBooked] = useState<boolean>(false);
  const [submittingHoliday, setSubmittingHoliday] = useState<boolean>(false);
  const [holidayConfirmed, setHolidayConfirmed] = useState<EditHolidayPayload | NewHolidayPayload>();

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<EditHolidayPayload | NewHolidayPayload>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: holidayToEdit && {
      ...holidayToEdit,
      commencement_date: moment(holidayToEdit.commencement_date).toDate(),
      override_reason:
        holidayToEdit?.override_reason
          ?.split('\n')
          ?.find((r) => r.includes('Reason: '))
          ?.split(': ')?.[1] ?? '',
    },
  });

  const commencementDate: string | Moment | Date = watch('commencement_date');
  const holidayLength: number = watch('holiday_length');
  const overrideReason: string | undefined = watch('override_reason');

  const onNewPlan: boolean = [
    R2B_CLASSIC_PLUS_8,
    R2B_USED_PLUS_8,
    R2B_NEW_NATIONAL_PLUS_8,
    R2B_USED_NATIONAL_PLUS_8,
  ].includes(driverDetails?.agreement_type?.toUpperCase());

  const holidayLengthOptions: OptionList[] = onNewPlan ? holidayLengthsNew : holidayLengthsOld;

  const totalHolidayAvailable: number =
    (subscriptionDetails?.holiday_eligibility?.rollover ?? 0) +
    (subscriptionDetails?.holiday_eligibility?.holidayWeeksRemaining ?? 0);

  const firstAvailableHolidayDate: Moment | undefined =
    subscriptionDetails && moment(subscriptionDetails?.holiday_eligibility?.firstAvailableHolidayDate);
  const nextAvailableHolidayDate: Moment | undefined =
    subscriptionDetails && moment(subscriptionDetails?.holiday_eligibility?.nextAvailableHolidayDate);
  const isFirstHolidayDateInFuture: boolean | undefined =
    firstAvailableHolidayDate && firstAvailableHolidayDate?.isAfter(moment(getUpcomingThursday()), 'day');
  const isNextHolidayDateInFuture: boolean | undefined =
    nextAvailableHolidayDate && nextAvailableHolidayDate?.isAfter(moment(getUpcomingThursday()), 'day');
  const arrearsValue: number = subscriptionDetails ? +subscriptionDetails.arrears : 0;
  const noHolidayAvailable: boolean | undefined = holidayLength > totalHolidayAvailable;
  const inEligibleFor4WeekBreak: boolean =
    holidayLength === 4 && !subscriptionDetails?.holiday_eligibility?.eligibleFor4Weeks;
  const approvalNeeded: boolean =
    inEligibleFor4WeekBreak || noHolidayAvailable || dateSelectedBeforeNextAvailable || arrearsValue > 0;

  const getAvailableHolidayStartDates = useCallback(
    ({ subscriptions, billing_schedule_items, holiday_eligibility }: OrdwayHolidaySubscriptionDetails): void => {
      const termEndDate: Moment = moment(subscriptions?.current_term_end_date);
      const availableStartDates: Date[] = [getUpcomingThursday()];

      // get all available Thursdays up until week before end of agreement end date
      for (let index = 1; index <= billing_schedule_items?.remaining_total_weeks; index += 1) {
        const nextThursday: string = moment(availableStartDates?.at(-1))?.add(7, 'days').toISOString();
        const daysTillEnd: Duration = moment.duration(termEndDate?.diff(nextThursday));
        const dateForCalendar: Date = new Date(nextThursday);

        if (Math.round(daysTillEnd.asDays()) >= 6) {
          availableStartDates.push(dateForCalendar);
        }
      }
      let bookedDate: BookedHolidayDetails | undefined;
      for (let index = availableStartDates.length - 1; index >= 0; index -= 1) {
        bookedDate = bookedDates?.find(
          (holiday) =>
            moment(holiday?.commencement_date).isSame(
              `${moment(availableStartDates[index]).format('YYYY-MM-DDT00:00:00.000')}Z`
            ) && holiday?.holiday_batch_id !== holidayToEdit?.id
        );

        if (bookedDate) {
          availableStartDates.splice(index, 1);
        }
      }

      setAvailableHolidayStartDates(availableStartDates);

      const firstAvailableDate: Moment = moment(holiday_eligibility?.firstAvailableHolidayDate);
      const nextAvailableDate: Moment = moment(holiday_eligibility?.nextAvailableHolidayDate);

      const firstAvailableIndex: number = availableStartDates?.findIndex((date) =>
        moment(date).isSame(firstAvailableDate, 'day')
      );
      const nextAvailableIndex: number = availableStartDates?.findIndex((date) =>
        moment(date).isSame(nextAvailableDate, 'day')
      );

      if (!holidayToEdit) {
        if (!onNewPlan) {
          // if driver on old R2B plan
          setValue('commencement_date', availableStartDates?.[0]);
          // new plans
        } else if (moment(nextAvailableDate).isAfter(moment(firstAvailableDate))) {
          setValue('commencement_date', availableStartDates?.[nextAvailableIndex < 0 ? 0 : nextAvailableIndex]);
        } else {
          setValue('commencement_date', availableStartDates?.[firstAvailableIndex < 0 ? 0 : firstAvailableIndex]);
        }
      }

      const completed: DriverHoliday[] = [];
      holiday_eligibility?.currentPeriodHoliday?.holidayBatches?.forEach((batch) => {
        batch?.holidays?.forEach((hol) => {
          if (hol.holiday_status === HOLIDAY_COMPLETED_STATUS) {
            completed.push(hol);
          }
        });
      });

      if (completed.length > 0) {
        setNextAvailableHolidayDateFromLastCompleted(moment(completed.at(-1)?.commencement_date).add(8, 'weeks'));
      } else {
        setNextAvailableHolidayDateFromLastCompleted(moment(holiday_eligibility?.firstAvailableHolidayDate));
      }
    },
    [bookedDates, holidayToEdit, onNewPlan, setValue]
  );

  const getReasonString = useCallback((): string => {
    let text: string = '';
    if (arrearsValue > 0) {
      text += `* Driver is in arrears of £${arrearsValue.toFixed(2)}\n`;
    }
    if (dateSelectedBeforeNextAvailable) {
      if (moment(commencementDate).isBefore(firstAvailableHolidayDate, 'day')) {
        text += `${driver12WeekRule}\n`;
      } else {
        text += `${driver8WeekRule}\n`;
      }
    }
    if (inEligibleFor4WeekBreak) {
      text += `${driver4WeekRule}\n`;
    }
    if (noHolidayAvailable) {
      text += noHolidayText;
    }
    return text;
  }, [
    arrearsValue,
    inEligibleFor4WeekBreak,
    dateSelectedBeforeNextAvailable,
    firstAvailableHolidayDate,
    noHolidayAvailable,
    commencementDate,
  ]);

  const onSubmitClick = useCallback(
    (values: OnSubmitHolidayValues): void => {
      let reason: string | undefined;
      if (approvalNeeded && values.override_reason) {
        reason = `${getReasonString()}\nReason: ${values.override_reason}`;
      }
      const newHolidayParams: NewHolidayPayload = {
        driver_id: driverDetails?.id,
        agreement_id: driverDetails?.agreement_id,
        holiday_length: Number(values.holiday_length),
        commencement_date: moment(values.commencement_date).format('YYYY-MM-DD'),
        override_reason: reason,
      };
      setSubmittingHoliday(true);
      if (holidayToEdit) {
        const updateHolidayParams: EditHolidayPayload = {
          id: holidayToEdit?.id,
          driver_id: driverDetails?.id,
          holiday_length: Number(values.holiday_length),
          commencement_date: moment.utc(values.commencement_date).format('YYYY-MM-DD'),
          override_reason: reason,
          agreement_id: driverDetails?.agreement_id,
        };
        updateHoliday(updateHolidayParams)
          .then(() => {
            setSubmittingHoliday(false);
            setHolidayConfirmed(updateHolidayParams);
          })
          .catch(() => setSubmittingHoliday(false));
      } else {
        createHoliday(newHolidayParams)
          .then(() => {
            setSubmittingHoliday(false);
            setHolidayConfirmed(newHolidayParams);
          })
          .catch(() => setSubmittingHoliday(false));
      }
    },
    [driverDetails, holidayToEdit, approvalNeeded, getReasonString]
  );

  useEffect(() => {
    // check if the date selected is before the first available date according to new rules
    // Can only take a break after 12 weeks into agreement
    // There should be an 8 week gap between holidays
    if (onNewPlan && commencementDate) {
      if (
        moment(commencementDate).isBefore(firstAvailableHolidayDate, 'day') ||
        moment(commencementDate).isBefore(
          holidayToEdit ? nextAvailableHolidayDateFromLastCompleted : nextAvailableHolidayDate,
          'day'
        )
      ) {
        setDateSelectedBeforeNextAvailable(true);
      } else {
        setDateSelectedBeforeNextAvailable(false);
      }
    }
  }, [
    commencementDate,
    onNewPlan,
    firstAvailableHolidayDate,
    nextAvailableHolidayDate,
    holidayToEdit,
    nextAvailableHolidayDateFromLastCompleted,
  ]);

  useEffect(() => {
    const commencementDates: string[] = [];
    if (holidayLength && commencementDate) {
      for (let index = 0; index < holidayLength; index += 1) {
        const nextDate: Moment = moment(commencementDate)?.add(7 * index, 'days');
        commencementDates.push(`${moment(nextDate)?.format('YYYY-MM-DDT00:00:00.000')}Z`);
      }

      setDateAlreadyBooked(
        commencementDates.some((r) =>
          bookedDates?.find((hol) => hol.commencement_date === r && hol.holiday_batch_id !== holidayToEdit?.id)
        )
      );
    }
  }, [holidayLength, bookedDates, commencementDate, holidayToEdit]);

  useEffect(() => {
    if (driverDetails.ordway_customer_id && driverDetails.ordway_subscription_id && !subscriptionDetails) {
      setSubscriptionDetailsLoading(true);
      getOrdwaySubscriptionDetails(driverDetails?.ordway_customer_id, driverDetails?.ordway_subscription_id).then(
        ({ data }) => {
          setSubscriptionDetails(data);
          if (!selectedPlan) {
            setSelectedPlan(data?.subscriptions?.plans[0]?.effective_price);
            getAvailableHolidayStartDates(data);
          }
          setSubscriptionDetailsLoading(false);
        }
      );
    }
  }, [
    driverDetails.ordway_customer_id,
    driverDetails.ordway_subscription_id,
    selectedPlan,
    subscriptionDetails,
    getAvailableHolidayStartDates,
  ]);

  if (subscriptionDetailsLoading) {
    return (
      <SpinnerContainer>
        <Spinner color={PRIMARY_PURPLE} size={24} />
      </SpinnerContainer>
    );
  }

  if (holidayConfirmed) {
    return (
      <>
        <Eligibility>
          <ConfirmationCircle size={32} color={PRIMARY_GREEN} />
          <EligibilityText>
            {holidayToEdit ? 'Holiday successfully updated' : 'Holiday successfully created'}
          </EligibilityText>
        </Eligibility>
        <FieldGrid
          numColumns={3}
          headers={['Driver:', 'Commencement date:', 'Holiday length:']}
          values={[
            driverDetails.driver_name,
            moment(holidayConfirmed.commencement_date)?.format('DD MMM YYYY'),
            holidayConfirmed.holiday_length,
          ]}
        />
      </>
    );
  }

  return (
    <>
      {subscriptionDetails && (
        <>
          <FieldGrid
            numColumns={3}
            headers={['Subscription ID:', 'Customer ID:', 'Status:']}
            values={[
              driverDetails?.ordway_subscription_id ?? '-',
              driverDetails?.ordway_customer_id ?? '-',
              subscriptionDetails?.subscriptions?.status,
            ]}
          />
          <FieldGrid
            numColumns={3}
            headers={['Renewal term:', 'Customer name:', 'Contract term:']}
            values={[
              subscriptionDetails?.subscriptions?.renewal_term,
              driverDetails.driver_name,
              subscriptionDetails?.subscriptions?.contract_term,
            ]}
          />
          <FieldGrid
            numColumns={3}
            headers={['Effective price:', 'Current term start date:', 'Current term end date:']}
            values={[
              `£${selectedPlan}`,
              moment(subscriptionDetails?.subscriptions?.current_term_start_date)?.format('DD MMM YYYY'),
              moment(subscriptionDetails?.subscriptions?.current_term_end_date)?.format('DD MMM YYYY'),
            ]}
          />
          <FieldGrid
            numColumns={1}
            headers={['Remaining contract balance:']}
            values={[`£${subscriptionDetails?.billing_schedule_items?.unbilled_subscription_amount ?? '0.00'}`]}
          />

          <Text variant="h4" color={PRIMARY_PURPLE} weight={800}>
            Payment holiday
          </Text>

          <FieldGrid
            numColumns={2}
            headers={['Annual holiday allowance:', 'Current unpaid balance:']}
            values={[
              subscriptionDetails?.holiday_eligibility?.totalHolidayAllowance,
              `£${subscriptionDetails?.arrears}`,
            ]}
          />

          {subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday ? (
            <FieldGrid
              numColumns={1}
              headers={[
                `Holiday weeks taken in previous period: ${moment(
                  subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday?.periodStartDate
                ).format('DD MMM YYYY')} 
          to ${moment(subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday?.periodEndDate).format(
            'DD MMM YYYY'
          )}`,
              ]}
              values={[subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday?.numberOfWeeksTaken]}
            />
          ) : (
            <FieldGrid
              numColumns={1}
              headers={[
                `Holiday weeks taken in this period (including upcoming): ${moment(
                  subscriptionDetails?.holiday_eligibility?.currentPeriodHoliday?.periodStartDate
                ).format('DD MMM YYYY')} 
            to ${moment(subscriptionDetails?.holiday_eligibility?.currentPeriodHoliday?.periodEndDate).format(
              'DD MMM YYYY'
            )}`,
              ]}
              values={[subscriptionDetails?.holiday_eligibility?.currentPeriodHoliday?.numberOfWeeksTaken]}
            />
          )}

          <FieldGrid
            numColumns={1}
            headers={[
              onNewPlan
                ? 'Holiday remaining:'
                : `Holiday accrued this period: ${moment(
                    subscriptionDetails?.holiday_eligibility?.currentPeriodHoliday?.periodStartDate
                  ).format('DD MMM YYYY')} 
        to today`,
            ]}
            values={[
              subscriptionDetails?.holiday_eligibility?.holidayWeeksRemaining < 0
                ? 0
                : subscriptionDetails?.holiday_eligibility?.holidayWeeksRemaining,
            ]}
          />

          {!onNewPlan && (
            <>
              <FieldGrid
                numColumns={subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday ? 2 : 1}
                headers={
                  subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday
                    ? ['Rollover weeks available:', 'Total holiday available:']
                    : ['Total holiday available:']
                }
                values={
                  subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday
                    ? [
                        subscriptionDetails?.holiday_eligibility?.rollover,
                        totalHolidayAvailable < 0 ? 0 : totalHolidayAvailable,
                      ]
                    : [totalHolidayAvailable < 0 ? 0 : totalHolidayAvailable]
                }
              />
            </>
          )}

          {(isFirstHolidayDateInFuture || isNextHolidayDateInFuture) && onNewPlan && (
            <ValidationText>
              * A valid reason is needed if selecting a date before&nbsp;
              {holidayToEdit ? (
                nextAvailableHolidayDateFromLastCompleted?.format('DD MMM YYYY')
              ) : (
                <>
                  {nextAvailableHolidayDate && nextAvailableHolidayDate.isAfter(firstAvailableHolidayDate)
                    ? nextAvailableHolidayDate?.format('DD MMM YYYY')
                    : firstAvailableHolidayDate?.format('DD MMM YYYY')}
                </>
              )}
            </ValidationText>
          )}

          <FlexLayout styled={{ width: '100%', marginBottom: 30 }} gap={20} itemsX="space-between">
            <DatePickerComponent
              control={control}
              name="commencement_date"
              label={'Holiday commencement date:'}
              includedDates={availableHolidayStartDates}
              rules={{
                required: true,
              }}
              errors={errors}
              dayClassName={(date) => {
                if (holidayToEdit) {
                  if (moment(date).isBefore(nextAvailableHolidayDateFromLastCompleted, 'day')) {
                    return 'needsApproval';
                  }
                  return 'approved';
                }
                if (
                  moment(date)?.isBefore(firstAvailableHolidayDate, 'day') ||
                  moment(date)?.isBefore(nextAvailableHolidayDate, 'day')
                ) {
                  return 'needsApproval';
                }
                return 'approved';
              }}
            />
            <DropdownContainer>
              <TextFieldLabel $isRequired>Number of weeks:</TextFieldLabel>
              <DropDown
                name="holiday_length"
                options={holidayLengthOptions}
                placeholder="Holiday length"
                control={control as unknown as Control<FieldValues>}
                required={{
                  required: true,
                }}
                error={errors.holiday_length}
              />
            </DropdownContainer>
          </FlexLayout>

          {approvalNeeded && (
            <AprovalNeededReasonSection>
              <Eligibility>
                <IoIosWarning size={24} color={STATUS_YELLOW} />
                <ViolationText>
                  The driver does not meet all the criteria for a holiday, so a reason needs to be provided why an
                  exception was made.
                </ViolationText>
              </Eligibility>

              <TextArea
                label="Override reason"
                {...register('override_reason')}
                name="override_reason"
                placeholder="Override reason"
                required={approvalNeeded}
                error={errors?.override_reason}
              />
            </AprovalNeededReasonSection>
          )}

          <Errors>
            {(isFirstHolidayDateInFuture || isNextHolidayDateInFuture) && onNewPlan && dateAlreadyBooked && (
              <ViolationText>
                The holiday dates selected conflict with an already booked holiday. Please choose a different
                commencement date or change the holiday length.
              </ViolationText>
            )}
            {dateSelectedBeforeNextAvailable && commencementDate && (
              <ViolationText>
                {moment(commencementDate).isBefore(firstAvailableHolidayDate, 'day')
                  ? driver12WeekRule
                  : driver8WeekRule}
              </ViolationText>
            )}
            {noHolidayAvailable && <ViolationText>{noHolidayText}</ViolationText>}
            {arrearsValue > 0 && (
              <ViolationText>{`* Driver's account is in arrears of £${subscriptionDetails?.arrears}`}</ViolationText>
            )}
            {inEligibleFor4WeekBreak && <ViolationText>{driver4WeekRule}</ViolationText>}
          </Errors>

          <PrimaryButton
            onClick={handleSubmit(onSubmitClick)}
            isProcessing={submittingHoliday}
            styled={{ padding: '0px 38px' }}
            disabled={(approvalNeeded && !overrideReason) || !commencementDate || dateAlreadyBooked}
          >
            {holidayToEdit ? 'UPDATE' : 'APPLY'}
          </PrimaryButton>
        </>
      )}
    </>
  );
};
