import styled, { css } from 'styled-components';
import { FiSearch } from 'react-icons/fi';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { Menu } from '../../uiComponents/menu/menu';
import { Spinner } from '../../uiComponents/uiControls/spinner/spinner';
import { TextInput } from '../../uiComponents/inputs/textInput/textInput';
import { PRIMARY_PURPLE, PRIMARY_GREEN, SECONDARY_PURPLE_90, PRIMARY_WHITE, GRAY_1 } from '../../common/styles/Colors';
import { darken } from '../../utils/colorUtils';

export const PageTitleContainer = styled.div<{ $showShadow: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 32px;
  min-width: 1024px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${GRAY_1};
  z-index: 150;

  ${(props) => {
    if (props.$showShadow) {
      return css`
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      `;
    }
  }}
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-left: 34px;
`;

export const TitleText = styled.h1`
  font-size: 32px;
  line-height: 34px;
  margin-left: 16px;
  margin-top: 0;
  margin-bottom: 0;
  color: ${SECONDARY_PURPLE_90};
`;

export const FlexContainer = styled(FlexLayout)<{ $showPointer?: boolean; $relative?: boolean }>`
  cursor: ${(props) => (props?.$showPointer ? 'pointer' : 'inherit')};
  ${(props) => {
    if (props.$relative) {
      return css`
        position: relative;
      `;
    }
    return null;
  }}
`;

export const SearchIcon = styled(FiSearch)`
  position: absolute;
  right: 16px;
  top: 10px;
`;

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  right: 44px;
  top: 14px;
`;

export const UserText = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: ${SECONDARY_PURPLE_90};
  margin-left: 24px;
`;

export const OttoLogo = styled.img`
  width: 66px;
  cursor: pointer;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const StyledDropdownMenu = styled(Menu)`
  position: absolute;
  top: 22px;
  right: 0;
  z-index: 120;
`;

export const StyledTextInput = styled(TextInput)`
  padding: 8px 40px 8px 16px;
  min-width: 232px;

  &::placeholder {
    font-size: 12px;
  }
`;

export const SearchResults = styled.div`
  position: absolute;
  width: 550px;
  max-height: 500px;
  overflow-y: auto;
  background-color: ${PRIMARY_WHITE};
  top: 50px;
  right: 0;
  border-radius: 16px;
  border: 1px solid ${PRIMARY_GREEN};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 20;
`;

export const ResultSelector = styled(FlexLayout)`
  padding: 8px;
  cursor: pointer;
`;

export const ResultRow = styled(FlexLayout)`
  padding: 0 8px;
  height: 40px;
  transition: background-color 0.3s ease-in;
  cursor: pointer;

  &:hover {
    background-color: ${darken(PRIMARY_WHITE, 5)};
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  }

  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

export const ResultText = styled.div<{ $noWrap?: boolean }>`
  font-size: 14px;
  color: ${PRIMARY_PURPLE};

  ${(props) => {
    if (props.$noWrap) {
      return css`
        white-space: nowrap;
      `;
    }
    return null;
  }}
`;

export const ClearIcon = styled(FlexLayout)`
  position: absolute;
  right: 16px;
  width: 32px;
  top: 0px;
  height: 42px;
  cursor: pointer;
`;
