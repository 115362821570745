import React, { useCallback, useEffect, useState } from 'react';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PackagePricingForm } from './packagePricingForm';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { ConfirmationModal } from '../../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Modal } from '../../../../uiComponents/modals/modal';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { ActionIcon } from '../../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../../uiComponents/table/table';
import { vehiclePackageColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import { DropDownFilter, FilterItem } from '../../../../uiComponents/table/tableFilters/tableFilters';
import { TableHeader } from '../../../../uiComponents/table/tableHeader/tableHeader';
import { getQueryString, TableTextCell } from '../../../../uiComponents/table/tableUtils/tableUtils';
import { Notification } from '../../../../uiComponents/toast/toast';
import { deleteVehiclePackage } from '../../../../api/delete/vehiclePackage.delete';
import { getAllVehiclePackages } from '../../../../api/get/vehiclePackage.get';
import { VehiclePackage } from '../../../../models/vehicle';
import { PRIMARY_GRAY } from '../../../../common/styles/Colors';
import { OptionList } from '../../../../utils/props';

export const VehiclePackages = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { manufacturer, model } = location.state.queryParams;
  const [deletePayload, setDeletePayload] = useState<VehiclePackage>();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [isCreatePackageModalOpen, setCreatePackageModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [vehiclePackage, setVehiclePackage] = useState<VehiclePackage | null>(null);
  const [selectedPackageType, setSelectedPackageType] = useState<OptionList[]>([]);
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setSortAscending,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  useEffect(() => {
    const vehicleTypeTitle = `${manufacturer} ${model}`;
    setTitle(vehicleTypeTitle);
  }, [manufacturer, model]);

  useEffect(() => {
    setTableFilters([{ columnName: 'package_type', options: selectedPackageType }]);
  }, [setTableFilters, selectedPackageType]);

  const openEditPackageModal = useCallback(
    (param: VehiclePackage) => {
      setVehiclePackage(param);
      setEditModalOpen(true);
    },
    [setVehiclePackage, setEditModalOpen]
  );

  const handleGetVehiclePackageResponse = useCallback(
    (count: number, lists: VehiclePackage[]) => {
      const handleDeleteVehiclePackage = (row: VehiclePackage) => {
        setIsDeleteConfirmationModalOpen(true);
        setDeletePayload(row);
      };

      const vehiclePackage = lists?.map((list: VehiclePackage) => {
        return {
          rowData: { data: list },
          cells: [
            <TableTextCell value={list.package_name} />,
            <TableTextCell value={list.prices ? list.prices.length.toString() : ''} />,
            <TableTextCell value={list.total_weeks.toString()} />,
            <TableTextCell value={list?.package_type ? list.package_type : ''} />,
            <FlexLayout itemsX="start" itemsY="center">
              <ActionIcon
                icon={
                  <BsPencil
                    onClick={() => {
                      openEditPackageModal(list);
                    }}
                    style={{ marginRight: '30px' }}
                    size={24}
                    color={PRIMARY_GRAY}
                  />
                }
                tooltip="Edit"
              />
              <ActionIcon
                icon={
                  <BsTrash
                    onClick={() => {
                      handleDeleteVehiclePackage(list);
                    }}
                    size={24}
                    color={PRIMARY_GRAY}
                  />
                }
                tooltip="Delete"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(vehiclePackage);
      setTotalRows(count);
    },
    [setTableData, setTotalRows, openEditPackageModal]
  );

  useEffect(() => {
    setSortingColumn('updated_date');
    setSortAscending(false);
    if (id) {
      getAllVehiclePackages(id, '').then((response: { count: number; data: VehiclePackage[] }) => {
        handleGetVehiclePackageResponse(response.count, response.data);
      });
    }
  }, [handleGetVehiclePackageResponse, id, setSortAscending, setSortingColumn]);

  const openCreatePackageModal = useCallback(() => {
    setCreatePackageModalOpen(true);
  }, [setCreatePackageModalOpen]);

  const closePackageModal = useCallback(
    (param?: string) => {
      setCreatePackageModalOpen(false);
      setEditModalOpen(false);
      if (param === 'close') {
        if (id) {
          getAllVehiclePackages(id, '').then((response: { count: number; data: VehiclePackage[] }) => {
            handleGetVehiclePackageResponse(response.count, response.data);
          });
        }
      }
    },
    [handleGetVehiclePackageResponse, id]
  );

  const filters: FilterItem[] = [
    {
      name: 'package_type',
      element: (
        <DropDownFilter
          name="package_type"
          placeholder="Select a package"
          options={[
            { value: 'FRIENDLY-FINANCE', label: 'Friendly Finance' },
            { value: 'R2B', label: 'R2B' },
          ]}
          multiValues={selectedPackageType}
          title="Package type"
          onChange={(items) => setSelectedPackageType(items as OptionList[])}
        />
      ),
    },
  ];

  const fetchVehiclePackages = useCallback(
    (queryString: string) => {
      if (id) {
        getAllVehiclePackages(id, queryString).then((response: { count: number; data: VehiclePackage[] }) => {
          handleGetVehiclePackageResponse(response.count, response.data);
        });
      }
    },
    [handleGetVehiclePackageResponse, id]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchVehiclePackages(queryString);
    },
    [setTableData, goToPageNumber, tableFilters, fetchVehiclePackages]
  );

  const onClearClick = useCallback(() => {
    setSelectedPackageType([]);
  }, []);

  return (
    <>
      <div style={{ margin: '0 32px' }}>
        <FlexLayout itemsX="end" itemsY="end" styled={{ marginBottom: '15px' }}>
          <SecondaryButton onClick={() => navigate(-1)}>Go back</SecondaryButton>
        </FlexLayout>
        <Table
          header={
            <TableHeader
              tableHeaderTitle={title}
              actionButtonText="Create package"
              onButtonClick={() => openCreatePackageModal()}
            />
          }
          onColumnHeaderClick={(columnId: string) =>
            applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
          }
          sortAscending={sortAscending}
          columns={vehiclePackageColumns}
          rows={tableData}
          totalRows={totalRows}
          rowsPerPage={numRowsPerPage}
          filterQuery={filterQuery}
          filters={filters}
          currentPageNumber={pageNumber}
          sortingColumn={sortingColumn}
          goToPage={(pageNumber: number) => {
            goToPageNumber(pageNumber);
            applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }}
          onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
          onClearClick={() => onClearClick()}
          onNumRowsPerPageChange={(value: number) => {
            setNumRowsPerPage(value);
            goToPageNumber(0);
            applyFilters(0, value, searchString, sortingColumn, sortAscending);
          }}
          onSearchChange={(value: string) => {
            setSearchString(value);
            applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
          }}
        />
        <Modal
          title={isEditModalOpen ? 'Edit package' : 'Create package'}
          open={isCreatePackageModalOpen || isEditModalOpen}
          onClose={closePackageModal}
          showClose
          showOverflow={false}
          styled={{ width: '80vw', minWidth: 800 }}
        >
          <PackagePricingForm
            close={closePackageModal}
            type={isEditModalOpen ? 'edit' : 'create'}
            vehiclePackage={vehiclePackage}
          />
        </Modal>
        {deletePayload && deletePayload.id && deletePayload.vehicle_type_id && (
          <ConfirmationModal
            title="Are you sure you want to delete this vehicle package?"
            isOpen={isDeleteConfirmationModalOpen}
            onClose={() => setIsDeleteConfirmationModalOpen(false)}
            preConfirm={async () => {
              await deleteVehiclePackage(
                deletePayload?.vehicle_type_id ? deletePayload.vehicle_type_id : '',
                deletePayload?.id ? deletePayload.id : ''
              ).then(() => {
                Notification({
                  type: 'success',
                  title: 'Success',
                  message: 'Vehicle type has been successfully deleted',
                });
                if (id) {
                  setIsDeleteConfirmationModalOpen(false);
                  getAllVehiclePackages(id, '').then((response: { count: number; data: VehiclePackage[] }) => {
                    handleGetVehiclePackageResponse(response.count, response.data);
                  });
                }
              });
            }}
            confirmButtonCaption={'Yes'}
            closeButtonCaption={'No'}
          />
        )}
      </div>
    </>
  );
};
