import React from 'react';
import { Control, FieldErrors, FieldValues, UseFormRegister, UseFormWatch, useFieldArray } from 'react-hook-form';
import { CheckContainer } from './checkSections.styles';
import { VehicleCheck } from './sections/vehicleCheck';
import { DriverTagsCheck } from './sections/driverTagsCheck';
import { DamageLogCheck } from './sections/damageLogCheck';
import { ConditionReportSignOff } from './sections/signOffCheck';
import { TERMINATED, TERMINATE_AND_SERVICE } from '../../../../consts';
import { ConditionReportFields, DamageReportItem, DamageResolutionItem } from '../../../../consts/conditionReport';
import { MandatoryPhotosCheck } from './sections/mandatoryPhotosCheck';
import { DamageLogReport } from './sections/damageLogReport/damageLogReport';
import { Notification } from '../../../../uiComponents/toast/toast';
import { createDamageLog } from '../../../../api/post/conditionReport.post';
import { DamageLogPayload, DamageResolutionPayload, ImageArrayType } from '../../../../models/transfer';
import { editDamageLog, resolveDamageLog } from '../../../../api/patch/conditionReport.patch';
import { fileToBase64 } from '../../../../utils/utils';
import moment from 'moment';
import { uploadToS3 } from '../../../../api/post/document.post';

export interface CheckSectionsProps {
  control: Control<FieldValues>;
  disabled: boolean;
  errors: FieldErrors<ConditionReportFields>;
  uploadProgress: number;
  uploadingImages: boolean;
  reason: string | undefined;
  register: UseFormRegister<ConditionReportFields>;
  watch: UseFormWatch<ConditionReportFields>;
  previousMileage: number;
  conditionReportId: string | undefined;
  vehicle_id?: string;
}

export const CheckSections = ({
  control,
  disabled,
  errors,
  uploadProgress,
  uploadingImages,
  reason,
  register,
  watch,
  previousMileage,
  conditionReportId,
  vehicle_id,
}: CheckSectionsProps) => {
  const { append, update } = useFieldArray({
    control,
    name: 'damageLog',
  });

  const mandatoryPhotosChecklistComplete =
    watch('front') != null &&
    watch('back') != null &&
    watch('passengerSide') != null &&
    watch('driverSide') != null &&
    watch('chargeCables') != null &&
    watch('lockingNut') != null &&
    watch('repairKitTire') != null &&
    watch('dashboard') != null;

  const vehicleInformationComplete =
    watch('oilLevel') != null &&
    watch('oilLevel') !== '' &&
    watch('screenWashLevel') != null &&
    watch('screenWashLevel') !== '' &&
    watch('fuelLevel') != null &&
    watch('fuelLevel') !== '' &&
    watch('mileage') != null &&
    watch('mileage') !== '';

  const damageLogs = watch('damageLog');
  const damageLogComplete = damageLogs != null && damageLogs.length > 0;

  const tagsChecklistComplete = watch('tags') !== undefined && watch('tags_note') !== '';

  async function uploadImages(damageLogId: string, files: Array<File | Blob> | undefined) {
    const allImages: ImageArrayType[] = [];

    const path = `DOCUMENTS/CONDITION_REPORT/${vehicle_id}/${moment().format(
      'YYYY-MM-DD_HH-MM-SS'
    )}/DAMAGE_LOG/${damageLogId}/PHOTOS/`;
    let index = 0;
    if (files && files.length > 0) {
      for await (const i of files) {
        const item: File = i as File;
        allImages.push({
          fileName: `${damageLogId}_${index}`,
          type: item?.type,
          s3_url: await uploadToS3({
            path: `${path}${damageLogId}_${index}`,
            fileName: `${damageLogId}_${index}` ?? '',
            type: item?.type,
            contents: await fileToBase64(item),
          }).then(({ data }) => {
            index += 1;
            return data.s3_url;
          }),
        });
      }
    }
    return allImages;
  }

  async function onAddDamageLog(submitValues: DamageReportItem) {
    const damageLogId = new Date().getTime();
    createDamageLog({
      condition_report_id: conditionReportId,
      damaged_type: submitValues.type,
      damaged_area: submitValues.name,
      damage_description: submitValues.description,
      damage_detail: submitValues.details,
      images_folder: damageLogId.toString(),
      files: await uploadImages(damageLogId.toString(), submitValues.photos),
    } as DamageLogPayload)
      .then(({ id }) => {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Damage log created successfully.',
        });
        submitValues.id = id;
        append(submitValues);
      })
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Error',
          message: 'There was a problem adding the damage log.',
        });
      });
  }

  async function onEditDamageLog(index: number, submitValues: DamageReportItem) {
    if (submitValues.id) {
      const damageLogId = new Date().getTime();
      editDamageLog(submitValues.id, {
        condition_report_id: conditionReportId,
        damaged_type: submitValues.type,
        damaged_area: submitValues.name,
        damage_description: submitValues.description,
        damage_detail: submitValues.details,
        images_folder: damageLogId.toString(),
        files: await uploadImages(damageLogId.toString(), submitValues.photos),
      } as DamageLogPayload)
        .then(() => {
          Notification({
            type: 'success',
            title: 'Success',
            message: 'Damage log edited successfully.',
          });
          update(index, submitValues);
        })
        .catch(() => {
          Notification({
            type: 'error',
            title: 'Error',
            message: 'There was a problem editing the damage log.',
          });
        });
    }
  }

  async function onResolutionSubmit(submitValues: DamageResolutionItem, id?: string) {
    if (id) {
      resolveDamageLog({
        id: id,
        date: submitValues.date,
        supplier: submitValues.supplier,
        resolution: submitValues.resolution,
        photos: submitValues.photos,
      } as DamageResolutionPayload)
        .then(() => {
          Notification({
            type: 'success',
            title: 'Success',
            message: 'Damage log resolved successfully.',
          });
        })
        .catch(() => {
          Notification({
            type: 'error',
            title: 'Error',
            message: 'There was a problem resolving the damage log.',
          });
        });
    }
  }

  return (
    <CheckContainer $disabled={disabled}>
      <MandatoryPhotosCheck isComplete={mandatoryPhotosChecklistComplete} control={control} errors={errors} />
      <VehicleCheck
        previousMileage={previousMileage}
        isComplete={vehicleInformationComplete}
        control={control}
        register={register}
        errors={errors}
      />
      {reason && [TERMINATED, TERMINATE_AND_SERVICE].includes(reason) && (
        <DriverTagsCheck control={control} register={register} isComplete={!!tagsChecklistComplete} errors={errors} />
      )}
      <DamageLogCheck isComplete={damageLogComplete} onSubmit={onAddDamageLog} />
      {damageLogs &&
        damageLogs.map((damageLog, index) => {
          return (
            <DamageLogReport
              key={index}
              values={damageLog}
              onSubmit={(values) => onEditDamageLog(index, values)}
              onResolutionSubmit={(values) => onResolutionSubmit(values, damageLog.id)}
            />
          );
        })}
      <ConditionReportSignOff
        control={control}
        register={register}
        errors={errors}
        uploadProgress={uploadProgress}
        uploadingImages={uploadingImages}
      />
    </CheckContainer>
  );
};
