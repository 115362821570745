import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// utils
import { useTableFilters } from '../../../hooks/useTableFilters';
import moment from 'moment';
import {
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { APP_CONTEXT } from '../../../utils/context';
import { OptionList } from '../../../utils/props';
import { AGREEMENTS } from '../../../consts/routes';
// api
import { cancelAftercareSetup } from '../../../api/delete/aftercare.delete';
import { getAftercareReviewAgreements } from '../../../api/get/aftercare.get';
// models
import { AftercareReview } from '../../../models/aftercare';
// styles
import { PRIMARY_PURPLE, SECONDARY_PURPLE_30, STATUS_ORANGE, STATUS_YELLOW } from '../../../common/styles/Colors';
// components
import { AftercareSetupForm } from '../aftercareSetup/aftercareSetup';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { BsCheckCircle, BsDashCircleDotted, BsEye, BsPencil } from 'react-icons/bs';
import { Table } from '../../../uiComponents/table/table';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import { Modal } from '../../../uiComponents/modals/modal';
import { CompleteAftercare } from './activateAftercare/activateAftercare';
import { Tag } from '../../../uiComponents/customComponents/tag/tag';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Notification } from '../../../uiComponents/toast/toast';
import { AxiosError } from 'axios';
import { aftercareServiceOptions } from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import { aftercareReviewColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { AGREEMENT_SIGNATURE_PENDING, AGREEMENT_SIGNED } from '../../../consts/agreement';
import { useDateRangeFilter } from '../../../hooks/useDateRangeFilter';

export const AftercareReviewList = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [serviceFilter, setServiceFilter] = useState<OptionList[]>([]);
  const [activeAftercare, setActiveAftercare] = useState<AftercareReview | null>(null);
  const [isAftercareSetupModalOpen, setIsAftercareSetupModalOpen] = useState<boolean>(false);
  const [isCancelAftercareModalOpen, setIsCancelAftercareModalOpen] = useState<boolean>(false);
  const [isCompleteAftercareModalOpen, setIsCompleteAftercareModalOpen] = useState<boolean>(false);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const navigate = useNavigate();

  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    getSortDirection,
    setSortingColumn,
    setTableFilters,
    setNumRowsPerPage,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  useEffect(() => {
    setActiveSideNav('aftercareListPage');
    setPageTitle('Aftercare');
    setSortingColumn('end_date');
  }, [setActiveSideNav, setPageTitle, setSortingColumn]);

  const handleCancelAftercare = () => {
    return cancelAftercareSetup({
      id: activeAftercare?.id ?? '',
    })
      .then(() => {
        setIsCancelAftercareModalOpen(false);
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Aftercare agreement cancelled successfully',
          isAlert: true,
        });
        applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
      })
      .catch((e: AxiosError<Error>) => {
        Notification({
          type: 'error',
          title: 'Error',
          message: e.message,
        });
      });
  };

  useEffect(() => {
    if (!serviceFilter) return;
    setTableFilters([
      { columnName: 'aftercare_type', options: serviceFilter },
      {
        columnName: 'agreement.end_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [setTableFilters, dateRangeFilter, serviceFilter]);

  const handleCompleteAftercareModalClose = () => {
    setIsCompleteAftercareModalOpen(false);
    setActiveAftercare(null);
    fetchData(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}`);
  };

  const handleGetAftercareReviewResponse = useCallback(
    (count: number, data: AftercareReview[]) => {
      const aftercareRows = data?.map((aftercareItem: AftercareReview) => {
        return {
          rowData: { data: aftercareItem },
          cells: [
            <TableTextCell value={aftercareItem?.driver_name} />,
            <TableTextCell value={aftercareItem?.vehicle_name} />,
            <TableTextCell value={aftercareItem?.vrm} />,
            <TableTextCell value={aftercareItem?.driver_mobile_phone} />,
            <TableTextCell
              value={aftercareItem?.end_date ? moment(aftercareItem?.end_date).format('DD MMM YYYY') : '-'}
            />,
            <FlexLayout itemsX="start">
              <Tag key={`aftercare_agreement_status_${aftercareItem?.aftercare_type}`} color={PRIMARY_PURPLE}>
                {aftercareItem?.aftercare_type}
              </Tag>
            </FlexLayout>,
            <FlexLayout itemsX="start">
              <Tag
                key={`aftercare_agreement_status_${aftercareItem.original_agreement_id}`}
                color={aftercareItem.scrive_signed ? STATUS_YELLOW : STATUS_ORANGE}
              >
                {aftercareItem.scrive_signed ? AGREEMENT_SIGNED : AGREEMENT_SIGNATURE_PENDING}
              </Tag>
            </FlexLayout>,
            <FlexLayout gap={16}>
              <ActionIcon
                icon={
                  <BsPencil
                    onClick={() => {
                      setActiveAftercare(aftercareItem);
                      setIsAftercareSetupModalOpen(true);
                    }}
                    size={24}
                    color={PRIMARY_PURPLE}
                  />
                }
                tooltip="Edit aftercare setup"
              />
              <ActionIcon
                icon={
                  <BsCheckCircle
                    size={24}
                    aria-disabled={!aftercareItem.scrive_signed}
                    color={aftercareItem.scrive_signed === true ? PRIMARY_PURPLE : SECONDARY_PURPLE_30}
                    onClick={() => {
                      if (aftercareItem.scrive_signed === false) return;
                      setActiveAftercare(aftercareItem);
                      setIsCompleteAftercareModalOpen(true);
                    }}
                  />
                }
                disabled={aftercareItem.scrive_signed === false}
                tooltip="Go live"
              />
              <ActionIcon
                icon={
                  <BsDashCircleDotted
                    size={24}
                    onClick={() => {
                      setActiveAftercare(aftercareItem);
                      setIsCancelAftercareModalOpen(true);
                    }}
                    color={PRIMARY_PURPLE}
                  />
                }
                tooltip="Cancel agreement"
              />
              <ActionIcon
                icon={
                  <BsEye
                    onClick={() => navigate(`${AGREEMENTS}/${aftercareItem?.original_agreement_id}`)}
                    size={24}
                    color={PRIMARY_PURPLE}
                  />
                }
                tooltip="View agreement page"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(aftercareRows);
      setTotalRows(count);
    },
    [setTableData, setTotalRows, navigate]
  );

  const fetchData = useCallback(
    async (queryString: string) => {
      const controller = new AbortController();

      getAftercareReviewAgreements(queryString, controller.signal).then(({ data, count }) => {
        handleGetAftercareReviewResponse(count, data);
      });
    },
    [handleGetAftercareReviewResponse]
  );

  const filters: FilterItem[] = [
    {
      name: 'plan',
      element: (
        <DropDownFilter
          name="plan"
          placeholder="Plan selected"
          options={aftercareServiceOptions}
          multiValues={serviceFilter}
          title="Plan selected"
          onChange={(items) => setServiceFilter(items as OptionList[])}
        />
      ),
    },
    {
      name: 'date-range',
      element: (
        <DateRangeFilter
          title="Agreement completion"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const AgreementListColumns = useMemo(() => [...aftercareReviewColumns], []);

  useEffect(() => {
    fetchData(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}`);
  }, [fetchData]);

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchData(queryString);
    },
    [fetchData, setTableData, goToPageNumber, tableFilters]
  );

  const onClearClick = useCallback(() => {
    setServiceFilter([]);
    setDateRangeFilter([]);
    fetchData(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=first_name:ASC`);
  }, [fetchData, setServiceFilter, setDateRangeFilter]);

  return (
    <>
      <Table
        header="Aftercare review"
        onColumnHeaderClick={(columnId: string) => {
          applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId));
        }}
        disableApply={invalidDates}
        sortAscending={sortAscending}
        columns={AgreementListColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onRowClick={({ data }: { data: AftercareReview }) => {
          navigate(`${AGREEMENTS}/${data?.original_agreement_id}`);
        }}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
        }}
      />
      {activeAftercare && (
        <>
          <Modal
            open={isCompleteAftercareModalOpen}
            showClose
            showOverflow={false}
            onClose={handleCompleteAftercareModalClose}
          >
            <CompleteAftercare aftercareId={activeAftercare.id} onClose={handleCompleteAftercareModalClose} />
          </Modal>
          <Modal
            title="Edit aftercare setup"
            open={isAftercareSetupModalOpen}
            showClose
            showOverflow={false}
            onClose={() => setIsAftercareSetupModalOpen(false)}
          >
            <AftercareSetupForm
              aftercare={activeAftercare}
              onClose={() => setIsAftercareSetupModalOpen(false)}
              isInEdit={true}
              onSubmit={() => applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending)}
            />
          </Modal>
          <ConfirmationModal
            title={'You are about to cancel this agreement before it has gone live.'}
            isOpen={isCancelAftercareModalOpen}
            onClose={() => setIsCancelAftercareModalOpen(false)}
            preConfirm={handleCancelAftercare}
            closeButtonCaption="Cancel"
            confirmButtonCaption="Yes"
          />
        </>
      )}
    </>
  );
};
