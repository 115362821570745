import { useCallback, useContext, useEffect, useState } from 'react';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Table } from '../../../uiComponents/table/table';
import { TableHeader } from '../../../uiComponents/table/tableHeader/tableHeader';
import { useTableFilters } from '../../../hooks/useTableFilters';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableTagCell,
  TableTextCell,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { getAllInsurancePolices } from '../../../api/get/insurance.get';
import {
  InitiateDataValidationPayload,
  InitiateInsuranceRunPayload,
  ModifiedInsurancePolicy,
} from '../../../models/insurancePolicy';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { BsEye, BsPencil, BsExclamationTriangle } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';
import {
  PRIMARY_PURPLE,
  PRIMARY_WHITE,
  STATUS_BLUE,
  STATUS_BLUE_OPACITY,
  STATUS_YELLOW,
  STATUS_YELLOW_OPACITY,
} from '../../../common/styles/Colors';
import { insurancePolicyColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { Modal } from '../../../uiComponents/modals/modal';
import { CreateEditPolicyForm } from '../createEditPolicyForm/createEditPolicyForm';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Notification } from '../../../uiComponents/toast/toast';
import { deleteInsurancePolicy } from '../../../api/delete/insurance.delete';
import { APP_CONTEXT } from '../../../utils/context';
import moment from 'moment';
import { ViewReviewPolicyForm } from '../viewReviewPolicyForm/viewReviewPolicyForm';
import { isDateWithinOneDayBefore, isDateWithinOneMonthBefore, isDateWithinOneWeekBefore } from '../../../utils/utils';
import { initiateInsuranceDataValidation, initiateInsuranceRunProcess } from '../../../api/post/insurance.post';
import {
  UPCOMING_INSURANCE_POLICY,
  FIRST_DATA_REVIEW_COMPLETE,
  SECOND_DATA_REVIEW_COMPLETE,
} from '../../../consts/insurance';

export const PoliciesList = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [policyValues, setPolicyValues] = useState<ModifiedInsurancePolicy | undefined>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditPolicy, setIsEditPolicy] = useState<boolean>(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [isViewPolicy, setIsViewPolicy] = useState<boolean>(false);
  const [isRenewPolicy, setIsRenewPolicy] = useState<boolean>(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState<boolean>(false);
  const [isInitiateDataValidationModalOpen, setIsInitiateDataValidationModalOpen] = useState<boolean>(false);
  const [isInitiateInsuranceRunModalOpen, setIsInitiateInsuranceRunModalOpen] = useState<boolean>(false);

  const upcomingInsuranceProcess = (policy: ModifiedInsurancePolicy) => {
    switch (true) {
      case isDateWithinOneMonthBefore(policy.start_date) &&
        policy.insurance_policy_status === UPCOMING_INSURANCE_POLICY:
        return STATUS_YELLOW_OPACITY;
      case isDateWithinOneWeekBefore(policy.start_date) &&
        policy.insurance_policy_status === FIRST_DATA_REVIEW_COMPLETE:
        return STATUS_YELLOW_OPACITY;
      case isDateWithinOneDayBefore(policy.start_date) &&
        policy.insurance_policy_status === SECOND_DATA_REVIEW_COMPLETE:
        return STATUS_BLUE_OPACITY;
      default:
        return PRIMARY_WHITE;
    }
  };
  const {
    setTableData,
    // setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortAscending,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const handleGetInsurancePoliciesResponse = useCallback(
    (count: number, policies: ModifiedInsurancePolicy[]) => {
      const policyRows = policies?.map((policy: ModifiedInsurancePolicy) => {
        if (policy.id === '2bd9798b-184c-42c8-8f2e-e466ead99151') {
          isDateWithinOneDayBefore(policy.start_date);
        }
        return {
          rowData: { data: policy, rowColour: upcomingInsuranceProcess(policy) },
          cells: [
            <TableTextCell value={policy?.insurance_company} />,
            <TableTextCell value={policy?.policy_number} />,
            <TableTextCell value={moment(policy?.start_date).format('DD/MM/YYYY [at] HH:mm')} />,
            <TableTextCell value={moment(policy?.end_date).format('DD/MM/YYYY [at] HH:mm')} />,
            <TableTextCell value={policy?.vrm_number_range.join(', ')} />,
            <TableTagCell tags={[policy?.insurance_policy_status]} />,
            <FlexLayout gap={16}>
              <ActionIcon
                icon={
                  <BsEye
                    onClick={() => {
                      setPolicyValues(policy);
                      setIsViewModalOpen(true);
                      setIsViewPolicy(true);
                    }}
                    size={24}
                    color={PRIMARY_PURPLE}
                  />
                }
                tooltip="View policy"
              />
              <ActionIcon
                icon={
                  <BsPencil
                    onClick={() => {
                      setPolicyValues(policy);
                      setIsEditPolicy(true);
                      setIsModalOpen(true);
                    }}
                    size={24}
                    color={PRIMARY_PURPLE}
                  />
                }
                tooltip="Edit policy"
              />

              <ActionIcon
                icon={
                  <FiTrash2
                    onClick={() => {
                      setPolicyValues(policy);
                      setIsConfirmDeleteModalOpen(true);
                    }}
                    size={24}
                    color={PRIMARY_PURPLE}
                  />
                }
                tooltip="Delete policy"
              />

              {(isDateWithinOneMonthBefore(policy.start_date) &&
                policy.insurance_policy_status === UPCOMING_INSURANCE_POLICY) ||
              (isDateWithinOneWeekBefore(policy.start_date) &&
                policy.insurance_policy_status === FIRST_DATA_REVIEW_COMPLETE) ? (
                <ActionIcon
                  icon={
                    <BsExclamationTriangle
                      onClick={() => {
                        setPolicyValues(policy);
                        setIsInitiateDataValidationModalOpen(true);
                      }}
                      size={24}
                      color={STATUS_YELLOW}
                    />
                  }
                  tooltip="Data review"
                />
              ) : null}

              {isDateWithinOneDayBefore(policy.start_date) &&
                policy.insurance_policy_status === SECOND_DATA_REVIEW_COMPLETE && (
                  <ActionIcon
                    icon={
                      <BsExclamationTriangle
                        onClick={() => {
                          setPolicyValues(policy);
                          setIsInitiateInsuranceRunModalOpen(true);
                        }}
                        size={24}
                        color={STATUS_BLUE}
                      />
                    }
                    tooltip="Insurance run"
                  />
                )}
            </FlexLayout>,
          ],
        };
      });
      setTableData(policyRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const fetchPolciesList = useCallback(
    (queryString: string) => {
      getAllInsurancePolices(queryString).then((response: { count: number; data: ModifiedInsurancePolicy[] }) => {
        handleGetInsurancePoliciesResponse(response.count, response.data);
      });
    },
    [handleGetInsurancePoliciesResponse]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string | undefined,
      sortAscending: boolean
    ) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchPolciesList(queryString);
    },
    [fetchPolciesList, setTableData, goToPageNumber, tableFilters]
  );

  const deletePolicy = async (id: string) => {
    await deleteInsurancePolicy(id)
      .then(() => {
        applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Policy has been successfully deleted',
          isAlert: true,
        });
      })
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Error',
          message: 'Error while deleting policy',
          isAlert: true,
        });
      });
  };

  const initiateDataValidationCheck = async (policy: ModifiedInsurancePolicy) => {
    const payload: InitiateDataValidationPayload = {
      id: policy.id,
      body: {
        vrm_number_range: policy.vrm_number_range,
        insurance_policy_status: policy.insurance_policy_status,
        start_date: policy.start_date,
        policy_number: policy.policy_number,
      },
    };
    await initiateInsuranceDataValidation(payload).then(() => {
      Notification({
        type: 'success',
        title: 'Success',
        message:
          'Policy data review has been initiated, please keep a lookout for completion of the data review, well notify you via email',
        isAlert: true,
      });
    });
  };

  const initiateInsuranceRun = async (policy: ModifiedInsurancePolicy) => {
    const payload: InitiateInsuranceRunPayload = {
      id: policy.id,
      body: {
        vrm_number_range: policy.vrm_number_range,
        policy_start_date: policy.start_date,
        policy_end_date: policy.end_date,
        policy_date_format: policy.policy_date_format,
        policy_number: policy.policy_number,
        policy_permission_letter_url: policy.policy_permission_letter_url,
        policy_certificate_url: policy.policy_certificate_url,
        policy_insurance_company: policy.insurance_company,
      },
    };
    await initiateInsuranceRunProcess(payload).then(() => {
      Notification({
        type: 'success',
        title: 'Success',
        message:
          'Insurance run has been initiated, please keep a lookout for completion of the process, well notify you via email',
        isAlert: true,
      });
    });
  };

  const onClearClick = useCallback(() => {
    fetchPolciesList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=start_date:DESC`);
  }, [fetchPolciesList]);

  useEffect(() => {
    setActiveSideNav('policiestListPage');
    setPageTitle('Policies');
    setSortingColumn('start_date');
    setSortAscending(false);
    fetchPolciesList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=start_date:DESC`);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, setSortAscending, fetchPolciesList]);

  return (
    <>
      <Table
        header={
          <TableHeader
            tableHeaderTitle="Policies list"
            actionButtonText="Add policy"
            onButtonClick={() => setIsModalOpen(true)}
          />
        }
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={insurancePolicyColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={[]}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(pageNumber, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        downloadApi={getAllInsurancePolices}
        downloadName="Policies"
        dataDownloadMethod="download"
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        }}
        onApplyClick={() => applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(0, value, searchString, sortingColumn, sortAscending);
        }}
      />

      <Modal
        title={`${isEditPolicy ? 'Edit' : 'Add new'} policy`}
        open={isModalOpen}
        showClose
        onClose={() => {
          setIsModalOpen(false);
          setPolicyValues(undefined);
          isEditPolicy && setIsEditPolicy(false);
        }}
        styled={{ width: '60vw', minWidth: '600px' }}
        showOverflow={false}
      >
        <CreateEditPolicyForm
          isInEdit={isEditPolicy}
          values={policyValues}
          onClose={() => {
            setIsModalOpen(false);
            setPolicyValues(undefined);
            isEditPolicy && setIsEditPolicy(false);
          }}
          onFormSubmit={() => {
            setIsModalOpen(false);
            setPolicyValues(undefined);
            isEditPolicy && setIsEditPolicy(false);
            applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }}
        />
      </Modal>

      <Modal
        title={isRenewPolicy ? 'Renew policy' : 'View policy'}
        open={isViewModalOpen}
        showClose
        onClose={() => {
          setIsViewModalOpen(false);
          setIsRenewPolicy(false);
          setPolicyValues(undefined);
        }}
        styled={{ width: '60vw', minWidth: '600px' }}
        showOverflow={false}
      >
        <ViewReviewPolicyForm
          isInViewMode={isViewPolicy}
          renewPolicy={() => {
            setIsViewPolicy(false);
            setIsRenewPolicy(true);
          }}
          values={policyValues}
          onClose={() => {
            setIsViewModalOpen(false);
            setIsRenewPolicy(false);
            setPolicyValues(undefined);
          }}
          onFormSubmit={() => {
            setIsRenewPolicy(false);
            setIsViewModalOpen(false);
            setPolicyValues(undefined);
            applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }}
        />
      </Modal>

      <ConfirmationModal
        title={'Are you sure you want to delete this policy?'}
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => {
          setIsConfirmDeleteModalOpen(false);
          setPolicyValues(undefined);
        }}
        onConfirm={() => {
          if (policyValues?.id) {
            deletePolicy(policyValues.id);
            setIsConfirmDeleteModalOpen(false);
            setPolicyValues(undefined);
          }
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />

      <ConfirmationModal
        title={'Are you sure you want to initiate email data check for this policy?'}
        isOpen={isInitiateDataValidationModalOpen}
        onClose={() => {
          setIsInitiateDataValidationModalOpen(false);
          setPolicyValues(undefined);
        }}
        onConfirm={() => {
          if (policyValues) {
            initiateDataValidationCheck(policyValues);
            setIsInitiateDataValidationModalOpen(false);
            setPolicyValues(undefined);
            applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />

      <ConfirmationModal
        title={'Are you sure you want to initiate the insurance run for this policy?'}
        isOpen={isInitiateInsuranceRunModalOpen}
        onClose={() => {
          setIsInitiateInsuranceRunModalOpen(false);
          setPolicyValues(undefined);
        }}
        onConfirm={() => {
          if (policyValues) {
            initiateInsuranceRun(policyValues);
            setIsInitiateInsuranceRunModalOpen(false);
            setPolicyValues(undefined);
            applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
    </>
  );
};
