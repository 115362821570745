import React, { useState, useCallback, useEffect } from 'react';
import { BsPencil } from 'react-icons/bs';
import { MdKeyboardArrowDown, MdOutlineDoneOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { CreateSingleServicing } from './createSingleServicing';
import { EditSubServicing } from './editSubServicing';
import { FinishSubServicing } from './finishSubServicing';
import { AGREEMENT_FROZEN, AGREEMENT_LIVE } from '../../../consts/agreement';
import { CREATE_TRANSFER } from '../../../consts/routes';
import { SERVICING_ACTIVE } from '../../../consts/servicing';
import {
  FINISH_OFF_ROAD_VEH,
  FINISH_SERVICING,
  FINISH_SERVICE_AND_UNFREEZE_AGREEMENT,
  REPAIR_FINISHED,
  REPLACEMENT_RETURN,
  RETURN_COURTESY,
} from '../../../consts/transfer';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Modal } from '../../../uiComponents/modals/modal';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { servicingListForOneVehicleColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import {
  TableTextCell,
  TableTagCell,
  getQueryString,
  DEFAULT_NUM_ROWS_PER_PAGE,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { Text } from '../../../uiComponents/text/text';
import { getServicingList, getSingleServicingByVehicle } from '../../../api/get/servicing.get';
import { Courtesy, Servicing } from '../../../models/servicing';
import { SECONDARY_PURPLE_90, PRIMARY_GREEN, STATUS_RED } from '../../../common/styles/Colors';
import { handleAPIError, ErrorType } from '../../../utils/handleAPIError';

interface AddFinishServicingProps {
  rowData?: Servicing;
}

export const AddFinishServicing = ({ rowData }: AddFinishServicingProps) => {
  const navigate = useNavigate();
  const [servicingDetails, setServicingDetails] = useState<Servicing>();
  const [courtesy, setCourtesy] = useState<Courtesy>();
  const [hasUncompleteServicings, setHasUncompleteServicings] = useState<boolean>(false);
  const [isAddModal, setIsAddModal] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [isFinishModal, setIsFinishModal] = useState<boolean>(false);
  const [isReturnCourtesyModal, setIsReturnCourtesyModal] = useState<boolean>(false);
  const [isOffRoadVehModal, setIsOffRoadVehModal] = useState<boolean>(false);
  const [isGeneralTransferModal, setIsGeneralTransferModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedServicing, setSelectedServicing] = useState<Servicing | null>(null);

  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
    tableFilters,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=created_date:DESC`;

  const agrId = servicingDetails?.agreement_id;
  const servicingId = rowData?.servicing_id;
  const vehicleId = rowData?.vehicle_id;
  const vrm = servicingDetails?.vrm;

  const handleGetServicingsResponse = useCallback(
    (count: number, servicing: Servicing[]) => {
      const servicingRows = servicing?.map((servicing: Servicing) => {
        return {
          rowData: { data: servicing },
          cells: [
            <TableTextCell value={servicing?.start_date ? moment(servicing.start_date).format('DD MMM YYYY') : '-'} />,
            <TableTextCell
              value={servicing?.projected_ttr ? moment(servicing.projected_ttr).format('DD MMM YYYY') : '-'}
            />,
            <TableTextCell value={servicing?.end_date ? moment(servicing.end_date).format('DD MMM YYYY') : '-'} />,
            <TableTagCell tags={servicing?.servicing_type} />,
            <TableTextCell value={servicing?.branch_name || '-'} />,
            <TableTextCell value={servicing?.employee_name || '-'} />,
            <div>
              {servicing?.servicing_status ? (
                <TableTagCell tags={[servicing.servicing_status]} />
              ) : (
                <TableTextCell value={'-'} />
              )}
            </div>,
            <TableTextCell value={servicing?.notes || '-'} />,
            <div>
              {servicing?.servicing_status === SERVICING_ACTIVE ? (
                <FlexLayout gap={16}>
                  <ActionIcon
                    icon={
                      <BsPencil
                        onClick={() => {
                          setIsEditModal(true);
                          setSelectedServicing(servicing);
                        }}
                        size={24}
                        color={SECONDARY_PURPLE_90}
                      />
                    }
                    tooltip="Edit subservicing"
                  />
                  <ActionIcon
                    onClick={() => {
                      setIsFinishModal(true);
                      setSelectedServicing(servicing);
                    }}
                    icon={<MdOutlineDoneOutline onClick={() => null} size={24} color={SECONDARY_PURPLE_90} />}
                    tooltip="Finish subservicing"
                  />
                </FlexLayout>
              ) : (
                <TableTextCell value={'N/A'} />
              )}
            </div>,
          ],
        };
      });
      setTableData(servicingRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const fetchServicingList = useCallback(
    async (queryString: string) => {
      setIsLoading(true);
      try {
        if (vehicleId) {
          // Fetch subservicing list
          const response = await getServicingList(rowData.vehicle_id, queryString);
          handleGetServicingsResponse(response.count, response.data);

          // Check if there is any uncomplete subservicings
          setHasUncompleteServicings(
            response.data?.filter(
              (serv: Servicing) => serv.end_date === null && serv.servicing_status === SERVICING_ACTIVE
            ).length > 0
          );

          // Fetch main servicing details and assign courtesy
          const { data: servicingData } = await getSingleServicingByVehicle(rowData.vehicle_id);
          setServicingDetails(servicingData);
          setCourtesy(servicingData.courtesyVehicle);
        }
        setIsLoading(false);
      } catch (err) {
        handleAPIError(err as ErrorType);
        setIsLoading(false);
      }
    },
    [handleGetServicingsResponse, rowData?.vehicle_id, vehicleId]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchServicingList(queryString);
    },
    [setTableData, goToPageNumber, tableFilters, searchString, fetchServicingList]
  );

  useEffect(() => {
    setSortingColumn('start_date');
    fetchServicingList(defaultString);
  }, [setSortingColumn, fetchServicingList, defaultString]);

  // Complete servicing logic
  const returnCourtesy = useCallback(
    () => (courtesy !== undefined ? courtesy.end_date === null : undefined),
    [courtesy]
  );

  const offRoadVehicle = useCallback(() => {
    const { agreement_status: agreementStatus } = servicingDetails || {};
    if (agreementStatus === AGREEMENT_FROZEN || agreementStatus === AGREEMENT_LIVE) {
      return false;
    }
    return agreementStatus !== undefined ? true : undefined;
  }, [servicingDetails]);

  const returnCourtesyText =
    'Replacement vehicle must be checked in first. Would you like to check-in the replacement vehicle now?';
  const offRaodVehText = 'Finish servicing for off-road vehicle?';
  const gereralTransferText = 'You will be redirected to fill in a transfer!';

  const completeServicing = () => {
    let params;
    if (returnCourtesy()) {
      params = {
        agreement_id: agrId,
        courtesy_id: courtesy && courtesy.id,
        dynamic: RETURN_COURTESY,
        reason: REPLACEMENT_RETURN,
        vrm: courtesy && courtesy.courtesy_vrm,
      };
    } else if (offRoadVehicle()) {
      params = {
        dynamic: FINISH_OFF_ROAD_VEH,
        servicing_id: servicingId,
        reason: REPAIR_FINISHED,
        vrm,
      };
    } else {
      params = {
        vrm,
        reason: REPAIR_FINISHED,
        dynamic: `${
          servicingDetails?.hasTermInServiceRental ? FINISH_SERVICE_AND_UNFREEZE_AGREEMENT : FINISH_SERVICING
        }`,
        agreement_id: agrId,
        agreement_status: servicingDetails?.agreement_status,
        ordway_customer_id: servicingDetails?.ordway_customer_id,
        vehicle_source: servicingDetails?.vehicle_source,
      };
    }
    navigate(CREATE_TRANSFER, { state: params });
  };

  // Modal logic - regular modal
  const handleModalClose = useCallback(() => {
    setIsEditModal(false);
    setIsFinishModal(false);
    setIsReturnCourtesyModal(false);
    setIsGeneralTransferModal(false);
  }, []);

  const handleFormSubmit = useCallback(() => {
    setIsEditModal(false);
    setIsFinishModal(false);
    fetchServicingList(defaultString);
  }, [defaultString, fetchServicingList]);

  const handFormClose = useCallback(() => {
    setIsEditModal(false);
    setIsFinishModal(false);
    setSelectedServicing(null);
  }, []);

  // Modal logic - comfirmation modal
  const handleModalTitle = () => {
    switch (true) {
      case isReturnCourtesyModal:
        return returnCourtesyText;
      case isOffRoadVehModal:
        return offRaodVehText;
      case isGeneralTransferModal:
        return gereralTransferText;
      default:
        return '';
    }
  };

  const isModalOpen = isReturnCourtesyModal || isOffRoadVehModal || isGeneralTransferModal;

  const handleOnClickComplete = () => {
    if (returnCourtesy()) {
      setIsReturnCourtesyModal(true);
    }
    return offRoadVehicle() ? setIsOffRoadVehModal(true) : setIsGeneralTransferModal(true);
  };

  return (
    <>
      <SecondaryButton
        styled={{ margin: '20px 0' }}
        isProcessing={isLoading}
        onClick={() => setIsAddModal(!isAddModal)}
      >
        <>
          Add subservicing
          <MdKeyboardArrowDown
            style={isAddModal ? { transform: 'rotate(180deg)' } : { transform: 'none' }}
            size={20}
            color={PRIMARY_GREEN}
          />
        </>
      </SecondaryButton>
      {isAddModal && vehicleId && (
        <CreateSingleServicing
          vehicleId={rowData.vehicle_id}
          onFormSubmit={() => {
            setIsAddModal(false);
            fetchServicingList(defaultString);
          }}
          onClose={() => setIsAddModal(false)}
        />
      )}
      <Table
        variant="compact"
        tableTheme="purple"
        embedded
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(pageNumber, numRowsPerPage, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={servicingListForOneVehicleColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={[]}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, sortingColumn, sortAscending);
        }}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(0, value, sortingColumn, sortAscending);
        }}
        styled={{ marginTop: 20 }}
      />
      {servicingDetails?.hasLiveInServiceRental && (
        <FlexLayout styled={{ marginTop: 20 }}>
          <Text color={STATUS_RED}>
            *In-service rental is live, you cannot complete servicing unless the in-service rental agreement is
            terminated!
          </Text>
        </FlexLayout>
      )}
      {hasUncompleteServicings && (
        <FlexLayout styled={{ marginTop: 20 }}>
          {' '}
          <Text color={STATUS_RED}>*You cannot complete servicing unless there are active subservicings above!</Text>
        </FlexLayout>
      )}
      <FlexLayout styled={{ margin: '40px 0 20px' }} itemsX="end">
        <PrimaryButton
          disabled={hasUncompleteServicings || servicingDetails?.hasLiveInServiceRental}
          isProcessing={isLoading}
          onClick={handleOnClickComplete}
        >
          Complete servicing
        </PrimaryButton>
      </FlexLayout>
      <Modal
        styled={{ minWidth: 800 }}
        title={`${isEditModal ? 'Edit' : 'Finish'} subservicing`}
        open={isEditModal || isFinishModal}
        showClose
        onClose={handleModalClose}
      >
        <>
          {selectedServicing && isEditModal && (
            <EditSubServicing servicing={selectedServicing} onFormSubmit={handleFormSubmit} onClose={handFormClose} />
          )}
          {selectedServicing && isFinishModal && (
            <FinishSubServicing servicing={selectedServicing} onFormSubmit={handleFormSubmit} onClose={handFormClose} />
          )}
        </>
      </Modal>
      <ConfirmationModal
        title={handleModalTitle}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={completeServicing}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
    </>
  );
};
