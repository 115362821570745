import React, { useCallback, SyntheticEvent } from 'react';
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import moment from 'moment';
import { TextField } from '../../../../../uiComponents/inputs/textField/textField';
import { GridLayout } from '../../../../../uiComponents/layouts/gridLayout/gridLayout';
import { CustomerProfile, DuplicatePcoCheck } from '../../../../../models/driver';
import { getDriverByPCO } from '../../../../../api/get/driver.get';
import { ukPostCode } from '../../../../../utils/validations';

interface DriverDetailsProps {
  errors: FieldErrors<CustomerProfile>;
  register: UseFormRegister<CustomerProfile>;
  setError: UseFormSetError<CustomerProfile>;
  clearErrors: UseFormClearErrors<CustomerProfile>;
  setValue: UseFormSetValue<CustomerProfile>;
  control: Control<CustomerProfile>;
  isComplete: boolean;
  driverData: DriverData;
}
interface DriverData {
  driver_id: string;
  address_line_1?: string;
  address_city?: string;
  post_code?: string;
}
export const DriverDetailsForm = ({
  errors,
  register,
  setError,
  clearErrors,
  isComplete,
  driverData,
}: DriverDetailsProps) => {
  const validatePCOIsAvailable = useCallback(
    (pcoNo: string) => {
      if (pcoNo) {
        getDriverByPCO(driverData?.driver_id, pcoNo).then((response: { data: DuplicatePcoCheck[] }) => {
          const otherDriversWithPcoNo = response?.data?.filter((item) => item?.id !== driverData.driver_id) ?? [];
          if (otherDriversWithPcoNo?.length > 0) {
            setError('pco_no', { message: 'This PCO number is in use' });
          } else {
            clearErrors('pco_no');
          }
        });
      }
    },
    [driverData?.driver_id, setError, clearErrors]
  );

  return (
    <div style={{ marginTop: 24 }}>
      <GridLayout template={2} gap="16px 38px">
        {!driverData.address_line_1 && (
          <TextField
            {...register('address_line_1', {
              required: 'Address is required',
            })}
            label="Address line 1"
            type="text"
            error={errors?.address_line_1}
            disabled={isComplete}
            required
          />
        )}
        {!driverData.address_city && (
          <TextField
            {...register('address_city', {
              required: 'City is required',
            })}
            label="City"
            type="text"
            error={errors?.address_city}
            disabled={isComplete}
            required
          />
        )}
        {!driverData.post_code && (
          <TextField
            {...register('address_post_code', {
              required: 'Post code is required',
              pattern: {
                value: ukPostCode,
                message: 'Must be post code format e.g. PO1 3AX',
              },
            })}
            label="Post code"
            type="text"
            error={errors?.address_post_code}
            disabled={isComplete}
            required
          />
        )}
        <TextField
          {...register('dvla_test_date', {
            validate: {
              validateExpiryDate: (date: string | undefined) => {
                if (date && new Date(date) > new Date()) {
                  return 'DVLA test date should be in the past';
                }
              },
            },
          })}
          label="DVLA test date"
          type="date"
          error={errors?.dvla_test_date}
          disabled={isComplete}
        />
        <TextField
          {...register('dvla_expiry_date', {
            required: 'DVLA expiry date is a required field',
            validate: {
              validateExpiryDate: (date: string | undefined) => {
                if (date && new Date(date) < new Date()) {
                  return 'DVLA expiry date should be in the future';
                }
              },
            },
          })}
          required
          label="DVLA expiry date"
          type="date"
          error={errors?.dvla_expiry_date}
          disabled={isComplete}
        />
        <TextField
          {...register('pco_no', {
            required: 'PCO badge number is a required field',
            onBlur: (e: SyntheticEvent) => validatePCOIsAvailable((e.target as HTMLInputElement).value),
          })}
          required
          label="PCO badge number"
          type="text"
          placeholder="PCO badge number"
          error={errors?.pco_no}
          disabled={isComplete}
        />
        <TextField
          {...register('pco_expiry_date', {
            required: 'PCO expiry date is a required field',
            validate: {
              validateExpiryDate: (date: string | undefined) => {
                if (date && new Date(date) < new Date()) {
                  return 'PCO date should be in the future';
                }
              },
            },
          })}
          required
          label="PCO badge expiry"
          type="date"
          error={errors?.pco_expiry_date}
          disabled={isComplete}
        />
        <TextField
          {...register('pco_cert_expiry_date', {
            required: 'PCO certificate expiry date is a required field',
            validate: {
              validateExpiryDate: (date: string | undefined) => {
                if (date && new Date(date) < new Date()) {
                  return 'PCO certificate expiry date should be in the future';
                }
              },
            },
          })}
          required
          label="PCO certificate expiry date"
          type="date"
          error={errors?.pco_cert_expiry_date}
          disabled={isComplete}
        />
        <TextField
          {...register('poa_expiry_date', {
            required: 'Proof of address issue date is a required field',
            validate: {
              validateExpiryDate: (date: string | undefined) => {
                const isFuture: boolean = moment(date).isAfter(moment());
                const olderThan3Months: boolean = moment().diff(moment(date), 'months', true) > 3;
                if (isFuture || olderThan3Months) {
                  return 'Proof of address issue date should be within the last 3 months';
                }
              },
            },
          })}
          required
          label="Proof of address issue date"
          type="date"
          error={errors?.poa_expiry_date}
          disabled={isComplete}
        />
      </GridLayout>
    </div>
  );
};
