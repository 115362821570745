import { GenericAbortSignal } from 'axios';
import { Congestion } from '../../models/congestion';
import api from '../../utils/api';

export const getAllCongestionCharges = (
  query: string = '',
  signal: GenericAbortSignal
): Promise<{ count: number; data: Congestion[] }> =>
  api.get(`additional-charge/congestion${query && `?${query}`}`, { signal });

export const searchDriver = (driverName: string) => api.get(`driver/list/search?search=${driverName}`);
