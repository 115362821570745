import {
  CANCELLED,
  COLLECTION_ARRANGED,
  COMPLETED,
  DECLARATION_COMPLETED,
  OPEN,
  PRACTICAL_FAILED,
  PRACTICAL_PASSED,
  REJECTED,
  THEORY_COMPLETED,
  VEHICLE_ASSIGNED,
  AFTERCARE_FULL,
  AFTERCARE_INSURANCE,
  CAR_HIRE_NATIONAL,
  FRIENDLY_FINANCE,
  IN_SERVICE_RENTAL,
  PCO_CAR_HIRE_FLEX,
  R2B_CLASSIC,
  R2B_CLASSIC_PLUS_8,
  R2B_NEW_NATIONAL,
  R2B_NEW_NATIONAL_PLUS_8,
  R2B_USED,
  R2B_USED_NATIONAL,
  R2B_USED_NATIONAL_PLUS_8,
  R2B_USED_PLUS_8,
  RENTAL,
  AGREEMENT_CANCELLED,
  CHECK_IN,
  CHECK_OUT,
} from '../../../consts';
import {
  AGREEMENT_LIVE,
  AGREEMENT_COMPLETED,
  AGREEMENT_FROZEN,
  AGREEMENT_PAID_OFF,
  AGREEMENT_TERMINATED,
  AGREEMENT_AFTERCARE_REVIEW,
  AGREEMENT_DROPPED,
  AGREEMENT_PAUSED,
  AGREEMENT_DEC_COMPLETED,
} from '../../../consts/agreement';
import { ONLINE_FAILED, ONLINE_PASSED, ONLINE_PENDING, PRACTICAL_PENDING } from '../../../consts/assessment';
import {
  AFTERCARE,
  ASSIGNED,
  AVAILABLE,
  AWAITING_EVALUATION,
  COURTESY_ASSIGNED,
  IN_SERVICING,
  LEUMI,
  LIVE,
  NO_SALE,
  ORDERED,
  OTTO_CAR,
  PAID_OFF,
  SOLD,
  WITH_3RD_PARTY,
} from '../../../consts/vehicle';
import { OptionList } from '../../../utils/props';

export const agreementStatuses: OptionList[] = [
  { value: AGREEMENT_LIVE, label: 'Live' },
  { value: AGREEMENT_FROZEN, label: 'Frozen' },
  { value: AGREEMENT_PAID_OFF, label: 'Paid off' },
  { value: AGREEMENT_DROPPED, label: 'Dropped' },
  { value: AGREEMENT_PAUSED, label: 'Paused' },
  { value: AGREEMENT_TERMINATED, label: 'Terminated' },
  { value: AGREEMENT_COMPLETED, label: 'Completed' },
  { value: AGREEMENT_CANCELLED, label: 'Cancelled' },
  { value: AGREEMENT_AFTERCARE_REVIEW, label: 'Aftercare review' },
  { value: AGREEMENT_DEC_COMPLETED, label: 'Declaration completed' },
];

export const agreementTypes: OptionList[] = [
  { value: R2B_CLASSIC, label: 'R2B classic' },
  { value: R2B_NEW_NATIONAL, label: 'R2B new national' },
  { value: R2B_NEW_NATIONAL_PLUS_8, label: 'R2B new national plus 8' },
  { value: R2B_USED, label: 'R2B used' },
  { value: R2B_USED_NATIONAL, label: 'R2B used national' },
  { value: R2B_USED_NATIONAL_PLUS_8, label: 'R2B used national plus 8' },
  { value: R2B_CLASSIC_PLUS_8, label: 'R2B classic plus 8' },
  { value: R2B_USED_PLUS_8, label: 'R2B used plus 8' },
  { value: RENTAL, label: 'Rental' },
  { value: IN_SERVICE_RENTAL, label: 'In-service rental' },
  { value: CAR_HIRE_NATIONAL, label: 'Car hire national' },
  { value: FRIENDLY_FINANCE, label: 'Friendly finance' },
  { value: AFTERCARE_FULL, label: 'Aftercare full' },
  { value: AFTERCARE_INSURANCE, label: 'Aftercare insurance' },
  { value: PCO_CAR_HIRE_FLEX, label: 'PCO car hire flex' },
];

export const aftercareStatusListOptions = [
  { value: AGREEMENT_LIVE, label: 'Live' },
  { value: AGREEMENT_COMPLETED, label: 'Completed' },
  { value: AGREEMENT_DROPPED, label: 'Dropped' },
  { value: AGREEMENT_CANCELLED, label: 'Cancelled' },
  { value: AGREEMENT_TERMINATED, label: 'Terminated' },
  { value: AGREEMENT_FROZEN, label: 'Frozen' },
];

export const aftercareServiceOptions = [
  { value: AFTERCARE_INSURANCE, label: 'Aftercare insurance' },
  { value: AFTERCARE_FULL, label: 'Aftercare full' },
];

export const departmentOptions: OptionList[] = [
  { value: 'FULFILLMENT', label: 'Fulfillment' },
  { value: 'SERVICES', label: 'Services' },
  { value: 'ASSESSMENT', label: 'Assessment' },
  { value: 'FINANCE', label: 'Finance' },
  { value: 'SALES', label: 'Sales' },
  { value: 'CLAIMS', label: 'Claims' },
  { value: 'ENQUIRIES', label: 'Enquires' },
  { value: 'OPERATIONS', label: 'Operations' },
  { value: 'MARKETING', label: 'Marketing' },
  { value: 'VANS', label: 'Vans' },
];

export const usedVehicleOptions: OptionList[] = [
  { value: 'all', label: 'New and used' },
  { value: 'false', label: 'New' },
  { value: 'true', label: 'Used' },
];

export const vehicleSources: OptionList[] = [
  { value: OTTO_CAR, label: 'Otto car' },
  { value: LEUMI, label: 'Leumi' },
];

export const vehicleStatuses: OptionList[] = [
  { value: LIVE, label: 'Live' },
  { value: AFTERCARE, label: 'Aftercare' },
  { value: PAID_OFF, label: 'Paid off' },
  { value: ASSIGNED, label: 'Assigned' },
  { value: AVAILABLE, label: 'Available' },
  { value: ORDERED, label: 'Ordered' },
  { value: AWAITING_EVALUATION, label: 'Awaiting evaluation' },
  { value: COURTESY_ASSIGNED, label: 'Courtesy assigned' },
  { value: IN_SERVICING, label: 'In servicing' },
  { value: NO_SALE, label: 'No sale' },
  { value: SOLD, label: 'Sold' },
  { value: WITH_3RD_PARTY, label: 'With 3rd Party' },
];

export const orderStatusOptions: OptionList[] = [
  { value: OPEN, label: 'Open' },
  { value: VEHICLE_ASSIGNED, label: 'Vehicle assigned' },
  { value: COLLECTION_ARRANGED, label: 'Collection arranged' },
  { value: DECLARATION_COMPLETED, label: 'Declaration completed' },
  { value: COMPLETED, label: 'Completed' },
  { value: CANCELLED, label: 'Cancelled' },
  { value: REJECTED, label: 'Rejected' },
  { value: THEORY_COMPLETED, label: 'Theory completed' },
  { value: PRACTICAL_PASSED, label: 'Practical passed' },
  { value: PRACTICAL_FAILED, label: 'Practical failed' },
];

export const vehicleSourceOptions: OptionList[] = [
  {
    value: 'all',
    label: 'All vehicles',
  },
  {
    value: OTTO_CAR,
    label: 'Otto vehicles',
  },
  {
    value: LEUMI,
    label: 'Leumi vehicles',
  },
];

export const transferReasons: OptionList[] = [
  { value: 'ACCIDENT', label: 'Accident' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'COLLECTION-COURTESY', label: 'Collection-courtesy' },
  { value: 'COLLECTION-NEW', label: 'Collection-new' },
  { value: 'COURTESY-RETURN', label: 'Courtesy-return' },
  { value: 'DRIVER-ISSUE', label: 'Driver-issue' },
  { value: 'DROP', label: 'Drop' },
  { value: 'MOT', label: 'Mot' },
  { value: 'PHV-PROBLEM', label: 'Phv-problem' },
  { value: 'PULL-OFF', label: 'Pull-off' },
  { value: 'REPAIR-FINISHED', label: 'Repair-finished' },
  { value: 'REPAIR-OTHER', label: 'Repair-other' },
  { value: 'REPAIR-WARRANTY', label: 'Repair-warranty' },
  { value: 'SWAP', label: 'Swap' },
  { value: 'TERMINATED', label: 'Terminated' },
  { value: 'TERMINATE-AND-SERVICE', label: 'Terminate and service' },
];

export const transferTypes: OptionList[] = [
  { value: CHECK_IN, label: 'Check in' },
  { value: CHECK_OUT, label: 'Check out' },
];

export const assessmentStatusOption: OptionList[] = [
  { value: ONLINE_PENDING, label: 'Online pending' },
  { value: ONLINE_PASSED, label: 'Online passed' },
  { value: ONLINE_FAILED, label: 'Online failed' },
  { value: PRACTICAL_PENDING, label: 'Practical pending' },
  { value: PRACTICAL_PASSED, label: 'Practical passed' },
  { value: PRACTICAL_FAILED, label: 'Practical failed' },
];

export const servicingTypes: OptionList[] = [
  {
    label: 'Bodywork repair',
    value: 'BODYWORK-REPAIR',
  },
  {
    label: 'Mechanical repair',
    value: 'MECHANICAL-REPAIR',
  },
  {
    label: 'MOT',
    value: 'MOT',
  },
  {
    label: 'PHV',
    value: 'PHV',
  },
  {
    label: 'Service',
    value: 'SERVICE',
  },
  {
    label: 'Warranty',
    value: 'WARRANTY',
  },
  {
    label: 'Non Warranty',
    value: 'NON-WARRANTY',
  },
];

export const congestionChargeStatuses: OptionList[] = [
  { value: 'INVALID-VRM', label: 'Invalid-vrm' },
  { value: 'INVOICED', label: 'Invoiced' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'INVALID-ACCOUNT', label: 'Invalid-account' },
  { value: 'INVALID-DRIVER', label: 'Invalid-driver' },
  { value: 'IN-SERVICING', label: 'In-servicing' },
  { value: 'VAN', label: 'Van' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'STAFF', label: 'Staff' },
  { value: 'PAID', label: 'Paid' },
];

export const archivedOptions: OptionList[] = [
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
];

export const profileStatusOptions: OptionList[] = [
  { value: 'INCOMPLETE', label: 'Incomplete' },
  { value: 'COMPLETE', label: 'Complete' },
];

export const evaluationTypeOptions: OptionList[] = [
  {
    value: 'PRACTICAL',
    label: 'Practical',
  },
  { value: 'ONLINE', label: 'Online' },
  { value: 'NONE', label: 'None' },
];

export const interimVehicleOptions: OptionList[] = [
  {
    value: 'IN-SERVICE-RENTAL',
    label: 'In service rental',
  },
  { value: 'COURTESY', label: 'Replacement' },
];

export const vehicleTagTypes: OptionList[] = [
  { value: 'ALTERNATIVE-INSURANCE', label: 'Alternative Insurance' },
  { value: 'OTTOCAR-INSURANCE', label: 'Ottocar Insurance' },
];
