import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { AGREEMENT_FROZEN, AGREEMENT_LIVE } from '../../../consts/agreement';
import { APPLICATION_COMPLETED, APPLICATION_CANCELLED } from '../../../consts/application';
import { COURTESY, IN_SERVICE_RENTAL, isAftercare, isRental } from '../../../consts/plans';
import { FINANCE } from '../../../consts/department';
import {
  AGREEMENTS,
  CREATE_ORDER,
  COURTESY_ORDER,
  CREATE_TRANSFER,
  DRIVERS,
  ORDERS,
  VEHICLES,
} from '../../../consts/routes';
import {
  COURTESY_VEHICLE,
  IN_SERVICE_RENTAL_VEHICLE,
  courtVehHeaders,
  interimVehicleOptions,
  origAgrmHeaders,
} from '../../../consts/servicing';
import { REPLACEMENT_RETURN, RETURN_COURTESY, TERMINATE, TERMINATED } from '../../../consts/transfer';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FieldGrid } from '../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { NavigationPill } from '../../../uiComponents/layouts/navigationPill/navigationPill';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Text } from '../../../uiComponents/text/text';
import { Notification } from '../../../uiComponents/toast/toast';
import { Checkbox, CheckboxLabel } from '../../../uiComponents/uiControls/checkbox/checkbox';
import { getAgreementsByServicingId } from '../../../api/get/agreement.get';
import { getApplicationsByServicingId } from '../../../api/get/application.get';
import { getSingleServicingByVehicle } from '../../../api/get/servicing.get';
import { updateAgreement } from '../../../api/patch/agreement.patch';
import { Agreement } from '../../../models/agreement';
import { Application } from '../../../models/application';
import { Courtesy, Servicing } from '../../../models/servicing';
import { PRIMARY_GREEN, PRIMARY_PURPLE, STATUS_RED } from '../../../common/styles/Colors';
import { handleAPIError, ErrorType } from '../../../utils/handleAPIError';
import { OptionList } from '../../../utils/props';
import { APP_CONTEXT } from '../../../utils/context';

interface InterimVehicleOpt {
  rowData?: Servicing;
}

export const InterimVehicleOptions = ({ rowData }: InterimVehicleOpt) => {
  const navigate = useNavigate();
  const { superAdmin } = useContext(APP_CONTEXT);
  const [servicingDetails, setServicingDetails] = useState<Servicing>();
  const [agreementListData, setAgreementListData] = useState<Agreement[]>([]);
  const [applicationListData, setApplicationListData] = useState<Application[]>([]);
  const [arrearsChecked, setArrearsChecked] = useState<boolean>(false);
  const [courtesy, setCourtesy] = useState<Courtesy>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReturnCourtesyModal, setIsReturnCourtesyModal] = useState<boolean>(false);
  const [isCreateInServiceRentalModal, setIsCreateInServiceRentalModal] = useState<boolean>(false);
  const [isTerminateInServiceRentalModal, setIsTerminateInServiceRentalModal] = useState<boolean>(false);
  const [isFreezeModal, setIsFreezeModal] = useState<boolean>(false);
  const [isUnfreezeModal, setIsUnfreezeModal] = useState<boolean>(false);
  const [isTerminateModal, setIsTerminateModal] = useState<boolean>(false);
  const [selectedHeader, setSelectedHeader] = useState<OptionList>(interimVehicleOptions?.[0]);
  const currentDepartment = localStorage.getItem('department');
  const [view, setView] = useState<string>('');
  const customerBalance = +(servicingDetails?.ordway_details?.balance || '0');
  const isInArrears = customerBalance > 0;

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      if (rowData?.vehicle_id) {
        const { data: servicingData } = await getSingleServicingByVehicle(rowData.vehicle_id);
        setServicingDetails(servicingData);
        if (servicingData.courtesyVehicle) {
          setCourtesy(servicingData.courtesyVehicle);
        }
      }
      if (rowData?.servicing_id) {
        const { data: agreementData } = await getAgreementsByServicingId(rowData.servicing_id);
        setAgreementListData(agreementData);
        const { data: applicationData } = await getApplicationsByServicingId(rowData.servicing_id);
        setApplicationListData(applicationData);
      }
      setIsLoading(false);
    } catch (err) {
      handleAPIError(err as ErrorType);
      setIsLoading(false);
    }
  }, [rowData?.servicing_id, rowData?.vehicle_id]);

  useEffect(() => {
    fetchData();
    setArrearsChecked(customerBalance <= 0);
    setView(interimVehicleOptions?.[0].value);
    setSelectedHeader(interimVehicleOptions?.[0]);
  }, [fetchData, customerBalance]);

  // Original agreement details
  const renderAccountText = useCallback(() => {
    if (!servicingDetails?.ordway_details) {
      return 'N/A';
    }
    return servicingDetails?.ordway_details && isInArrears
      ? `In arrears of £${customerBalance.toFixed(2)}`
      : 'Account up to date';
  }, [servicingDetails?.ordway_details, isInArrears, customerBalance]);

  const origAgrmValues = useMemo(() => {
    return [
      servicingDetails?.vrm ? (
        <Link
          key="vrm-link"
          style={{ color: PRIMARY_PURPLE }}
          to={{ pathname: `${VEHICLES}/${servicingDetails?.vehicle_id}` }}
        >
          {servicingDetails?.vrm}
        </Link>
      ) : (
        'N/A'
      ),
      servicingDetails?.branch_name ? (
        <FlexLayout itemsY="center" gap={20} vertical>
          {servicingDetails?.branch_name}
        </FlexLayout>
      ) : (
        'N/A'
      ),
      servicingDetails?.agreement_type && servicingDetails?.agreement_status ? (
        <Link
          key="agreement-link"
          style={{ color: PRIMARY_PURPLE }}
          to={{ pathname: `${AGREEMENTS}/${servicingDetails?.agreement_id}` }}
        >
          {servicingDetails.agreement_type} ({servicingDetails?.agreement_status})
        </Link>
      ) : (
        'N/A'
      ),
      servicingDetails?.vrm ? (
        <Link
          key="driver-link"
          style={{ color: PRIMARY_PURPLE }}
          to={{ pathname: `${DRIVERS}/${servicingDetails?.driver_id}` }}
        >
          {servicingDetails?.driver_name}
        </Link>
      ) : (
        'N/A'
      ),
      servicingDetails?.mobile_phone || 'N/A',
      <span style={isInArrears ? { color: STATUS_RED } : {}}>{renderAccountText()}</span>,
    ];
  }, [
    isInArrears,
    renderAccountText,
    servicingDetails?.agreement_id,
    servicingDetails?.agreement_status,
    servicingDetails?.agreement_type,
    servicingDetails?.branch_name,
    servicingDetails?.driver_id,
    servicingDetails?.driver_name,
    servicingDetails?.mobile_phone,
    servicingDetails?.vehicle_id,
    servicingDetails?.vrm,
  ]);

  const handleSelect = (option?: OptionList) => {
    if (option) {
      setView(option.value);
    }
    setSelectedHeader(option || interimVehicleOptions?.[0]);
  };

  const handleCheckboxChange = useCallback((checked: boolean) => setArrearsChecked(checked), [setArrearsChecked]);

  // Courtesy and in-service vehicle consts; note 'courtesy' is equal 'replacement'
  const liveInServiceRentalAgreement = agreementListData?.find(
    (agreement) => agreement?.agreement_status === AGREEMENT_LIVE
  );
  const pendingRentalApplication = applicationListData?.find(
    (application) => ![APPLICATION_COMPLETED, APPLICATION_CANCELLED].includes(application?.application_status)
  );
  const openCourtesyApplication = servicingDetails?.courtesies?.find(
    (c: Courtesy) => !c?.condition_report_in && !c?.condition_report_out
  );

  const inServiceRentalApplicationId = liveInServiceRentalAgreement?.application_id;
  const inServiceRentalAgreementId = liveInServiceRentalAgreement?.id;
  const inServiceRentalVrm = liveInServiceRentalAgreement?.vehicle_vrm;
  const servicingId = rowData?.servicing_id;

  // Courtesy vehicle details
  const courtVehValues = useMemo(() => {
    const driverName = servicingDetails?.driver_id ? servicingDetails.driver_name : 'N/A';
    const courtesyModel =
      courtesy?.courtesy_manufacturer && courtesy?.courtesy_model
        ? `${courtesy.courtesy_manufacturer} ${courtesy.courtesy_model}`
        : 'N/A';
    const courtesyBranch = courtesy?.courtesy_branch_name || 'N/A';
    return [
      driverName,
      courtesy?.courtesy_vrm ? (
        <Link
          key="vrm-link"
          style={{ color: PRIMARY_PURPLE }}
          to={{ pathname: `${VEHICLES}/${servicingDetails?.courtesyVehicle?.vehicle_id}` }}
        >
          {courtesy?.courtesy_vrm}
        </Link>
      ) : (
        'N/A'
      ),
      courtesyModel,
      courtesyBranch,
    ];
  }, [courtesy, servicingDetails]);

  // Courtesy button function - ASSIGN
  const onAssignCourtesyClick = useCallback(() => {
    const params = {
      agreementType: COURTESY,
      agreement_id: servicingDetails?.agreement_id,
      driverDvlaNo: servicingDetails?.dvla_no,
      servicingId,
    };
    navigate(CREATE_ORDER, { state: params });
  }, [navigate, servicingDetails?.agreement_id, servicingDetails?.dvla_no, servicingId]);

  // Courtesy button function - COMPLETE
  const onCompleteOrViewCourtesyClick = useCallback(() => {
    navigate(`${COURTESY_ORDER}/${openCourtesyApplication ? openCourtesyApplication?.id : courtesy?.id}`, {
      state: servicingDetails,
    });
  }, [courtesy?.id, navigate, openCourtesyApplication, servicingDetails]);

  // Courtesy button function - RETURN
  const returnCourtesyText = 'You need to fill a condition report to return the replacement vehicle!';
  const onReturnCourtesyClick = useCallback(() => {
    const params = {
      agreement_id: servicingDetails?.agreement_id,
      courtesy_id: courtesy && courtesy.id,
      dynamic: RETURN_COURTESY,
      reason: REPLACEMENT_RETURN,
      vrm: courtesy && courtesy.courtesy_vrm,
    };
    setIsReturnCourtesyModal(false);
    navigate(CREATE_TRANSFER, { state: params });
  }, [courtesy, navigate, servicingDetails?.agreement_id]);

  // Courtesy button logic
  const evaluateCourtesyConditions = useCallback(() => {
    if (servicingDetails) {
      const { agreement_type, agreement_id } = servicingDetails;
      const showAssignCourtesy = !courtesy && !isRental(agreement_type) && !!agreement_id;
      const returnCourtesy = courtesy !== undefined ? courtesy.end_date === null : undefined;
      return { showAssignCourtesy, returnCourtesy };
    }
    return { showAssign: undefined, returnCourtesy: undefined };
  }, [courtesy, servicingDetails]);

  const courtesyDisabled =
    !!liveInServiceRentalAgreement || !!pendingRentalApplication || (!openCourtesyApplication && !arrearsChecked);

  const getCourtesyButtonProperties = (): {
    text: string;
    action: string;
    isDisabled: boolean;
  } => {
    let text = '';
    let action = '';
    let isDisabled = false;

    if (openCourtesyApplication) {
      text = 'Complete replacement vehicle assignment';
      action = 'complete';
      isDisabled = courtesyDisabled;
    } else if (!!courtesy && Object.keys(courtesy).length > 0) {
      text = 'Return replacement vehicle';
      action = 'return';
      isDisabled = !evaluateCourtesyConditions().returnCourtesy;
    } else {
      text = 'Assign replacement vehicle';
      action = 'assign';
      isDisabled =
        servicingDetails?.agreement_status === AGREEMENT_FROZEN ||
        courtesyDisabled ||
        !evaluateCourtesyConditions().showAssignCourtesy ||
        !!courtesy;
    }

    return { text, action, isDisabled };
  };

  const {
    text: courtesyBtnText,
    isDisabled: courtesyBtnDisabled,
    action: courtesyButtonAction,
  } = getCourtesyButtonProperties();

  const handleCourtesyBtnClick = () => {
    if (courtesyButtonAction === 'return') {
      setIsReturnCourtesyModal(true);
      return;
    }
    return courtesyButtonAction === 'assign' ? onAssignCourtesyClick() : onCompleteOrViewCourtesyClick();
  };

  // In-service button function - CREATE
  const createInServiceRentalText =
    'This action will freeze the driver’s current agreement and start an in-service (short-term) rental agreement. Are you sure you want to proceed?';
  const onCreateInServiceRentalButtonClick = useCallback(() => {
    const params = {
      agreementType: IN_SERVICE_RENTAL,
      driverDvlaNo: servicingDetails?.dvla_no,
      servicingId,
    };
    setIsCreateInServiceRentalModal(false);
    navigate(CREATE_ORDER, { state: params });
  }, [navigate, servicingDetails?.dvla_no, servicingId]);

  // In-service button function - CONTINUE
  const onContinueInServiceRentalButtonClick = useCallback(() => {
    navigate(`${ORDERS}/${pendingRentalApplication?.id}`);
  }, [navigate, pendingRentalApplication?.id]);

  // In-service button function - TERMINATE
  const terminateInServiceRentalText =
    'You need to fill a condition report to terminate the in-service rental vehicle!';
  const onTerminateInServiceRentalButtonClick = useCallback(() => {
    const params = {
      agreement_id: inServiceRentalAgreementId,
      agreement_type: IN_SERVICE_RENTAL,
      application_id: inServiceRentalApplicationId,
      dynamic: TERMINATE,
      reason: TERMINATED,
      servicing_id: servicingId,
      vrm: inServiceRentalVrm,
    };
    setIsTerminateInServiceRentalModal(false);
    navigate(CREATE_TRANSFER, { state: params });
  }, [inServiceRentalApplicationId, inServiceRentalVrm, inServiceRentalAgreementId, servicingId, navigate]);

  // In-service button logic
  const inServiceDisabled = !servicingDetails?.agreement_id || !!courtesy;

  const getInServiceButtonProperties = (): {
    text: string;
    action: string;
    isDisabled: boolean;
  } => {
    let text = '';
    let action = '';
    let isDisabled = false;

    if (!liveInServiceRentalAgreement && !pendingRentalApplication) {
      text = 'Create in-service rental';
      action = 'create';
      isDisabled = inServiceDisabled || !!openCourtesyApplication || !arrearsChecked;
    } else if (!liveInServiceRentalAgreement && pendingRentalApplication) {
      text = 'Continue in-service rental';
      action = 'continue';
      isDisabled = inServiceDisabled || !!openCourtesyApplication;
    } else if (liveInServiceRentalAgreement) {
      text = 'Terminate in-service rental';
      action = 'terminate';
      isDisabled = inServiceDisabled;
    }

    return { text, action, isDisabled };
  };

  const {
    text: inServiceBtnText,
    isDisabled: inServiceBtnDisabled,
    action: inServiceButtonAction,
  } = getInServiceButtonProperties();

  const handleInServiceBtnClick = () => {
    if (inServiceButtonAction === 'continue') {
      return onContinueInServiceRentalButtonClick();
    }
    return inServiceButtonAction === 'create'
      ? setIsCreateInServiceRentalModal(true)
      : setIsTerminateInServiceRentalModal(true);
  };

  // Freeze and unfreeze button function
  const freezeText =
    'This is not SWAP or IN-SERVICE (SHORT-TERM) RENTAL! This action will freeze the driver’s current agreement and pause weekly payments from the next Thursday. You will no longer be able to create an in-service rental agreement. Are you sure you want to freeze this agreement?';
  const unfreezeText =
    'This action will unfreeze (make LIVE) the driver’s current agreement and resume weekly payments. Are you sure you want to unfreeze this agreement?';
  const freezeOrUnfreezeAgreementClick = async () => {
    try {
      if (servicingDetails) {
        await updateAgreement(servicingDetails.agreement_id, {
          agreement_status: freezeButtonAction === 'freeze' ? AGREEMENT_FROZEN : AGREEMENT_LIVE,
        });
        Notification({
          type: 'success',
          title: 'Success',
          message: `Agreement status has been successfully changed to ${
            freezeButtonAction === 'freeze' ? AGREEMENT_FROZEN : AGREEMENT_LIVE
          }`,
        });
        fetchData();
      }
    } catch (error) {
      handleAPIError(error as ErrorType);
    }
    setIsFreezeModal(false);
    setIsUnfreezeModal(false);
  };

  const checkDeptAndArrears = () => {
    if ((currentDepartment && [FINANCE].includes(currentDepartment)) || superAdmin) {
      return false;
    } else {
      return isInArrears;
    }
  };

  // Freeze and unfreeze button logic for original agreement
  const getFreezeButtonProperties = (): {
    text: string;
    action: string;
    isDisabled: boolean;
  } => {
    let text = 'Freeze agreement';
    let action = '';
    let isDisabled = !servicingDetails?.agreement_status;

    if (servicingDetails?.agreement_status === AGREEMENT_FROZEN) {
      text = 'Unfreeze agreement';
      action = 'unfreeze';
      isDisabled = !!liveInServiceRentalAgreement;
    } else if (servicingDetails?.agreement_status === AGREEMENT_LIVE) {
      text = 'Freeze agreement';
      action = 'freeze';
      isDisabled =
        checkDeptAndArrears() ||
        !!courtesy ||
        !!pendingRentalApplication ||
        !!liveInServiceRentalAgreement ||
        !!openCourtesyApplication;
    }

    return { text, action, isDisabled };
  };

  const {
    text: freezeBtnText,
    isDisabled: freezeBtnDisabled,
    action: freezeButtonAction,
  } = getFreezeButtonProperties();

  const handleFreezeBtnClick = () =>
    freezeButtonAction === 'freeze' ? setIsFreezeModal(true) : setIsUnfreezeModal(true);

  // Terminatation for original agreement
  const terminateText = 'To terminate the agreement, you must create a check in!';
  const onTerminateAgreementClick = useCallback(() => {
    setIsLoading(true);
    if (servicingDetails?.agreement_status !== AGREEMENT_LIVE) {
      Notification({
        type: 'error',
        title: 'Error',
        message: 'This agreement is not live, cannot terminate!',
      });
      fetchData();
      setIsLoading(false);
      setIsTerminateModal(false);
      return;
    }
    const params = {
      agreement_id: servicingDetails?.agreement_id,
      agreement_type: servicingDetails?.agreement_type,
      application_id: servicingDetails?.application_id,
      dynamic: TERMINATE,
      ordway_customer_id: servicingDetails?.ordway_customer_id,
      reason: TERMINATED,
      vehicle_source: servicingDetails?.vehicle_source,
      vrm: servicingDetails?.vrm,
    };
    setIsLoading(false);
    setIsTerminateModal(false);
    navigate(CREATE_TRANSFER, { state: params });
  }, [
    servicingDetails?.agreement_status,
    servicingDetails?.application_id,
    servicingDetails?.vrm,
    servicingDetails?.agreement_id,
    servicingDetails?.vehicle_source,
    servicingDetails?.agreement_type,
    servicingDetails?.ordway_customer_id,
    navigate,
    fetchData,
  ]);

  // Confirmation modal logic
  const handleModalTitle = () => {
    switch (true) {
      case isReturnCourtesyModal:
        return returnCourtesyText;
      case isCreateInServiceRentalModal:
        return createInServiceRentalText;
      case isTerminateInServiceRentalModal:
        return terminateInServiceRentalText;
      case isFreezeModal:
        return freezeText;
      case isUnfreezeModal:
        return unfreezeText;
      case isTerminateModal:
        return terminateText;
      default:
        return '';
    }
  };

  const isModalOpen =
    isReturnCourtesyModal ||
    isCreateInServiceRentalModal ||
    isTerminateInServiceRentalModal ||
    isFreezeModal ||
    isUnfreezeModal ||
    isTerminateModal;

  const handleModalConfirm = () => {
    if (isReturnCourtesyModal) {
      return onReturnCourtesyClick();
    } else if (isCreateInServiceRentalModal) {
      return onCreateInServiceRentalButtonClick();
    } else if (isTerminateInServiceRentalModal) {
      return onTerminateInServiceRentalButtonClick();
    } else if (isFreezeModal || isUnfreezeModal) {
      return freezeOrUnfreezeAgreementClick();
    } else if (isTerminateModal) {
      return onTerminateAgreementClick();
    }
    return null;
  };

  const handleModalClose = () => {
    setIsReturnCourtesyModal(false);
    setIsCreateInServiceRentalModal(false);
    setIsTerminateInServiceRentalModal(false);
    setIsFreezeModal(false);
    setIsUnfreezeModal(false);
    setIsTerminateModal(false);
  };

  return (
    <>
      {/* ORIGINAL AGREEMENTS DETAILS */}
      <div style={{ color: PRIMARY_PURPLE }}>
        <Text variant="body6" weight={500}>
          Original agreement details
        </Text>
        <FieldGrid styled={{ margin: '20px 0' }} numColumns={3} headers={origAgrmHeaders} values={origAgrmValues} />
      </div>
      <NavigationPill
        styled={{ width: '100%', marginBottom: 20 }}
        onSelect={handleSelect}
        isSecondaryNav
        options={interimVehicleOptions}
        selected={selectedHeader}
      />
      {/* COURTESY AND IN-SERVICE VEHICLE DETAILS */}
      {isInArrears && (
        <FlexLayout styled={{ marginBottom: 40 }} gap={10}>
          <Checkbox
            name="arrearsChecked"
            defaultChecked={false}
            onCheck={(checked: boolean) => handleCheckboxChange(checked)}
          />
          <CheckboxLabel
            color={STATUS_RED}
            label={`This driver’s account is in arrears of £${customerBalance?.toFixed(
              2
            )}. Please contact the accounts team before proceeding`}
          />
        </FlexLayout>
      )}
      {view === COURTESY_VEHICLE && !!courtesy && Object.keys(courtesy).length > 0 && (
        <>
          <Text color={PRIMARY_PURPLE} variant="body6" weight={500}>
            Replacement vehicle details
          </Text>
          <FieldGrid styled={{ margin: '20px 0' }} numColumns={4} headers={courtVehHeaders} values={courtVehValues} />
          <FlexLayout
            styled={{ cursor: 'pointer' }}
            itemsX="center"
            itemsY="center"
            onClick={onCompleteOrViewCourtesyClick}
          >
            <span style={{ color: PRIMARY_PURPLE, textDecoration: 'underline' }}>View order</span>
            <MdKeyboardArrowRight size={24} color={PRIMARY_PURPLE} />
          </FlexLayout>
        </>
      )}
      {evaluateCourtesyConditions().returnCourtesy !== undefined && !evaluateCourtesyConditions().returnCourtesy && (
        <Text color={PRIMARY_GREEN} variant="body8">
          *Replacement vehicle returned
        </Text>
      )}
      {view === IN_SERVICE_RENTAL_VEHICLE && (
        <Text color={PRIMARY_GREEN} variant="body8">
          *Please note that initiating an in-service rental will automatically freeze the original agreement
        </Text>
      )}
      <FlexLayout styled={{ margin: '20px 0' }} wrap itemsX="end" gap={18}>
        {/* COURTESY VEHICLE BUTTON */}
        {view === COURTESY_VEHICLE && courtesyBtnText && (
          <PrimaryButton disabled={courtesyBtnDisabled} isProcessing={isLoading} onClick={handleCourtesyBtnClick}>
            {courtesyBtnText}
          </PrimaryButton>
        )}
        {/* IN-SERVICE VEHICLE BUTTON */}
        {view === IN_SERVICE_RENTAL_VEHICLE && inServiceBtnText && (
          <PrimaryButton disabled={inServiceBtnDisabled} isProcessing={isLoading} onClick={handleInServiceBtnClick}>
            {inServiceBtnText}
          </PrimaryButton>
        )}
      </FlexLayout>
      {/* ORIGINAL AGREEMENT BUTTONS */}
      <Text variant="h4" color={PRIMARY_PURPLE} weight={800}>
        Original agreement actions
      </Text>
      <FlexLayout wrap itemsX="start" gap={18} styled={{ marginTop: 25 }}>
        <SecondaryButton disabled={freezeBtnDisabled} isProcessing={isLoading} onClick={handleFreezeBtnClick}>
          {freezeBtnText}
        </SecondaryButton>
        <PrimaryButton
          isProcessing={isLoading}
          disabled={
            servicingDetails?.agreement_status !== AGREEMENT_LIVE ||
            isAftercare(servicingDetails?.agreement_type) ||
            !!courtesy ||
            !!liveInServiceRentalAgreement ||
            !!pendingRentalApplication ||
            !!openCourtesyApplication
          }
          onClick={() => setIsTerminateModal(true)}
        >
          Terminate agreement
        </PrimaryButton>
      </FlexLayout>
      <ConfirmationModal
        title={handleModalTitle}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
    </>
  );
};
