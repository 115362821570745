import React from 'react';
import ReactDOM from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { FiX } from 'react-icons/fi';
import { ModalOverlay, Modal as ModalComponent, ModalChildren, Header } from './modal.styles';
import { Text } from '../text/text';
import { PRIMARY_PURPLE } from '../../common/styles/Colors';
import { FlexLayout } from '../layouts/flexLayout/flexLayout';
import { withStyledProps } from '../../utils/colorUtils';

const modalRoot = document.getElementById('modal');

interface ModalProps {
  children: JSX.Element;
  open: boolean;
  onClose: () => void;
  showClose?: boolean;
  showOverflow?: boolean;
  title?: string;
}
export const Modal = withStyledProps(
  ({ children, open, onClose, showClose, showOverflow, title, ...props }: ModalProps) => {
    return ReactDOM.createPortal(
      <AnimatePresence>
        {open && (
          <>
            <ModalOverlay key="modalOverlay" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} />
            <ModalComponent
              key="modal"
              initial={{ opacity: 0, y: 0, x: '-50%' }}
              animate={{ opacity: 1, y: '-50%', x: '-50%' }}
              exit={{ opacity: 0, y: 0, x: '-50%' }}
              {...props}
            >
              {showClose && (
                <Header itemsX="space-between" itemsY="center">
                  <Text variant="h4" color={PRIMARY_PURPLE} weight={800}>
                    {title ?? ''}
                  </Text>
                  <FlexLayout onClick={onClose} styled={{ padding: 8 }}>
                    <FiX size={20} id="close" color={PRIMARY_PURPLE} />
                  </FlexLayout>
                </Header>
              )}
              <ModalChildren $showOverflow={showOverflow}>{children}</ModalChildren>
            </ModalComponent>
          </>
        )}
      </AnimatePresence>,
      modalRoot!
    );
  }
);
