import React from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { DamageForm } from './damageForm.styles';
import { GridLayout } from '../../../../../../uiComponents/layouts/gridLayout/gridLayout';
import { FlexLayout } from '../../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { TextField } from '../../../../../../uiComponents/inputs/textField/textField';
import { TextArea } from '../../../../../../uiComponents/inputs/textArea/textArea';
import { PrimaryButton } from '../../../../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { MultiUploaderInput } from '../../../../../../uiComponents/inputs/multiUploaderInput/multiUploaderInput';
import { DamageReportItem } from '../../../../../../consts/conditionReport';
import { DropDown } from '../../../../../../uiComponents/uiControls/dropDown/dropDown';
import { damageTypeOptions } from '../../../../../../consts/vehicle';
import { TextFieldLabel } from '../../../../../../uiComponents/inputs/textField/textField.styles';

interface DamageReportFormInfo {
  values?: DamageReportItem;
  onClose: () => void;
  onSubmit: (submitValues: DamageReportItem) => void;
}

export const DamageReportForm = ({ values, onClose, onSubmit }: DamageReportFormInfo) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<DamageReportItem>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: values,
  });

  const onFormSubmit = (submitValues: DamageReportItem) => {
    onSubmit(submitValues);
  };

  return (
    <DamageForm>
      <GridLayout template={2} gap={27}>
        <div>
          <TextFieldLabel $isRequired={true}>Damaged type</TextFieldLabel>
          <DropDown
            options={damageTypeOptions}
            placeholder="ex. Mechanical"
            name="type"
            defaultValue={values?.type}
            error={errors?.type}
            required={{
              required: 'Damaged type is a required field',
            }}
            control={control as unknown as Control<FieldValues>}
          />
        </div>
        <div />
        <TextField
          {...register('name', {
            required: 'Damaged area name is a required field',
          })}
          required={true}
          label="Damaged area name"
          placeholder="ex. Rear bumper"
          type="text"
          name="name"
          width="100%"
          defaultValue={values?.name}
          error={errors.name}
        />
        <TextField
          {...register('description', {
            required: 'Damage description is a required field',
          })}
          required={true}
          label="Damage description"
          placeholder="ex. Dent"
          type="text"
          name="description"
          width="100%"
          defaultValue={values?.description}
          error={errors.description}
        />
        <MultiUploaderInput
          control={control as unknown as Control<FieldValues>}
          label="Add multiple photos"
          name="photos"
          maxFiles={10}
          defaultValue={values?.photos}
          required={true}
        />
        <div>
          <TextArea
            {...register('details')}
            styled={{ height: 108 }}
            label="Damage detail"
            name="details"
            placeholder="Type here"
            defaultValue={values?.details}
            error={errors.details}
          />
        </div>
      </GridLayout>
      <FlexLayout itemsX="end" itemsY="end" styled={{ marginTop: 16 }}>
        <SecondaryButton disabled={false} styled={{ marginRight: '10px', padding: '0px 38px' }} onClick={onClose}>
          Cancel
        </SecondaryButton>
        <PrimaryButton isProcessing={false} styled={{ padding: '0px 38px' }} onClick={handleSubmit(onFormSubmit)}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </DamageForm>
  );
};
