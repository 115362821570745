import { useCallback, useState } from 'react';
import { TableFilter, getFilters } from '../uiComponents/table/tableUtils/tableUtils';

export const useTableFilters = () => {
  const [tableData, setTableData] = useState<{ rowData: { rowColour?: string; data: any }; cells: JSX.Element[] }[]>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [numRowsPerPage, setNumRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [searchString, setSearchString] = useState<string>('');
  const [sortingColumn, setSortingColumn] = useState<string>('created_date');
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [tableFilters, setTableFilters] = useState<TableFilter[]>([]);
  const filterQuery =
    (searchString?.length > 0 ? `search=${searchString}&` : '') +
    getFilters(tableFilters, sortingColumn, sortAscending ? 'ASC' : 'DESC');

  const goToPageNumber = useCallback((pageNumber: number) => {
    setPageNumber(pageNumber);
  }, []);

  const getSortDirection = useCallback(
    (columnId: string) => {
      if (sortingColumn === columnId) {
        setSortAscending(!sortAscending);
        return !sortAscending;
      }
      setSortingColumn(columnId);
      setSortAscending(true);
      return true;
    },
    [sortAscending, sortingColumn]
  );

  return {
    goToPageNumber,
    setNumRowsPerPage,
    setTotalRows,
    setSearchString,
    setTableData,
    setSortingColumn,
    setSortAscending,
    setTableFilters,
    getSortDirection,
    filterQuery,
    tableFilters,
    sortingColumn,
    sortAscending,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
    searchString,
  };
};
