import React, { useState } from 'react';
import { BsSpeedometer2, BsArrowUpCircle } from 'react-icons/bs';
import { FiDroplet } from 'react-icons/fi';
import { getCurrentAndLastWeekRanges } from './telematicsCards/utils';
import { TelematicsData } from '../driverPage/driverPage';
import { StyledDiv } from './driverTelematics.styles';
import { TelematicsLargeCard } from './telematicsCards/telematicsLargeCard/telematicsLargeCard';
import { TelematicsMediumCard } from './telematicsCards/telematicsMediumCard';
import { TelematicsSmallCard } from './telematicsCards/telematicsSmallCard';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';
import { NoResults } from '../../../../uiComponents/table/noResults/noResults';
import { Spinner } from '../../../../uiComponents/uiControls/spinner/spinner';
import { Text } from '../../../../uiComponents/text/text';
import { LIVE_STATUS } from '../../../../consts/status';
import { PRIMARY_PURPLE, PRIMARY_GREEN } from '../../../../common/styles/Colors';

interface DriverTelematicsProps {
  telematicsData: TelematicsData | undefined;
  showTelematics: boolean;
  agrStatus?: string;
  vin: string;
  loading: boolean;
}

export const DriverTelematics = ({
  telematicsData,
  showTelematics,
  agrStatus,
  vin,
  loading,
}: DriverTelematicsProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <CollapsiblePanel
      header={
        <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
          Telematics (Samsara)
        </Text>
      }
      expanded={!isCollapsed}
      onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
      styled={{ marginTop: 16 }}
    >
      <div>
        <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
          Telematics (Samsara)
        </Text>
        {(() => {
          if (loading && vin) {
            return (
              <FlexLayout itemsX="center">
                <Spinner size={32} color={PRIMARY_PURPLE} />
              </FlexLayout>
            );
          }
          if (telematicsData && showTelematics && agrStatus === LIVE_STATUS && vin) {
            return (
              <>
                <FlexLayout styled={{ marginTop: '-56px', paddingRight: '32px' }} itemsX="end">
                  <StyledDiv>
                    Engine state:
                    <span className={telematicsData?.engineState === 'On' ? 'green-text' : 'red-text'}>
                      {` ${telematicsData?.engineState}`}
                    </span>
                  </StyledDiv>
                </FlexLayout>
                <FlexLayout styled={{ marginTop: '16px' }} itemsX="start">
                  <Text variant="body5" color={PRIMARY_PURPLE} weight={500}>
                    Driver mileage
                  </Text>
                </FlexLayout>
                <GridLayout template={'2fr 2fr'} gap={8}>
                  <TelematicsLargeCard
                    title="Last week"
                    weekRange={getCurrentAndLastWeekRanges()[0]}
                    weeklyMileage={telematicsData?.weeklyMileage?.lastWeeksMileage}
                    yearlyMileage={telematicsData?.yearlyMileage}
                  />
                  <TelematicsLargeCard
                    title="This week"
                    weekRange={getCurrentAndLastWeekRanges()[1]}
                    weeklyMileage={telematicsData?.weeklyMileage?.thisWeeksMileage}
                    yearlyMileage={telematicsData?.yearlyMileage}
                  />
                </GridLayout>
                <GridLayout template={'2fr 2fr 1fr 1 fr'} gap={8} direction="y">
                  <TelematicsMediumCard
                    title="Odometer reading"
                    icon={<BsSpeedometer2 size={42} color={PRIMARY_GREEN} />}
                    telematicsType={telematicsData?.odometer}
                  />
                  <TelematicsMediumCard
                    title="Driver location"
                    icon={<FiDroplet size={42} color={PRIMARY_GREEN} style={{ transform: 'rotate(180deg)' }} />}
                    telematicsType={telematicsData?.driverLocation}
                  />
                  <TelematicsSmallCard
                    title="Safety score"
                    icon={<BsArrowUpCircle size={12} />}
                    score={telematicsData?.driverScore}
                    scoreDifference={telematicsData?.driverScoreDifference}
                  />
                  <TelematicsSmallCard
                    title="Skill level"
                    score={telematicsData?.driverScore}
                    skillLevel={telematicsData?.driverSkillLevel}
                  />
                </GridLayout>
              </>
            );
          }
          return <NoResults />;
        })()}
      </div>
    </CollapsiblePanel>
  );
};
