import React, { useCallback, useState } from 'react';
import { PlanSummary } from './planSummary';
import { SectionProps } from '../../order/order';
import { EVOptions, EvField, FieldHeader, StyledSpinner } from '../../order/order.styles';
import { SectionHeader } from '../sectionHeader';
import { isRental } from '../../../../consts';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../../uiComponents/modals/modal';
import { Text } from '../../../../uiComponents/text/text';
import { TextInput } from '../../../../uiComponents/inputs/textInput/textInput';
import { Checkbox } from '../../../../uiComponents/uiControls/checkbox/checkbox';
import { RadioButton } from '../../../../uiComponents/uiControls/radioButton/radioButton';
import { getDriverByShell } from '../../../../api/get/driver.get';
import { updateApplication } from '../../../../api/patch/application.patch';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../../common/styles/Colors';
import { VehicleAgreementDetails } from '../../../../models/vehicle';
import { shellNumberFormat } from '../../../../utils/validations';
import { DuplicateShellCardCheck } from '../../../../models/driver';

export const EvPackagesSection = ({ isComplete, isLocked, progress, checkProgress }: SectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isEvChecked, setIsEvChecked] = useState<boolean>(false);
  const [isCarAndChargeChecked, setIsCarAndChargeChecked] = useState<boolean>(false);
  const [isUberCleanChecked, setIsUberCleanChecked] = useState<boolean>(false);
  const [isPlanSumModal, setIsPlanSumModal] = useState<boolean>(false);
  const [checkingForUniqueShellNumber, setCheckingForUniqueShellNumber] = useState<boolean>(false);
  const [uberCleanAmount, setUberCleanAmount] = useState<number>(0);
  const [shellNumberError, setShellNumberError] = useState<string | null>(null);
  const [shellCardNumber, setShellCardNumber] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const applicationId: string = progress?.application_id;
  const driverId: string = progress?.driver_id;

  const vehicleDetails: VehicleAgreementDetails = {
    total_due: progress?.total_due,
    agreement_length_weeks: progress?.agreement_length_weeks,
    agreement_value_weeks: progress?.agreement_value_weeks,
    vat_value: progress?.vat_value,
    rental_value: progress?.rental_value,
  };

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    updateApplication(applicationId, {
      bp_card_id: '',
      car_and_charge: isCarAndChargeChecked,
      driver_id: driverId,
      has_uber_clean_fund: isUberCleanChecked,
      shell_card_id: shellCardNumber,
      uber_clean_air_amount: uberCleanAmount.toFixed(2),
      uber_clean_air_confirmed: true,
    }).then(() => {
      checkProgress?.();
      setSubmitting(false);
      setIsCollapsed(true);
    });
  }, [
    applicationId,
    driverId,
    isCarAndChargeChecked,
    isUberCleanChecked,
    shellCardNumber,
    uberCleanAmount,
    checkProgress,
  ]);

  return (
    <>
      <CollapsiblePanel
        styled={{ marginTop: 16 }}
        locked={isLocked}
        header={
          <SectionHeader
            title="EV packages"
            completedText="Completed"
            isComplete={isComplete}
            isLocked={isLocked}
            expanded={false}
          />
        }
        expanded={!isCollapsed}
        onCollapse={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        <div>
          <SectionHeader
            title="EV packages"
            completedText="Completed"
            isComplete={isComplete}
            isLocked={isLocked}
            expanded
          />
          <EVOptions>
            <FlexLayout itemsY="center" gap={8}>
              <Checkbox
                onCheck={(checked: boolean) => {
                  setIsEvChecked(checked);
                  if (!checked) {
                    setIsCarAndChargeChecked(false);
                    setIsUberCleanChecked(false);
                    setShellCardNumber('');
                    setUberCleanAmount(0);
                  }
                }}
                name="ev_confirm"
              />
              <Text variant="body6" weight={500} color={PRIMARY_PURPLE}>
                Is this an electric vehicle?
              </Text>
            </FlexLayout>
            {isEvChecked && (
              <>
                <FlexLayout itemsY="center" gap={8} styled={{ marginTop: 8 }}>
                  <Checkbox onCheck={(checked: boolean) => setIsCarAndChargeChecked(checked)} name="ev_confirm" />
                  <Text variant="body6" weight={500} color={PRIMARY_PURPLE}>
                    Include car and charge package
                  </Text>
                  {isCarAndChargeChecked && (
                    <EvField>
                      <FieldHeader variant="body7" weight={300} color={PRIMARY_PURPLE} block>
                        Shell card number:
                      </FieldHeader>
                      <TextInput
                        styled={{ marginTop: 16 }}
                        name="shell_card_no"
                        placeholder="Shell card number"
                        maxLength={6}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e?.target?.value?.length === 6) {
                            setCheckingForUniqueShellNumber(true);
                            setShellNumberError(null);
                            getDriverByShell(progress?.driver_id, e?.target?.value).then(
                              (response: { data: DuplicateShellCardCheck[] }) => {
                                const otherDriversWithShellId =
                                  response?.data?.filter((item) => item?.id !== driverId) ?? [];
                                setCheckingForUniqueShellNumber(false);
                                if (otherDriversWithShellId?.length > 0) {
                                  setShellNumberError('* This card number is already in use.');
                                } else {
                                  setShellNumberError(null);
                                }
                              }
                            );
                          }
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value.match(shellNumberFormat) == null || e?.target?.value?.length < 6) {
                            setShellNumberError('* Shell card number should start with 08 and be 6 digits long.');
                          } else {
                            setShellNumberError(null);
                            setShellCardNumber(e.target.value);
                          }
                        }}
                      />
                      {checkingForUniqueShellNumber && <StyledSpinner color={PRIMARY_PURPLE} size={16} />}
                      {shellNumberError && (
                        <Text
                          variant="body8"
                          color={STATUS_RED}
                          weight={300}
                          block
                          styled={{ position: 'absolute', whiteSpace: 'nowrap', marginTop: 4 }}
                        >
                          {shellNumberError}
                        </Text>
                      )}
                    </EvField>
                  )}
                </FlexLayout>
                <FlexLayout itemsY="center" gap={8} styled={{ marginTop: 24 }}>
                  <Checkbox onCheck={(checked: boolean) => setIsUberCleanChecked(checked)} name="ev_confirm" />
                  <Text variant="body6" weight={500} color={PRIMARY_PURPLE}>
                    Include Uber Clean Air package
                  </Text>
                  {isUberCleanChecked && (
                    <EvField>
                      {isRental(progress?.application_type) ? (
                        <FlexLayout gap={24}>
                          <FlexLayout gap={8} itemsY="center">
                            <RadioButton
                              id="none"
                              name="uber_clean"
                              defaultChecked
                              onCheck={() => setUberCleanAmount(0)}
                            />
                            <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
                              None
                            </Text>
                          </FlexLayout>
                          <FlexLayout gap={8} itemsY="center">
                            <RadioButton id="hundred" name="uber_clean" onCheck={() => setUberCleanAmount(100)} />
                            <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
                              £100 off over 4 weeks
                            </Text>
                          </FlexLayout>
                          <FlexLayout gap={8} itemsY="center">
                            <RadioButton id="three_hundred" name="uber_clean" onCheck={() => setUberCleanAmount(300)} />
                            <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
                              £300 off over 13 weeks
                            </Text>
                          </FlexLayout>
                          <FlexLayout gap={8} itemsY="center">
                            <RadioButton id="three_hundred" name="uber_clean" onCheck={() => setUberCleanAmount(900)} />
                            <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
                              £900 off over 12 weeks
                            </Text>
                          </FlexLayout>
                        </FlexLayout>
                      ) : (
                        <>
                          <FieldHeader
                            styled={{ marginTop: 24 }}
                            variant="body7"
                            weight={300}
                            color={PRIMARY_PURPLE}
                            block
                          >
                            Uber clean air amount:
                          </FieldHeader>
                          <TextInput
                            styled={{ marginTop: 16 }}
                            name="uber_clean_air_amount"
                            placeholder="Uber clean air amount"
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUberCleanAmount(+e?.target?.value)}
                          />
                        </>
                      )}
                    </EvField>
                  )}
                </FlexLayout>
              </>
            )}
            <PrimaryButton
              disabled={!isEvChecked}
              isProcessing={submitting}
              styled={{ width: 154, marginTop: 24, marginRight: 20 }}
              onClick={() => setIsPlanSumModal(true)}
            >
              Plan summary
            </PrimaryButton>
            <PrimaryButton
              disabled={
                (isCarAndChargeChecked && (shellNumberError !== null || shellCardNumber?.length < 6)) ||
                (isUberCleanChecked && !isRental && uberCleanAmount <= 0)
              }
              isProcessing={submitting}
              styled={{ width: 154, marginTop: 24 }}
              onClick={() => onSubmit()}
            >
              Confirm
            </PrimaryButton>
          </EVOptions>
        </div>
      </CollapsiblePanel>
      <Modal
        styled={{ width: '80%' }}
        title="Plan summary"
        open={isPlanSumModal}
        showClose
        onClose={() => setIsPlanSumModal(false)}
      >
        <PlanSummary
          vehicleDetails={vehicleDetails}
          params={{
            carAndChargeBoolean: isCarAndChargeChecked,
            uberCleanAirAmount: isUberCleanChecked ? uberCleanAmount : 0,
          }}
          isRental={isRental(progress?.application_type)}
          onClose={() => setIsPlanSumModal(false)}
        />
      </Modal>
    </>
  );
};
