import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BsArrowLeftRight, BsDownload, BsEye } from 'react-icons/bs';
import { APP_CONTEXT } from '../../../utils/context';
import { getAllBranches } from '../../../api/get/branch.get';
import { exportAllTransfers, getAllTransfers } from '../../../api/get/transfer.get';
import { Branch } from '../../../models/branch';
import { PRIMARY_GRAY, PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { Table } from '../../../uiComponents/table/table';
import { transferListColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import { OptionList } from '../../../utils/props';
import {
  agreementTypes,
  transferReasons,
  transferTypes,
} from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableTagCell,
  TableTextCell,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { useDateRangeFilter } from '../../../hooks/useDateRangeFilter';
import { TableHeader } from '../../../uiComponents/table/tableHeader/tableHeader';
import { Transfer } from '../../../models/transfer';
import { CREATE_TRANSFER } from '../../../consts/routes';
import { getVehicleTypesSelect } from '../../../api/get/vehicleType.get';
import { VehicleTypeSelectResponse } from '../../../models/vehicle';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../uiComponents/modals/modal';
import { InternalTransfer } from './internalTransfer/internalTransfer';
import { handleDownload } from '../../../uiComponents/table/documentTable/documentTable';
import { CHECK_IN, IN_SERVICING } from '../../../consts';
import { FieldGrid } from '../../../uiComponents/layouts/fieldGrid/fieldGrid';

interface navigationParams {
  vrm: string | null;
  reason: string | null;
  dynamic: string | null;
  agreement_id: string | null;
}
export const TransferList = () => {
  const navigate = useNavigate();
  const [branches, setBranches] = useState<OptionList[]>([]);
  const [manufacturerOptions, setManufacturerOptions] = useState<OptionList[]>([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState<OptionList[]>([]);
  const [selectedAgreementTypes, setSelectedAgreementTypes] = useState<OptionList[]>([]);
  const [isInternalTransferModal, setIsInternalTransferModal] = useState<boolean>(false);
  const [selectedReason, setSelectReason] = useState<OptionList[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<OptionList[]>([]);
  const [selectedTransfer, setSelectedTransfer] = useState<Transfer | null>(null);
  const [selectedTransferTypes, setSelectedTransferTypes] = useState<OptionList[]>([]);
  const [modal, setModal] = useState<JSX.Element>();
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setSortAscending,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);

  const handleCreateTransferModal = useCallback(() => {
    const params: navigationParams = {
      vrm: null,
      reason: null,
      dynamic: null,
      agreement_id: null,
    };
    navigate(CREATE_TRANSFER, { state: params });
  }, [navigate]);

  const onClickInternalTransfer = (transfer: Transfer) => {
    setSelectedTransfer(transfer);
    setIsInternalTransferModal(true);
  };

  const handleGetTransfersResponse = useCallback(
    (count: number, lists: Transfer[]) => {
      const transferList = lists?.map((list: Transfer) => {
        return {
          rowData: { data: list },
          cells: [
            <TableTextCell value={list?.vrm ? list.vrm : ''} />,
            <FlexLayout
              gap={8}
              itemsY="center"
              styled={{ cursor: list?.driver_name ? 'pointer' : 'default' }}
              onClick={() =>
                setModal(
                  <Modal open showClose onClose={() => setModal(undefined)} title="Driver details">
                    <FieldGrid
                      numColumns={3}
                      headers={['Driver name', 'Mobile number', 'Email address']}
                      values={[list?.driver_name ?? '-', list?.driver_phone ?? '-', list?.driver_email ?? '-']}
                    />
                  </Modal>
                )
              }
            >
              {list?.driver_name && <BsEye size={16} color={PRIMARY_GRAY} />}
              <TableTextCell value={list?.driver_name ? list.driver_name : '-'} />
            </FlexLayout>,
            <TableTextCell value={list?.vehicle_type ? list.vehicle_type : ''} />,
            <TableTextCell value={list?.agreement_type ? list.agreement_type : ''} />,
            <TableTextCell value={list?.mileage ? list.mileage.toString() : ''} />,
            <TableTextCell
              value={list?.previous_branch_name ? list.previous_branch_name : list.check_in_branch_name ?? '-'}
            />,
            <TableTextCell
              value={list?.current_branch_name ? list.current_branch_name : list.check_in_branch_name ?? '-'}
            />,
            <TableTextCell value={list?.check_in_branch_name ? list.check_in_branch_name : ''} />,
            <TableTextCell value={list?.reason ? list.reason : ''} />,
            <TableTagCell tags={[list?.transfer_type ?? '-']} />,
            <TableTextCell
              value={`${moment(list?.transfer_created_date).format('DD MMM YYYY')} at ${moment(
                list?.transfer_created_date
              ).format('HH:mm')}`}
            />,
            <FlexLayout gap={16}>
              <>
                {list.transfer_type === CHECK_IN && list.vehicle_status === IN_SERVICING && (
                  <ActionIcon
                    icon={
                      <BsArrowLeftRight
                        size={20}
                        color={PRIMARY_PURPLE}
                        onClick={() => onClickInternalTransfer(list)}
                      />
                    }
                    tooltip="Internal transfer"
                  />
                )}
              </>
              <>
                {list?.s3_url != null && (
                  <ActionIcon
                    icon={
                      <BsDownload
                        size={20}
                        color={PRIMARY_PURPLE}
                        onClick={() =>
                          handleDownload(
                            list?.s3_url ?? '',
                            `${list?.vrm}_${list?.transfer_type}_${moment(list?.transfer_created_date)?.format(
                              'DD MMM YYYY'
                            )}`
                          )
                        }
                      />
                    }
                    tooltip="Download"
                  />
                )}
              </>
            </FlexLayout>,
          ],
        };
      });
      setTableData(transferList);
      setTotalRows(count);
    },
    [setTableData, setTotalRows]
  );
  const fetchTransferList = useCallback(
    (queryString: string) => {
      const controller = new AbortController();
      getAllTransfers(queryString, controller.signal).then((response: { count: number; data: Transfer[] }) => {
        handleGetTransfersResponse(response.count, response.data);
      });
    },
    [handleGetTransfersResponse]
  );
  useEffect(() => {
    setActiveSideNav('transferListPage');
    setPageTitle('Transfers');
    getAllBranches().then(({ data }) => {
      const branches: OptionList[] = data.map((branch: Branch) => {
        return { value: branch.branch_id, label: branch.branch_name };
      });
      setBranches(branches);
    });
    const control = new AbortController();
    getVehicleTypesSelect(control.signal).then((response: { data: VehicleTypeSelectResponse }) => {
      const manufacturers: OptionList[] = response?.data?.manufacturers?.map((manufacturer) => {
        return {
          value: manufacturer?.value,
          label: manufacturer?.text,
        };
      });
      setManufacturerOptions(manufacturers);
    });
    setSortingColumn('transfer.created_date');
    setSortAscending(false);
    fetchTransferList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=transfer.created_date:DESC`);
  }, [fetchTransferList, setActiveSideNav, setPageTitle, setSortAscending, setSortingColumn]);
  const filters: FilterItem[] = [
    {
      name: 'vehicle_type',
      element: (
        <DropDownFilter
          name="vehicle_type"
          placeholder="Manufacturer"
          options={manufacturerOptions}
          multiValues={selectedManufacturers}
          title="Manufacturer"
          onChange={(items) => setSelectedManufacturers(items as OptionList[])}
        />
      ),
    },
    {
      name: 'agreement_type',
      element: (
        <DropDownFilter
          name="agreement_type"
          placeholder="Agreement type"
          options={agreementTypes}
          multiValues={selectedAgreementTypes}
          title="Agreement type"
          onChange={(items) => setSelectedAgreementTypes(items as OptionList[])}
        />
      ),
    },
    {
      name: 'reason',
      element: (
        <DropDownFilter
          name="reason"
          placeholder="Select a Reason"
          options={transferReasons}
          multiValues={selectedReason}
          title="Reason:"
          onChange={(items) => setSelectReason(items as OptionList[])}
        />
      ),
    },
    {
      name: 'transferType',
      element: (
        <DropDownFilter
          name="transferType"
          placeholder="Transfer type"
          options={transferTypes}
          multiValues={selectedTransferTypes}
          title="Transfer type:"
          onChange={(items) => setSelectedTransferTypes(items as OptionList[])}
        />
      ),
    },
    {
      name: 'location',
      element: (
        <DropDownFilter
          name="location"
          placeholder="Location"
          options={branches}
          multiValues={selectedBranch}
          title="Location"
          onChange={(items) => setSelectedBranch(items as OptionList[])}
        />
      ),
    },
    {
      name: 'created_date',
      element: (
        <DateRangeFilter
          title="Transfer date"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];
  useEffect(() => {
    setTableFilters([
      {
        columnName: 'vehicle_type',
        options: selectedManufacturers,
      },
      {
        columnName: 'agreement_type',
        options: selectedAgreementTypes,
      },
      {
        columnName: 'reason',
        options: selectedReason,
      },
      {
        columnName: 'branch.id',
        options: selectedBranch,
      },
      {
        columnName: 'transfer.transfer_type',
        options: selectedTransferTypes,
      },
      {
        columnName: 'created_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [
    selectedBranch,
    dateRangeFilter,
    setTableFilters,
    selectedTransferTypes,
    selectedManufacturers,
    selectedAgreementTypes,
    selectedReason,
  ]);

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchTransferList(queryString);
    },
    [fetchTransferList, setTableData, goToPageNumber, tableFilters]
  );
  const closeModal = (reload: boolean) => {
    setIsInternalTransferModal(false);
    if (reload) {
      fetchTransferList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=transfer.created_date:DESC`);
    }
  };
  const onClearClick = useCallback(() => {
    setSelectedManufacturers([]);
    setSelectedAgreementTypes([]);
    setSelectReason([]);
    setSelectedBranch([]);
    setDateRangeFilter([]);
    setSelectedTransferTypes([]);
  }, [setDateRangeFilter]);

  return (
    <>
      <Table
        header={
          <TableHeader
            tableHeaderTitle="Transfer list"
            actionButtonText="Create condition report"
            onButtonClick={() => handleCreateTransferModal()}
          />
        }
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        disableApply={invalidDates}
        sortAscending={sortAscending}
        columns={transferListColumns}
        rows={tableData}
        downloadApi={exportAllTransfers}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        filterQuery={filterQuery}
        filters={filters}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(0, value, searchString, sortingColumn, sortAscending);
        }}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
      />
      {selectedTransfer && (
        <Modal
          styled={{ width: '70%' }}
          title="Internal transfer"
          open={isInternalTransferModal}
          showClose
          onClose={() => setIsInternalTransferModal(false)}
        >
          <InternalTransfer rowData={selectedTransfer} close={closeModal} />
        </Modal>
      )}
      {modal != null && modal}
    </>
  );
};
