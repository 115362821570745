// DEPENDENCIES
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { BsPencil } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';
// COMPONENTS & STYLES
import { CreateEditUser } from './createEditUser/createEditUser';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Modal } from '../../../uiComponents/modals/modal';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { userColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { departmentOptions } from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import { DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import { TableHeader } from '../../../uiComponents/table/tableHeader/tableHeader';
import {
  TableTextCell,
  getQueryString,
  DEFAULT_NUM_ROWS_PER_PAGE,
} from '../../../uiComponents/table/tableUtils/tableUtils';
// API CALLS & MODELS
import { getAllEmployees } from '../../../api/get/employee.get';
import { deleteEmployee } from '../../../api/delete/employee.delete';
import { Employee } from '../../../models/employee';
// HOOKS & UTILS & COMMONS
import { useTableFilters } from '../../../hooks/useTableFilters';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { APP_CONTEXT } from '../../../utils/context';
import { OptionList } from '../../../utils/props';

const defaultUsersQuery = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=first_name:ASC`;

export const UserList = () => {
  const { setActiveSideNav, setPageTitle, superAdmin } = useContext(APP_CONTEXT);
  const [rowData, setRowData] = useState<Employee | null>();
  const [activeUserId, setActiveUserId] = useState<string | null>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);
  const [departmentFilter, setDepartmentFilter] = useState<OptionList[]>([]);
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const filters: FilterItem[] = [
    {
      name: 'department',
      element: (
        <DropDownFilter
          name="department"
          placeholder="Select a department"
          options={departmentOptions}
          multiValues={departmentFilter}
          title="Department:"
          onChange={(items) => setDepartmentFilter(items as OptionList[])}
        />
      ),
    },
  ];

  const handleEditEmployeeModal = useCallback((employee: Employee) => {
    setRowData(employee);
    return setIsEditModalOpen(true);
  }, []);

  const deleteUser = (userId: string) => {
    deleteEmployee(userId)
      .then(() => {
        fetchUserList(defaultUsersQuery);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setActiveUserId(null);
      });
  };

  const handleDeleteUser = useCallback((id: string) => {
    if (!id) return;
    setIsDeleteConfirmationModalOpen(true);
    setActiveUserId(id);
  }, []);

  const handleGetUsersResponse = useCallback(
    ({ count, data }: { count: number; data: Employee[] }) => {
      const userRows = data?.map((user: Employee) => {
        return {
          rowData: { data: user },
          cells: [
            <TableTextCell value={user?.first_name} />,
            <TableTextCell value={user?.last_name} />,
            <TableTextCell value={user?.email} />,
            <TableTextCell value={user?.contact_number} />,
            <TableTextCell value={user?.department} />,
            <FlexLayout gap={16}>
              <ActionIcon
                icon={<BsPencil size={24} color={PRIMARY_PURPLE} onClick={() => handleEditEmployeeModal(user)} />}
                tooltip="Edit user"
              />
              {superAdmin && (
                <ActionIcon
                  icon={<FiTrash2 size={24} color={PRIMARY_PURPLE} onClick={() => handleDeleteUser(user.id)} />}
                  tooltip="Delete"
                />
              )}
            </FlexLayout>,
          ],
        };
      });
      setTableData(userRows);
      setTotalRows(count);
    },
    [setTableData, setTotalRows, handleEditEmployeeModal, handleDeleteUser, superAdmin]
  );

  const fetchUserList = useCallback(
    (queryString: string) => {
      const controller = new AbortController();
      getAllEmployees(queryString, controller.signal).then((response: { count: number; data: Employee[] }) => {
        handleGetUsersResponse(response);
      });
      return () => {
        controller.abort();
      };
    },
    [handleGetUsersResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchUserList(queryString);
    },
    [fetchUserList, setTableData, goToPageNumber, tableFilters]
  );

  useEffect(() => {
    setActiveSideNav('userListPage');
    setPageTitle('Users');
    setSortingColumn('first_name');
    fetchUserList(defaultUsersQuery);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, fetchUserList]);

  useEffect(() => {
    setTableFilters([{ columnName: 'department', options: departmentFilter }]);
  }, [setTableFilters, departmentFilter]);

  const onClearClick = useCallback(() => {
    setDepartmentFilter([]);
    fetchUserList(`limit=${numRowsPerPage}&sort=first_name:ASC`);
  }, [fetchUserList, numRowsPerPage]);

  const onCreateEditSubmit = useCallback(() => {
    setIsEditModalOpen(false);
    setIsCreateModalOpen(false);
    applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
    setRowData(null);
  }, [setIsEditModalOpen, applyFilters, pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending]);

  return (
    <>
      <Table
        header={
          <>
            {superAdmin ? (
              <TableHeader
                tableHeaderTitle="User list"
                actionButtonText="Add user"
                onButtonClick={() => setIsCreateModalOpen(true)}
              />
            ) : (
              'User List'
            )}
          </>
        }
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={userColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        sortingColumn={sortingColumn}
        currentPageNumber={pageNumber}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        downloadApi={getAllEmployees}
        downloadName="Employees"
        dataDownloadMethod="download"
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(0, value, searchString, sortingColumn, sortAscending);
        }}
      />
      <Modal
        styled={{ width: '60%', minWidth: '800px' }}
        showClose
        title={isEditModalOpen ? 'Edit user' : 'Add new user'}
        open={isEditModalOpen || isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
          setIsEditModalOpen(false);
        }}
        showOverflow={false}
      >
        <CreateEditUser
          onClose={() => {
            setIsCreateModalOpen(false);
            setIsEditModalOpen(false);
          }}
          isInEdit={isEditModalOpen}
          values={rowData}
          onFormSubmit={onCreateEditSubmit}
        />
      </Modal>
      <ConfirmationModal
        title={'Are you sure you want to delete this user?'}
        isOpen={isDeleteConfirmationModalOpen}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        onConfirm={() => {
          deleteUser(activeUserId!);
          setIsDeleteConfirmationModalOpen(false);
        }}
        closeButtonCaption={'No'}
        confirmButtonCaption={'Yes'}
      />
    </>
  );
};
