import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import {
  editFields,
  otherStatuses,
  CONG_APPROVED,
  CONG_INVOICED,
  CONG_PAID,
  CONG_REJECTED,
} from '../../../../consts/congestion';
import { AnswerText } from '../../newDriver/createNewDriver/createNewDriver.styles';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { TextField } from '../../../../uiComponents/inputs/textField/textField';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';
import { Notification } from '../../../../uiComponents/toast/toast';
import { RadioButton } from '../../../../uiComponents/uiControls/radioButton/radioButton';
import { updateCongestion } from '../../../../api/post/additionalCharges.post';
import { Congestion } from '../../../../models/congestion';

interface ExceptionSettingFormProps {
  congestion: Congestion;
  onFormSubmit: () => void;
  onClose: () => void;
}

export const ExceptionSettingForm = ({ congestion, onFormSubmit, onClose }: ExceptionSettingFormProps) => {
  const [otherStatus, setOtherStatus] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { register, handleSubmit } = useForm<Congestion>({
    defaultValues: {
      ...congestion,
      transaction_date: moment(congestion.transaction_date).format('DD MMM YYYY HH:mm'),
      journey_date: moment(congestion.journey_date).format('DD MMM YYYY HH:mm'),
    },
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    const disableConditions: string[] = [CONG_APPROVED, CONG_INVOICED, CONG_PAID, CONG_REJECTED];

    setIsDisabled(disableConditions.includes(congestion.congestion_status));
  }, [congestion.congestion_status]);

  const congEditFields = useMemo(() => editFields, []);
  const additionalStatuses = useMemo(() => otherStatuses, []);

  const onSubmit = useCallback(
    async (value: Congestion) => {
      try {
        const payload = {
          vrm: value.vrm,
          driver_id: value.driver_id,
          status: otherStatus,
        };
        const { data } = await updateCongestion(congestion.id, payload);
        if (data.success) {
          Notification({
            type: 'success',
            title: 'Success',
            message: data.message,
            isAlert: true,
          });
          setOtherStatus('');
        } else {
          Notification({
            type: 'error',
            title: 'Error',
            message: data.message,
            isAlert: true,
          });
        }
      } catch (err) {
        const error: Error = err as Error;
        Notification({
          type: 'error',
          title: 'Error',
          message: `${error}`,
          isAlert: true,
        });
      }
      onFormSubmit();
    },
    [congestion.id, onFormSubmit, otherStatus]
  );

  return (
    <>
      <GridLayout template={3} gap={24}>
        {congEditFields?.map(({ ...field }, id) => (
          <div key={id}>
            <TextField
              {...register(field?.name, {
                required: field.required,
                pattern: field.pattern,
              })}
              disabled={field.disabled || isDisabled}
              label={field.label}
              name={field.name}
              placeholder={field.placeholder ? field.placeholder : ''}
              required={!!field.required}
              type={field.type !== 'checkbox' && field.type !== 'select' ? field.type : 'text'}
            />
          </div>
        ))}
      </GridLayout>
      {!isDisabled && (
        <FlexLayout styled={{ marginTop: 32 }} itemsY="center" gap={32}>
          {additionalStatuses?.map((option) => (
            <FlexLayout key={option.value} itemsY="center" gap={1} onClick={() => setOtherStatus(option.value)}>
              <RadioButton {...register('congestion_status')} id={option.value} disabled={false} value={option.value} />
              <AnswerText htmlFor={option.label}>{option.label}</AnswerText>
            </FlexLayout>
          ))}
        </FlexLayout>
      )}
      <FlexLayout itemsX="end" itemsY="end" styled={{ marginTop: 16 }}>
        <SecondaryButton styled={{ marginRight: '10px', padding: '0px 38px' }} onClick={() => onClose()}>
          Cancel
        </SecondaryButton>
        <PrimaryButton disabled={isDisabled} styled={{ padding: '0px 38px' }} onClick={handleSubmit(onSubmit)}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
