import styled, { css } from 'styled-components';
import { PRIMARY_GREEN, PRIMARY_PURPLE, PRIMARY_WHITE, GRAY_2, GRAY_1 } from '../../../common/styles/Colors';
import { lighten } from '../../../utils/colorUtils';

export const StyledPrimaryButton = styled.button<{ $isGreen?: boolean; $isProcessing?: boolean }>`
  min-height: 40px;
  min-width: 120px;
  background-color: ${(props) => (props.$isGreen ? PRIMARY_GREEN : PRIMARY_PURPLE)};
  color: ${PRIMARY_WHITE};
  font-size: 14px;
  padding: 0 20px;
  border-radius: 50px;
  line-height: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in;

  ${(props) => {
    if (props.$isProcessing) {
      return css`
        pointer-events: none;
        cursor: default;
      `;
    }
    return null;
  }}

  &:hover:not(:disabled) {
    background-color: ${(props) => (props.$isGreen ? lighten(PRIMARY_GREEN, 15) : lighten(PRIMARY_PURPLE, 15))};
  }

  &:disabled {
    background-color: ${GRAY_2};
    pointer-events: none;
    cursor: not-allowed;
    color: ${GRAY_1};
  }
`;
