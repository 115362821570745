import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AgreementButtonGroup } from '../agreementButtons/agreementButtonGroup';
import { AgreementDetails } from '../agreementDetails/agreementDetails';
import { AgreementTransfers } from '../agreementTables/agreementTransfers';
import { DriverButtonGroup } from '../../../drivers/driver/driverButtons/driverButtonGroup';
import { DriverDetails } from '../../../drivers/driver/driverDetails/driverDetails';
import { DriverHolidays } from '../../../drivers/driver/driverHolidays/driverHolidays';
import { DriverInfoCard } from '../../../drivers/driver/driverInfoCard/driverInfoCard';
import { DriverOrdwayDetails } from '../../../drivers/driver/driverOrdwayDetails/driverOrdwayDetails';
import { TelematicsData } from '../../../drivers/driver/driverPage/driverPage';
import { PageContainer, Sections } from '../../../drivers/driver/driverPage/driverPage.styles';
import { DriverAgreements } from '../../../drivers/driver/driverTables/driverAgreements';
import { DriverApplications } from '../../../drivers/driver/driverTables/driverApplications';
import { DriverFreshdeskTickets } from '../../../drivers/driver/driverTables/driverFreshdeskTickets';
import { DriverMileages } from '../../../drivers/driver/driverTables/driverMileages';
import { DriverVehicles } from '../../../drivers/driver/driverTables/driverVehicles';
import { DriverTags } from '../../../drivers/driver/driverTags/driverTags';
import { DriverTelematics } from '../../../drivers/driver/driverTelematics/driverTelematics';
import { FINANCE } from '../../../../consts/department';
import { IN_SERVICE_RENTAL, isAftercare } from '../../../../consts/plans';
import { AFTERCARE, AFTERCARE_REVIEWS, AGREEMENTS, CREATE_TRANSFER } from '../../../../consts/routes';
import { SWAP, TERMINATE, TERMINATED, TERMINATE_AND_SERVICE } from '../../../../consts/transfer';
import { IN_SERVICING, COURTESY_ASSIGNED } from '../../../../consts/vehicle';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { ConfirmationModal } from '../../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Modal } from '../../../../uiComponents/modals/modal';
import { Notes } from '../../../../uiComponents/notes/notes';
import { DocumentsTable } from '../../../../uiComponents/table/documentTable/documentTable';
import { Notification } from '../../../../uiComponents/toast/toast';
import { Spinner } from '../../../../uiComponents/uiControls/spinner/spinner';
import { confirmAftercareSetup } from '../../../../api/post/aftercare.post';
import { getAftercareByOriginalAgreement } from '../../../../api/get/aftercare.get';
import { getAgreement } from '../../../../api/get/agreement.get';
import { getDriver, getDriverTelematics, getOrdwayDetails } from '../../../../api/get/driver.get';
import { getVehicleById } from '../../../../api/get/vehicle.get';
import { completeAgreement } from '../../../../api/post/agreement.post';
import { Aftercare } from '../../../../models/aftercare';
import { Agreement } from '../../../../models/agreement';
import { Driver, OrdwayDetails } from '../../../../models/driver';
import { Vehicle } from '../../../../models/vehicle';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { APP_CONTEXT } from '../../../../utils/context';
import { AftercareSetupForm } from '../../../aftercare/aftercareSetup/aftercareSetup';
import { AGREEMENT_COMPLETED } from '../../../../consts/agreement';

export interface AgreementInfoProps {
  agreement: Agreement;
}

export const AgreementPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { agreementId } = useParams();
  const [agreement, setAgreement] = useState<Agreement>();
  const [driver, setDriver] = useState<Driver>();
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [loading, setLoading] = useState<boolean>(false);
  const [telematicsData, setTelematicsData] = useState<TelematicsData>();
  const [showTelematics, setShowTelematics] = useState<boolean>(false);
  const [telematicsLoading, setTelematicsLoading] = useState<boolean>(true);
  const [isFreshdeskOpen, setIsFreshdeskOpen] = useState<boolean>(false);
  const [isMileageModalOpen, setIsMileageModalOpen] = useState<boolean>(false);
  const [isTerminationModalOpen, setIsTerminationModalOpen] = useState<boolean>(false);
  const [isSwapModalOpen, setIsSwapModalOpen] = useState<boolean>(false);
  const [isConfirmTerminationModalOpen, setIsConfirmTerminationModalOpen] = useState<boolean>(false);
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState<boolean>(false);
  const [isArrearsModalOpen, setIsArrearsModalOpen] = useState<boolean>(false);
  const [isAftercareModalOpen, setIsAftercareModalOpen] = useState<boolean>(false);
  const [isCompleteProcess, setIsCompleteProcess] = useState<boolean>(false);
  const [isServiceRental, setIsServiceRental] = useState<boolean>(false);
  const [hasCourtesyVehicle, setHasCourtesyVehicle] = useState<boolean>(false);
  const [ordwayData, setOrdwayData] = useState<OrdwayDetails>();
  const [originalAgreement, setOriginalAgreement] = useState<Aftercare[]>();
  const [terminationTitle, setTerminationTitle] = useState<string>('');
  const [isInArrears, setIsInArrears] = useState<boolean>(false);
  const [ordwayDataLoaded, setOrdwayDataLoaded] = useState<boolean>(false);
  const [isAftercareSetupModalOpen, setIsAftercareSetupModalOpen] = useState<boolean>(false);
  const employeeDepartment: string | null = localStorage.getItem('department');
  const { superAdmin } = useContext(APP_CONTEXT);
  const driverId: string | undefined = agreement?.driver_id;
  const showOrdwayCancelSubscription = params?.showOrdwayCancelSubscription;
  const vrm: string | undefined = agreement?.vrm;

  const fetchAgreementData = useCallback(() => {
    const controller = new AbortController();
    setLoading(true);
    getAgreement(agreementId)
      .then(({ data }) => {
        setAgreement(data);
        setLoading(false);
        if (showOrdwayCancelSubscription && !data.ordway_subscription_id) {
          Notification({
            type: 'success',
            title: 'Success',
            message: `The ordway subscription ${showOrdwayCancelSubscription} has been deactivated in Ordway.`,
            isAlert: true,
          });
        }
      })
      .catch(() => {
        navigate(AGREEMENTS);
        setLoading(false);
      });
    return () => {
      controller.abort();
    };
  }, [setLoading, agreementId, showOrdwayCancelSubscription, navigate]);

  const fetchDriverData = useCallback(() => {
    setLoading(true);
    if (driverId) {
      getDriver(driverId).then(({ data }) => {
        setDriver(data);
        setLoading(false);
      });
    }
  }, [driverId]);

  const fetchTelematics = useCallback((pulseDriverId: string, vehicleId: string) => {
    getDriverTelematics({
      pulseDriverId,
      vehicleId,
    }).then(({ data }) => {
      if (data) {
        setTelematicsLoading(false);
        setShowTelematics(data.shouldShowTelematicsInfo);
        setTelematicsData(data.results);
      }
    });
  }, []);

  const fetchOrdwayDetails = useCallback(() => {
    if (driver?.ordway_customer_id) {
      getOrdwayDetails(driver?.ordway_customer_id).then(({ data }) => {
        if (data) {
          setOrdwayData(data);
        }
      });
    }
  }, [driver?.ordway_customer_id]);

  const fetchVehicleDetails = useCallback(() => {
    if (agreement?.vehicle_id) {
      getVehicleById(agreement?.vehicle_id).then(({ data }) => {
        if (data) {
          setVehicle(data);
        }
      });
    }
  }, [agreement?.vehicle_id]);

  const fetchAftercareByAgreementId = useCallback(async () => {
    if (agreement?.id) {
      const { data } = await getAftercareByOriginalAgreement(agreement?.id);
      setOriginalAgreement(data);
    }
  }, [agreement?.id]);

  const onConfirmCompleteAgreementClick = useCallback(async () => {
    if (agreement?.id) {
      const { data } = await completeAgreement({ agreement_id: agreement?.id, status: AGREEMENT_COMPLETED });
      if (data) {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Agreement Completed Successfully',
          isAlert: true,
        });
      }
      fetchAgreementData();
    }
  }, [fetchAgreementData, agreement?.id]);

  useEffect(() => {
    fetchAgreementData();
    fetchDriverData();
    fetchOrdwayDetails();
    fetchVehicleDetails();
    fetchAftercareByAgreementId();
  }, [fetchAgreementData, fetchDriverData, fetchOrdwayDetails, fetchVehicleDetails, fetchAftercareByAgreementId]);

  useEffect(() => {
    if (driver?.id && driver?.vehicle_vin) {
      fetchTelematics(driver?.id, driver?.vehicle_vin);
    }
  }, [driver, fetchTelematics]);

  const onCompleteAgreementClick = useCallback(async () => {
    if (agreement && isAftercare(agreement?.agreement_type)) {
      navigate(AFTERCARE);
    }
    setIsCompleteProcess(true);
    if (Number(ordwayData?.arrears) < 0.0) {
      setIsArrearsModalOpen(true);
    } else if (Number(agreement?.remaining_balance) > 0.0) {
      setIsBalanceModalOpen(true);
    } else if (agreement?.agreement_type === IN_SERVICE_RENTAL) {
      setIsServiceRental(true);
    } else if (vehicle?.vehicle_status === COURTESY_ASSIGNED) {
      setHasCourtesyVehicle(true);
    } else if (originalAgreement?.length) {
      const { data } = await confirmAftercareSetup({ aftercare_id: originalAgreement[0].id });
      if (data) {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Agreement submitted for aftercare review',
          isAlert: true,
        });
        fetchAgreementData();
      }
      setIsCompleteProcess(false);
    } else {
      setIsAftercareModalOpen(true);
      setIsCompleteProcess(false);
    }
  }, [agreement, fetchAgreementData, navigate, ordwayData?.arrears, originalAgreement, vehicle?.vehicle_status]);

  const onTerminateAgreementClick = useCallback(() => {
    if (vehicle?.vehicle_status === IN_SERVICING) {
      setTerminationTitle(
        'Please note this vehicle is currently in servicing. Would you like to use the current condition report?'
      );
    } else {
      setTerminationTitle(`Will vehicle ${vrm ?? ''} go into servicing after termination?`);
    }
    setIsTerminationModalOpen(true);
  }, [vehicle, vrm]);

  return (
    <>
      <PageContainer>
        <DriverButtonGroup
          loading={loading}
          setIsMileageModalOpen={setIsMileageModalOpen}
          setIsFreshdeskOpen={setIsFreshdeskOpen}
        />
        {agreement && driver ? (
          <FlexLayout gap={16}>
            <FlexLayout vertical gap={32} styled={{ width: 440 }}>
              <DriverInfoCard driver={driver} agreement={agreement} inArrears={isInArrears} />
              {driver?.tag_type && <DriverTags tags={driver.tag_type} />}
              <AgreementButtonGroup
                agreement={agreement}
                ordwayDataLoaded={ordwayDataLoaded}
                originalAgreement={originalAgreement}
                isCompleteProcess={isCompleteProcess}
                onCompleteAgreementClick={onCompleteAgreementClick}
                onTerminateAgreementClick={onTerminateAgreementClick}
                setIsSwapModalOpen={setIsSwapModalOpen}
              />

              <Notes categoryId={agreement?.id} />
            </FlexLayout>
            <Sections>
              <AgreementDetails agreement={agreement} />
              <DriverOrdwayDetails
                driver={driver}
                inArrears={isInArrears}
                checkForArrears={(inArrears) => {
                  setOrdwayDataLoaded(true);
                  setIsInArrears(inArrears);
                }}
              />
              <DriverDetails driver={driver} tableHeader="Driver details" fetchDrivereData={() => fetchDriverData()} />
              <DocumentsTable
                categoryId={agreement?.id}
                documentCategory="AGREEMENT"
                tableHeader="Agreement documents"
                embedded
              />
              <AgreementTransfers agreementId={agreement?.id} tableHeader="Agreement transfers" />
              {driverId && (
                <>
                  <DriverVehicles driverId={driverId} tableHeader="Driver current vehicles" />
                  <DriverAgreements driverId={driverId} tableHeader="Driver agreements" />
                  <DriverApplications driverId={driverId} tableHeader="Driver applications" />
                </>
              )}
              {driver?.id && (
                <DocumentsTable
                  categoryId={driver?.id}
                  documentCategory="DRIVER"
                  tableHeader="Driver documents"
                  embedded
                />
              )}
              {(employeeDepartment === FINANCE || superAdmin) &&
                driver?.ordway_customer_id &&
                driver?.driver_name &&
                driverId && (
                  <DriverHolidays
                    driverId={driverId}
                    tableHeader="Payment holidays"
                    agreementStatus={agreement?.agreement_status}
                    agreementType={agreement?.agreement_type}
                    ordwayCustomerId={driver?.ordway_customer_id}
                    ordwaySubscriptionId={agreement?.ordway_subscription_id}
                    driverName={driver?.driver_name}
                    agreementId={agreement?.id}
                    fetchAgreementData={() => fetchAgreementData()}
                  />
                )}
              {driver?.agreement_status && (
                <DriverTelematics
                  telematicsData={telematicsData}
                  showTelematics={showTelematics}
                  agrStatus={driver?.agreement_status}
                  vin={driver?.vehicle_vin ?? ''}
                  loading={telematicsLoading}
                />
              )}
            </Sections>
          </FlexLayout>
        ) : (
          <FlexLayout itemsX="center">
            <Spinner size={32} color={PRIMARY_PURPLE} />
          </FlexLayout>
        )}
      </PageContainer>
      {driver?.id && (
        <Modal
          styled={{ width: '80%' }}
          title="Driver mileage"
          open={isMileageModalOpen}
          showClose
          onClose={() => setIsMileageModalOpen(false)}
        >
          <DriverMileages driverId={driver?.id} />
        </Modal>
      )}
      {driver?.email && (
        <Modal
          styled={{ width: '80%' }}
          title="Freshdesk tickets"
          open={isFreshdeskOpen}
          showClose
          onClose={() => setIsFreshdeskOpen(false)}
        >
          <DriverFreshdeskTickets email={driver?.email} />
        </Modal>
      )}
      {agreement && (
        <Modal
          title="Aftercare setup"
          open={isAftercareSetupModalOpen}
          showClose
          showOverflow={false}
          onClose={() => setIsAftercareSetupModalOpen(false)}
        >
          <AftercareSetupForm
            aftercare={{
              agreement_id: agreement?.id,
              driver_mobile_phone: '',
              driver_name: '',
              end_date: '',
              vrm: '',
              vehicle_name: '',
              aftercare_type: '',
              scrive_signed: false,
              contact_request_exists: false,
            }}
            onClose={() => setIsAftercareSetupModalOpen(false)}
            isInEdit={false}
            onSubmit={() => {
              setIsAftercareModalOpen(false);
              navigate(AFTERCARE_REVIEWS);
            }}
          />
        </Modal>
      )}
      <ConfirmationModal
        title="Completing this agreement will mean Otto car no longer possess this vehicle. Would you like to send this vehicle to aftercare or complete agreeement"
        isOpen={isAftercareModalOpen}
        onConfirm={() => {
          setIsAftercareModalOpen(false);
          setIsAftercareSetupModalOpen(true);
        }}
        onClose={() => {
          setIsAftercareModalOpen(false);
          setIsConfirmTerminationModalOpen(true);
        }}
        confirmButtonCaption={'Send to aftercare'}
        closeButtonCaption={'Complete agreement'}
      />
      <ConfirmationModal
        title={
          isBalanceModalOpen
            ? 'The driver still has a balance due on this agreement, you cannot complete this agreement'
            : isServiceRental
              ? 'This driver has service rental in place, please check this vehicle in before completing this agreement'
              : isArrearsModalOpen
                ? 'This driver has outstanding payments on this agreement. You cannot complete this agreement. Please complete credit control'
                : 'The driver still has courtesy vehicle, Please check this in before completing this agreement'
        }
        isOpen={isBalanceModalOpen || isServiceRental || hasCourtesyVehicle}
        onClose={() => {
          setIsBalanceModalOpen(false);
          setIsServiceRental(false);
          setHasCourtesyVehicle(false);
          setIsCompleteProcess(false);
        }}
        closeButtonCaption={'Ok'}
      />
      <ConfirmationModal
        title={terminationTitle}
        isOpen={isTerminationModalOpen}
        onClose={() => {
          if (vehicle?.vehicle_status === IN_SERVICING) {
            const params = {
              application_id: agreement?.application_id,
              reason: TERMINATE_AND_SERVICE,
              dynamic: TERMINATE,
              vrm: agreement?.vrm,
              agreement_id: agreement?.id,
            };
            navigate(CREATE_TRANSFER, { state: params });
          } else {
            const params = {
              application_id: agreement?.application_id,
              reason: TERMINATED,
              dynamic: TERMINATE,
              vrm: agreement?.vrm,
              agreement_id: agreement?.id,
            };
            navigate(CREATE_TRANSFER, { state: params });
          }
        }}
        onConfirm={() => {
          if (vehicle?.vehicle_status === IN_SERVICING) {
            const params = {
              application_id: agreement?.application_id,
              reason: TERMINATE_AND_SERVICE,
              dynamic: TERMINATE,
              vrm: agreement?.vrm,
              agreement_id: agreement?.id,
            };
            navigate(CREATE_TRANSFER, { state: params });
          } else {
            const params = {
              application_id: agreement?.application_id,
              reason: TERMINATE_AND_SERVICE,
              dynamic: TERMINATE,
              vrm: agreement?.vrm,
              agreement_id: agreement?.id,
            };
            navigate(CREATE_TRANSFER, { state: params });
          }
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
      <ConfirmationModal
        title={'To swap the agreement, you must create a check in!'}
        isOpen={isSwapModalOpen}
        onClose={() => setIsSwapModalOpen(false)}
        onConfirm={() => {
          const params = {
            application_id: agreement?.application_id,
            reason: SWAP,
            dynamic: TERMINATE,
            vrm: agreement?.vrm,
            agreement_id: agreement?.id,
          };
          navigate(CREATE_TRANSFER, { state: params });
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
      <ConfirmationModal
        title={'Are you sure you want to complete this agreement?'}
        isOpen={isConfirmTerminationModalOpen}
        onClose={() => setIsConfirmTerminationModalOpen(false)}
        preConfirm={async () => {
          await onConfirmCompleteAgreementClick();
          setIsConfirmTerminationModalOpen(false);
        }}
        confirmButtonCaption={'Complete agreement'}
        closeButtonCaption={'Cancel'}
      />
    </>
  );
};
