import React, { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiList, FiShoppingBag } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { HiArrowLeft } from 'react-icons/hi';
import { MdOutlinePersonPin } from 'react-icons/md';
import { BsGear, BsPerson, BsUmbrella } from 'react-icons/bs';
import { Tray, TrayItemLink, Burger, RouteName, TrayCloseButton } from './navTray.styles';
import { PRIMARY_WHITE } from '../../common/styles/Colors';
import { DASHBOARD, DRIVERS, SERVICING_LIST, USERS, VEHICLES, POLICIES } from '../../consts/routes';
import { useInteractOutside } from '../../hooks/useInteractOutside';
import { APP_CONTEXT } from '../../utils/context';
import { CLAIMS } from '../../consts/department';

interface TrayItem {
  path: string;
  icon: JSX.Element;
  title: string;
}

const trayItems: TrayItem[] = [
  {
    path: DASHBOARD,
    icon: <FiShoppingBag size={24} color={PRIMARY_WHITE} />,
    title: 'Sales',
  },
  {
    path: DRIVERS,
    icon: <MdOutlinePersonPin size={24} color={PRIMARY_WHITE} />,
    title: 'Drivers',
  },
  {
    path: VEHICLES,
    icon: <FiList size={24} color={PRIMARY_WHITE} />,
    title: 'Fleet',
  },
  {
    path: SERVICING_LIST,
    icon: <BsGear size={24} color={PRIMARY_WHITE} />,
    title: 'Servicing',
  },
  {
    path: USERS,
    icon: <BsPerson size={24} color={PRIMARY_WHITE} />,
    title: 'Admin',
  },
  {
    path: POLICIES,
    icon: <BsUmbrella size={24} color={PRIMARY_WHITE} />,
    title: 'Insurance',
  },
];

export const NavTray = () => {
  const trayRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [trayOpen, setTrayOpen] = useState<boolean>(false);
  const [slideTrayOpen, setSlideTrayOpen] = useState<boolean>(false);
  const [xOffset, setXOffset] = useState<number>(0);
  const trayWidth = trayRef?.current?.clientWidth ?? 0;
  const employeeDepartment: string | null = localStorage.getItem('department');
  const { superAdmin } = useContext(APP_CONTEXT);

  useEffect(() => {
    setXOffset(-trayWidth);
  }, [slideTrayOpen, trayWidth]);

  useEffect(() => {
    window.setTimeout(
      () => {
        setTrayOpen(slideTrayOpen);
        setXOffset(slideTrayOpen ? 0 : -trayWidth);
      },
      slideTrayOpen ? 0 : 200
    );
  }, [slideTrayOpen, xOffset, trayWidth]);

  useInteractOutside(
    trayRef,
    (event: SyntheticEvent) => {
      if (!trayRef.current?.contains(event.target as HTMLDivElement)) {
        setSlideTrayOpen(false);
        event.stopPropagation();
        event.preventDefault();
      }
    },
    !slideTrayOpen
  );

  let trayRoutes: TrayItem[] = [];

  if (superAdmin && employeeDepartment === CLAIMS) {
    trayRoutes = trayItems;
  } else {
    trayRoutes = trayItems.filter((item: TrayItem) => item.path !== POLICIES);
  }

  return (
    <>
      <Tray ref={trayRef} $open={trayOpen} $xOffset={xOffset}>
        {trayRoutes.map((item: TrayItem) => (
          <TrayItemLink key={item?.title + item?.path} itemsY="center" onClick={() => navigate(item.path)}>
            {item?.icon}
            <RouteName color={PRIMARY_WHITE} variant="body6" weight={300}>
              {item?.title ?? ''}
            </RouteName>
          </TrayItemLink>
        ))}
        <TrayCloseButton onClick={() => setSlideTrayOpen(false)} itemsX="center" itemsY="center">
          <HiArrowLeft size={24} color={PRIMARY_WHITE} />
        </TrayCloseButton>
      </Tray>
      <Burger onClick={() => setSlideTrayOpen(true)} itemsY="center" itemsX="center" $show={!trayOpen}>
        <GiHamburgerMenu color={PRIMARY_WHITE} size={22} />
      </Burger>
    </>
  );
};
