import React, { useCallback, useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import moment from 'moment';
import { Label, TransferCard, Vrm } from './transferSection.styles';
import { terminatedAgreementReasonText } from '../utils';
import { AGREEMENT_FROZEN } from '../../../../consts/agreement';
import {
  conRepReasons,
  AFTERCARE,
  IN_SERVICING,
  LIVE,
  MOT,
  PHV_PROBLEM,
  REPO,
  SWAP,
  TERMINATED,
  TERMINATE_AND_SERVICE,
} from '../../../../consts';
import { AGREEMENTS, TRANSFERS } from '../../../../consts/routes';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { ConfirmationModal } from '../../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Text } from '../../../../uiComponents/text/text';
import { TextInput } from '../../../../uiComponents/inputs/textInput/textInput';
import { Notification } from '../../../../uiComponents/toast/toast';
import { Checkbox } from '../../../../uiComponents/uiControls/checkbox/checkbox';
import { DropDown } from '../../../../uiComponents/uiControls/dropDown/dropDown';
import { getAllBranches } from '../../../../api/get/branch.get';
import { getAllCities } from '../../../../api/get/city.get';
import { getRecentConditionReport } from '../../../../api/get/conditionReport.get';
import { getVehicleByVrm } from '../../../../api/get/vehicle.get';
import { createTransfer } from '../../../../api/post/transfer.post';
import { createUsingPreviousCR } from '../../../../api/post/conditionReport.post';
import { terminationRequestWithArrears } from '../../../../api/post/agreement.post';
import { Branch } from '../../../../models/branch';
import { City } from '../../../../models/city';
import { OrdwayDetails } from '../../../../models/driver';
import { Vehicle, VehicleStatus } from '../../../../models/vehicle';
import { Transfer, TransferReason, TransferType } from '../../../../models/transfer';
import { PRIMARY_PURPLE, PRIMARY_WHITE, STATUS_RED, PRIMARY_GREEN } from '../../../../common/styles/Colors';
import { OptionList } from '../../../../utils/props';
import { Spinner } from '../../../../uiComponents/uiControls/spinner/spinner';
import { SearchButton } from '../../../orders/order/order.styles';
import { FiSearch } from 'react-icons/fi';
import { ConditionReportOrdwayDetails } from '../conditionReportOrdwayDetails';
import { getOrdwayDetails } from '../../../../api/get/driver.get';

interface TransferDetailsProps {
  defaultReason?: TransferReason;
  defaultVrm?: string;
  action?: string;
  vehicleDetails?: Vehicle;
  updateVehicleDetails: (vehicle: Vehicle) => void;
  previousConditionReportMileage: (mileage: number) => void;
  onConfirm: (transferDetails: TransferFields) => void;
}

export interface TransferFields {
  vrm: string | null;
  reason: TransferReason;
  city_id: string | null;
  branch_id: string | null;
  transfer: boolean;
}

export const TransferDetails = ({
  defaultReason,
  defaultVrm,
  vehicleDetails,
  action,
  onConfirm,
  updateVehicleDetails,
  previousConditionReportMileage,
}: TransferDetailsProps) => {
  const navigate = useNavigate();
  const [branches, setBranches] = useState<Branch[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionList[]>([]);
  const [isFrozenAgreement, setIsFrozenAgreement] = useState<boolean>(false);
  const [invalidVrm, setInvalidVrm] = useState<boolean>(false);
  const [isSixMonthsApart, setIsSixMonthsApart] = useState<boolean>(false);
  const [isTerminatedReason, setIsTerminatedReason] = useState<boolean>(false);
  const [recentConditionReportUrl, setRecentConditionReportUrl] = useState<string>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showIsServiceModal, setShowIsServiceModal] = useState<boolean>(false);
  const [fetchingDetails, setFetchingDetails] = useState<boolean>(false);
  const [transferDetails, setTransferDetails] = useState<TransferFields>();
  const [terminationWarning, setTerminationWarning] = useState<string>();
  const [vehicleId, setVehicleId] = useState<string>();
  const [vehicleStatus, setVehicleStatus] = useState<VehicleStatus>();
  const [ordwayDetails, setOrdwayDetails] = useState<OrdwayDetails>();
  const [gettingOrdwayInfo, setGettingOrdwayInfo] = useState<boolean>(false);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm<TransferFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      vrm: defaultVrm,
      reason: defaultReason,
      city_id: null,
      branch_id: null,
      transfer: false,
    },
  });

  const transferReason = watch('reason');
  const city = watch('city_id');
  const location = watch('branch_id');
  const reason = watch('reason');
  const ordwayCustomerId: string | null | undefined = vehicleDetails?.ordway_customer_id;

  const cityBranches: OptionList[] = branches
    ?.filter((b: Branch) => b.city_id === city)
    ?.map((item: Branch) => {
      return {
        value: item.branch_id,
        label: item.branch_name,
      };
    });

  useEffect(() => {
    setValue('branch_id', null);
  }, [city, setValue]);

  useEffect(() => {
    if ([SWAP, TERMINATED, REPO, TERMINATE_AND_SERVICE].includes(reason) && !ordwayDetails && ordwayCustomerId) {
      setGettingOrdwayInfo(true);
      getOrdwayDetails(ordwayCustomerId)
        .then((response: { data: OrdwayDetails }) => {
          setOrdwayDetails(response?.data);
          setGettingOrdwayInfo(false);
        })
        .catch(() => {
          setGettingOrdwayInfo(false);
        });
    }
  }, [reason, ordwayDetails, ordwayCustomerId]);

  const disableSubmit =
    invalidVrm || reason == null || city == null || location == null || (isFrozenAgreement && isTerminatedReason);

  const showTransferOnlyCheck =
    transferReason === TERMINATED ||
    transferReason === SWAP ||
    transferReason === MOT ||
    transferReason === PHV_PROBLEM;

  useEffect(() => {
    getAllCities().then((response: { count: number; data: City[] }) => {
      const cities: OptionList[] = response?.data?.map((city: City) => {
        return {
          value: city?.city_id,
          label: city?.city_name,
        };
      });
      setCityOptions(cities);
    });

    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      setBranches(response?.data);
    });
  }, []);

  const getConditionReport = useCallback(
    (vehicleId: string) => {
      getRecentConditionReport(vehicleId).then((response: { data: Transfer }) => {
        const startDate = moment(response.data.created_date);
        const currentData = moment();
        const sixMonthsAgo = moment().subtract(6, 'months');
        setIsSixMonthsApart(startDate.isBetween(sixMonthsAgo, currentData, null, '[]'));
        setRecentConditionReportUrl(response?.data?.s3_url);
        response.data.mileage && previousConditionReportMileage(response.data.mileage);
      });
    },
    [previousConditionReportMileage]
  );

  const getVehicleDetails = useCallback(
    (vrm: string) => {
      if (vrm.length > 0) {
        setFetchingDetails(true);
        setInvalidVrm(false);
        getVehicleByVrm(vrm)
          .then((response: { data: Vehicle }) => {
            setFetchingDetails(false);
            updateVehicleDetails(response?.data);
            const { vehicle_id, vehicle_status: status, agreement_status: agrStatus } = response?.data;
            setIsFrozenAgreement(agrStatus === AGREEMENT_FROZEN);
            setVehicleId(vehicle_id);
            setVehicleStatus(status);
            if (!action && ![LIVE, AFTERCARE, IN_SERVICING].includes(status)) {
              setError('vrm', { type: 'custom', message: 'Vehicle is not live' });
              setInvalidVrm(true);
            } else {
              getConditionReport(vehicle_id);
            }
          })
          .catch((e: AxiosError<Error>) => {
            setError('vrm', { message: e?.response?.data?.message });
            setInvalidVrm(true);
            setFetchingDetails(false);
          });
      }
    },
    [updateVehicleDetails, setError, getConditionReport, action]
  );

  useEffect(() => {
    if (defaultVrm && !vehicleDetails) {
      getVehicleDetails(defaultVrm);
    }
  }, [defaultVrm, vehicleDetails, getVehicleDetails]);

  const onSubmit = useCallback(
    (submitValues: TransferFields) => {
      setTransferDetails(submitValues);

      const makeTransfer = () => {
        const payload: Transfer = {
          vehicle_id: vehicleDetails?.vehicle_id,
          transfer_type: 'CHECK-IN' as TransferType,
          ...transferDetails,
        };
        createTransfer(payload)
          .then(() => {
            Notification({
              type: 'success',
              title: 'New transfer',
              message: 'Your transfer was successfully created.',
              isAlert: true,
            });
            navigate(TRANSFERS);
          })
          .catch(() => {
            Notification({
              type: 'error',
              title: 'New transfer',
              message: 'There was a problem creating the transfer.',
              isAlert: true,
            });
          });
      };

      const showArrearsModal = (reasonText?: string | null) => {
        if (reasonText) {
          setTerminationWarning(reasonText);
          setShowConfirmationModal(true);
          return;
        } else if (transferDetails?.transfer) {
          makeTransfer();
        }
      };

      if ([SWAP, TERMINATED, REPO, TERMINATE_AND_SERVICE].includes(reason)) {
        const reasonText = vehicleDetails?.ordway_subscription_id
          ? terminatedAgreementReasonText(vehicleDetails, reason)
          : null;
        showArrearsModal(reasonText);
      } else {
        onConfirm(submitValues);
      }
    },
    [reason, vehicleDetails, transferDetails, navigate, onConfirm]
  );

  return (
    <>
      <TransferCard>
        <Label variant="body6" weight={500} color={PRIMARY_WHITE} block styled={{ marginBottom: 8, marginTop: 0 }}>
          VRM
        </Label>
        <Vrm>
          <TextInput
            defaultValue={defaultVrm}
            placeholder="VRM"
            error={errors?.vrm}
            required
            {...register('vrm', {
              required: 'VRM is required.',
              onBlur: (e: React.ChangeEvent<HTMLInputElement>) => getVehicleDetails(e.target.value),
            })}
            styled={{ width: '100%' }}
          />
          {fetchingDetails ? (
            <Spinner size={16} color={PRIMARY_PURPLE} styled={{ position: 'absolute', right: 24, top: 14 }} />
          ) : (
            <SearchButton itemsX="center" itemsY="center">
              <FiSearch color={PRIMARY_GREEN} size={24} />
            </SearchButton>
          )}
        </Vrm>
        {isFrozenAgreement && isTerminatedReason && (
          <FlexLayout styled={{ color: STATUS_RED, paddingTop: 25 }}>
            *The agreement attached to this vehicle is frozen! Unfreeze the agreement or terminate the in-service rental
            if it is active!
          </FlexLayout>
        )}
        <Label variant="body6" weight={500} color={PRIMARY_WHITE} block styled={{ marginTop: 16, marginBottom: 8 }}>
          Reason
        </Label>
        <DropDown
          options={conRepReasons}
          placeholder="Select a reason"
          name="reason"
          required={{
            required: 'Reason is required.',
          }}
          error={errors?.reason}
          control={control as unknown as Control<FieldValues>}
          onSelect={(selected) => {
            if ([TERMINATED, TERMINATE_AND_SERVICE].includes((selected as OptionList).value)) {
              setIsTerminatedReason(true);
            }
          }}
        />
        <Label variant="body6" weight={500} color={PRIMARY_WHITE} block styled={{ marginTop: 16, marginBottom: 8 }}>
          City
        </Label>
        <DropDown
          options={cityOptions}
          placeholder="Select city"
          name="city_id"
          required={{
            required: 'City is required.',
          }}
          error={errors?.city_id}
          control={control as unknown as Control<FieldValues>}
        />
        <Label variant="body6" weight={500} color={PRIMARY_WHITE} block styled={{ marginTop: 24, marginBottom: 8 }}>
          Location
        </Label>
        <DropDown
          options={cityBranches}
          placeholder="Select location"
          name="branch_id"
          control={control as unknown as Control<FieldValues>}
          required={{
            required: 'Location is required.',
          }}
          error={errors?.branch_id}
        />
        {showTransferOnlyCheck && (
          <FlexLayout itemsY="center" gap={8} styled={{ marginTop: 16 }}>
            <Checkbox {...register('transfer')} />
            <Text variant="body6" color={PRIMARY_WHITE} weight={500}>
              Transfer only
            </Text>
          </FlexLayout>
        )}
        <FlexLayout gap={16} itemsY="center" styled={{ marginTop: 24 }}>
          <PrimaryButton
            isGreen
            disabled={!recentConditionReportUrl}
            onClick={() => window.open(recentConditionReportUrl, '_blank')}
          >
            View recent condition report
          </PrimaryButton>
          <PrimaryButton
            isProcessing={gettingOrdwayInfo}
            disabled={disableSubmit}
            isGreen
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </PrimaryButton>
        </FlexLayout>
      </TransferCard>
      <ConfirmationModal
        onClose={() => setShowConfirmationModal(false)}
        icon={<></>}
        closeButtonCaption="No"
        confirmButtonCaption="Yes"
        onConfirm={() => {
          if ([TERMINATED, TERMINATE_AND_SERVICE].includes(reason) && vehicleStatus === IN_SERVICING) {
            setShowIsServiceModal(true);
          } else if (transferDetails) {
            onConfirm(transferDetails);
          }
          if (vehicleDetails && +(vehicleDetails?.arrears ?? '0') > 0) {
            terminationRequestWithArrears(vehicleDetails);
          }
          setShowConfirmationModal(false);
        }}
        title={
          <div>
            <ConditionReportOrdwayDetails ordwayData={ordwayDetails} arrears={+(vehicleDetails?.arrears ?? '0')} />
            {terminationWarning}
            <br />
            <br />
            {`${
              +(vehicleDetails?.total_for_future_orders ?? '0') > 0
                ? `This driver has future orders of £${vehicleDetails?.total_for_future_orders}.\n`
                : ''
            }`}
            Do you wish to continue?
          </div>
        }
        isOpen={showConfirmationModal}
      />
      <ConfirmationModal
        onClose={() => {
          if (transferDetails) {
            onConfirm(transferDetails);
            setShowIsServiceModal(false);
          }
        }}
        preConfirm={async () => {
          if (showIsServiceModal && vehicleId) {
            if (isSixMonthsApart) {
              try {
                const { data } = await createUsingPreviousCR(vehicleId, {
                  reason,
                  agreement_id: vehicleDetails?.agreement_id ?? '',
                });
                if (data) {
                  window.open(data.crResults, '_blank');
                  navigate(`${AGREEMENTS}/${data.agreementUpdatedOnTermination.id}`);
                }
              } finally {
                setShowIsServiceModal(false);
              }
            } else {
              Notification({
                type: 'info',
                title: 'Created date limit exceeded',
                message: 'Created date of previous the condition report is more that 6 months apart.',
                isAlert: true,
              });
              setShowIsServiceModal(false);
            }
          } else {
            if (transferDetails) {
              setValue('reason', TERMINATE_AND_SERVICE);
              setTransferDetails({ ...transferDetails, reason: TERMINATE_AND_SERVICE });
              onConfirm({ ...transferDetails, reason: TERMINATE_AND_SERVICE });
            }
          }
        }}
        closeButtonCaption="No"
        confirmButtonCaption="Yes"
        title="Please note that this vehicle is currently being serviced. Would you like to use the current condition report?"
        isOpen={showIsServiceModal}
      />
    </>
  );
};
