import React, { useState } from 'react';
import { Control, FieldError, FieldValues, useForm } from 'react-hook-form';
import { FlexContainer } from '../../../core/pageTitle/pageTitle.styles';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import {
  StyledSecondaryButton,
  StyledTextField,
  StyledUploaderInput,
  StyledFieldContainer,
} from './createEditPolicyForm.styles';
import { CreateEditPolicyPayload, InsurancePolicy, ModifiedInsurancePolicy } from '../../../models/insurancePolicy';
import { createInsurancePolicy } from '../../../api/post/insurance.post';
import { updateInsurancePolicy } from '../../../api/patch/insurance.patch';
import { Notification } from '../../../uiComponents/toast/toast';
import { DateTimePickerComponent } from '../../../uiComponents/customComponents/dateTimePicker/dateTimePicker';
import { InputChips } from '../../../uiComponents/inputChips/inputChips';
import { fileToBase64 } from '../../../utils/utils';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { isPolicyNumberExistsing } from '../../../api/get/insurance.get';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { TextFieldLabel } from '../../../uiComponents/inputs/textField/textField.styles';

interface CreateEditPolicyFormProps {
  isInEdit: boolean;
  values?: ModifiedInsurancePolicy | null;
  onFormSubmit: () => void;
  onClose: () => void;
}

export const CreateEditPolicyForm = ({ isInEdit, values, onClose, onFormSubmit }: CreateEditPolicyFormProps) => {
  const getColorsList = () => {
    if (!values?.vrm_number_range || !values!.vrm_number_range[0]) return [];
    return values?.vrm_number_range.map((item) => {
      return { value: item };
    });
  };
  const [isConfirmEditModalOpen, setIsConfirmEditModalOpen] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isRenewPolicyModalOpen, setIsRenewPolicyModalOpen] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<InsurancePolicy>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues:
      isInEdit && values
        ? { ...values, vrm_number_range: getColorsList() }
        : {
            insurance_company: '',
            policy_number: '',
            start_date: '',
            end_date: '',
            policy_certificate_url: undefined,
            policy_date_format: '',
          },
  });

  const onSubmit = async (values: InsurancePolicy) => {
    if (!isInEdit) {
      const policyExists = await isPolicyNumberExistsing(values.policy_number.toUpperCase());

      if (policyExists.data.exists) {
        setIsRenewPolicyModalOpen(true);
        return;
      }
    }
    setIsFormSubmitting(true);
    const {
      id,
      insurance_company,
      policy_number,
      start_date,
      end_date,
      vrm_number_range,
      policy_certificate,
      policy_date_format,
    } = values;
    const payload: CreateEditPolicyPayload = {
      id,
      body: {
        insurance_company,
        policy_number: policy_number.toUpperCase(),
        start_date,
        end_date,
        vrm_number_range: vrm_number_range.map((plateRule: { value: string }) => plateRule.value),
        policy_date_format,
        policyCertificateUrl: policy_certificate
          ? {
              content: await fileToBase64(policy_certificate),
              fileName: 'insurance-policy-certificate',
              type: policy_certificate.type,
            }
          : undefined,
      },
    };

    const createEditPromise = isInEdit ? updateInsurancePolicy : createInsurancePolicy;

    createEditPromise(payload)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Success',
          message: `Policy has been successfully ${isInEdit ? 'updated' : 'created'}`,
          isAlert: true,
        });
        onFormSubmit();
      })
      .catch(() =>
        Notification({
          type: 'error',
          title: 'Error',
          message: `Error ${isInEdit ? 'updating' : 'creating'} policy`,
          isAlert: true,
        })
      )
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  return (
    <>
      <GridLayout template={2} gap="32px 24px">
        <StyledTextField
          {...register('insurance_company', {
            required: 'Insurance company is a required field',
          })}
          label="Insurance company"
          error={errors.insurance_company}
          type="text"
          placeholder="Insurance company name"
          required
        />
        <StyledTextField
          {...register('policy_number', {
            required: 'Policy number is a required field',
          })}
          label="Policy number"
          error={errors.policy_number}
          placeholder="Policy number"
          type="text"
          required
        />

        <DateTimePickerComponent
          control={control}
          name="start_date"
          label="Start date"
          errors={errors.start_date}
          rules={{
            required: 'Start date is a required field',
          }}
        />
        <DateTimePickerComponent
          control={control}
          name="end_date"
          label="End date"
          errors={errors.end_date}
          rules={{
            required: 'End date is a required field',
          }}
        />
        <InputChips
          name="vrm_number_range"
          label=""
          header="Vrm rules"
          register={register}
          control={control}
          required={{
            required: 'Vrm plates rules are a required field',
          }}
          error={errors.vrm_number_range as FieldError}
        />

        <StyledFieldContainer vertical>
          <TextFieldLabel $isRequired>Policy date format</TextFieldLabel>
          <DropDown
            name="policy_date_format"
            required={{
              required: 'Policy date format is required',
            }}
            error={errors.policy_date_format}
            placeholder="Choose policy date/time format"
            options={[
              {
                value: 'DD/MM/YYYY [at] HH:mm',
                label: 'DD/MM/YYYY at HH:mm',
              },
              {
                value: 'HHmm [hours] Do MMMM YYYY',
                label: 'HHmm hours Day MMMM YYYY',
              },
              {
                value: 'HH:mm [at] DD/MM/YYYY',
                label: 'HH:mm at DD/MM/YYYY',
              },
            ]}
            control={control as unknown as Control<FieldValues>}
          />
        </StyledFieldContainer>

        <StyledUploaderInput
          control={control as unknown as Control<FieldValues>}
          name="policy_certificate"
          error={errors?.policy_certificate}
          label="Insurance policy"
        />
      </GridLayout>

      <FlexContainer style={{ marginTop: 20 }} itemsX="end">
        <StyledSecondaryButton onClick={() => onClose()}>Cancel</StyledSecondaryButton>
        <PrimaryButton
          isProcessing={isFormSubmitting}
          onClick={isInEdit ? () => setIsConfirmEditModalOpen(true) : handleSubmit(onSubmit)}
        >
          Submit
        </PrimaryButton>
      </FlexContainer>

      <ConfirmationModal
        title={'Are you sure you want to edit this policy?'}
        isOpen={isConfirmEditModalOpen}
        onClose={() => setIsConfirmEditModalOpen(false)}
        onConfirm={() => {
          handleSubmit(onSubmit)();
          setIsConfirmEditModalOpen(false);
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />

      <ConfirmationModal
        title={'This policy already exists, please renew the policy '}
        isOpen={isRenewPolicyModalOpen}
        onClose={() => {
          setIsRenewPolicyModalOpen(false);
          onClose();
        }}
        onConfirm={() => {
          // navigate to policy to renew
          setIsRenewPolicyModalOpen(false);
        }}
        confirmButtonCaption={'Renew'}
        closeButtonCaption={'Cancel'}
      />
    </>
  );
};
