import api from '../../utils/api';
import { BatchVehicle, UpdateVehiclePayload } from '../../models/vehicle';
import { OrderInsuranceDocument } from '../../models/order';

export const updateVehicle = (id: string, payload: UpdateVehiclePayload | BatchVehicle) =>
  api.patch(`vehicle/${id}`, payload);

/**
 * Update Insurance
 * @category API/POST
 * @param {string} id - Vehicle id to be updated
 * @param {object} payload - Data for the vehicle to be updated, insurance doc and expiry date.
 * @return {Promise} Returns a promise for the api request
 */

export const uploadInsurance = (id: string, payload: OrderInsuranceDocument) =>
  api.patch(`vehicle/${id}/insurance`, payload);

export default { updateVehicle, uploadInsurance };
