import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  FormContainer,
  InputContainer,
  StyledEyeIcon,
  StyledH2,
  StyledLink,
  StyledMailIcon,
  StyledPrimaryButton,
  StyledSlashEyeIcon,
  StyledTextField,
} from './login.styles';
import { PrimaryButton } from '../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { InputType } from '../../uiComponents/inputs/textInput/textInput';
import { Notification } from '../../uiComponents/toast/toast';
import { forgotPassword, logIn, requestPasswordReset } from '../../api/post/auth.post';
import { LoginDetails, LoginResponse } from '../../models/employee';
import { APP_CONTEXT } from '../../utils/context';
import { emailFormat } from '../../utils/validations';
import { DASHBOARD, LOGIN } from '../../consts/routes';

interface LoginProps {
  formName: 'login' | 'forgot-password' | 'reset-password';
}

interface LoginFormFields {
  email: string;
  password: string;
  confirmPassword: string;
}

interface ResetPasswordResponse {
  id?: string;
  message?: string;
}

export const Login = ({ formName }: LoginProps) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginFormFields>({
    mode: 'all',
  });

  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState<InputType>('password');
  const [showEmailSentConfirmation, setShowEmailSentConfirmation] = useState<boolean>(false);
  const { setSuperAdmin } = useContext(APP_CONTEXT);
  const { id } = useParams();

  const togglePasswordReveal = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const onSubmit = (values: LoginDetails) => {
    const loginSubmit = () => {
      logIn(values)
        .then((response: { data: LoginResponse }) => {
          setLoading(false);
          localStorage.setItem('jwt', response?.data?.token);
          localStorage.setItem('userId', response?.data?.user?.userId);
          localStorage.setItem('department', response?.data?.user?.department);
          setSuperAdmin(response?.data?.super_admin);
          setLoading(false);
          navigate(DASHBOARD);
        })
        .finally(() => setLoading(false));
    };

    const forgotPasswordSubmit = () => {
      requestPasswordReset(values.email)
        .then(() => {
          setLoading(false);
          setShowEmailSentConfirmation(true);
        })
        .catch((error: AxiosError<ResetPasswordResponse>) =>
          setError('email', { type: 'server', message: error.response?.data?.message }, { shouldFocus: true })
        )
        .finally(() => setLoading(false));
    };

    const resetPasswordSubmit = () => {
      forgotPassword(id ?? '', values)
        .then(() => {
          Notification({
            type: 'success',
            title: 'Password changed',
            message: 'You can now log in with your new credentials',
            isAlert: true,
          });
          navigate(LOGIN);
        })
        .finally(() => setLoading(false));
    };

    if (formName === 'login') {
      loginSubmit();
    } else if (formName === 'forgot-password') {
      forgotPasswordSubmit();
    } else {
      resetPasswordSubmit();
    }
    /**
     * login
     * save token in local storage
     * push to admin dashboard
     */
    setLoading(true);
  };

  useEffect(() => {
    setShowEmailSentConfirmation(false);
  }, [formName]);

  const getButtonLabel = useCallback(() => {
    if (formName === 'login') {
      return 'Login';
    } else if (formName === 'forgot-password') {
      return 'Reset password';
    } else {
      return 'Confirm';
    }
  }, [formName]);

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      {showEmailSentConfirmation ? (
        <FlexLayout itemsX="center" vertical>
          <StyledH2>An email has been sent with a link to change your password.</StyledH2>
          <PrimaryButton isGreen onClick={() => navigate(LOGIN)}>
            Return to login
          </PrimaryButton>
        </FlexLayout>
      ) : (
        <>
          <StyledH2>Welcome Back</StyledH2>
          {(formName === 'login' || formName === 'forgot-password') && (
            <InputContainer>
              <StyledTextField
                {...register('email', {
                  required: 'You must specify an email',
                  pattern: {
                    value: emailFormat,
                    message: 'Wrong email format',
                  },
                })}
                label=""
                name="email"
                type="email"
                placeholder="Enter Email Address"
                error={errors.email}
              />
              <StyledMailIcon size={24} />
            </InputContainer>
          )}
          {(formName === 'login' || formName === 'reset-password') && (
            <InputContainer>
              <StyledTextField
                {...register('password', {
                  required: 'You must specify a password',
                  minLength: 5,
                })}
                label=""
                placeholder={formName === 'login' ? 'Password' : 'New Password'}
                name="password"
                type={passwordType as InputType}
                error={errors.password}
              />
              {passwordType === 'password' ? (
                <StyledEyeIcon onClick={togglePasswordReveal} size={24} />
              ) : (
                <StyledSlashEyeIcon onClick={togglePasswordReveal} size={24} />
              )}{' '}
            </InputContainer>
          )}
          {formName === 'reset-password' && (
            <InputContainer>
              <StyledTextField
                {...register('confirmPassword', {
                  required: 'You must specify a password',
                  minLength: 5,
                })}
                label=""
                placeholder={'Confirm Password'}
                name="confirmPassword"
                type={passwordType as InputType}
                error={errors.password}
              />
              {passwordType === 'password' ? (
                <StyledEyeIcon onClick={togglePasswordReveal} size={24} />
              ) : (
                <StyledSlashEyeIcon onClick={togglePasswordReveal} size={24} />
              )}
            </InputContainer>
          )}
          <div>
            {(formName === 'login' || formName === 'forgot-password') && (
              <StyledLink to={formName === 'login' ? '/forgot-password' : '/login'}>
                {formName === 'login' ? 'Forgot password?' : 'Back to login'}
              </StyledLink>
            )}
          </div>
          <StyledPrimaryButton isGreen onClick={handleSubmit(onSubmit)} isProcessing={loading}>
            {getButtonLabel()}
          </StyledPrimaryButton>
        </>
      )}
    </FormContainer>
  );
};
