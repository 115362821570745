import React, { useState } from 'react';
import moment from 'moment';
import { AgreementInfoProps } from '../agreementPage/agreementPage';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FieldGrid } from '../../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { Text } from '../../../../uiComponents/text/text';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';

export const AgreementDetails = ({ agreement }: AgreementInfoProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <>
      <CollapsiblePanel
        header={
          <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
            Agreement details
          </Text>
        }
        onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
        expanded={!isCollapsed}
      >
        <div>
          <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
            Agreement details
          </Text>
          <FieldGrid
            numColumns={5}
            headers={[
              'Agreement start date:',
              'Agreement end date:',
              'Payment start date:',
              'Agreement type:',
              'Agreement length:',
              'Weekly amount:',
              'Car and charge package:',
              'Uber clean air package:',
            ]}
            values={[
              (agreement?.start_date && moment(agreement?.start_date).format('DD MMMM YYYY')) || '-',
              (agreement?.end_date && moment(agreement?.end_date).format('DD MMMM YYYY')) || '-',
              (agreement?.payment_start_date && moment(agreement?.payment_start_date).format('DD MMMM YYYY')) || '-',
              agreement?.agreement_type,
              agreement?.agreement_length || '-',
              agreement?.agreement_value_weeks || '-',
              agreement?.car_and_charge ? 'Yes' : 'No',
              agreement?.uber_clean_air_amount || '-',
            ]}
          />
        </div>
      </CollapsiblePanel>
    </>
  );
};
