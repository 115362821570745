import { createContext, Dispatch, SetStateAction } from 'react';

const APP_CONTEXT = createContext<{
  activeSideNav: string;
  superAdmin: boolean;
  setSuperAdmin: Dispatch<SetStateAction<boolean>>;
  setPermissions: Dispatch<SetStateAction<string>>;
  userName: string;
  setUserName: Dispatch<SetStateAction<string>>;
  setActiveSideNav: Dispatch<SetStateAction<string>>;
  setPageTitle: Dispatch<SetStateAction<string>>;
  showShadow: boolean;
  setShowShadow: Dispatch<SetStateAction<boolean>>;
}>({
  userName: '',
  superAdmin: false,
  setUserName: () => null,
  activeSideNav: 'dashboard',
  setSuperAdmin: () => null,
  setPermissions: () => null,
  setActiveSideNav: () => null,
  setPageTitle: () => null,
  showShadow: false,
  setShowShadow: () => null,
});
const AppContextProvider = APP_CONTEXT.Provider;
const AppContextConsumer = APP_CONTEXT.Consumer;

export { AppContextProvider, AppContextConsumer, APP_CONTEXT };
