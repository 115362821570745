import React, { useState } from 'react';
import { Icon, IconWrapper, Tooltip } from './actionIcon.styles';
import { Text } from '../../text/text';
import { PRIMARY_WHITE } from '../../../common/styles/Colors';
import { withStyledProps } from '../../../utils/colorUtils';

interface ActionIconProps {
  icon: JSX.Element;
  tooltip?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const ActionIcon = withStyledProps(({ icon, tooltip, disabled, onClick, ...props }: ActionIconProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  return (
    <Icon {...props}>
      {tooltip && !disabled && (
        <Tooltip $show={showTooltip}>
          <Text color={PRIMARY_WHITE} variant="body7" weight={300} styled={{ whiteSpace: 'nowrap' }}>
            {tooltip}
          </Text>
        </Tooltip>
      )}
      <IconWrapper
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          if (!disabled) {
            onClick?.();
          }
        }}
        $disabled={disabled ?? false}
        onPointerEnter={() => setShowTooltip(!disabled)}
        onPointerLeave={() => setShowTooltip(false)}
      >
        {icon}
      </IconWrapper>
    </Icon>
  );
});
