import styled from 'styled-components';
import { motion } from 'framer-motion';
import { PRIMARY_GREEN, PRIMARY_WHITE } from '../../common/styles/Colors';
import { FlexLayout } from '../layouts/flexLayout/flexLayout';

export const ModalOverlay = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(57, 39, 105, 0.6);
  z-index: 9999;
`;

export const Modal = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${PRIMARY_WHITE};
  border-radius: 16px;
  padding: 32px;
  z-index: 9999;
  .close {
    position: absolute;
    top: 5px;
    font-size: 24px;
    color: ${PRIMARY_GREEN};
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    z-index: 999;
    &:hover {
      transform: rotate(180deg);
    }
  }
  max-height: 90vh;
  overflow-y: scroll;
`;

export const Header = styled(FlexLayout)`
  margin-bottom: 20px;
  svg {
    cursor: pointer;
  }
`;

export const ModalChildren = styled.div<{ $showOverflow?: boolean }>`
  width: 100%;
  overflow-y: ${(props) => (props.$showOverflow ? 'visible' : 'auto')};
  @media (max-width: 1200px) {
    max-height: 75vh;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: white;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  scrollbar-face-color: rgba(0, 0, 0, 0.3); /* Firefox 63 compatibility */
  scrollbar-track-color: rgba(0, 0, 0, 0.2); /* Firefox 63 compatibility */
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.2);
  scrollbar-width: thin;
`;
