import React, { useCallback, useState } from 'react';
import { Control, FieldError, FieldValues, useForm } from 'react-hook-form';
import { FlexContainer } from '../../../core/pageTitle/pageTitle.styles';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { StyledSecondaryButton, StyledTextField, StyledUploaderInput } from './viewReviewPolicyForm.styles';
import {
  InsurancePolicy,
  InsurancePolicyDocuments,
  ModifiedInsurancePolicy,
  RenewPolicy,
} from '../../../models/insurancePolicy';
import { DateTimePickerComponent } from '../../../uiComponents/customComponents/dateTimePicker/dateTimePicker';
import { InputChips } from '../../../uiComponents/inputChips/inputChips';
import { updateInsurancePolicy } from '../../../api/patch/insurance.patch';
import { fileToBase64 } from '../../../utils/utils';
import { Notification } from '../../../uiComponents/toast/toast';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { StyledFieldContainer } from '../createEditPolicyForm/createEditPolicyForm.styles';
import { TextFieldLabel } from '../../../uiComponents/inputs/textField/textField.styles';
import { getInsurancePolicyDocuments } from '../../../api/get/insurance.get';

interface ViewReviewPolicyFormProps {
  isInViewMode: boolean;
  renewPolicy: () => void;
  values?: ModifiedInsurancePolicy | null;
  onFormSubmit: () => void;
  onClose: () => void;
}

export const ViewReviewPolicyForm = ({
  isInViewMode,
  values,
  onClose,
  onFormSubmit,
  renewPolicy,
}: ViewReviewPolicyFormProps) => {
  const getVrmList = () => {
    if (!values?.vrm_number_range || !values!.vrm_number_range[0]) return [];
    return values?.vrm_number_range.map((item) => {
      return { value: item };
    });
  };
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<InsurancePolicy>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues:
      isInViewMode && values
        ? { ...values, vrm_number_range: getVrmList() }
        : {
            insurance_company: values?.insurance_company,
            policy_number: values?.policy_number,
            start_date: '',
            end_date: '',
            vrm_number_range: getVrmList(),
          },
  });

  const onSubmit = async (values: InsurancePolicy) => {
    setIsFormSubmitting(true);
    const { id, start_date, end_date, policy_certificate } = values;
    const payload: RenewPolicy = {
      id,
      body: {
        start_date,
        end_date,
        policyCertificateUrl: policy_certificate
          ? {
              content: await fileToBase64(policy_certificate),
              fileName: 'insurance-policy-certificate',
              type: policy_certificate.type,
            }
          : undefined,
      },
    };

    updateInsurancePolicy(payload)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Policy has been successfully renewed',
          isAlert: true,
        });
        onFormSubmit();
      })
      .catch(() =>
        Notification({
          type: 'error',
          title: 'Error',
          message: 'Error renewing policy',
          isAlert: true,
        })
      )
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  const fetchInsurancePolicyDocs = useCallback(() => {
    if (values?.id) {
      getInsurancePolicyDocuments(values?.id).then((response: { data: InsurancePolicyDocuments[] }) => {
        if (response.data) {
          response.data.forEach((doc) => {
            window.open(doc.url, '_blank');
          });
        }
      });
    }
  }, [values?.id]);

  return (
    <>
      <GridLayout template={2} gap="32px 24px">
        <StyledTextField
          {...register('insurance_company', {
            required: 'Insurance company is a required field',
          })}
          label="Insurance company"
          error={errors.insurance_company}
          type="text"
          placeholder="Insurance company name"
          required
          disabled={true}
        />
        <StyledTextField
          {...register('policy_number', {
            required: 'Policy number is a required field',
          })}
          label="Policy number"
          error={errors.policy_number}
          placeholder="Policy number"
          type="text"
          required
          disabled={true}
        />

        <DateTimePickerComponent
          control={control}
          name="start_date"
          label="Start date"
          errors={errors.start_date}
          rules={{
            required: 'Start date is a required field',
          }}
          disabled={isInViewMode}
        />
        <DateTimePickerComponent
          control={control}
          name="end_date"
          label="End date"
          errors={errors.end_date}
          rules={{
            required: 'End date is a required field',
          }}
          disabled={isInViewMode}
        />
        <InputChips
          name="vrm_number_range"
          disabled={true}
          label=""
          header="Vrm rules"
          register={register}
          control={control}
          required={{
            required: 'Vrm plates rules are a required field',
          }}
          error={errors.vrm_number_range as FieldError}
        />
        <StyledFieldContainer vertical>
          <TextFieldLabel $isRequired>Policy date format</TextFieldLabel>
          <DropDown
            disabled={true}
            name="policy_date_format"
            required={{
              required: 'Policy date format is required',
            }}
            error={errors.policy_date_format}
            placeholder="Choose policy date/time format"
            options={[
              {
                value: 'DD/MM/YYYY [at] HH:mm',
                label: 'DD/MM/YYYY at HH:mm',
              },
              {
                value: 'HHmm [hours] Do MMMM YYYY',
                label: 'HHmm hours Day MMMM YYYY',
              },
              {
                value: 'HH:mm [at] DD/MM/YYYY',
                label: 'HH:mm at DD/MM/YYYY',
              },
            ]}
            control={control as unknown as Control<FieldValues>}
          />
        </StyledFieldContainer>

        <StyledUploaderInput
          control={control as unknown as Control<FieldValues>}
          name="policy_certificate"
          error={errors?.policy_certificate}
          label="Insurance policy"
          disabled={isInViewMode}
        />
      </GridLayout>

      <FlexContainer style={{ marginTop: 20 }} itemsX="end">
        <StyledSecondaryButton onClick={() => onClose()}>Cancel</StyledSecondaryButton>
        <StyledSecondaryButton onClick={() => fetchInsurancePolicyDocs()}>Generate samples</StyledSecondaryButton>
        <PrimaryButton
          isProcessing={isFormSubmitting}
          onClick={isInViewMode ? () => renewPolicy() : handleSubmit(onSubmit)}
        >
          {isInViewMode ? 'Renew' : 'Save'}
        </PrimaryButton>
      </FlexContainer>
    </>
  );
};
