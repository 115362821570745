import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Text } from '../../../uiComponents/text/text';
import { EditVehicleForm } from './vehicleForm/editVehicleForm';
import { VehicleInfoProps } from '../vehiclePage/vehiclePage';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FieldGrid } from '../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../uiComponents/modals/modal';
import { TextFieldLabel } from '../../../uiComponents/inputs/textField/textField.styles';
import { Toggle } from '../../../uiComponents/uiControls/toggle/toggle';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { APP_CONTEXT } from '../../../utils/context';

interface VehicleDetailsProps extends VehicleInfoProps {
  fetchVehicleData: () => void;
}

export const VehicleDetails = ({ vehicle, fetchVehicleData }: VehicleDetailsProps) => {
  const { superAdmin } = useContext(APP_CONTEXT);
  const [adminEdit, setAdminEdit] = useState<boolean>(false);
  const [editVehicle, setEditVehicle] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isSecImmobChecked, setIsSecImmobChecked] = useState<boolean>(!!vehicle?.is_sec_immob);
  const { register } = useForm();

  useEffect(() => setIsSecImmobChecked(vehicle?.is_sec_immob), [vehicle]);

  return (
    <>
      <CollapsiblePanel
        header={
          <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
            Basic details
          </Text>
        }
        onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
        expanded={!isCollapsed}
      >
        <div>
          <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
            Basic details
          </Text>
          <FieldGrid
            numColumns={5}
            headers={[
              'Manufacturer:',
              'Model:',
              'Colour:',
              'Status:',
              'Available on:',
              'VIN number:',
              'Net value:',
              'Gross value:',
              'Total due:',
              'Condition:',
              'Key location:',
              'Last service date:',
              'Last service mileage:',
              'MOT expiry:',
              'PHV expiry:',
              'Telematics:',
              'Vehicle source:',
              'Policy number:',
            ]}
            values={[
              vehicle?.manufacturer,
              `${vehicle?.model} ${vehicle?.spec}`,
              vehicle?.color ?? '-',
              vehicle?.vehicle_status,
              vehicle?.available_on ? moment(vehicle?.available_on)?.format('DD MMM YYYY') : '-',
              vehicle?.vin ?? '-',
              vehicle?.net_value?.toString() ?? '-',
              vehicle?.gross_value?.toString() ?? '-',
              vehicle?.total_due?.toString() ?? '-',
              vehicle?.condition ?? '-',
              vehicle?.carkey ?? '-',
              vehicle?.last_service_date ? moment(vehicle?.last_service_date)?.format('DD MMM YYYY') : '-',
              vehicle?.last_service_mileage?.toString() ?? '-',
              vehicle?.mot_exp ? moment(vehicle?.mot_exp)?.format('DD MMM YYYY') : '-',
              vehicle?.phv_exp ? moment(vehicle?.phv_exp)?.format('DD MMM YYYY') : '-',
              vehicle?.telematics ?? '-',
              vehicle?.vehicle_source,
              vehicle?.policy_number ?? '-',
            ]}
          />
          <FlexLayout itemsX="start" itemsY="center" styled={{ margin: '30px 0 0px 10px' }}>
            <Toggle
              {...register('is_sec_immob')}
              styled={{ marginRight: '10px', cursor: 'not-allowed' }}
              disabled
              checked={isSecImmobChecked}
            />
            <TextFieldLabel>Secondary immobiliser</TextFieldLabel>
          </FlexLayout>
          <FlexLayout gap={16} itemsX="end">
            <SecondaryButton onClick={() => setEditVehicle(true)}>Edit vehicle</SecondaryButton>
            {superAdmin && <PrimaryButton onClick={() => setAdminEdit(true)}>Admin edit</PrimaryButton>}
          </FlexLayout>
        </div>
      </CollapsiblePanel>
      <Modal
        title={editVehicle ? 'Edit vehicle' : 'Admin edit'}
        open={editVehicle || adminEdit}
        onClose={() => {
          setEditVehicle(false);
          setAdminEdit(false);
        }}
        showClose={true}
        showOverflow={false}
        styled={{ width: adminEdit ? '80vw' : '60vw' }}
      >
        <EditVehicleForm
          vehicleData={vehicle}
          close={async (refresh?: boolean) => {
            if (refresh) {
              await fetchVehicleData();
            }
            setEditVehicle(false);
            setAdminEdit(false);
          }}
          editType={adminEdit ? 'adminEdit' : 'vehicleEdit'}
        />
      </Modal>
    </>
  );
};
