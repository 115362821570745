import { css, styled } from 'styled-components';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { FieldGrid } from '../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { UploaderInput } from '../../../uiComponents/inputs/uploaderInput/uploaderInput';
import { Text } from '../../../uiComponents/text/text';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { Spinner } from '../../../uiComponents/uiControls/spinner/spinner';
import {
  GRAY_2,
  GRAY_3,
  PRIMARY_GREEN,
  PRIMARY_PURPLE,
  PRIMARY_WHITE,
  SECONDARY_PURPLE_30,
  SECONDARY_PURPLE_5,
  STATUS_RED,
  STATUS_YELLOW,
} from '../../../common/styles/Colors';
import { DownpaymentStatus } from '../../../models/application';
import { darken } from '../../../utils/colorUtils';

export const Reassessment = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px dashed ${PRIMARY_GREEN};
`;

export const PreviousAssessment = styled.div`
  margin-top: 16px;
`;

export const Notes = styled.div`
  flex: 1;
`;

export const FieldHeader = styled(Text)`
  border-radius: 50px;
  padding: 8px 32px;
  background-color: ${SECONDARY_PURPLE_5};
`;

export const DropdownMenuAbove = styled(DropDown)`
  [id$='-listbox'] {
    top: auto;
    bottom: 100%;
  }
`;

export const AssignVehicleFields = styled.div`
  margin-top: 32px;
`;

export const VehicleSourceFilter = styled.div`
  margin-top: 32px;
`;

export const OrderOptions = styled.div`
  border-radius: 16px;
  padding: 24px 32px;
  background-color: ${PRIMARY_WHITE};
  flex: 1;
`;

export const StatusBarBG = styled.div`
  position: relative;
  margin-top: 2px;
  background-color: ${GRAY_2};
  width: 120px;
  height: 20px;
  border-radius: 100px;
`;

export const StatusBar = styled.div<{ $progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  border-radius: 100px;
  width: ${(props) => `${props?.$progress.toFixed(2)}%`};
  background-color: ${PRIMARY_GREEN};
`;

export const RequiredField = styled(Text)`
  &:after {
    content: '*';
    position: absolute;
    margin-left: 8px;
    color: ${PRIMARY_GREEN};
  }
`;

export const PartExchange = styled.div`
  margin-top: 24px;
`;

export const TermsAndConditions = styled.div`
  margin-top: 24px;
  border-top: 1px dashed ${PRIMARY_GREEN};
`;

export const Ul = styled.ul`
  margin-top: 24px;
`;

export const Li = styled.li`
  margin: 16px 0;
`;

const statusColours: Record<DownpaymentStatus, { bgColor: string; borderColor: string }> = {
  AWAITING: { bgColor: SECONDARY_PURPLE_5, borderColor: SECONDARY_PURPLE_30 },
  PENDING: { bgColor: STATUS_YELLOW, borderColor: darken(STATUS_YELLOW, 10) },
  SUCCEEDED: { bgColor: PRIMARY_GREEN, borderColor: PRIMARY_GREEN },
  FAILED: { bgColor: STATUS_RED, borderColor: darken(STATUS_RED, 10) },
};

export const Status = styled(FlexLayout)<{ $status: DownpaymentStatus }>`
  background-color: ${(props) => statusColours?.[props?.$status]?.bgColor};
  border: 1px solid ${(props) => statusColours?.[props?.$status]?.borderColor};
  border-radius: 50px;
  width: 208px;
  height: 46px;
  margin-top: 8px;
  padding: 0 32px;
`;

export const DownpaymentPanel = styled.div`
  position: relative;
`;

export const EVOptions = styled.div`
  margin-top: 32px;
`;

export const EvField = styled.div`
  position: relative;
  margin-left: 24px;
`;

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  margin-top: 30px;
  right: 24px;
`;

export const Insurance = styled(FlexLayout)`
  margin-top: 32px;
`;

export const Declaration = styled.div`
  margin-top: 32px;
`;

export const LiabilityAnswer = styled(FlexLayout)<{ $fill: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${PRIMARY_GREEN};
  background-color: ${(props) => (props?.$fill ? PRIMARY_GREEN : PRIMARY_WHITE)};
`;

export const OrderSections = styled.div`
  position: relative;
  flex: 1;
`;

export const InfoBanner = styled(FlexLayout)`
  margin-top: 16px;
  padding: 16px;
  border: 1px solid ${STATUS_RED};
  border-radius: 100px;
  background-color: ${PRIMARY_WHITE};
`;

export const LoadingMask = styled(FlexLayout)`
  position: fixed;
  top: 164px;
  left: 396px;
  right: 32px;
  bottom: 32px;
  border-radius: 32px;
`;

export const PaymentMethodPanel = styled(CollapsiblePanel)<{ $paymentMethodMissing: boolean }>`
  margin-top: 16px;

  ${(props) => {
    if (props.$paymentMethodMissing) {
      return css`
        border: 2px solid ${STATUS_RED};
      `;
    }
    return null;
  }}
`;

export const PaymentStartDateArea = styled.div`
  padding: 22px 32px;
  background-color: ${PRIMARY_WHITE};
  border-radius: 16px;
  margin-top: 16px;
`;

export const Plan = styled(FlexLayout)<{ $selected: boolean; $disabled: boolean }>`
  height: 360px;
  border-radius: 16px;
  padding: 16px;
  background-color: ${SECONDARY_PURPLE_5};

  ${(props) => {
    if (props?.$selected) {
      return css`
        box-shadow: 0 0 0 2px ${PRIMARY_GREEN} inset;
      `;
    }
    if (props.$disabled) {
      return css`
        opacity: 0.3;
        pointer-events: none;
      `;
    }
  }}
`;

export const ThisOne = styled(FlexLayout)`
  height: 48px;
  width: 162px;
  margin-top: 32px;
  border-radius: 50px;
  border: 1px solid ${PRIMARY_GREEN};
`;
export const DriverDetailsGrid = styled(FieldGrid)`
  margin-top: 32px;
`;

export const DvlaNumber = styled.div`
  margin-top: 32px;
`;

export const GreyPanel = styled(FlexLayout)`
  height: 64px;
  border-radius: 100px;
  padding-left: 64px;
  padding-right: 24px;
  margin-top: 16px;
  background-color: ${GRAY_3};
`;

export const SendScrivePlaceholder = styled(FlexLayout)`
  display: inline-flex;
  float: right;
  width: 192px;
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 50px;
  height: 40px;
  border: 1px solid ${SECONDARY_PURPLE_30};
  background-color: ${PRIMARY_WHITE};
`;

export const DvlaInputDiv = styled.div`
  position: relative;
  padding-bottom: 16px;
`;

export const SearchButton = styled(FlexLayout)`
  position: absolute;
  right: 8px;
  top: 0;
  width: 44px;
  height: 44px;
  cursor: pointer;
`;

export const Separator = styled.div`
  border-top: 1px dashed ${PRIMARY_GREEN};
  margin: 37px 0;
`;

export const Documents = styled.div`
  border-radius: 16px;
  margin-top: 17px;
  padding: 14px 32px;

  a {
    color: ${PRIMARY_PURPLE};
    font-size: 14px;
    margin-top: 4px;
  }
`;

export const Uploader = styled(UploaderInput)`
  margin-top: 32px;
  width: 100%;
`;
