import React, { useCallback, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { BsExclamationCircle } from 'react-icons/bs';
import { cancellationOptions } from '../../../../consts/aftercare';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../../uiComponents/text/text';
import { TextArea } from '../../../../uiComponents/inputs/textArea/textArea';
import { TextFieldLabel } from '../../../../uiComponents/inputs/textField/textField.styles';
import { DropDown } from '../../../../uiComponents/uiControls/dropDown/dropDown';
import { Toggle } from '../../../../uiComponents/uiControls/toggle/toggle';
import { RadioButton } from '../../../../uiComponents/uiControls/radioButton/radioButton';
import { terminateAftercare } from '../../../../api/patch/aftercare.patch';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_30, STATUS_RED } from '../../../../common/styles/Colors';
import { Aftercare } from '../../../../models/aftercare';
import { renderNotification } from '../../../../utils/utils';

interface CancelAftercare {
  agreement_id: string;
  is_part_exchange: boolean;
  termination_note?: string;
  termination_reason: string;
  vehicle_action: string;
}
export const CancellationForm = ({ aftercare, onFormSubmit }: { aftercare?: Aftercare; onFormSubmit: () => void }) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [isPartExch, setIsPartExch] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm<CancelAftercare>({
    defaultValues: {
      termination_note: '',
      termination_reason: '',
      agreement_id: aftercare?.id,
    },
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const terminationReason: string = watch('termination_reason');

  const onSubmit = useCallback(
    async (submitValues: CancelAftercare) => {
      setProcessing(true);
      try {
        await terminateAftercare(submitValues);
        setProcessing(false);
        renderNotification('success', 'Success', 'Aftercare cancelled successfully');
      } catch (err) {
        renderNotification('error', 'Error', `Failed to create driver’s tag: ${err}`, false);
      }
      onFormSubmit();
    },
    [onFormSubmit]
  );

  return (
    <FlexLayout gap={16} vertical itemsX="center">
      <BsExclamationCircle size={200} color={SECONDARY_PURPLE_30} />
      <Text styled={{ marginBottom: 32, textAlign: 'center' }} variant="h4" weight={800} color={PRIMARY_PURPLE} block>
        You are about to cancel this aftercare agreement
      </Text>
      <Text styled={{ textAlign: 'center' }} variant="body5" color={PRIMARY_PURPLE}>
        Please select a reason for cancellation below
      </Text>
      <div style={{ width: '100%' }}>
        <DropDown
          control={control as unknown as Control<FieldValues>}
          error={errors?.termination_reason}
          name="termination_reason"
          options={cancellationOptions}
          placeholder={'Reason for cancellation'}
          required={{
            required: 'Reason is required',
          }}
        />
      </div>
      <FlexLayout itemsX="start" itemsY="center" styled={{ marginTop: 20, width: '100%' }}>
        <Toggle
          {...register('is_part_exchange')}
          onChecked={() => setIsPartExch(!isPartExch)}
          styled={{ marginRight: '10px' }}
        />
        <TextFieldLabel>Part exchange</TextFieldLabel>
      </FlexLayout>
      {isPartExch && (
        <div style={{ width: '100%' }}>
          <FlexLayout styled={{ marginTop: 8 }} itemsX="start" itemsY="center" gap={64}>
            <FlexLayout gap={8} itemsX="start">
              <RadioButton
                {...register('vehicle_action', {
                  required: 'Please select one of the two options',
                })}
                id="servicing"
                name="vehicle_action"
                value="servicing"
              />
              <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
                Send to servicing
              </Text>
            </FlexLayout>
            <FlexLayout gap={8} itemsX="start">
              <RadioButton
                {...register('vehicle_action', {
                  required: 'Please select one of the two options',
                })}
                id="available"
                name="vehicle_action"
                value="available"
              />
              <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
                Make available
              </Text>
            </FlexLayout>
          </FlexLayout>
          {errors?.vehicle_action && (
            <Text variant="body8" weight={300} color={STATUS_RED}>
              {errors?.vehicle_action?.message as string}
            </Text>
          )}
        </div>
      )}
      <div style={{ width: '100%', marginTop: 16 }}>
        <TextArea
          {...register('termination_note')}
          label="Notes"
          placeholder="Add note"
          required={terminationReason === 'OTHER'}
        />
      </div>
      <FlexLayout itemsX="center">
        <PrimaryButton isProcessing={processing} onClick={handleSubmit(onSubmit)}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </FlexLayout>
  );
};
