import React from 'react';
import { Control, FieldError, FieldErrors, FieldValues } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Documents, Uploader } from '../../../order/order.styles';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { CustomerProfile } from '../../../../../models/driver';

interface DVLADocumentationProps {
  errors: FieldErrors<CustomerProfile>;
  control: Control<CustomerProfile>;
  dvlaBackUrl: string;
  dvlaFrontUrl: string;
  pcoUrl: string;
  pcoCertUrl: string;
  poaUrl: string;
  isComplete: boolean;
}

export const DriverDocs = React.forwardRef<HTMLDivElement, DVLADocumentationProps>(
  (
    { control, errors, dvlaBackUrl, dvlaFrontUrl, pcoUrl, pcoCertUrl, poaUrl, isComplete }: DVLADocumentationProps,
    ref
  ) => {
    return (
      <Documents ref={ref}>
        <FlexLayout itemsX="space-between" gap={40}>
          <Uploader
            styled={{ width: '100%' }}
            required
            label="Upload DVLA front image"
            control={control as unknown as Control<FieldValues>}
            name="dvla_front"
            error={errors?.dvla_front as FieldError}
            disabled={isComplete}
          />
          {dvlaFrontUrl && (
            <Link target="_blank" to={{ pathname: dvlaFrontUrl }}>
              View current DVLA front image
            </Link>
          )}
          <Uploader
            required
            label="Upload DVLA back image"
            control={control as unknown as Control<FieldValues>}
            name="dvla_back"
            error={errors?.dvla_back as FieldError}
            disabled={isComplete}
          />
          {dvlaBackUrl && (
            <Link target="_blank" to={{ pathname: dvlaBackUrl }}>
              View current DVLA back image
            </Link>
          )}
        </FlexLayout>
        <FlexLayout itemsX="space-between" gap={40}>
          <Uploader
            required
            label="PCO badge"
            control={control as unknown as Control<FieldValues>}
            name="pco"
            error={errors?.pco as FieldError}
            disabled={isComplete}
          />
          {pcoUrl && (
            <Link target="_blank" to={{ pathname: pcoUrl }}>
              View current PCO badge
            </Link>
          )}
          <Uploader
            required
            loading={false}
            label="PCO certificate"
            control={control as unknown as Control<FieldValues>}
            name="pco_cert"
            error={errors?.pco_cert as FieldError}
            disabled={isComplete}
          />
          {pcoCertUrl && (
            <Link target="_blank" to={{ pathname: pcoCertUrl }}>
              View current PCO certificate
            </Link>
          )}
        </FlexLayout>
        <FlexLayout styled={{ width: '48%' }} itemsX="start">
          <Uploader
            required
            label="Upload proof of address"
            control={control as unknown as Control<FieldValues>}
            name="poa"
            error={errors?.poa as FieldError}
            disabled={isComplete}
          />
          {poaUrl && (
            <Link target="_blank" to={{ pathname: poaUrl }}>
              View current POA document
            </Link>
          )}
        </FlexLayout>
      </Documents>
    );
  }
);
