export type AnyString = string & {};
export type CssLength = AnyString | number;
export type CssGlobals = 'inherit' | 'initial' | 'revert' | 'unset';
export type Size = CssLength | 'inherit' | 'initial' | 'revert' | 'unset' | 'auto';
export type CssSize = CssLength | CssGlobals | 'auto' | 'fill';
export interface ChildrenProps<T = React.ReactNode> {
  children: T;
}
export interface StyleProps {
  style?: React.CSSProperties;
  className?: string;
}
export interface HTMLAttributes {
  id?: string;
}
export declare type DOMEvents<E = HTMLElement> = Omit<React.DOMAttributes<E>, 'children' | 'dangerouslySetInnerHTML'>;
export interface BaseProps<E = HTMLElement> extends StyleProps, HTMLAttributes, DOMEvents<E> {}
const applyMetric = (value: Size, metric = 'px') => {
  if (value === null) {
    return undefined;
  }
  if (typeof value === 'string') {
    return value;
  }
  return `${value}${metric}`;
};
export const withPx = (value: Size) => applyMetric(value, 'px');
export interface OptionList {
  label: string;
  value: string;
  type?: string;
}
