// DEPENDENCIES
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { BsPencil } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';
// COMPONENTS & STYLES
import { CreateEditCityForm } from './createEditCityForm';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../uiComponents/modals/modal';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { cityColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { TableHeader } from '../../../uiComponents/table/tableHeader/tableHeader';
import {
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { Notification } from '../../../uiComponents/toast/toast';
// API CALLS & MODELS
import { getAllCities } from '../../../api/get/city.get';
import { deleteCity } from '../../../api/delete/city.delete';
import { City } from '../../../models/city';
// HOOKS & UTILS & COMMONS
import { useTableFilters } from '../../../hooks/useTableFilters';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { APP_CONTEXT } from '../../../utils/context';

export const CityList = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [cityToEdit, setCityToEdit] = useState<City>();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const handleGetCitiesResponse = useCallback(
    (count: number, cities: City[]) => {
      const cityRows = cities?.map((city: City) => {
        return {
          rowData: { data: city },
          cells: [
            <TableTextCell value={city?.city_name} />,
            <TableTextCell value={city?.country_name} />,
            <FlexLayout gap={16}>
              <ActionIcon
                icon={
                  <BsPencil
                    onClick={() => {
                      setCityToEdit(city);
                      setIsEditModalOpen(true);
                    }}
                    size={24}
                    color={PRIMARY_PURPLE}
                  />
                }
                tooltip="Edit city"
              />
              <ActionIcon
                icon={
                  <FiTrash2
                    onClick={() => {
                      setCityToEdit(city);
                      setIsDeleteConfirmationModalOpen(true);
                    }}
                    size={24}
                    color={PRIMARY_PURPLE}
                  />
                }
                tooltip="Delete city"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(cityRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const fetchCityList = useCallback(
    (queryString: string) => {
      const controller = new AbortController();
      getAllCities(queryString, controller.signal).then((response: { count: number; data: City[] }) => {
        handleGetCitiesResponse(response.count, response.data);
      });
    },
    [handleGetCitiesResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, sortingColumn: string | undefined, sortAscending: boolean) => {
      setTableData(undefined);
      const queryString = getQueryString([], rowsPerPage, pageNumber, '', sortingColumn, sortAscending);
      fetchCityList(queryString);
    },
    [fetchCityList, setTableData]
  );

  useEffect(() => {
    setActiveSideNav('cityListPage');
    setPageTitle('Cities');
    setSortingColumn('first_name');
    setSortingColumn('city_name');
    fetchCityList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=city_name:ASC`);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, fetchCityList]);

  const deleteActiveCity = (userId: string) => {
    deleteCity(userId).then(() => {
      applyFilters(pageNumber, numRowsPerPage, sortingColumn, sortAscending);
      Notification({
        type: 'success',
        title: 'Success',
        message: 'User has been successfully deleted',
        isAlert: true,
      });
    });
  };

  const handleFormSubmit = () => {
    getAllCities();
    setIsCreateModalOpen(false);
    setIsEditModalOpen(false);
  };
  return (
    <>
      <Table
        header={
          <TableHeader
            tableHeaderTitle="City list"
            actionButtonText="Add city"
            onButtonClick={() => setIsCreateModalOpen(true)}
          />
        }
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(pageNumber, numRowsPerPage, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={cityColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={[]}
        downloadName="Users"
        dataDownloadMethod="download"
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, sortingColumn, sortAscending);
        }}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(0, value, sortingColumn, sortAscending);
        }}
      />
      <Modal
        title={isEditModalOpen ? 'Edit city' : 'Add new city'}
        open={isEditModalOpen || isCreateModalOpen}
        showClose
        onClose={() => {
          setIsEditModalOpen(false);
          setIsCreateModalOpen(false);
        }}
        styled={{ width: '60vw', minWidth: '600px' }}
      >
        <CreateEditCityForm
          isInEdit={isEditModalOpen}
          onFormSubmit={handleFormSubmit}
          values={isEditModalOpen ? cityToEdit : undefined}
          onClose={() => {
            setIsEditModalOpen(false);
            setIsCreateModalOpen(false);
            fetchCityList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=city_name:ASC`);
          }}
        />
      </Modal>
      <ConfirmationModal
        title={'Are you sure you want to delete this city?'}
        isOpen={isDeleteConfirmationModalOpen}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        onConfirm={() => {
          if (cityToEdit?.city_id) {
            deleteActiveCity(cityToEdit?.city_id);
            setIsDeleteConfirmationModalOpen(false);
          }
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
    </>
  );
};
