import { useInView as libUseInView } from 'react-intersection-observer';
import { CssSize, withPx } from '../utils/props';

export function useInView(
  rootMargin: CssSize = '50%',
  root?: Element | null,
  threshold?: number | number[]
): [React.RefCallback<HTMLElement>, boolean] {
  const [ref, inView] = libUseInView({
    rootMargin: withPx(rootMargin),
    root: root,
    threshold: threshold,
    fallbackInView: true,
  });

  return [ref, inView];
}
