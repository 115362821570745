import { OptionList } from '../utils/props';

export const UPCOMING_INSURANCE_POLICY = 'UPCOMING';
export const EXPIRED_INSURANCE_POLICY = 'EXPIRED';
export const FIRST_DATA_REVIEW_COMPLETE = 'REVIEW-ONE-COMPLETE';
export const SECOND_DATA_REVIEW_COMPLETE = 'REVIEW-TWO-COMPLETE';
export const POLICY_CERTIFICATE = 'CERTIFICATE';
export const POLICY_PERMISSION_LETTER = 'PERMISSION-LETTER';

export const insurancePolicyDocsOptions: OptionList[] = [
  { value: POLICY_CERTIFICATE, label: 'Certificate' },
  { value: POLICY_PERMISSION_LETTER, label: 'Permission letter' },
];
