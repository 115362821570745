import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// utils
import { useTableFilters } from '../../../hooks/useTableFilters';
import {
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { APP_CONTEXT } from '../../../utils/context';
import { aftercareContactListColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
// api
import { getAftercareContactList } from '../../../api/get/aftercare.get';
// models
import { AftercareContact } from '../../../models/aftercare';
// styles
import { PRIMARY_PURPLE, STATUS_ORANGE } from '../../../common/styles/Colors';
// components
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { BsClipboard, BsEye, BsPhoneVibrate } from 'react-icons/bs';
import { Table } from '../../../uiComponents/table/table';
import { Modal } from '../../../uiComponents/modals/modal';
import { AftercareSetupForm } from '../aftercareSetup/aftercareSetup';
import { Tag } from '../../../uiComponents/customComponents/tag/tag';
import { CallbackForm } from './callbackForm/callbackForm';
// contants
import { AGREEMENTS } from '../../../consts/routes';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import { aftercareStatusListOptions } from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import { OptionList } from '../../../utils/props';
import { useDateRangeFilter } from '../../../hooks/useDateRangeFilter';

export const ContactList = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [activeAftercare, setActiveAftercare] = useState<AftercareContact | null>(null);
  const [isCallbackModalOpen, setIsCallbackModalOpen] = useState<boolean>(false);
  const [isAftercareSetupModalOpen, setIsAftercareSetupModalOpen] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<OptionList[]>([]);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const navigate = useNavigate();

  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  useEffect(() => {
    setActiveSideNav('aftercareContactList');
    setPageTitle('Aftercare');
    setSortingColumn('start_date');
  }, [setActiveSideNav, setPageTitle, setSortingColumn]);

  const filters: FilterItem[] = [
    {
      name: 'agreement_status',
      element: (
        <DropDownFilter
          name="agreement_status"
          placeholder="Status"
          options={aftercareStatusListOptions}
          multiValues={statusFilter}
          title="Status"
          onChange={(items) => setStatusFilter(items as OptionList[])}
        />
      ),
    },
    {
      name: 'date-range',
      element: (
        <DateRangeFilter
          title="Agreement completion"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const handleGetAftercareContactListResponse = useCallback(
    (count: number, data: AftercareContact[]) => {
      const aftercareRows = data?.map((aftercareItem: AftercareContact) => {
        return {
          rowData: { data: aftercareItem },
          cells: [
            <TableTextCell value={aftercareItem?.driver_name} />,
            <TableTextCell value={aftercareItem?.vehicle_name} />,
            <TableTextCell value={aftercareItem?.vrm} />,
            <TableTextCell value={aftercareItem?.driver_mobile_phone} />,
            <TableTextCell
              value={aftercareItem?.end_date ? moment(aftercareItem?.end_date).format('DD MMM YYYY') : '-'}
            />,
            <FlexLayout itemsX="start">
              {aftercareItem.contact_request_exists && (
                <Tag key={`aftercare_agreement_status_${aftercareItem.agreement_id}`} color={STATUS_ORANGE}>
                  Callback requested
                </Tag>
              )}
            </FlexLayout>,
            <FlexLayout gap={16}>
              <ActionIcon icon={<BsEye size={24} color={PRIMARY_PURPLE} />} tooltip="View agreement page" />
              <ActionIcon
                icon={
                  <BsClipboard
                    onClick={() => {
                      setActiveAftercare(aftercareItem);
                      setIsAftercareSetupModalOpen(true);
                    }}
                    size={24}
                    color={PRIMARY_PURPLE}
                  />
                }
                tooltip="Aftercare setup"
              />
              <ActionIcon
                icon={
                  <BsPhoneVibrate
                    onClick={() => {
                      if (!aftercareItem?.contact_request_exists) {
                        setActiveAftercare(aftercareItem);
                        setIsCallbackModalOpen(true);
                      }
                    }}
                    size={24}
                    color={PRIMARY_PURPLE}
                  />
                }
                tooltip="Call back"
                disabled={aftercareItem?.contact_request_exists}
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(aftercareRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const fetchData = useCallback(
    async (queryString: string) => {
      const controller = new AbortController();

      getAftercareContactList(queryString, controller.signal).then(
        ({ data, count }: { data: AftercareContact[]; count: number }) => {
          handleGetAftercareContactListResponse(count, data);
        }
      );
    },
    [handleGetAftercareContactListResponse]
  );

  const contactListColumns = useMemo(() => [...aftercareContactListColumns], []);

  useEffect(() => {
    if (!statusFilter) return;
    setTableFilters([
      { columnName: 'contact_status', options: statusFilter },
      {
        columnName: 'agreement.end_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [setTableFilters, dateRangeFilter, statusFilter]);

  useEffect(() => {
    fetchData(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}`);
    setSortingColumn('first_name');
  }, [fetchData, setSortingColumn]);

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchData(queryString);
    },
    [fetchData, setTableData, goToPageNumber, tableFilters]
  );

  const onClearClick = useCallback(() => {
    setStatusFilter([]);
    setDateRangeFilter([]);
  }, [setDateRangeFilter]);

  return (
    <>
      <Table
        header="Contact list"
        onColumnHeaderClick={(columnId: string) => {
          applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId));
        }}
        sortAscending={sortAscending}
        columns={contactListColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        filters={filters}
        disableApply={invalidDates}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onRowClick={({ data }: { data: AftercareContact }) => {
          navigate(`${AGREEMENTS}/${data.agreement_id}`);
        }}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
        }}
      />
      {activeAftercare && (
        <>
          <Modal
            open={isAftercareSetupModalOpen}
            title="Aftercare setup"
            showClose
            showOverflow={false}
            onClose={() => setIsAftercareSetupModalOpen(false)}
          >
            <AftercareSetupForm
              aftercare={activeAftercare}
              onClose={() => setIsAftercareSetupModalOpen(false)}
              isInEdit={false}
              onSubmit={() => {
                applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
              }}
            />
          </Modal>
          <Modal
            title="Callback request"
            open={isCallbackModalOpen}
            showClose
            showOverflow={false}
            onClose={() => setIsCallbackModalOpen(false)}
          >
            <CallbackForm
              aftercare={activeAftercare}
              onSubmit={() => fetchData(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}`)}
              onClose={() => setIsCallbackModalOpen(false)}
            />
          </Modal>
        </>
      )}
    </>
  );
};
