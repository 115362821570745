// DEPENDENCIES
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BsEye } from 'react-icons/bs';
import moment from 'moment';
// COMPONENTS & STYLES
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { vehicleListColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import {
  vehicleStatuses,
  vehicleTagTypes,
  vehicleSourceOptions,
  usedVehicleOptions,
} from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import {
  TableTagCell,
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { Text } from '../../../uiComponents/text/text';
// API CALLS & MODELS
import { exportAllVehicles, getAllVehicles } from '../../../api/get/vehicle.get';
import { getVehicleTypesSelect } from '../../../api/get/vehicleType.get';
import { Vehicle, VehicleTypeSelectResponse } from '../../../models/vehicle';
// HOOKS & UTILS & COMMONS
import { VEHICLES } from '../../../consts/routes';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../common/styles/Colors';
import { OptionList } from '../../../utils/props';
import { getAllBranches } from '../../../api/get/branch.get';
import { Branch } from '../../../models/branch';
import { FilterInput } from '../../../uiComponents/table/tableFilters/tableFilters.styles';

export const VehicleList = () => {
  const navigate = useNavigate();
  const [branchOptions, setBranchOptions] = useState<OptionList[]>([]);
  const [selectedBranches, setSelectedBranches] = useState<OptionList[]>([]);
  const [selectedTags, setSelectedTags] = useState<OptionList[]>([]);
  const [vehicleConditionSelected, setVehicleConditionSelected] = useState<OptionList>(usedVehicleOptions[0]);
  const [manufacturerOptions, setManufacturerOptions] = useState<OptionList[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<OptionList[]>([]);
  const [selectedVehicleSource, setSelectedVehicleSource] = useState<OptionList[]>([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState<OptionList[]>([]);
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortAscending,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=updated_date:DESC`;

  const { register, reset, watch } = useForm({
    defaultValues: { mot_exp: '', phv_exp: '' },
  });
  const motExp = watch('mot_exp');
  const phvExp = watch('phv_exp');

  const filters: FilterItem[] = [
    {
      name: 'manufacturer',
      element: (
        <DropDownFilter
          name="manufacturer"
          placeholder="Manufacturer"
          multiValues={selectedManufacturer}
          options={manufacturerOptions}
          title="Manufacturer"
          onChange={(items) => setSelectedManufacturer(items as OptionList[])}
        />
      ),
    },
    {
      name: 'location',
      element: (
        <DropDownFilter
          name="location"
          placeholder="Location"
          multiValues={selectedBranches}
          options={branchOptions}
          title="Location"
          onChange={(items) => setSelectedBranches(items as OptionList[])}
        />
      ),
    },
    {
      name: 'tags',
      element: (
        <DropDownFilter
          name="tags"
          placeholder="Tags"
          multiValues={selectedTags}
          options={vehicleTagTypes}
          title="Tags"
          onChange={(items) => setSelectedTags(items as OptionList[])}
        />
      ),
    },
    {
      name: 'status',
      element: (
        <DropDownFilter
          name="status"
          placeholder="Status"
          options={vehicleStatuses}
          multiValues={selectedStatus}
          title="Status"
          onChange={(items) => setSelectedStatus(items as OptionList[])}
        />
      ),
    },
    {
      name: 'vehicle_source',
      element: (
        <DropDownFilter
          name="vehicle_source"
          placeholder="Vehicle source"
          options={vehicleSourceOptions}
          multiValues={selectedVehicleSource}
          title="Vehicle source"
          onChange={(items) => setSelectedVehicleSource(items as OptionList[])}
        />
      ),
    },
    {
      name: 'vehicle_condition',
      element: (
        <DropDownFilter
          name="vehicle_condition"
          placeholder="New or used"
          options={usedVehicleOptions}
          value={vehicleConditionSelected}
          singleSelection
          title="New or used"
          onChange={(item) => setVehicleConditionSelected(item as OptionList)}
        />
      ),
    },
    {
      name: 'mot_exp',
      element: (
        <div>
          <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
            MOT expiry
          </Text>
          <FilterInput type="date" {...register('mot_exp')} />
        </div>
      ),
    },
    {
      name: 'phv_exp',
      element: (
        <div>
          <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
            PHV expiry
          </Text>
          <FilterInput type="date" {...register('phv_exp')} />
        </div>
      ),
    },
  ];

  const handleGetVehiclesResponse = useCallback(
    (count: number, vehicles: Vehicle[]) => {
      const vehicleRows = vehicles?.map((vehicle: Vehicle) => {
        return {
          rowData: { data: vehicle },
          cells: [
            <TableTextCell value={vehicle?.vrm ?? '-'} />,
            <TableTextCell value={vehicle?.manufacturer ?? '-'} />,
            <TableTextCell value={vehicle?.color} />,
            <TableTextCell value={vehicle?.vehicle_location ?? '-'} />,
            <TableTagCell tags={[vehicle?.vehicle_status]} />,
            <TableTagCell tags={vehicle?.current_tag ? [vehicle?.current_tag] : null} />,
            <TableTextCell value={vehicle?.mot_exp ? moment(vehicle?.mot_exp).format('DD MMM YYYY') : '-'} />,
            <TableTextCell value={vehicle?.phv_exp ? moment(vehicle?.phv_exp).format('DD MMM YYYY') : '-'} />,
            <TableTextCell
              value={vehicle?.is_sec_immob === null ? 'Not installed' : vehicle?.is_sec_immob ? 'Active' : 'Inactive'}
            />,
            <ActionIcon
              icon={<BsEye onClick={() => navigate(`${VEHICLES}/${vehicle?.id}`)} size={24} color={PRIMARY_PURPLE} />}
              tooltip="View vehicle"
            />,
          ],
        };
      });
      setTableData(vehicleRows);
      setTotalRows(count);
    },
    [setTotalRows, navigate, setTableData]
  );

  const fetchVehicleList = useCallback(
    (queryString: string) => {
      const controller = new AbortController();
      getAllVehicles(queryString, controller.signal).then((response: { count: number; data: Vehicle[] }) => {
        handleGetVehiclesResponse(response.count, response.data);
      });
    },
    [handleGetVehiclesResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchVehicleList(queryString);
    },
    [fetchVehicleList, setTableData, goToPageNumber, tableFilters]
  );

  useEffect(() => {
    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      const branches: OptionList[] = response.data.map((branch: Branch) => {
        return { value: branch.branch_id, label: branch.branch_name };
      });
      setBranchOptions(branches);
    });
    const control = new AbortController();
    getVehicleTypesSelect(control.signal).then((response: { data: VehicleTypeSelectResponse }) => {
      const manufacturers: OptionList[] = response?.data?.manufacturers?.map((manufacturer) => {
        return {
          value: manufacturer?.value,
          label: manufacturer?.text,
        };
      });
      setManufacturerOptions(manufacturers);
    });
    setSortingColumn('created_date');
    setSortAscending(false);
    fetchVehicleList(defaultString);
  }, [defaultString, fetchVehicleList, setSortAscending, setSortingColumn]);

  useEffect(() => {
    setTableFilters([
      {
        columnName: 'manufacturer',
        options: selectedManufacturer,
        clause: '$in',
      },
      {
        columnName: 'branch_id',
        options: selectedBranches,
      },
      {
        columnName: 'current_tag',
        options: selectedTags,
      },
      {
        columnName: 'vehicle.vehicle_status',
        options: selectedStatus,
      },
      {
        columnName: 'vehicle.vehicle_source',
        options: selectedVehicleSource,
      },
      {
        columnName: 'mot_exp',
        options: { value: motExp ?? '', label: motExp ?? '' },
      },
      {
        columnName: 'phv_exp',
        options: { value: phvExp ?? '', label: phvExp ?? '' },
      },
      {
        columnName: 'used',
        options: vehicleConditionSelected,
      },
    ]);
  }, [
    setTableFilters,
    selectedStatus,
    selectedManufacturer,
    selectedVehicleSource,
    selectedBranches,
    selectedTags,
    vehicleConditionSelected,
    motExp,
    phvExp,
  ]);

  const onClearClick = useCallback(() => {
    setSelectedStatus([]);
    setSelectedVehicleSource([]);
    setSelectedManufacturer([]);
    setVehicleConditionSelected(usedVehicleOptions[0]);
    setSelectedBranches([]);
    reset({ mot_exp: '', phv_exp: '' });
  }, [reset]);

  return (
    <Table
      header="Vehicle list"
      onColumnHeaderClick={(columnId: string) => {
        applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId));
      }}
      sortAscending={sortAscending}
      columns={vehicleListColumns}
      rows={tableData}
      totalRows={totalRows}
      rowsPerPage={numRowsPerPage}
      currentPageNumber={pageNumber}
      sortingColumn={sortingColumn}
      filters={filters}
      onSearchChange={(value: string) => {
        setSearchString(value);
        applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
      }}
      downloadApi={exportAllVehicles}
      filterQuery={filterQuery}
      goToPage={(pageNumber: number) => {
        goToPageNumber(pageNumber);
        applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
      }}
      onRowClick={(vehicle: { data: Vehicle }) => navigate(`${VEHICLES}/${vehicle?.data?.id}`)}
      onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
      onClearClick={() => onClearClick()}
      onNumRowsPerPageChange={(value: number) => {
        setNumRowsPerPage(value);
        goToPageNumber(0);
        applyFilters(0, value, searchString, sortingColumn, sortAscending);
      }}
    />
  );
};
