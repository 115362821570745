import React from 'react';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';
import { TextField } from '../../../../uiComponents/inputs/textField/textField';
import { Text } from '../../../../uiComponents/text/text';
import { VehicleAgreementDetails } from '../../../../models/vehicle';
import { calculateRentalPlanSummary, calculateR2BPlanSummary } from '../../../../utils/vehicleFigures';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';

interface PlanSummaryProps {
  vehicleDetails: VehicleAgreementDetails;
  params: {
    carAndChargeBoolean: boolean;
    uberCleanAirAmount: number;
  };
  isRental: boolean;
  onClose: () => void;
}

export const PlanSummary = ({ vehicleDetails, params, isRental, onClose }: PlanSummaryProps) => {
  const formValues = isRental
    ? calculateRentalPlanSummary(vehicleDetails, params)
    : calculateR2BPlanSummary(vehicleDetails, params);

  const { register } = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: { ...formValues },
  });

  return (
    <>
      {!isRental && (
        <GridLayout template={3} gap={24}>
          <TextField disabled label="Number of weeks" type="text" {...register('agreement_length_weeks')} />
          <TextField disabled label="Initial contract value" type="text" {...register('total_contract_value')} />
          <TextField disabled label="Final contract value" type="text" {...register('final_contract_value')} />
        </GridLayout>
      )}
      {isRental ? (
        <div style={{ marginTop: 30 }}>
          <Text variant="body6" color={PRIMARY_PURPLE} weight={800} block>
            Car and change & Uber clean air
          </Text>
          <GridLayout styled={{ marginTop: 20 }} template={3} gap={24}>
            <TextField
              disabled
              label="Total car and charge Amount"
              type="text"
              {...register('total_car_and_charge_amount')}
            />
            <TextField
              disabled
              label="Total Uber clean air amount"
              type="text"
              {...register('total_uber_clean_air_amount')}
            />
          </GridLayout>
        </div>
      ) : (
        <>
          <div style={{ marginTop: 30 }}>
            <Text variant="body6" color={PRIMARY_PURPLE} weight={800} block>
              After car and charge package
            </Text>
            <GridLayout styled={{ marginTop: 20 }} template={3} gap={24}>
              <TextField
                disabled
                label="Total car and charge amount"
                type="text"
                {...register('total_car_and_charge_amount')}
              />
              <TextField
                disabled
                label="Total contract value + car and charge plan"
                type="text"
                {...register('total_contract_after_car_and_charge')}
              />
            </GridLayout>
          </div>
          <div style={{ marginTop: 30 }}>
            <Text variant="body6" color={PRIMARY_PURPLE} weight={800} block>
              After Uber clean air
            </Text>
            <GridLayout styled={{ marginTop: 20 }} template={3} gap={24}>
              <TextField
                disabled
                label="Total Uber clean air amount"
                type="text"
                {...register('total_uber_clean_air_amount')}
              />
              <TextField
                disabled
                label="Total contract calue - Uber clean air"
                type="text"
                {...register('total_contract_after_uber_clean_air')}
              />
            </GridLayout>
          </div>
        </>
      )}
      <div style={{ marginTop: 30 }}>
        <Text variant="body6" color={PRIMARY_PURPLE} weight={800} block>
          Weekly figures
        </Text>
        <GridLayout styled={{ marginTop: 20 }} template={3} gap={24}>
          <TextField disabled label="Headline weekly price" type="text" {...register('headline_weekly_price')} />
          <TextField
            disabled
            label="Weekly Uber clean air discount"
            type="text"
            {...register('uber_clean_air_discount')}
          />
          <TextField
            disabled
            label="Weekly rental - assist"
            type="text"
            {...register('weekly_rental_less_ev_assist')}
          />
        </GridLayout>
      </div>
      <FlexLayout itemsX="end" itemsY="end" styled={{ marginTop: 16 }}>
        <PrimaryButton styled={{ marginRight: '10px', padding: '0px 38px' }} onClick={() => onClose()}>
          Cancel
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
