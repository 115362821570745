import React, { useState } from 'react';
import moment from 'moment';
import { EditDriverDetailsForm } from '../editDriverDetailsForm/editDriverDetailsForm';
import { DrivereInfoProps } from '../driverPage/driverPage';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FieldGrid } from '../../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../../uiComponents/modals/modal';
import { Text } from '../../../../uiComponents/text/text';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { withStyledProps } from '../../../../utils/colorUtils';

interface DrivereDetailsProps extends DrivereInfoProps {
  fetchDrivereData: () => void;
  tableHeader: string;
}

export const DriverDetails = withStyledProps(
  ({ driver, tableHeader, fetchDrivereData, ...props }: DrivereDetailsProps) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [editDriver, setEditDriver] = useState<boolean>(false);
    return (
      <>
        <CollapsiblePanel
          header={
            <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
              {tableHeader}
            </Text>
          }
          onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
          expanded={!isCollapsed}
          {...props}
        >
          <div>
            <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
              {tableHeader}
            </Text>
            <FieldGrid
              numColumns={5}
              headers={[
                'Title:',
                'First name:',
                'Middle name:',
                'Last name:',
                'Date of birth:',
                'Mobile phone:',
                'Email:',
                'Address line 1:',
                'Address line 2:',
                'City:',
                'Postcode:',
              ]}
              values={[
                driver?.title ?? '-',
                driver?.first_name ?? '-',
                driver?.middle_name ?? '-',
                driver?.last_name ?? '-',
                moment(driver?.date_of_birth)?.format('DD MMM YYYY'),
                driver?.no_area_code && driver?.mobile_phone ? `${driver?.no_area_code}${driver?.mobile_phone}` : '-',
                driver?.email ?? '-',
                driver?.address_line_1 ?? '-',
                driver?.address_line_2 || '-',
                driver?.address_city ?? '-',
                driver?.address_post_code ?? '-',
              ]}
            />
            <FieldGrid
              numColumns={5}
              headers={[
                'DVLA number:',
                'DVLA expiry date:',
                'DVLA test date:',
                'PCO number:',
                'PCO expiry date:',
                'National insurance number:',
                'Signon source:',
                'Uber rating:',
                'Shell card number',
                'BP card number:',
              ]}
              values={[
                driver?.dvla_no ?? '-',
                driver?.dvla_expiry_date ? moment(driver?.dvla_expiry_date)?.format('DD MMM YYYY') : '-',
                driver?.dvla_test_date ? moment(driver?.dvla_test_date)?.format('DD MMM YYYY') : '-',
                driver?.pco_no ?? '-',
                driver?.pco_expiry_date ? moment(driver?.pco_expiry_date)?.format('DD MMM YYYY') : '-',
                driver?.national_insurance_number ?? '-',
                driver?.signon_source || '-',
                driver?.uber_rating?.toString() ?? '-',
                driver?.bp_card_id || '-',
                driver?.shell_card_id || '-',
              ]}
            />
            <FlexLayout styled={{ marginTop: '32px' }} gap={16} itemsX="end">
              <PrimaryButton onClick={() => setEditDriver(true)}>Edit driver details</PrimaryButton>
            </FlexLayout>
          </div>
        </CollapsiblePanel>
        <Modal
          title="Edit driver details"
          open={editDriver}
          onClose={() => {
            setEditDriver(false);
          }}
          showClose
          styled={{ width: '80vw' }}
        >
          <EditDriverDetailsForm
            driverData={driver}
            close={(refresh?: boolean): void => {
              if (refresh) {
                fetchDrivereData();
              }
              setEditDriver(false);
            }}
          />
        </Modal>
      </>
    );
  }
);
