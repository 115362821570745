import React, { useState, useEffect, useCallback, useContext } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { ImCross } from 'react-icons/im';
import moment from 'moment';
import { AnimatePresence } from 'framer-motion';
import { Container, StyledTextField, NotesHolder, NoteContent, Icon } from './notes.styles';
import { PrimaryButton } from '../buttons/primaryButton/primaryButton';
import { ConfirmationModal } from '../modals/confirmationModal/confirmationModal';
import { FlexLayout } from '../layouts/flexLayout/flexLayout';
import { Text } from '../text/text';
import { Notification } from '../toast/toast';
import { deleteNote as ctrlDeleteNote } from '../../api/delete/note.delete';
import { getAllNotes } from '../../api/get/note.get';
import { createNote } from '../../api/post/note.post';
import { Note } from '../../models/notes';
import { PRIMARY_PURPLE } from '../../common/styles/Colors';
import { APP_CONTEXT } from '../../utils/context';

interface NoteSubmitValues extends FieldValues {
  note_content: string;
}

export const Notes = ({ categoryId }: { categoryId: string }) => {
  const { superAdmin } = useContext(APP_CONTEXT);
  const [notes, setNotes] = useState<Note[]>([]);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);
  const [activeNoteId, setActiveNoteId] = useState<string>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NoteSubmitValues>({ mode: 'all', reValidateMode: 'onChange' });

  const fetchNotes = useCallback(async () => {
    const controller = new AbortController();
    setNotes([]);
    getAllNotes(`filter=category_id$eq=${categoryId}&sort=created_date:DESC`, controller.signal)
      .then(({ data }) => {
        return setNotes(data);
      })
      .catch(() => {});
    return () => {
      controller.abort();
    };
  }, [categoryId, setNotes]);

  const addNote = ({ note_content }: NoteSubmitValues) => {
    const payload = { note_content: note_content, category_id: categoryId };
    createNote(payload).then(() => {
      Notification({
        type: 'success',
        title: 'Success',
        message: 'Note created',
        isAlert: true,
      });
      reset({ note_content: '' });
      return fetchNotes();
    });
  };

  const deleteNote = (noteId: string) => {
    ctrlDeleteNote(noteId)
      .then(() => {
        fetchNotes();
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Note has been successfully deleted',
          isAlert: true,
        });
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setActiveNoteId(undefined);
      });
  };

  const handleDeleteNote = useCallback((id: string) => {
    if (!id) return;
    setIsDeleteConfirmationModalOpen(true);
    setActiveNoteId(id);
  }, []);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  return (
    <>
      <Container>
        <Text variant="h1" color={PRIMARY_PURPLE} weight={800} block>
          Notes
        </Text>
        <FlexLayout vertical gap={16}>
          <StyledTextField
            {...register('note_content')}
            error={errors.note_content}
            type="text"
            placeholder="Add note"
          />
          <FlexLayout itemsX="end">
            <PrimaryButton onClick={handleSubmit(addNote)}>Add note</PrimaryButton>
          </FlexLayout>
          <NotesHolder>
            <AnimatePresence>
              <FlexLayout vertical gap={8}>
                {notes.map((note, index) => {
                  return (
                    <NoteContent
                      key={index}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <FlexLayout vertical gap={8}>
                        <FlexLayout styled={{ paddingTop: '16px' }} itemsX="space-between">
                          <Text block>{moment(note.created_date).format('DD MMM YYYY')}</Text>
                          <Text block>
                            <div>By: {note?.employee_name}</div>
                          </Text>
                          {superAdmin && (
                            <Icon>
                              <ImCross
                                onClick={() => superAdmin && handleDeleteNote(note?.id)}
                                color={PRIMARY_PURPLE}
                              />
                            </Icon>
                          )}
                        </FlexLayout>
                        <Text block>{note.note_content}</Text>
                      </FlexLayout>
                    </NoteContent>
                  );
                })}
              </FlexLayout>
            </AnimatePresence>
          </NotesHolder>
        </FlexLayout>
      </Container>
      <ConfirmationModal
        title={'Are you sure you want to delete this note?'}
        isOpen={isDeleteConfirmationModalOpen}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        onConfirm={() => {
          deleteNote(activeNoteId!);
          setIsDeleteConfirmationModalOpen(false);
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
    </>
  );
};
