import { useCallback, useEffect, useState } from 'react';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { Text } from '../../../uiComponents/text/text';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { Table } from '../../../uiComponents/table/table';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { Transfer } from '../../../models/transfer';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableTextCell,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import moment from 'moment';
import { VehicleInfoProps } from '../vehiclePage/vehiclePage';
import { transferColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { getAllTransfers } from '../../../api/get/transfer.get';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { BsDownload } from 'react-icons/bs';

export const VehicleTransfers = ({ vehicle }: VehicleInfoProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();
  const vehicleId: string = vehicle?.vehicle_id;

  const downloadConditionReport = useCallback((row: Transfer) => {
    const { s3_url } = row;
    window.open(s3_url);
  }, []);

  const handleGetTransfersResponse = useCallback(
    (count: number, transfers: Transfer[]) => {
      const transferRows = transfers?.map((transfer: Transfer) => {
        return {
          rowData: { data: transfer },
          cells: [
            <TableTextCell value={transfer?.transfer_type ? transfer.transfer_type : ''} />,
            <TableTextCell value={transfer?.reason ?? '-'} />,
            <TableTextCell value={transfer?.in_date ? moment(transfer?.in_date)?.format('DD MMM YYYY') : '-'} />,
            <TableTextCell value={transfer?.out_date ? moment(transfer?.out_date)?.format('DD MMM YYYY') : '-'} />,
            <ActionIcon
              icon={<BsDownload size={20} color={PRIMARY_PURPLE} onClick={() => downloadConditionReport(transfer)} />}
              tooltip="Download"
            />,
          ],
        };
      });
      setTableData(transferRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData, downloadConditionReport]
  );

  const fetchTransferList = useCallback(
    (queryString: string) => {
      getAllTransfers(queryString).then((response: { count: number; data: Transfer[] }) => {
        handleGetTransfersResponse(response.count, response.data);
      });
    },
    [handleGetTransfersResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchTransferList(queryString);
    },
    [fetchTransferList, setTableData, goToPageNumber, tableFilters]
  );

  useEffect(() => {
    setSortingColumn('transfer_created_date');
    fetchTransferList(
      `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=transfer_created_date:ASC&filter=vehicle_id$eq=${vehicleId}`
    );
  }, [fetchTransferList, setSortingColumn, vehicleId]);

  useEffect(() => {
    setTableFilters([{ columnName: 'vehicle_id', options: { value: vehicleId, label: vehicleId } }]);
  }, [setTableFilters, vehicleId]);

  return (
    <CollapsiblePanel
      header={
        <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
          Transfer history
        </Text>
      }
      expanded={!isCollapsed}
      onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
      styled={{ marginTop: 16 }}
    >
      <div>
        <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
          Transfer history
        </Text>
        <Table
          variant="compact"
          tableTheme="purple"
          embedded
          header="Transfer history"
          onColumnHeaderClick={(columnId: string) =>
            applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
          }
          sortAscending={sortAscending}
          columns={transferColumns}
          rows={tableData}
          totalRows={totalRows}
          rowsPerPage={numRowsPerPage}
          currentPageNumber={pageNumber}
          sortingColumn={sortingColumn}
          filters={[]}
          onSearchChange={(value: string) => {
            setSearchString(value);
            applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
          }}
          filterQuery={filterQuery}
          goToPage={(pageNumber: number) => {
            goToPageNumber(pageNumber);
            applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }}
          onNumRowsPerPageChange={(value: number) => {
            setNumRowsPerPage(value);
            goToPageNumber(0);
            applyFilters(0, value, searchString, sortingColumn, sortAscending);
          }}
        />
      </div>
    </CollapsiblePanel>
  );
};
