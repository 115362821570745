import { Control, FieldValues, useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { FieldGrid } from '../../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { TextFieldLabel } from '../../../../uiComponents/inputs/textField/textField.styles';
import { DropDown } from '../../../../uiComponents/uiControls/dropDown/dropDown';
import { TextArea } from '../../../../uiComponents/inputs/textArea/textArea';
import { Table } from '../../../../uiComponents/table/table';
import { Servicing } from '../../../../models/servicing';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableTagCell,
  TableTextCell,
  getQueryString,
} from '../../../../uiComponents/table/tableUtils/tableUtils';
import { internalTransferTableColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { OptionList } from '../../../../utils/props';
import { getAllInternalTransfers } from '../../../../api/get/transfer.get';
import { CreateInternalTransfer, InternalTransferInfo, Transfer } from '../../../../models/transfer';
import { getAllBranches } from '../../../../api/get/branch.get';
import { Branch } from '../../../../models/branch';
import { createInternalTransfer } from '../../../../api/post/transfer.post';
import { Notification } from '../../../../uiComponents/toast/toast';

interface InternalTransferParams {
  rowData: Servicing | Transfer;
  close: (reload: boolean) => void;
}

export const InternalTransfer = ({ rowData, close }: InternalTransferParams) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateInternalTransfer>({
    defaultValues: {
      from_branch_id: rowData.current_branch_id ?? rowData.branch_id,
    },
    mode: 'all',
    reValidateMode: 'onSubmit',
  });
  const [branchOptions, setBranchOptions] = useState<OptionList[]>([]);
  const [currentFromBranchId, setCurrentFromBranchId] = useState<string | null | undefined>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const filterType = useCallback(() => {
    if ('transfer_id' in rowData) {
      return `transfer_id$eq=${rowData.transfer_id}`;
    } else {
      return `servicing_id$eq=${rowData.id} `;
    }
  }, [rowData]);

  const handleGetInternalTransferResponse = useCallback(
    (count: number, internalTransfers: InternalTransferInfo[]) => {
      const internalTransferRows = internalTransfers?.map((internalTransfer: InternalTransferInfo) => {
        return {
          rowData: { data: internalTransfer },
          cells: [
            <TableTextCell value={internalTransfer.transfer_type ?? '-'} />,
            <TableTextCell value={internalTransfer.from_branch_name ?? '-'} />,
            <TableTextCell value={internalTransfer.to_branch_name ?? '-'} />,
            <TableTextCell
              value={
                internalTransfer.internal_transfer_created_date
                  ? moment(internalTransfer.internal_transfer_created_date)?.format('DD MMM YYYY')
                  : '-'
              }
            />,
            <TableTextCell
              value={internalTransfer.in_date ? moment(internalTransfer.in_date)?.format('DD MMM YYYY') : '-'}
            />,
            <TableTextCell
              value={internalTransfer.out_date ? moment(internalTransfer.out_date)?.format('DD MMM YYYY') : '-'}
            />,
          ],
        };
      });
      setTableData(internalTransferRows);
      setTotalRows(count);
    },
    [setTableData, setTotalRows]
  );

  const fetchInternalTransferList = useCallback(
    (queryString: string) => {
      getAllInternalTransfers(queryString).then((response: { count: number; data: InternalTransferInfo[] }) => {
        handleGetInternalTransferResponse(response.count, response.data);
      });
    },
    [handleGetInternalTransferResponse]
  );

  useEffect(() => {
    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      const branches: OptionList[] = response.data.map((branch: Branch) => {
        return { value: branch.branch_id, label: branch.branch_name };
      });
      setBranchOptions(branches);
    });
    setSortingColumn('internal_transfer_created_date');
    fetchInternalTransferList(
      `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=internal_transfer_created_date:ASC&filter=${filterType()}`
    );
    setCurrentFromBranchId(
      'transfer_id' in rowData ? rowData.current_branch_id ?? rowData.branch_id : rowData.branch_id
    );
  }, [fetchInternalTransferList, filterType, rowData, setSortingColumn]);

  const onSubmit = async (submitValues: CreateInternalTransfer) => {
    setLoading(true);
    const payload: CreateInternalTransfer = {
      internal_transfer_type: 'transfer_id' in rowData ? 'TRANSFER' : 'SERVICING',
      from_branch_id: submitValues.from_branch_id,
      to_branch_id: submitValues.to_branch_id,
      notes: submitValues.notes ?? '',
    };
    if ('transfer_id' in rowData) {
      payload.transfer_id = rowData.transfer_id;
    } else {
      payload.servicing_id = rowData.id;
    }
    try {
      const { data } = await createInternalTransfer(payload);
      if (data) {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Internal transfer successfully created',
        });
      }
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Error',
        message: `${err}`,
      });
    } finally {
      setLoading(false);
      close(true);
    }
  };

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchInternalTransferList(`${filterType()}&${queryString}&filter`);
    },
    [setTableData, goToPageNumber, tableFilters, fetchInternalTransferList, filterType]
  );
  const statusTagCell = (status: string) => {
    return <TableTagCell tags={[status]} />;
  };
  return (
    <>
      <FieldGrid
        numColumns={4}
        headers={['VRM', 'Current location', 'User', 'Status']}
        values={[
          rowData?.vrm ?? '-',
          'transfer_id' in rowData
            ? rowData?.current_branch_name ?? rowData.check_in_branch_name ?? '-'
            : rowData?.current_branch_name ?? rowData?.branch_name ?? '-',
          rowData?.driver_name ?? '-',
          statusTagCell(
            'agreement_status' in rowData ? rowData?.agreement_status ?? '-' : rowData?.transfer_type ?? '-'
          ),
        ]}
      />
      <FlexLayout styled={{ margin: '15px 0' }} itemsX="start" gap={40}>
        <div>
          <TextFieldLabel $isRequired>Location from</TextFieldLabel>
          <DropDown
            styled={{ width: '30vw' }}
            disabled
            options={branchOptions}
            placeholder="Location from"
            name="from_branch_id"
            error={errors.from_branch_id}
            control={control as unknown as Control<FieldValues>}
          />
        </div>
        <div>
          <TextFieldLabel $isRequired>Location To</TextFieldLabel>
          <DropDown
            styled={{ width: '30vw' }}
            options={branchOptions}
            placeholder="Location to"
            name="to_branch_id"
            error={errors.to_branch_id}
            required={{
              required: 'To location is required',
              validate: (v) => (v !== currentFromBranchId ? true : 'Both Branch cannot be same'),
            }}
            control={control as unknown as Control<FieldValues>}
          />
        </div>
      </FlexLayout>
      <TextArea label="Notes" {...register('notes')} styled={{ maxHeight: 100 }} placeholder="Type here" />
      <Table
        variant="compact"
        tableTheme="purple"
        header="Internal transfer history"
        styled={{ marginTop: 24 }}
        onColumnHeaderClick={(columnId: string) => {
          applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId));
        }}
        embedded
        columns={internalTransferTableColumns}
        rows={tableData}
        sortAscending={sortAscending}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={[]}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        }}
        filterQuery={filterQuery}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(0, value, searchString, sortingColumn, sortAscending);
        }}
      />
      <FlexLayout styled={{ marginTop: '15px' }} itemsY="end" itemsX="end">
        <PrimaryButton isProcessing={loading} onClick={handleSubmit(onSubmit)}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
