import React, { useState } from 'react';
import { BsClipboard } from 'react-icons/bs';
import { FiEye } from 'react-icons/fi';
import { CollapsiblePanel } from '../../../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../../../uiComponents/layouts/gridLayout/gridLayout';
import { Text } from '../../../../../../uiComponents/text/text';
import { PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../../../../common/styles/Colors';
import { Modal } from '../../../../../../uiComponents/modals/modal';
import { DamageReportForm } from '../damageReportForm/damageReportForm';
import { DamageReportItem, DamageResolutionItem } from '../../../../../../consts/conditionReport';
import {
  BackgroundImage,
  FakeButtonSection,
  ImageContainer,
  ImageContainerSection,
  TextBox,
  TextLabelPurple,
} from './damageLogReport.styles';
import { TextFieldLabel } from '../../../../../../uiComponents/inputs/textField/textField.styles';
import { damageTypeOptions } from '../../../../../../consts/vehicle';
import { PrimaryButton } from '../../../../../../uiComponents/buttons/primaryButton/primaryButton';
import { DamageResolutionForm } from '../damageResolutionForm/damageResolutionForm';

interface DamageLogReportInfo {
  values: DamageReportItem;
  onSubmit: (submitValues: DamageReportItem) => void;
  onResolutionSubmit: (submitValues: DamageResolutionItem) => void;
}

export const DamageLogReport = ({ values, onSubmit, onResolutionSubmit }: DamageLogReportInfo) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [openDamageLogModal, setOpenDamageLogModal] = useState<boolean>(false);
  const [openDamageImageModal, setOpenDamageImageModal] = useState<boolean>(false);
  const [openDamageResolutionFormModal, setOpenDamageResolutionFormModal] = useState<boolean>(false);
  const [viewImage, setViewImage] = useState<string>('');

  const onFormSubmit = (submitValues: DamageReportItem) => {
    onSubmit(submitValues);
    setOpenDamageLogModal(false);
  };

  const onFormResolutionSubmit = (submitValues: DamageResolutionItem) => {
    setOpenDamageResolutionFormModal(false);
    onResolutionSubmit(submitValues);
  };

  function getTypeOptionValue(val: string) {
    return damageTypeOptions.find((item) => item.value === val)?.label;
  }

  function view(photo: File | Blob) {
    setViewImage(URL.createObjectURL(photo));
    setOpenDamageImageModal(true);
  }

  return (
    <>
      <CollapsiblePanel
        header={
          <FlexLayout gap={16} itemsY="center">
            <BsClipboard size={32} color={PRIMARY_PURPLE} />
            <div>
              <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
                {`${values.name} - ${values.description}`}
              </Text>
            </div>
          </FlexLayout>
        }
        expanded={!isCollapsed}
        onCollapse={() => setIsCollapsed(!isCollapsed)}
        styled={{ marginTop: 16 }}
      >
        <div>
          <FlexLayout gap={16} itemsY="center">
            <BsClipboard size={32} color={PRIMARY_PURPLE} />
            <div>
              <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
                {`${values.name} - ${values.description}`}
              </Text>
            </div>
          </FlexLayout>
          <GridLayout template={2} gap={27} styled={{ marginTop: '25px' }}>
            <div>
              <TextFieldLabel $isRequired={true}>Damaged type</TextFieldLabel>
              <TextBox>{getTypeOptionValue(values?.type)}</TextBox>
            </div>
            <div />
            <div>
              <TextFieldLabel $isRequired={true}>Damaged area name</TextFieldLabel>
              <TextBox>{values?.name}</TextBox>
            </div>
            <div>
              <TextFieldLabel $isRequired={true}>Damage description</TextFieldLabel>
              <TextBox>{values?.description}</TextBox>
            </div>
            <div>
              <TextFieldLabel>Add photos</TextFieldLabel>
              <GridLayout template={10} gap={8}>
                {values.photos?.map((photo, index) => {
                  return (
                    <ImageContainerSection key={index}>
                      <ImageContainer>
                        <BackgroundImage draggable="false" alt="image" src={URL.createObjectURL(photo)} />
                      </ImageContainer>
                      <TextLabelPurple>{(photo as File)?.name}</TextLabelPurple>
                      <FakeButtonSection onClick={() => view(photo)}>
                        <FiEye size={26} color={PRIMARY_WHITE} />
                      </FakeButtonSection>
                    </ImageContainerSection>
                  );
                })}
              </GridLayout>
            </div>
            <div>
              <TextFieldLabel $isRequired={true}>Damage notes</TextFieldLabel>
              <TextBox $textArea>{values?.details}</TextBox>
            </div>
            <div />
            <FlexLayout gap={16} itemsX="end">
              <PrimaryButton onClick={() => setOpenDamageResolutionFormModal(true)}>Resolve damage</PrimaryButton>
              <PrimaryButton onClick={() => setOpenDamageLogModal(true)}>Edit</PrimaryButton>
            </FlexLayout>
          </GridLayout>
        </div>
      </CollapsiblePanel>
      <Modal
        title="Damage report"
        open={openDamageImageModal}
        onClose={() => {
          setOpenDamageImageModal(false);
        }}
        showClose={true}
        showOverflow={false}
        styled="60vw"
      >
        <BackgroundImage $view draggable="false" alt="image" src={viewImage} />
      </Modal>
      <Modal
        title="Damage report"
        open={openDamageLogModal}
        onClose={() => {
          setOpenDamageLogModal(false);
        }}
        showClose={true}
        showOverflow={false}
        styled="60vw"
      >
        <DamageReportForm
          values={values}
          onClose={() => {
            setOpenDamageLogModal(false);
          }}
          onSubmit={onFormSubmit}
        />
      </Modal>
      <Modal
        title="Damage resolution form"
        open={openDamageResolutionFormModal}
        onClose={() => {
          setOpenDamageResolutionFormModal(false);
        }}
        showClose={true}
        showOverflow={false}
        styled="60vw"
      >
        <DamageResolutionForm
          onClose={() => {
            setOpenDamageResolutionFormModal(false);
          }}
          onSubmit={onFormResolutionSubmit}
        />
      </Modal>
    </>
  );
};
