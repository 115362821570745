import { BsPlusCircleFill } from 'react-icons/bs';
import { PrimaryButton } from '../../buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { Text } from '../../text/text';
import { PRIMARY_GREEN, PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../common/styles/Colors';

interface TableHeaderProps {
  tableHeaderTitle: string;
  actionButtonText?: string;
  onButtonClick?: () => void;
}

export const TableHeader = ({ tableHeaderTitle, actionButtonText, onButtonClick }: TableHeaderProps) => {
  return (
    <FlexLayout itemsX="space-between" itemsY="center">
      <Text variant="h4" weight={800} color={PRIMARY_PURPLE} block>
        {tableHeaderTitle}
      </Text>
      {actionButtonText && (
        <PrimaryButton onClick={() => onButtonClick?.()}>
          <FlexLayout gap={8}>
            <BsPlusCircleFill color={PRIMARY_GREEN} size={16} />
            <Text variant="body7" color={PRIMARY_WHITE} weight={500}>
              {actionButtonText}
            </Text>
          </FlexLayout>
        </PrimaryButton>
      )}
    </FlexLayout>
  );
};
