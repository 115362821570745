import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// Filters and headers in common folders as used in both agreement lists
import { APP_CONTEXT } from '../../utils/context';
import { OptionList } from '../../utils/props';
import { UpcomingAftercare } from '../../models/aftercare';
import { useTableFilters } from '../../hooks/useTableFilters';
import { getAllUpcomingAgreements } from '../../api/get/aftercare.get';
import {
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../uiComponents/table/tableUtils/tableUtils';
import { upcomingAftercareColumns } from '../../uiComponents/table/tableColumns/tableColumns';
import { useNavigate } from 'react-router-dom';
import { ActionIcon } from '../../uiComponents/table/actionIcon/actionIcon';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { PRIMARY_GRAY, PRIMARY_GREEN } from '../../common/styles/Colors';
import { BsCheckCircle, BsCircle, BsEye } from 'react-icons/bs';
import { Table } from '../../uiComponents/table/table';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../uiComponents/table/tableFilters/tableFilters';
import moment from 'moment';
import { AGREEMENTS } from '../../consts/routes';
import { ConfirmationModal } from '../../uiComponents/modals/confirmationModal/confirmationModal';
import { updateAgreement } from '../../api/patch/agreement.patch';
import { Notification } from '../../uiComponents/toast/toast';
import { agreementTypes } from '../../uiComponents/table/tableFilters/tableFilterOptions';
import { useDateRangeFilter } from '../../hooks/useDateRangeFilter';

const defaultQuery = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=first_name:ASC`;
export const UpcomingAftercareList = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [isAftercareEligibilityModalOpen, setIsAftercareEligibilityModalOpen] = useState(false);
  const [activeAftercare, setActiveAftercare] = useState<UpcomingAftercare>();
  const [agreementTypeFilter, setAgreementTypeFilter] = useState<OptionList[]>([]);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const navigate = useNavigate();

  const {
    setTableData,
    setSortingColumn,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    setTableFilters,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  useEffect(() => {
    setActiveSideNav('aftercareListPage');
    setPageTitle('Aftercare');
    setSortingColumn('end_date');
  }, [setActiveSideNav, setPageTitle, setSortingColumn]);

  useEffect(() => {
    setTableFilters([
      { columnName: 'agreement_type', options: agreementTypeFilter },
      {
        columnName: 'agreement.end_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [setTableFilters, dateRangeFilter, agreementTypeFilter]);

  const handleGetUpcomingAftercareResponse = useCallback(
    (count: number, data: UpcomingAftercare[]) => {
      const aftercareRows = data?.map((aftercareItem: UpcomingAftercare) => {
        return {
          rowData: { data: aftercareItem },
          cells: [
            <TableTextCell value={aftercareItem?.driver_name} />,
            <TableTextCell value={aftercareItem?.vehicle_name} />,
            <TableTextCell value={aftercareItem?.vrm} />,
            <TableTextCell value={moment(aftercareItem?.end_date).format('DD MMM YYYY')} />,
            <TableTextCell value={aftercareItem?.agreement_type} />,
            <TableTextCell value={aftercareItem?.remaining_balance ? `£${aftercareItem?.remaining_balance}` : '-'} />,
            <FlexLayout gap={16}>
              <ActionIcon
                icon={
                  <BsEye
                    onClick={() => navigate(`${AGREEMENTS}/${aftercareItem?.id}`)}
                    size={24}
                    color={PRIMARY_GRAY}
                  />
                }
                tooltip="View agreement page"
              />

              <ActionIcon
                icon={
                  aftercareItem.aftercare_eligible ? (
                    <BsCheckCircle
                      onClick={() => {
                        setActiveAftercare(aftercareItem);
                        setIsAftercareEligibilityModalOpen(true);
                      }}
                      size={24}
                      color={PRIMARY_GREEN}
                    />
                  ) : (
                    <BsCircle
                      onClick={() => {
                        setActiveAftercare(aftercareItem);
                        setIsAftercareEligibilityModalOpen(true);
                      }}
                      size={24}
                      color={PRIMARY_GREEN}
                    />
                  )
                }
                tooltip="Aftercare eligibility"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(aftercareRows);
      setTotalRows(count);
    },
    [setTableData, setTotalRows, navigate]
  );

  const fetchData = useCallback(
    async (queryString: string) => {
      const controller = new AbortController();

      getAllUpcomingAgreements(queryString, controller.signal).then(({ data, count }) => {
        handleGetUpcomingAftercareResponse(count, data);
      });
    },
    [handleGetUpcomingAftercareResponse]
  );

  const filters: FilterItem[] = [
    {
      name: 'agreement_type',
      element: (
        <DropDownFilter
          name="agreement_type"
          placeholder="Select agreement type"
          options={agreementTypes}
          multiValues={agreementTypeFilter}
          title="Plan"
          onChange={(items) => setAgreementTypeFilter(items as OptionList[])}
        />
      ),
    },
    {
      name: 'date-range',
      element: (
        <DateRangeFilter
          title="Agreement completion"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const aftercareColumns = useMemo(() => [...upcomingAftercareColumns], []);

  useEffect(() => {
    fetchData(defaultQuery);
    setSortingColumn('first_name');
  }, [fetchData, setSortingColumn]);

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchData(queryString);
    },
    [fetchData, setTableData, goToPageNumber, tableFilters]
  );

  const onClearClick = useCallback(() => {
    setAgreementTypeFilter([]);
    setDateRangeFilter([]);
  }, [setDateRangeFilter]);

  return (
    <>
      <Table
        header="Agreements nearing completion"
        onColumnHeaderClick={(columnId: string) => {
          applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId));
        }}
        disableApply={invalidDates}
        sortAscending={sortAscending}
        columns={aftercareColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onRowClick={({ data }: { data: UpcomingAftercare }) => navigate(`${AGREEMENTS}/${data?.id}`)}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
        }}
      />
      {activeAftercare && (
        <ConfirmationModal
          title={'Are you sure you want to update eligibility?'}
          isOpen={isAftercareEligibilityModalOpen}
          onClose={() => setIsAftercareEligibilityModalOpen(false)}
          onConfirm={() => {
            updateAgreement(activeAftercare?.id, {
              aftercare_eligible: !activeAftercare?.aftercare_eligible,
            })
              .then(() => {
                Notification({
                  type: 'success',
                  title: 'Success',
                  message: 'Aftercare eligibility successfully updated.',
                  isAlert: true,
                });
              })
              .catch((e: Error) => {
                Notification({
                  type: 'error',
                  title: 'Error',
                  message: e.message,
                  isAlert: true,
                });
              });
            setIsAftercareEligibilityModalOpen(false);
            fetchData(defaultQuery);
          }}
          closeButtonCaption="Cancel"
          confirmButtonCaption="Confirm"
        />
      )}
    </>
  );
};
