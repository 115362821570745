// Driver validation
import moment from 'moment';

export const dvlaFormat =
  /^([A-Z]{2}[9]{3}|[A-Z]{3}[9]{2}|[A-Z]{4}[9]{1}|[A-Z]{5})[0-9]{6}([A-Z]{1}[9]{1}|[A-Z]{2})[A-Z0-9]{3}$/i;

export const letterFormat = /^([A-Z]+[- ]?)+$/i;

export const branchNameFormat = /^[a-zA-Z0-9'"\-\s]+$/i;

export const emailFormat = /^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

export const mobileNumberFormat = /^\d{10}$/;

const today = moment();

const validAge = today.add(-25, 'years');

export const validAgeDateFormat = moment(validAge).format('YYYY-MM-DD');

export const nationalInsurance = /^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]?\s*$/; // /^((?!BG|GB|KN|NK|NT|TN|ZZ|[A-Z]?[DFIQUV])[A-Z][A-NP-Z])[0-9]{6}[ABCD]$/i;

export const pcoFormat = /^([0-9]{5,11})+$/i;

export const addressFormat = /^(?!\s*$).+/;

export const bpNumberFormat = /^[F1]{1}[0-9]*$/;

export const shellNumberFormat = /^[0][8]{1}[0-9]*$/;

// UK VRM number plate validation
export const VRMRegex = /^[a-zA-Z0-9]+$/;

// Letters and numbers with spaces validation
export const LetterNumberRegex = /^[a-zA-Z0-9 ]+$/;

// date & time validation
export const dateTimeRegex =
  /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2} (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;

// numbers only regex
export const numberRegex = /^[0-9]+$/;

// numbers without leading zeros
export const numberWithoutLeadingZeros = /^([1-9][0-9]{0,6})$/;

// decimals only regex
export const decimalRegex = /^\d*\.?\d*$/;

// positive decimals only regex
export const positiveDecimalRegex = /^(0*[1-9]\d{0,9}(\.\d{0,2})?0*|0*\.([1-9]|\d[1-9]|[1-9]\d)0*)$/;

// uk post code format
export const ukPostCode =
  /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) ?[0-9][A-Z]{2}$/;

// uk post code format
export const ukPostCodeFormat = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/;

// VIN number validation
export const VINRegex = /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{13,17}$/;

// Hammersmith is stored in DB as Sussex Place
export const branchNameRegex = /\b(?!Hammersmith\b)\w+/gi;

export const scoreFormat = /^(\d{1,2}|100)$/;

export const validateDateFormat = (value: string): string | boolean => {
  const inputDate: Date = new Date(value);
  const year: string = inputDate.getFullYear().toString();
  if (year.length !== 4) {
    return 'Year has an incorrect format';
  }
  return true;
};

// Date format validation
export const validateFutureDateFormat = (value: string, isTodayAndFuture: boolean = false): string | boolean => {
  const inputDate: Date = new Date(value);

  // Check if the value is a valid date
  if (isNaN(inputDate.getTime())) {
    return 'Invalid date format';
  }

  // Check if the year is in the correct format (4 digits)
  const year: string = inputDate.getFullYear().toString();
  if (year.length !== 4) {
    return 'Year has an incorrect format';
  }

  const today: Date = new Date();
  today.setHours(0, 0, 0, 0); // Set hours to the beginning of the day for comparison

  if (isTodayAndFuture) {
    return inputDate >= today ? true : 'Date should be today or in the future';
  }

  return inputDate > today ? true : 'Date should be in the future';
};

// Check if start date is before end date
export const validateStartBeforeEndDate = (startDate: string, endDate: string): boolean | string => {
  if (startDate && endDate) {
    const startDateTime: Date = new Date(startDate);
    const endDateTime: Date = new Date(endDate);
    startDateTime.setHours(0, 0, 0, 0);
    endDateTime.setHours(0, 0, 0, 0);
    return startDateTime < endDateTime || 'Start date should be before end date';
  }
  return true;
};

export const validateUberRating = (value: string | number | undefined) => {
  const errMsg = 'Please enter a valid Uber rating between 0.00 and 5.00';

  // Check if the input is within the valid range (0.00 to 5.00) and has at most 2 decimal places
  const rating = parseFloat(value as string);
  let decimalCount;
  if (value) {
    decimalCount = value.toString().split('.')[1]?.length || 0;
  } else {
    return true;
  }
  if (isNaN(rating) || rating < 0.0 || rating > 5.0 || (decimalCount && decimalCount > 2)) {
    return errMsg;
  }

  return true;
};

export const handleIntegerRangeError = (value: string | number | undefined) => {
  const errMsg = 'Please provide a numerical value with a max length of 10 digits';

  // Check str length to avoid 'value is out of range for type integer' SQL error
  if (value && value.toString().length > 10) {
    return errMsg;
  }

  return true;
};

export const handleMileageRangeError = (value: string | number | undefined, previousMileage: number) => {
  const errMsg = 'Please provide a numerical value with a max length of 10 digits';

  if (value === undefined) {
    return errMsg;
  }

  // Check str length to avoid 'value is out of range for type integer' SQL error
  if (value.toString().length > 10) {
    return errMsg;
  }

  if (Number(value) < previousMileage) {
    return 'Mileage cannot be lower than previous transfer mileage';
  }

  return true;
};
