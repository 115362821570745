// DEPENDENCIES
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { BsDownload, BsFilter } from 'react-icons/bs';
import { FieldValues } from 'react-hook-form';
// COMPONENTS & STYLES
import { NoResults } from './noResults/noResults';
import { FilterItem, TableFilters } from './tableFilters/tableFilters';
import { PrimaryButton } from '../buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../layouts/flexLayout/flexLayout';
import { Spinner } from '../uiControls/spinner/spinner';
import { Text } from '../text/text';
import { TextInput } from '../inputs/textInput/textInput';
import {
  ColumnHeader,
  ExpandedRow,
  FirstPageIcon,
  HeaderArea,
  LastPageIcon,
  Loading,
  NextPageIcon,
  OverflowMask,
  PreviousPageIcon,
  RowArrow,
  RowsContainer,
  SearchFilters,
  SearchIcon,
  SelectRowsPerPage,
  StickyHeader,
  StickyHeaderMask,
  TableActions,
  TableContainer,
  TableFooter,
  TableHeaders,
  TableRowGrid,
} from './table.styles';
// HOOKS & UTILS & COMMONS
import { downloadXLSX } from './tableUtils/tableUtils';
import { CONGESTION_CHARGE, DART_CHARGES, EXCESS_MILEAGE } from '../../consts/additionalCharges';
import {
  PRIMARY_GREEN,
  PRIMARY_PURPLE,
  SECONDARY_GRAY_20,
  SECONDARY_PURPLE_30,
  SECONDARY_PURPLE_70,
  STATUS_RED,
} from '../../common/styles/Colors';
import { AnyNotNull, withStyledProps } from '../../utils/colorUtils';
import { ConfirmationModal } from '../modals/confirmationModal/confirmationModal';
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';

interface TableColumn {
  id: string;
  name: string;
  sortable: boolean;
}

interface TableRow {
  rowData: { rowColour?: string; data: FieldValues };
  cells: JSX.Element[];
}

type DownloadMethod = 'email' | 'download';
export type TableVariant = 'standard' | 'compact';

interface TableProps {
  header?: string | JSX.Element;
  filters?: FilterItem[];
  columns: TableColumn[];
  rows?: TableRow[];
  totalRows: number;
  filterQuery: string;
  rowsPerPage: number;
  currentPageNumber: number;
  sortAscending: boolean;
  sortingColumn?: string;
  disableApply?: boolean;
  downloadName?: string;
  variant?: TableVariant;
  tableTheme?: 'white' | 'purple';
  embedded?: boolean;
  getTableRowData?: (id: string) => Promise<JSX.Element>;
  onRowClick?: (row: any) => void;
  goToPage: (pageNumber: number) => void;
  dataDownloadMethod?: DownloadMethod;
  downloadApi?: (query: string) => Promise<{ count?: number; data: boolean | AnyNotNull }>;
  onSearchChange?: (value: string) => void;
  onApplyClick?: () => void;
  onClearClick?: () => void;
  onColumnHeaderClick: (columnName: string) => void;
  onNumRowsPerPageChange: (value: number) => void;
  onPrimaryBtnClick?: () => void;
  onSecondaryBtnClick?: () => void;
  onTertiaryBtnClick?: () => void;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  tertiaryBtnText?: string;
  disableTertiaryBtn?: boolean;
}

export const Table = withStyledProps(
  ({
    header,
    columns,
    rows,
    totalRows,
    filters,
    filterQuery,
    rowsPerPage,
    currentPageNumber,
    sortAscending,
    sortingColumn,
    disableApply,
    dataDownloadMethod,
    downloadName,
    variant,
    tableTheme,
    embedded,
    getTableRowData,
    onRowClick,
    goToPage,
    downloadApi,
    onSearchChange,
    onApplyClick,
    onClearClick,
    onColumnHeaderClick,
    onNumRowsPerPageChange,
    onPrimaryBtnClick,
    onSecondaryBtnClick,
    onTertiaryBtnClick,
    primaryBtnText,
    secondaryBtnText,
    tertiaryBtnText,
    disableTertiaryBtn,
    ...props
  }: TableProps) => {
    const tableType: TableVariant = variant ?? 'standard';
    const onFirstPage: boolean = currentPageNumber === 0;
    const lastPageNumber: number = Math.ceil(totalRows / rowsPerPage) - 1;
    const onLastPage: boolean = currentPageNumber === lastPageNumber;
    const firstRowNumberInView: number = currentPageNumber * rowsPerPage;
    const totalRowsInView: number = firstRowNumberInView + rowsPerPage;
    const lastRowNumberInView: number = totalRowsInView > totalRows ? totalRows : totalRowsInView;
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [confirmationModal, setConfirmationModal] = useState<JSX.Element>();

    const onDownloadClick = useCallback(() => {
      if (dataDownloadMethod === 'download') {
        setIsDownloading(true);
        downloadApi?.(filterQuery).then((response: { count?: number; data: any }) => {
          downloadXLSX(response.data, downloadName ?? 'new download');
          setIsDownloading(false);
        });
      } else {
        setIsDownloading(true);
        downloadApi?.(filterQuery)
          .then(() => {
            setConfirmationModal(
              <ConfirmationModal
                isOpen
                icon={<FaRegCheckCircle size={200} color={PRIMARY_GREEN} />}
                title="We emailed the export to your inbox."
                confirmButtonCaption="OK"
                onConfirm={() => setConfirmationModal(undefined)}
              />
            );
            setIsDownloading(false);
          })
          .catch(() => {
            setConfirmationModal(
              <ConfirmationModal
                isOpen
                icon={<FaRegTimesCircle size={200} color={STATUS_RED} />}
                title="There was a problem sending you the export."
                confirmButtonCaption="OK"
                onConfirm={() => setConfirmationModal(undefined)}
              />
            );
            setIsDownloading(false);
          });
      }
    }, [filterQuery, dataDownloadMethod, downloadName, downloadApi]);

    return (
      <>
        <TableContainer $tableType={tableType} {...props}>
          {!embedded && <StickyHeaderMask></StickyHeaderMask>}
          <StickyHeader $stick={!embedded} $theme={tableTheme}>
            {tableType === 'standard' && (
              <>
                <HeaderArea>
                  {header}
                  {header &&
                    typeof header === 'string' &&
                    [CONGESTION_CHARGE, DART_CHARGES, EXCESS_MILEAGE].includes(header) && (
                      <FlexLayout styled={{ marginTop: 32 }} itemsX="space-between">
                        <div>
                          <SecondaryButton
                            styled={{ marginRight: 8 }}
                            onClick={() => onPrimaryBtnClick && onPrimaryBtnClick()}
                          >
                            {primaryBtnText}
                          </SecondaryButton>
                          <PrimaryButton onClick={() => onSecondaryBtnClick && onSecondaryBtnClick()}>
                            {secondaryBtnText}
                          </PrimaryButton>
                        </div>
                        <PrimaryButton
                          onClick={() => onTertiaryBtnClick && onTertiaryBtnClick()}
                          disabled={disableTertiaryBtn}
                        >
                          {tertiaryBtnText}
                        </PrimaryButton>
                      </FlexLayout>
                    )}
                  <SearchFilters itemsX="space-between" itemsY="center">
                    {onSearchChange && (
                      <div>
                        <SearchIcon size={16} color={SECONDARY_PURPLE_70} />
                        <TextInput
                          onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            onSearchChange?.(e.target.value);
                          }}
                          placeholder="Search"
                        />
                      </div>
                    )}
                    <FlexLayout gap={16} styled={{ postion: 'relative' }}>
                      {filters && filters?.length > 0 && (
                        <TableActions gap={8} onClick={() => setShowFilters(!showFilters)}>
                          <BsFilter size={16} color={SECONDARY_PURPLE_70} />
                          <Text variant="body7" color={SECONDARY_PURPLE_70} weight={300}>
                            Filter
                          </Text>
                        </TableActions>
                      )}
                      {downloadApi && (
                        <TableActions gap={8} onClick={() => (isDownloading ? null : onDownloadClick())}>
                          {isDownloading ? (
                            <Spinner size={16} color={SECONDARY_PURPLE_70} />
                          ) : (
                            <BsDownload size={16} color={SECONDARY_PURPLE_70} />
                          )}
                          <Text variant="body7" color={SECONDARY_PURPLE_70} weight={300}>
                            Download
                          </Text>
                        </TableActions>
                      )}
                      <TableFilters
                        hidden={!showFilters}
                        filters={filters ?? []}
                        disabledApply={disableApply}
                        onApplyClick={() => {
                          onApplyClick?.();
                          setShowFilters(false);
                        }}
                        onClearClick={() => onClearClick?.()}
                        onCloseClick={() => setShowFilters(false)}
                      />
                    </FlexLayout>
                  </SearchFilters>
                </HeaderArea>
              </>
            )}
            <TableHeaders $tableType={tableType} gap={8} template={columns.length}>
              {columns?.map((column: TableColumn, index: number) => (
                <ColumnHeader
                  key={`${column.id}_${index}`}
                  $isSortable={column.sortable}
                  onClick={() => (column.sortable ? onColumnHeaderClick(column.id) : null)}
                  itemsY="center"
                >
                  <Text
                    key={column.id}
                    color={tableTheme === 'purple' || embedded ? SECONDARY_PURPLE_70 : PRIMARY_PURPLE}
                    variant="body7"
                    weight={500}
                  >
                    {column?.name}
                  </Text>
                  {column.sortable && sortingColumn === column.id && (
                    <>
                      {sortAscending ? (
                        <IoIosArrowUp
                          size={16}
                          color={tableTheme === 'purple' || embedded ? SECONDARY_PURPLE_70 : PRIMARY_PURPLE}
                        />
                      ) : (
                        <IoIosArrowDown
                          size={16}
                          color={tableTheme === 'purple' || embedded ? SECONDARY_PURPLE_70 : PRIMARY_PURPLE}
                        />
                      )}
                    </>
                  )}
                </ColumnHeader>
              ))}
            </TableHeaders>
          </StickyHeader>
          <RowsContainer $theme={tableTheme}>
            {rows ? (
              rows?.map((row: TableRow, index: number) => (
                <TableRow
                  key={`${row?.rowData?.data?.id}_${index}` ?? `${row?.rowData?.toString()}_${index}`}
                  row={row}
                  rowColour={tableTheme === 'purple' || embedded ? SECONDARY_PURPLE_30 : SECONDARY_GRAY_20}
                  onRowClick={onRowClick ? (data: AnyNotNull) => onRowClick?.(data) : undefined}
                  getTableRowExpandedData={getTableRowData}
                />
              ))
            ) : (
              <Loading itemsX="center">
                <Spinner size={24} color={PRIMARY_PURPLE} />
              </Loading>
            )}
            {rows?.length === 0 && <NoResults />}
          </RowsContainer>
          <TableFooter
            $theme={tableTheme}
            $embedded={embedded}
            $tableType={tableType}
            itemsX="space-between"
            itemsY="center"
          >
            <FlexLayout itemsY="center" gap={8}>
              <Text variant="body8" color={PRIMARY_PURPLE} weight={300}>
                Rows per page
              </Text>
              <SelectRowsPerPage
                $tableType={tableType}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onNumRowsPerPageChange(+e.target.value)}
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </SelectRowsPerPage>
            </FlexLayout>
            <FlexLayout itemsX="space-between" itemsY="center">
              {rows && rows?.length > 0 && (
                <>
                  <Text styled={{ paddingRight: 20 }} variant="body8" color={PRIMARY_PURPLE} weight={300}>
                    {`Showing ${firstRowNumberInView + 1} - ${lastRowNumberInView} of ${totalRows}`}
                  </Text>
                  <FirstPageIcon $disabled={onFirstPage} onClick={() => (onFirstPage ? null : goToPage(0))} size={20} />
                  <PreviousPageIcon
                    $disabled={onFirstPage}
                    onClick={() => (onFirstPage ? null : goToPage(currentPageNumber - 1))}
                    size={20}
                  />
                  <NextPageIcon
                    $disabled={onLastPage}
                    onClick={() => (onLastPage ? null : goToPage(currentPageNumber + 1))}
                    size={20}
                  />
                  <LastPageIcon
                    $disabled={onLastPage}
                    onClick={() => (onLastPage ? null : goToPage(lastPageNumber))}
                    size={20}
                  />
                </>
              )}
            </FlexLayout>
          </TableFooter>
        </TableContainer>
        {!embedded && <OverflowMask $tableType={tableType} />}
        {confirmationModal}
      </>
    );
  }
);

interface TableRowProps {
  row: TableRow;
  rowColour: string;
  getTableRowExpandedData?: (id: string) => Promise<JSX.Element>;
  onRowClick?: (data: AnyNotNull) => void | null;
}

const TableRow = ({ row, rowColour, getTableRowExpandedData, onRowClick }: TableRowProps) => {
  const [rowExpanded, setRowExpanded] = useState<boolean>(false);
  const [expandedContent, setExpandedContent] = useState<JSX.Element>();

  const handleExpandableRowClick = () => {
    setRowExpanded(!rowExpanded);
  };

  useEffect(() => {
    if (rowExpanded) {
      getTableRowExpandedData?.(row?.rowData?.data?.id).then((response: JSX.Element) => {
        setExpandedContent(response);
      });
    }
  }, [rowExpanded, getTableRowExpandedData, row]);
  return (
    <>
      <TableRowGrid
        $rowClickable={onRowClick != null || getTableRowExpandedData != null}
        $borderColur={rowColour}
        $rowColour={row?.rowData?.rowColour}
        gap={8}
        template={row?.cells?.length}
        onClick={() => (getTableRowExpandedData ? handleExpandableRowClick() : onRowClick?.(row?.rowData))}
      >
        {getTableRowExpandedData && <RowArrow color={PRIMARY_GREEN} size={16} $rowExpanded={rowExpanded} />}
        {row?.cells?.map((cell: JSX.Element, i: number) => <Fragment key={i}>{cell}</Fragment>)}
      </TableRowGrid>
      {rowExpanded && (
        <ExpandedRow>
          {expandedContent ? (
            expandedContent
          ) : (
            <FlexLayout itemsX="center" itemsY="center" styled={{ padding: '24px 0' }}>
              <Spinner size={24} color={PRIMARY_PURPLE} />
            </FlexLayout>
          )}
        </ExpandedRow>
      )}
    </>
  );
};
