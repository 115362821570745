import styled from 'styled-components';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { UploaderInput } from '../../../uiComponents/inputs/uploaderInput/uploaderInput';
import { FlexContainer } from '../../../core/pageTitle/pageTitle.styles';

export const StyledSecondaryButton = styled(SecondaryButton)`
  margin-right: 10px;
`;

export const StyledTextField = styled(TextField)`
  flex: 1;
`;

export const StyledUploaderInput = styled(UploaderInput)`
  margin: 16px 0;
`;

export const StyledFieldContainer = styled(FlexContainer)`
  width: 100%;
  padding: 10px;
`;
