import React, { useCallback, useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DisabledPanel } from './disabledPanel';
import { OrderOptions, PartExchange, RequiredField, SendScrivePlaceholder } from '../order/order.styles';
import { DriverInfoCard } from '../../drivers/driver/driverInfoCard/driverInfoCard';
import { PageContainer } from '../../../core/coreStyles';
import {
  COURTESY,
  IN_SERVICE_RENTAL,
  OPT_FOR_CAREER_BOOST,
  PART_EXCHANGE,
  PRACTICAL_FAILED,
  PRACTICAL_PASSED,
  newOrderLondonTags,
  newOrderNationalTags,
} from '../../../consts';
import { APPLICATION_COMPLETED } from '../../../consts/application';
import {
  ASSESSMENT_STATUS_NOT_NEEDED,
  ONLINE_PASSED,
  ONLINE_PENDING,
  PRACTICAL_PENDING,
} from '../../../consts/assessment';
import { PROFILE_INCOMPLETE } from '../../../consts/driver';
import { ASSESSMENTS, COURTESY_ORDER, NEW_DRIVER, ORDERS } from '../../../consts/routes';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Text } from '../../../uiComponents/text/text';
import { TextInput } from '../../../uiComponents/inputs/textInput/textInput';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { getAllCities } from '../../../api/get/city.get';
import { getAllBranches } from '../../../api/get/branch.get';
import { getDriverByDVLA } from '../../../api/get/driver.get';
import { getAllDepartments } from '../../../api/get/employee.get';
import { createApplication } from '../../../api/post/application.post';
import { setPlan } from '../../../api/patch/application.patch';
import { createReassessmentType } from '../../../api/post/assessmentType';
import { AgreementType } from '../../../models/agreement';
import { CreateApplication } from '../../../models/application';
import { Assessment, AssessmentStatus } from '../../../models/assessment';
import { Branch } from '../../../models/branch';
import { City } from '../../../models/city';
import { CustomerProfile } from '../../../models/driver';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_30, STATUS_RED } from '../../../common/styles/Colors';
import { OptionList } from '../../../utils/props';

interface CreateOrderParams {
  driver?: CustomerProfile;
  dvla: string;
  driverDvlaNo: string;
  agreementType: AgreementType;
  servicingId: string;
  agreement_id: string;
}

export const CreateOrder = () => {
  const navigate = useNavigate();
  const {
    dvla,
    driverDvlaNo,
    agreementType,
    servicingId,
    agreement_id: agreementId,
  } = (useLocation()?.state as CreateOrderParams) ?? {};

  const { register, setValue, reset, watch, handleSubmit, control } = useForm<CreateApplication>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {},
  });

  const [cityOptions, setCityOptions] = useState<OptionList[]>([]);
  const [creatingOrder, setCreatingOrder] = useState<boolean>(false);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [department, setDepartment] = useState<OptionList[]>([]);
  const [driverDetails, setDriverDetails] = useState<CustomerProfile | undefined | null>(null);
  const [loadingDriverDetails, setLoadingDriverDetails] = useState<boolean>(false);
  const [showModalAssessmentError, setShowModalAssessmentError] = useState<JSX.Element>();
  const [tagOptions, setTagOptions] = useState<OptionList[]>(newOrderLondonTags);
  const selectedCity: string = watch('city');
  const selectedBranch: string | undefined = watch('branch_id');
  const selectedDepartment: string = watch('department');
  const partExchangeVrm: string | undefined = watch('part_exchange_vrm');
  const selectedTag: string | undefined = watch('tag');
  const cityBranches: Branch[] = branches?.filter((branch: Branch) => (branch?.city_id ?? '') === (selectedCity ?? ''));
  const cityBranchOptions: OptionList[] = cityBranches?.map((cityBranch: Branch) => {
    return { value: cityBranch?.branch_id, label: cityBranch?.branch_name };
  });

  const interimVehicle: boolean = agreementType === IN_SERVICE_RENTAL || agreementType === COURTESY;
  const latestAssessStatus: AssessmentStatus | undefined =
    driverDetails?.assessment_type_data?.[driverDetails?.assessment_type_data?.length - 1]?.assessment_status;
  const allowedAssessmentStatuses = [ASSESSMENT_STATUS_NOT_NEEDED, ONLINE_PASSED, PRACTICAL_PASSED];
  const assessmentValid: boolean =
    allowedAssessmentStatuses.includes(latestAssessStatus ?? '-') ||
    driverDetails?.application_status === APPLICATION_COMPLETED ||
    driverDetails?.ordway_customer_id != null;

  const [blockOrderCreation, setBlockOrderCreation] = useState<boolean>(false);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const citiesResponse: { count: number; data: City[] } = await getAllCities();
        const cityOptions: OptionList[] = citiesResponse?.data?.map((city: City) => {
          return {
            value: city.city_id,
            label: city.city_name,
          };
        });
        setCityOptions(cityOptions);
      } catch {}
    };
    const fetchBranches = async () => {
      try {
        const branchesResponse: { count: number; data: Branch[] } = await getAllBranches('filter=has_sales$eq=true');
        setBranches(branchesResponse?.data);
      } catch {}
    };
    const fetchDepartments = async () => {
      try {
        const { data } = await getAllDepartments();
        const departmentOption: OptionList[] = data.map((dept) => {
          return { value: dept.department, label: dept.department };
        });
        setDepartment(departmentOption);
      } catch {}
    };
    fetchCities();
    fetchBranches();
    fetchDepartments();

    getAllBranches('filter=has_sales$eq=true').then((response: { count: number; data: Branch[] }) => {
      setBranches(response?.data);
    });
  }, []);

  useEffect(() => {
    const cityName = cityOptions?.find((c) => c.value === selectedCity)?.label;
    if (!cityName || cityName === 'London') {
      setTagOptions(newOrderLondonTags);
    } else {
      setTagOptions(newOrderNationalTags);

      if (selectedTag?.includes(OPT_FOR_CAREER_BOOST)) {
        setValue('tag_type', [newOrderNationalTags[0]?.value]);
      }
    }
    if (selectedTag?.includes('none')) {
      reset({ tag_type: undefined });
    }
  }, [selectedCity, selectedTag, cityOptions, setValue, reset]);

  useEffect(() => {
    setValue('branch_id', undefined);
  }, [selectedCity, setValue]);

  const onSubmit = useCallback(
    (submitValues: CreateApplication) => {
      setCreatingOrder(true);
      if (driverDetails?.id && selectedBranch) {
        const payload: CreateApplication = {
          driver_id: driverDetails?.id,
          country_id: driverDetails?.country_id,
          branch_id: submitValues?.branch_id,
          city: submitValues?.city,
          department: submitValues?.department,
          tag_type: submitValues?.tag ? [submitValues?.tag] : [],
          agreementId: agreementId,
          servicing_id: servicingId,
          part_exchange_vrm: submitValues?.part_exchange_vrm,
          application_type: agreementType,
        };

        createApplication(payload).then(async (response: { data: { application_id: string } }) => {
          setCreatingOrder(false);
          if (agreementType === COURTESY) {
            navigate(`${COURTESY_ORDER}/${response?.data?.application_id}`, {
              state: { agreementType, ...driverDetails },
            });
          } else {
            if (agreementType === IN_SERVICE_RENTAL) {
              await setPlan(response?.data?.application_id, { application_type: IN_SERVICE_RENTAL });
            }
            navigate(`${ORDERS}/${response?.data?.application_id}`);
          }
        });
      }
    },
    [driverDetails, selectedBranch, agreementId, servicingId, agreementType, navigate]
  );

  const getDriverDetailsByDvla = useCallback(
    (dvla: string) => {
      setLoadingDriverDetails(true);
      setDriverDetails(undefined);
      getDriverByDVLA(dvla).then(
        (response: { data: CustomerProfile; message: undefined } | { message: 'OK'; data: undefined }) => {
          const recentAssessmentIndex: number = (response?.data?.assessment_type_data?.length ?? 0) - 1;
          const daysSinceAssessment: number = moment().diff(
            response?.data?.assessment_type_data?.[recentAssessmentIndex]?.created_date,
            'days'
          );
          setLoadingDriverDetails(false);
          if (response?.message === 'OK') {
            setDriverDetails(null);
            setShowModalAssessmentError(
              <ConfirmationModal
                title="Driver not found. Would you like to create a new driver profile?"
                isOpen
                confirmButtonCaption="Yes"
                closeButtonCaption="No"
                onConfirm={() => navigate(NEW_DRIVER, { state: { dvla_no: dvla } })}
                onClose={() => setShowModalAssessmentError(undefined)}
              />
            );
            return;
          }
          setDriverDetails(response?.data);
          const assessment: Assessment | undefined =
            response?.data?.assessment_type_data?.[response?.data?.assessment_type_data?.length - 1];
          const assessmentStatus: AssessmentStatus | undefined = assessment?.assessment_status;

          if (
            !response?.data?.assessment_type_data ||
            response?.data?.assessment_type_data?.length === 0 ||
            response?.data?.driver_profile_status === PROFILE_INCOMPLETE
          ) {
            setShowModalAssessmentError(
              <ConfirmationModal
                title="This driver's profile is incomplete. Would you like to complete it now?"
                confirmButtonCaption="Yes"
                closeButtonCaption="No"
                onClose={() => setShowModalAssessmentError(undefined)}
                onConfirm={() => navigate(NEW_DRIVER, { state: { dvla_no: response?.data?.dvla_no } })}
                isOpen
              />
            );
          } else if (assessmentStatus === ONLINE_PENDING || assessmentStatus === PRACTICAL_PENDING) {
            setShowModalAssessmentError(
              <ConfirmationModal
                title="This driver has not completed an assessment. Would you like to go to assessments now?"
                closeButtonCaption="No"
                confirmButtonCaption="Yes"
                onClose={() => setShowModalAssessmentError(undefined)}
                onConfirm={() => navigate(ASSESSMENTS)}
                isOpen
              />
            );
          } else if (assessmentStatus === PRACTICAL_FAILED) {
            if (daysSinceAssessment < 90) {
              setShowModalAssessmentError(
                <ConfirmationModal
                  title={`This driver has failed their practical assessment as is not eligible for a vehicle right now, but will be eligible for re-assessment ${
                    90 - daysSinceAssessment
                  } days from now.`}
                  onConfirm={() => setShowModalAssessmentError(undefined)}
                  confirmButtonCaption="OK"
                  isOpen
                />
              );
            } else {
              setShowModalAssessmentError(
                <ConfirmationModal
                  title="This driver has failed their practical assessment but is eligible for re-assessment. Would you like to create a new practical assessment now?"
                  preConfirm={() =>
                    createReassessmentType({
                      assessment_id: response?.data?.assessment_type_data?.[recentAssessmentIndex]?.assessment_id ?? '',
                      evaluation_type: 'PRACTICAL',
                      notes: 'Eligible for re-assessment.',
                    }).then(() => {
                      setShowModalAssessmentError(undefined);
                      navigate(ASSESSMENTS);
                    })
                  }
                  onClose={() => setShowModalAssessmentError(undefined)}
                  confirmButtonCaption="Yes"
                  closeButtonCaption="No"
                  isOpen
                />
              );
            }
          }
        }
      );
    },
    [navigate]
  );

  useEffect(() => {
    if (dvla) {
      getDriverDetailsByDvla(dvla);
    }
  }, [dvla, getDriverDetailsByDvla]);

  useEffect(() => {
    if (driverDvlaNo && interimVehicle) {
      getDriverDetailsByDvla(driverDvlaNo);
    }
  }, [driverDvlaNo, agreementType, interimVehicle, getDriverDetailsByDvla]);

  return (
    <PageContainer>
      <FlexLayout itemsX="end">
        <SecondaryButton onClick={() => window.history.back()} styled={{ width: 152 }}>
          Back
        </SecondaryButton>
      </FlexLayout>
      <FlexLayout styled={{ marginTop: 16 }} gap={16} itemsY="start">
        <DriverInfoCard
          driver={driverDetails}
          orderProgress={0}
          loadingDriverDetails={loadingDriverDetails}
          onDvlaInputBlur={(dvlaNo: string) => getDriverDetailsByDvla(dvlaNo)}
          setBlockOrderCreation={setBlockOrderCreation}
        />
        <OrderOptions>
          <Text variant="h4" weight={800} color={PRIMARY_PURPLE} block>
            Create order
          </Text>
          <GridLayout
            styled={{
              marginTop: 24,
              opacity: assessmentValid ? 1 : 0.3,
              pointerEvents: assessmentValid ? 'inherit' : 'none',
            }}
            template={interimVehicle ? 2 : 3}
            gap={16}
          >
            {!interimVehicle && (
              <div>
                <Text color={PRIMARY_PURPLE} variant="body6" weight={500} block>
                  Tags
                </Text>
                <DropDown
                  styled={{ marginTop: 8 }}
                  name="tag"
                  placeholder="Select tag"
                  options={tagOptions}
                  control={control as unknown as Control<FieldValues>}
                />
                {selectedTag?.includes(OPT_FOR_CAREER_BOOST) && (
                  <Text styled={{ marginTop: 8 }} variant="body8" weight={300} color={STATUS_RED} block>
                    Note that if you choose Opt for career boost you will only be able to select the PCO car hire plan.
                  </Text>
                )}
                {selectedTag?.includes(PART_EXCHANGE) && (
                  <PartExchange>
                    <RequiredField color={PRIMARY_PURPLE} variant="body6" weight={500} block>
                      Part exchange VRM
                    </RequiredField>
                    <TextInput
                      styled={{ marginTop: 8 }}
                      {...register('part_exchange_vrm')}
                      placeholder="VRM"
                      type="text"
                      name="part_exchange_vrm"
                      required
                    />
                  </PartExchange>
                )}
                {selectedTag?.includes(PART_EXCHANGE) && (
                  <Text styled={{ marginTop: 8 }} variant="body8" weight={300} color={STATUS_RED} block>
                    Note that if you choose Part exchange you won't be able to select the PCO car hire plan.
                  </Text>
                )}
              </div>
            )}
            <div>
              <RequiredField color={PRIMARY_PURPLE} variant="body6" weight={500} block>
                Sales location
              </RequiredField>
              <DropDown
                styled={{ marginTop: 8 }}
                name="city"
                placeholder="Select a city"
                control={control as unknown as Control<FieldValues>}
                options={cityOptions}
                required={{ required: 'City is required' }}
                value={selectedCity}
              />
            </div>
            <div>
              <RequiredField color={PRIMARY_PURPLE} variant="body6" weight={500} block>
                Branch
              </RequiredField>
              <DropDown
                styled={{ marginTop: 8 }}
                name="branch_id"
                required={{ required: 'Branch is required' }}
                placeholder="Select a branch"
                options={cityBranchOptions}
                value={selectedBranch}
                control={control as unknown as Control<FieldValues>}
              />
            </div>
            {agreementType === COURTESY && (
              <div>
                <RequiredField color={PRIMARY_PURPLE} variant="body6" weight={500} block>
                  Department
                </RequiredField>
                <DropDown
                  styled={{ marginTop: 8 }}
                  name="department"
                  required={{ required: 'Department is required' }}
                  placeholder="Select a department"
                  options={department}
                  value={selectedDepartment}
                  control={control as unknown as Control<FieldValues>}
                />
              </div>
            )}
          </GridLayout>
          <FlexLayout itemsX="end" styled={{ marginTop: 48 }}>
            <PrimaryButton
              onClick={handleSubmit(onSubmit)}
              styled={{ width: 152 }}
              disabled={
                !selectedBranch ||
                !selectedCity ||
                (selectedTag?.includes(PART_EXCHANGE) && partExchangeVrm === '') ||
                blockOrderCreation
              }
              isProcessing={creatingOrder}
            >
              Submit
            </PrimaryButton>
          </FlexLayout>
          <div>
            <DisabledPanel title="Driver details" subTitle="-" />
            <DisabledPanel title="Assessment" subTitle="-" />
            <DisabledPanel title="Plan" subTitle="Choose" />
            <DisabledPanel title="Driver details" subTitle="Choose" />
            <DisabledPanel title="Choose model" subTitle="Choose" />
            <DisabledPanel title="Assign vehicle" subTitle="Choose" />
            <DisabledPanel title="Payment method" subTitle="Add card" />
            <DisabledPanel title="Downpayment" subTitle="None" />
            <DisabledPanel title="Declaration" subTitle="Give consent" />
            <DisabledPanel title="EV packages" subTitle="Choose" />
            <DisabledPanel title="Check DVLA" subTitle="-" />
            <SendScrivePlaceholder itemsX="center" itemsY="center">
              <Text variant="body7" color={SECONDARY_PURPLE_30} weight={500}>
                Send scrive agreement
              </Text>
            </SendScrivePlaceholder>
          </div>
        </OrderOptions>
      </FlexLayout>
      {showModalAssessmentError}
    </PageContainer>
  );
};
