import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { AGREEMENTS } from '../../../../consts/routes';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { Text } from '../../../../uiComponents/text/text';
import { Table } from '../../../../uiComponents/table/table';
import { driverAgreementColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import {
  TableTextCell,
  TableTagCell,
  getQueryString,
  DEFAULT_NUM_ROWS_PER_PAGE,
} from '../../../../uiComponents/table/tableUtils/tableUtils';
import { getAllAgreements } from '../../../../api/get/agreement.get';
import { Agreement } from '../../../../models/agreement';
import { DriverTableProps } from '../../../../models/driver';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { AGREEMENT_TERMINATED } from '../../../../consts/agreement';

interface DriverAgreementsProps extends DriverTableProps {
  tableHeader: string;
}

export const DriverAgreements = ({ driverId, tableHeader }: DriverAgreementsProps) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
    tableFilters,
  } = useTableFilters();

  const handleGetAgreementsResponse = useCallback(
    (count: number, agreements: Agreement[]) => {
      const agreementRows = agreements?.map((agreement: Agreement) => {
        return {
          rowData: { data: agreement },
          cells: [
            <TableTextCell value={agreement?.agreement_type ?? '-'} />,
            <TableTextCell value={agreement?.vrm ?? '-'} />,
            <TableTextCell value={agreement?.total_due ?? '-'} />,
            <TableTextCell value={agreement?.start_date ? moment(agreement?.start_date).format('DD MMM YYYY') : '-'} />,
            <TableTextCell
              value={
                agreement?.agreement_status === AGREEMENT_TERMINATED && agreement?.termination_date
                  ? moment(agreement?.termination_date).format('DD MMM YYYY')
                  : agreement?.end_date
                    ? moment(agreement?.end_date).format('DD MMM YYYY')
                    : '-'
              }
            />,
            <TableTagCell tags={[agreement?.agreement_status] ?? []} />,
          ],
        };
      });
      setTableData(agreementRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const fetchAgreementList = useCallback(
    (queryString: string) => {
      const controller = new AbortController();
      getAllAgreements(queryString, controller.signal).then((response: { count: number; data: Agreement[] }) => {
        handleGetAgreementsResponse(response.count, response.data);
      });
    },
    [handleGetAgreementsResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      const queryString = getQueryString(tableFilters, rowsPerPage, pageNumber, '', sortingColumn, sortAscending);
      fetchAgreementList(queryString);
    },
    [fetchAgreementList, setTableData, tableFilters]
  );

  useEffect(() => {
    setSortingColumn('start_date');
    fetchAgreementList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=start_date:DESC&filter=driver_id$eq=${driverId}`);
    setTableFilters([{ columnName: 'driver_id', options: { label: driverId, value: driverId } }]);
  }, [fetchAgreementList, setSortingColumn, setTableFilters, driverId]);

  return (
    <CollapsiblePanel
      header={
        <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
          {tableHeader}
        </Text>
      }
      expanded={!isCollapsed}
      onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
      styled={{ marginTop: 16 }}
    >
      <div>
        <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
          {tableHeader}
        </Text>
        <Table
          variant="compact"
          tableTheme="purple"
          embedded
          onColumnHeaderClick={(columnId: string) =>
            applyFilters(pageNumber, numRowsPerPage, columnId, getSortDirection(columnId))
          }
          sortAscending={sortAscending}
          columns={driverAgreementColumns}
          rows={tableData}
          totalRows={totalRows}
          rowsPerPage={numRowsPerPage}
          currentPageNumber={pageNumber}
          sortingColumn={sortingColumn}
          filters={[]}
          filterQuery={filterQuery}
          goToPage={(pageNumber: number) => {
            goToPageNumber(pageNumber);
            applyFilters(pageNumber, numRowsPerPage, sortingColumn, sortAscending);
          }}
          onRowClick={(agreement: { data: Agreement }) => navigate(`${AGREEMENTS}/${agreement?.data?.agreement_id}`)}
          onNumRowsPerPageChange={(value: number) => {
            setNumRowsPerPage(value);
            goToPageNumber(0);
            applyFilters(0, value, sortingColumn, sortAscending);
          }}
        />
      </div>
    </CollapsiblePanel>
  );
};
