import axios, { AxiosError, AxiosResponse } from 'axios';
import { getFromStorage } from './utils';
import { Notification } from '../uiComponents/toast/toast';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  timeout: +(process.env.REACT_APP_API_TIMEOUT ?? '0'),
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const showToastMessage = (message: string) => {
  Notification({
    type: 'error',
    title: 'Error',
    message: message,
  });
};

instance.interceptors.request.use(
  (req) => {
    const conf = req;
    conf.headers.Authorization = getFromStorage('jwt');
    return conf;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with response data
    return response.data;
  },
  (error: AxiosError<{ errors: AxiosError[]; message: string }>) => {
    // Do something with response error
    let extraDetails = '';
    error.name ? (extraDetails += `Error name: ${error.name}\n`) : '';
    error.message ? (extraDetails += `Error message: ${error.message}\n`) : '';
    error.code ? (extraDetails += `Error code: ${error.code}\n`) : '';
    error.stack ? (extraDetails += `Error stack: ${error.stack}\n`) : '';

    if (error.response) {
      switch (error.response.status) {
        case 401:
          showToastMessage(
            error.response && error.response.data && error.response.data.message
              ? `Error 401 ${error.response.data.message}\n ${extraDetails}`
              : `Error 401 User not authenticated.\n ${extraDetails}`
          );
          localStorage.removeItem('jwt');
          localStorage.removeItem('superAdmin');
          localStorage.removeItem('permissions');
          if (document.location.pathname !== '/login') {
            document.location.replace('/login');
          }
          // logout the
          return Promise.reject(error);
        case 400:
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            error.response.data.errors.forEach((e: AxiosError) => {
              showToastMessage(`Error 400 ${e.message}\n ${e.stack}`);
            });
            return Promise.reject(error);
          }
          showToastMessage(`Error 400 ${error.response.data.message}\n ${extraDetails}`);
          return Promise.reject(error);
        case 404:
          showToastMessage(`Error 404 ${error.response.data.message}\n ${extraDetails}`);
          return Promise.reject(error);
        case 409:
          showToastMessage(`Error 409 ${error.response.data.message}\n ${extraDetails}`);
          return Promise.reject(error);
        case 413:
          showToastMessage(`Error 413 ${error.response.data.message}\n ${extraDetails}`);
          return Promise.reject(error);
        case 500:
          showToastMessage(`Error 413 ${error.response.data.message ?? error.response.statusText}\n ${extraDetails}`);
          return Promise.reject(error);
        default:
          showToastMessage(`Error ${error.response.status} ${error.response.data.message}\n ${extraDetails}`);

          return Promise.reject(error);
      }
    }

    if (error.message === 'canceled') {
      return Promise.reject(error);
    }

    showToastMessage(`error.response is null ${error.message}\n ${extraDetails}`);
    return Promise.reject(error);
  }
);

export default instance;
