import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { TextField } from '../../uiComponents/inputs/textField/textField';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from '../../uiComponents/buttons/primaryButton/primaryButton';
import { useState } from 'react';
import { Text } from '../../uiComponents/text/text';
import { STATUS_RED } from '../../common/styles/Colors';
import { changePassword } from '../../api/patch/employee.patch';
import { Notification } from '../../uiComponents/toast/toast';

interface PasswordForm {
  newPassword: string;
  confirmPassword: string;
}

interface ChangePasswordFormProps {
  onClose: () => void;
}

export const ChangePasswordForm = ({ onClose }: ChangePasswordFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<PasswordForm>({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });
  const [processing, setProcessing] = useState<boolean>(false);
  const [matchError, setMatchError] = useState<boolean>(false);

  const onSubmit = (values: PasswordForm) => {
    if (values.newPassword !== values.confirmPassword) {
      setMatchError(true);
    } else {
      setProcessing(true);
      setMatchError(false);
      changePassword({ password: values.newPassword }).then(() => {
        setProcessing(false);
        Notification({
          type: 'success',
          title: 'Password changed',
          message: 'Your password was successfully changed',
        });
        onClose();
      });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {matchError && (
        <Text variant="body7" weight={300} color={STATUS_RED} block styled={{ marginBottom: 8 }}>
          Passwords do not match.
        </Text>
      )}
      <TextField
        label="New password"
        type="password"
        {...register('newPassword', {
          required: 'This is a required field',
          minLength: { value: 5, message: 'Password should be at least 5 characters long.' },
        })}
        error={errors.newPassword}
      />
      <TextField
        label="Confirm password"
        type="password"
        {...register('confirmPassword', {
          required: 'This is a required field',
          minLength: { value: 5, message: 'Password should be at least 5 characters long.' },
        })}
        error={errors.confirmPassword}
        styled={{ marginTop: 16 }}
      />
      <FlexLayout itemsX="end" styled={{ marginTop: 16 }}>
        <PrimaryButton onClick={handleSubmit(onSubmit)} isProcessing={processing}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </form>
  );
};
