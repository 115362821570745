import { InsurancePolicyDocuments, ModifiedInsurancePolicy } from '../../models/insurancePolicy';
import api from '../../utils/api';

/**
 * Get all insurance policies request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllInsurancePolices(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllInsurancePolices = (
  query: string = ''
): Promise<{ count: number; data: ModifiedInsurancePolicy[] }> => api.get(`insurance/policy${query && `?${query}`}`);

/**
 * Check if policy number exists
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * isPolicyNumberExistsing(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const isPolicyNumberExistsing = (policyNumber: string): Promise<{ data: { exists: boolean } }> =>
  api.get(`insurance/policy/${policyNumber}/check`);

/**
 * Get insurance policy docs
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getInsurancePolicyDocuments(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getInsurancePolicyDocuments = (policyId: string): Promise<{ data: InsurancePolicyDocuments[] }> =>
  api.get(`insurance/policy/${policyId}/documents`);
