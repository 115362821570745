import React from 'react';
import { LoginBackground, LoginContainer, OttoLogo, Form } from './login.styles';
import { Outlet } from 'react-router-dom';

export const LoginLayout = () => {
  return (
    <LoginContainer>
      <Form>
        <OttoLogo src="/logo_white.svg" width={154} />
        <Outlet />
      </Form>
      <LoginBackground src="/images/loginCurve.svg" width="100%" />
    </LoginContainer>
  );
};
