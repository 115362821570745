import { OptionList } from '../utils/props';

export const DRIVER_SIGNATURE = 'DRIVER-SIGNATURE';
export const CONTRACT = 'CONTRACT';
export const SCRIVE_CONTRACT = 'SCRIVE-CONTRACT';
export const PAYMENT_PLAN = 'PAYMENT-PLAN';
export const PERMISSION_LETTERS = 'PERMISSION-LETTERS';
export const DECLARATION_SIGNATURE = 'DECLARATION-SIGNATURE';
export const FRONT_BUMPER = 'FRONT-BUMPER';
export const WINDSCREEN = 'WINDSCREEN';
export const DRIVER_FC = 'DRIVER-FC';
export const DRIVER_RC = 'DRIVER-RC';
export const PASSENGER_FC = 'PASSENGER-FC';
export const PASSENGER_RC = 'PASSENGER-RC';
export const REAR_BUMPER = 'REAR-BUMPER';
export const ROOF = 'ROOF';
export const BOOT = 'BOOT';
export const INTERNAL_1 = 'INTERNAL-1';
export const INTERNAL_2 = 'INTERNAL-2';
export const ODOMETER = 'ODOMETER';
export const CONDITION_REPORT = 'CONDITION-REPORT';
export const INTERIOR_1 = 'INTERIOR-1';
export const INTERIOR_2 = 'INTERIOR-2';
export const TELEMATICS = 'TELEMATICS';
export const SEATBELTS = 'SEATBELTS';
export const DRIVER_FA = 'DRIVER-FA';
export const DRIVER_RA = 'DRIVER-RA';
export const PASSENGER_FA = 'PASSENGER-FA';
export const PASSENGER_RA = 'PASSENGER-RA';
export const SIGNATUER = 'SIGNATURE';
export const DVLA_FRONT = 'DVLA-FRONT';
export const DVLA_BACK = 'DVLA-BACK';
export const PCO = 'PCO';
export const PCO_CERT = 'PCO-CERT';
export const POA = 'POA';
export const COUNTERPART = 'COUNTERPART';
export const PHOTO = 'PHOTO';
export const PERMISSION = 'PERMISSION';
export const PRACTICAL_ASSESSMENT = 'PRACTICAL-ASSESSMENT';
export const INSURANCE = 'INSURANCE';
export const V5C = 'V5C';
export const PHV = 'PHV';
export const PIN_SETUP = 'PIN-SETUP';
export const MOT = 'MOT';
export const SERVICE_INVOICE = 'SERVICE-INVOICE';
export const REPAIR_INVOICE = 'REPAIR-INVOICE';
export const MOT_INVOICE = 'MOT-INVOICE';
export const WARRANTY_INVOICE = 'WARRANTY-INVOICE';
export const ROAD_TAX = 'ROAD-TAX';
export const WARRANTY = 'WARRANTY';
export const VEHICLE_DOCUMENTS = 'VEHICLE-DOCUMENTS';
export const LICENSE_SUMMARY = 'LICENSE-SUMMARY';

const agrDocList: OptionList[] = [
  { value: 'CONTRACT', label: 'Scrive contract' },
  { value: 'PAYMENT-PLAN', label: 'Payment plan' },
  {
    value: 'PERMISSION-LETTERS',
    label: 'Permission letter',
  },
  { value: 'INSURANCE', label: 'Insurance' },
];

const driverDocList: OptionList[] = [
  { value: 'DVLA-FRONT', label: 'DVLA front' },
  { value: 'DVLA-BACK', label: 'DVLA back' },
  { value: 'PCO', label: 'PCO license' },
  { value: 'PCO-CERT', label: 'PCO certificate' },
  { value: 'POA', label: 'Proof of address' },
  { value: 'SIGNATURE', label: 'Signature' },
  {
    value: 'PRACTICAL-ASSESSMENT',
    label: 'Practical assessment',
  },
];

const vehDocList: OptionList[] = [
  {
    value: MOT,
    label: MOT,
  },
  {
    value: V5C,
    label: V5C,
  },
  {
    value: PHV,
    label: PHV,
  },
  {
    value: INSURANCE,
    label: 'Insurance',
  },
  {
    value: SERVICE_INVOICE,
    label: 'Service invoice',
  },
  {
    value: WARRANTY,
    label: 'Warranty',
  },
  {
    value: PIN_SETUP,
    label: 'Pin setup',
  },
  {
    value: REPAIR_INVOICE,
    label: 'Repair invoice',
  },
  {
    value: WARRANTY_INVOICE,
    label: 'Warranty invoice',
  },
  {
    value: MOT_INVOICE,
    label: 'MOT invoice',
  },
  {
    value: ROAD_TAX,
    label: 'Road tax',
  },
];

export const docList: Record<'AGREEMENT' | 'DRIVER' | 'VEHICLE', OptionList[]> = {
  AGREEMENT: agrDocList,
  DRIVER: driverDocList,
  VEHICLE: vehDocList,
};

export const OTTOCAR_INSURANCE = 'OTTOCAR-INSURANCE';
export const ALTERNATIVE_INSURANCE = 'ALTERNATIVE-INSURANCE';

export const insuranceTypes: OptionList[] = [
  {
    value: OTTOCAR_INSURANCE,
    label: 'Otto Car insurance',
  },
  {
    value: ALTERNATIVE_INSURANCE,
    label: 'Alternative insurance',
  },
];
