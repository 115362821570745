import { SideNav } from '../sideNav/sideNav';
import { PowerBi } from '../..//powerBi/powerBi';

export const Dashboard = () => {
  return (
    <>
      <SideNav />
      <PowerBi
        accessToken="accessToken"
        reportId="reportId"
        embedUrl="https://app.powerbi.com/view?r=eyJrIjoiZmRkNTY4NDUtY2MzOC00MmM5LTg5NjEtMzU1OTY0NjQwZDg2IiwidCI6IjFlOTBjMmE0LTRjMDYtNDczZS1hNDg4LWY0NjMxNDIyMDMwNSIsImMiOjh9"
      />
    </>
  );
};
